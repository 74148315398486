@charset "utf-8";
/* less引入 */
/* var */
/* color */
/* font */
/* height & lineheight */
/* tips */
/* pie */
/* other */
/* radio & checkbox */
/* line */
/* btn */
/*input*/
/*table*/
/* css reset */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section,
summary,
time,
mark,
audio,
video,
main {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  background: transparent;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
svg:not(:root) {
  overflow: hidden;
}
table {
  border-collapse: separate;
  border-spacing: 0;
  font-size: inherit;
}
fieldset,
img {
  border: 0;
}
ol,
ul,
li {
  list-style: none outside none;
}
address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-weight: normal;
  font-style: normal;
}
caption,
th {
  text-align: left;
}
q:before,
q:after {
  content: '';
}
abbr,
acronym {
  border: 0;
}
img {
  width: auto \9;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic;
}
button,
input,
select,
textarea {
  color: inherit;
  font-size: 100%;
  font-family: inherit;
  font-weight: inherit;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
  font-family: inherit;
  font-weight: normal;
  line-height: 1.5;
}
button {
  overflow: visible;
  outline: 0 none;
}
button,
select {
  text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
button.disabled,
html input[disabled] {
  cursor: default;
  cursor: not-allowed;
  background: #fafafa;
  color: #666666;
  border: 1px solid #e5e5e5;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input {
  line-height: normal;
  /*-webkit-appearance:none;*/
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  cursor: pointer;
  margin: 4px 0 0;
  height: initial;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
input[readonly="readonly"] {
  cursor: default;
  cursor: not-allowed;
  background: #e5e5e5;
  color: #666666;
}
input::-moz-placeholder,
input:-ms-input-placeholder,
input::-webkit-input-placeholder {
  color: #666666;
}
textarea::-moz-placeholder,
textarea:-ms-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #666666;
}
select[multiple],
select[size] {
  height: auto;
}
/* global */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
*::after,
*::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  font-size: 100px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  font-size: 14px;
  font-family: Arial, "\5FAE\8F6F\96C5\9ED1", "Hiragino Sans GB", "Microsoft YaHei", "STHeiti", "WenQuanYi Micro Hei", "SimSun", "sans-serif";
  line-height: 1.4;
  color: #666666;
  background-color: #ffffff;
  height: 100%;
}
h1 {
  font-size: 30px;
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 20px;
}
h4 {
  font-size: 16px;
}
h5 {
  font-size: 14px;
}
h6 {
  font-size: 12px;
}
sub,
sup {
  font-size: 0.75em;
  height: 0;
  line-height: 1;
  position: relative;
  vertical-align: baseline;
  _vertical-align: bottom;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
em {
  font-style: normal;
}
i {
  font-style: italic;
}
strong {
  font-weight: bold;
}
b {
  font-weight: normal;
}
p {
  margin-bottom: 0;
}
a {
  color: #51a3ff;
  text-decoration: none;
  background: transparent;
  cursor: pointer;
  outline: 0 none;
  -webkit-tap-highlight-color: transparent;
  -webkit-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
a:hover {
  color: #51a3ff;
  text-decoration: none;
  outline: 0 none;
}
a:focus {
  text-decoration: none;
  outline: 0 none;
}
.show {
  display: block !important;
}
.hide {
  display: none !important;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.vertical-inline-block {
  vertical-align: middle;
  display: inline-block;
}
.pull-left {
  float: left !important;
}
.pull-left .question-tip {
  display: block;
  margin: 6px 10px;
}
.pull-right {
  float: right !important;
}
.margin-bottom10 {
  margin-bottom: 10px !important;
}
.margin-left20 {
  margin-left: 20px !important;
}
.margin-right20 {
  margin-right: 20px !important;
}
.margin-right40 {
  margin-right: 40px !important;
}
.margin-top20 {
  margin-top: 20px;
}
.col-12 {
  width: 100%;
}
.col-11 {
  width: 91.66666667%;
}
.col-10 {
  width: 83.33333333%;
}
.col-9 {
  width: 75%;
}
.col-8 {
  width: 66.66666667%;
}
.col-7 {
  width: 58.33333333%;
}
.col-6 {
  width: 50%;
}
.col-5 {
  width: 41.66666667%;
}
.col-4 {
  width: 33.33333333%;
}
.col-3 {
  width: 25%;
}
.col-2 {
  width: 16.66666667%;
}
.col-1 {
  width: 8.33333333%;
}
.blue {
  color: #51a3ff;
}
.green {
  color: #1abc9c;
}
.orange {
  color: #f39c12;
}
.red {
  color: #e74c3c;
}
.grey {
  color: #cdcdcd;
}
.bg-green,
.bg-red,
.bg-blue,
.bg-qing,
.bg-orange,
.bg-grey,
.bg-critical,
.bg-moderate,
.bg-lightgrey,
.bg-low,
.bg-Purple {
  font-size: 12px;
  line-height: 1;
  padding: 5px 6px;
  color: #ffffff;
  border-radius: 2px;
  display: inline-block;
  white-space: nowrap;
}
.bg-green {
  background-color: #1abc9c;
}
.bg-red {
  background-color: #e74c3c;
}
.bg-blue {
  background-color: #51a3ff;
}
.bg-orange {
  background-color: #f39c12;
}
.bg-grey {
  background-color: #666666;
}
.bg-qing {
  background-color: #4a6583;
}
.bg-critical {
  background-color: #e74c3c;
}
.bg-moderate {
  background-color: #f39c12;
}
.bg-low {
  background-color: #ebd519;
}
.bg-lightgrey {
  color: #666666;
  background-color: #dfdfdf;
}
.bg-Purple {
  background-color: #655CE6;
}
.notNull {
  font-family: '\5FAE\8F6F\96C5\9ED1', "Microsoft YaHei";
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: #e74c3c;
}
.nav:before,
.nav:after,
.clearFix:before,
.clearFix:after,
.form-group:before,
.form-group:after,
.box:before,
.box:after,
.layout-grid:before,
.layout-grid:after,
.table-action:before,
.table-action:after,
.control-group:before,
.control-group:after,
.control-items2:before,
.control-items2:after,
.control-items3:before,
.control-items3:after,
.btn-item-group:before,
.btn-item-group:after {
  content: "";
  display: table;
}
.nav:after,
.clearFix:after,
.form-group:after,
.box:after,
.layout-grid:after,
.table-action:after,
.control-group:after,
.control-items2:after,
.control-items3:after,
.btn-item-group:after {
  clear: both;
  overflow: hidden;
}
.nav,
.clearFix,
.form-group,
.box,
.layout-grid,
.table-action,
.control-group,
.control-items3,
.btn-item-group {
  zoom: 1;
  /* IE < 8 */
}
.container {
  min-width: 1200px;
  margin: 0 auto;
}
.clear {
  clear: both;
  height: 0;
  overflow: hidden;
}
.form-controls,
.form-control,
input[type="text"],
input[type="password"] {
  font-size: 14px;
  width: 100%;
  height: 28px;
  line-height: 28px;
  padding: 0 10px;
  border: 1px solid #e5e5e5;
  font-weight: normal;
  background: #ffffff;
  color: #666666;
  border-radius: 2px;
  box-shadow: none;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.form-controls.error,
.form-control.error,
input[type="text"].error,
input[type="password"].error {
  border: 1px solid #e74c3c;
  color: #e74c3c;
}
.form-controls:focus,
.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus {
  border-color: #51a3ff;
  box-shadow: none;
  outline: 0 none;
}
.form-controls::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder,
input[type="text"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder {
  color: #999999;
}
.form-controls.input-mini,
.form-control.input-mini,
input[type="text"].input-mini,
input[type="password"].input-mini {
  width: 100px;
}
.form-controls.input-sm,
.form-control.input-sm,
input[type="text"].input-sm,
input[type="password"].input-sm {
  width: 200px;
}
.form-controls.input-lg,
.form-control.input-lg,
input[type="text"].input-lg,
input[type="password"].input-lg {
  width: 500px;
}
textarea.form-controls {
  height: 90px;
  resize: none;
  line-height: 20px;
  padding: 8px 10px;
  vertical-align: top;
}
textarea.form-controls.min {
  height: 60px;
}
.control-group {
  margin-bottom: 10px;
}
.control-group.no-margin {
  margin-bottom: 0;
}
.control-group .control-label {
  float: left;
  width: 17%;
  margin-bottom: 0;
  line-height: 28px;
  text-align: right;
  font-weight: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
.control-group .controls {
  position: relative;
  float: left;
  padding-left: 25px;
  width: 80%;
}
.control-group .controls > i {
  position: absolute;
  left: 4px;
  line-height: 32px;
}
.control-group .controls > i.network-question-tip {
  line-height: 28px;
}
.control-group .controls .checkbox {
  padding-top: 4px;
  line-height: 20px;
  height: 28px;
  margin: 0;
  cursor: pointer;
  position: relative;
  font-weight: normal;
}
.control-group .controls .checkbox i,
.control-group .controls .checkbox .iconfont {
  margin-right: 5px;
  color: #c2d3eb;
  width: 20px;
  height: 18px;
  font-size: 16px;
  vertical-align: middle;
  font-style: normal;
}
.control-group .controls .checkbox i:before,
.control-group .controls .checkbox .iconfont:before {
  content: "\E6B1";
  font-weight: lighter;
}
.control-group .controls .checkbox input {
  position: absolute;
  left: -9999px;
  opacity: 0;
}
.control-group .controls .checkbox input:checked + i:before {
  content: "\E825";
  color: #4e80f5;
}
.control-group .controls .checkbox input:indeterminate + i:before {
  content: "\E6B0";
  color: #4e80f5;
}
.control-group .controls .checkbox.switch-btn {
  display: inline-block;
  height: 34px;
  padding-top: 5px;
}
.control-group .controls .checkbox.switch-btn label i,
.control-group .controls .checkbox.switch-btn label .iconfont {
  width: 54px;
  height: 24px;
  background: #efefef;
  border: 1px solid #e5e5e5;
  position: relative;
  display: inline-block;
  border-radius: 12px;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -ms-transition: 0.4s;
  transition: 0.4s;
}
.control-group .controls .checkbox.switch-btn label input + i,
.control-group .controls .checkbox.switch-btn label input + .iconfont {
  margin-right: 0;
}
.control-group .controls .checkbox.switch-btn label input + i:before,
.control-group .controls .checkbox.switch-btn label input + .iconfont:before {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  text-align: center;
  border-radius: 100%;
  left: 3px;
  top: 2px;
  z-index: 2;
  background: #ffffff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -ms-transition: 0.4s;
  transition: 0.4s;
}
.control-group .controls .checkbox.switch-btn label input + i:hover:before,
.control-group .controls .checkbox.switch-btn label input + .iconfont:hover:before {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.control-group .controls .checkbox.switch-btn label input:checked + .iconfont {
  background: #3b78e7;
  border: 1px solid #0064b6;
}
.control-group .controls .checkbox.switch-btn label input:checked + .iconfont:before {
  content: '';
  left: 31px;
  background: #ffffff;
}
.control-group .controls .checkbox .send-btn {
  margin-left: 10px;
  vertical-align: top;
  cursor: pointer;
}
.control-group .controls .checkbox .send-btn.send-active {
  color: #0085FE;
}
.control-group .controls .checkbox .verify-status {
  margin-left: 40px;
  vertical-align: top;
}
.control-group .controls .checkbox span.success {
  color: #0DD484;
}
.control-group .controls .checkbox span.fail {
  color: #e74c3c;
}
.control-group .controls .radio-item {
  display: block;
  margin: 0;
  position: relative;
  height: 20px;
  padding-top: 4px;
}
.control-group .controls .radio-item label {
  display: inline-block;
  cursor: pointer;
  font-weight: normal;
  margin-bottom: 0;
  min-height: 20px;
  line-height: 20px;
  padding-left: 0px;
  margin-right: 0px;
}
.control-group .controls .radio-item label i,
.control-group .controls .radio-item label .iconfont {
  font-size: 16px;
  width: 20px;
  height: 20px;
  font-style: normal;
}
.control-group .controls .radio-item label + label {
  margin-left: 10px;
}
.control-group .controls .radio-item label input {
  position: absolute;
  left: -9999px;
  opacity: 0;
  filter: alpha(opacity=0);
}
.control-group .controls .radio-item label input[type="checkbox"] + i,
.control-group .controls .radio-item label input[type="checkbox"] + .iconfont {
  margin-right: 2px;
  float: left;
}
.control-group .controls .radio-item label input[type="checkbox"] + i:before,
.control-group .controls .radio-item label input[type="checkbox"] + .iconfont:before {
  content: "\E6B1";
  color: #c2d3eb;
}
.control-group .controls .radio-item label input[type="checkbox"]:checked + i:before {
  content: "\E825";
  color: #4e80f5;
}
.control-group .controls .radio-item label input[type="radio"] + i,
.control-group .controls .radio-item label input[type="radio"] + .iconfont {
  margin-right: 2px;
  display: inline-block;
}
.control-group .controls .radio-item label input[type="radio"] + i:before,
.control-group .controls .radio-item label input[type="radio"] + .iconfont:before {
  content: "\E6B4";
  color: #c2d3eb;
}
.control-group .controls .radio-item label input[type="radio"]:checked + i:before {
  content: "\E6B3";
  color: #4e80f5;
}
.control-group .controls .error {
  color: #e74c3c;
  line-height: 1.5;
  font-size: 12px;
}
.control-group .controls .tips {
  color: #e74c3c;
}
.control-group .controls .tips a {
  font-style: italic;
  color: #e74c3c;
}
.control-group .controls > .val {
  line-height: 28px;
}
.control-group .controls .file-box .form-controls {
  width: 85%;
  float: left;
}
.control-group .controls .file-box .form-left {
  width: 84%;
  float: left;
  margin-right: 1%;
}
.control-group .controls .file-box .file-item {
  position: relative;
  width: 15%;
  float: left;
}
.control-group .controls .file-box .file-btn {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
}
.control-group .controls .file-box .file-input {
  width: 100%;
  height: 28px;
  z-index: 1;
  opacity: 0;
  filter: alpha(opacity=0);
  text-indent: -9999px;
}
.control-group .controls .file-box .file-choose {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  font-size: 12px;
  border-radius: 2px;
  text-align: center;
  height: 28px;
  padding: 8px 16px;
  color: #ffffff;
  background-color: #3b78e7;
  border: solid 1px #16a085;
  white-space: nowrap;
}
.control-group .controls .file-box .file-choose-bg {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  font-size: 12px;
  border-radius: 2px;
  text-align: center;
  height: 28px;
  background-color: #ffffff;
  border: solid 1px #e5e5e5;
}
.control-group .controls .des-tip {
  font-size: 12px;
  color: #999;
  border: 0;
}
.control-group .control-tip {
  font-size: 12px;
  color: #999999;
}
.control-group .help-tips {
  font-size: 12px;
  line-height: 1.5;
}
.control-group .control-span {
  line-height: 28px;
}
.insert-file-modal .title-margin {
  margin-bottom: 5px;
}
.insert-file-modal .file-box .form-controls {
  border-right: none;
}
.insert-file-modal .file-box .file-choose {
  color: #4e80f5 !important;
  background: #fafafa !important;
  border: 1px solid #e5e5e5 !important;
  border-left: 0 !important;
  cursor: pointer !important;
}
.modal-smallest .control-group .control-label {
  width: 25%;
}
.modal-smallest .control-group .controls {
  width: 75%;
}
/* nav-tabs */
.tab-offset-right .nav-tabs {
  margin-bottom: 15px;
  border-bottom: 1px solid #c2d3eb;
  padding-left: 10px;
}
.tab-offset-right .nav-tabs li {
  float: left;
  position: relative;
  cursor: pointer;
  background-color: #ffffff;
}
.tab-offset-right .nav-tabs li a {
  min-width: 96px;
  white-space: nowrap;
  text-align: center;
  padding: 0 20px;
  border: 1px solid #e5e5e5;
  border-bottom: 1px solid #c2d3eb;
  display: block;
  margin-bottom: -1px;
  height: 30px;
  line-height: 30px;
  text-decoration: none;
  color: #999999;
  background: #fafafa;
}
.tab-offset-right .nav-tabs li:first-child a {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.tab-offset-right .nav-tabs li:last-child a {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.tab-offset-right .nav-tabs li.active {
  background-color: #ffffff;
}
.tab-offset-right .nav-tabs li.active a {
  border: 1px solid #c2d3eb;
  background-color: #ffffff;
  border-bottom-color: #ffffff;
  color: #51a3ff;
}
.tab-offset-right .nav-tabs li.active + li a {
  border-left: 1px solid #c2d3eb;
}
.tab-offset-right .nav-tabs li[disabled] a,
.tab-offset-right .nav-tabs li.disabled a {
  cursor: not-allowed;
  color: #666666;
}
.tab-offset-right .nav-tabs li + li {
  margin-left: -1px;
}
.tab-offset-right .nav-tabs .control-group {
  float: left;
  margin-left: 35px;
}
.tab-offset-right .nav-tabs .control-group .control-label {
  width: 100px;
}
.tab-offset-right .nav-tabs .control-group .controls {
  width: 50px;
}
.tab-offset-right .nav-tabs.nav-ctrl {
  position: absolute;
  top: 10px;
  right: 30px;
  border-bottom: 0 !important;
}
.tab-offset-right .nav-tabs.nav-ctrl .view {
  padding: 0 15px;
  min-width: 0;
  font-size: 18px;
  border: none;
}
.detail-tab .nav-tabs {
  padding: 0 40px;
  margin-bottom: 0;
  border-bottom: solid 1px #c2d3eb;
  margin-bottom: 10px;
}
.detail-tab .nav-tabs li {
  background: none;
  margin-bottom: -1px;
}
.detail-tab .nav-tabs li a {
  min-width: 100px;
  border: none;
  transition: none;
}
.detail-tab .nav-tabs li:active,
.detail-tab .nav-tabs li.active {
  background: none;
}
.detail-tab .nav-tabs li:active a,
.detail-tab .nav-tabs li.active a {
  color: #666666;
  font-weight: bold;
  border: solid 1px #c2d3eb;
  border-top: solid 3px #51a3ff;
  border-bottom: solid 1px #ffffff;
  border-radius: 0;
}
.animateContent .detail-info {
  max-height: 825px;
  min-height: 300px;
  overflow-y: auto;
}
.animateContent .detail-info .info-block h4 {
  margin-bottom: 5px;
}
.tab-content {
  margin-bottom: 15px;
}
.tab-content .tab-pane {
  display: none;
}
.tab-content .tab-pane.active {
  display: block;
}
.tab-content .pagination {
  margin-bottom: 0;
}
/* 画板 */
.panels {
  position: relative;
  min-height: 160px;
  /*提示框样式*/
  /* 横向柱条图 */
  /* 圆环图 */
  /* 圆环图text */
  /* 面积图 */
}
.panels .tooltip {
  position: absolute;
  min-width: 200px;
  min-height: 40px;
  line-height: 40px;
  text-align: center;
  border-width: 1px;
  padding: 5px;
  background-color: #e5e5e5;
  border-radius: 2px;
  border: solid 2px #e5e5e5;
  filter: alpha(opacity=80);
  opacity: 0.8;
  display: none;
  z-index: 100;
}
.panels .tooltip > .title {
  border-bottom: 1px solid #c2d3eb;
  text-align: left;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  padding: 0;
  text-align: center;
}
.panels .tooltip > .des {
  height: 22px;
  line-height: 22px;
  overflow: hidden;
}
.panels .tooltip > .des .desColor {
  width: 20px;
  height: 6px;
  float: left;
  margin: 8px 8px;
}
.panels .tooltip > .des .desText {
  float: left;
  font-family: "\5FAE\8F6F\96C5\9ED1";
}
.panels .bar-wrap {
  color: #4a6583;
  min-height: 160px;
  padding: 0 20px;
  width: 100%;
  display: -webkit-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}
.panels .bar-wrap ul li {
  line-height: 20px;
}
.panels .bar-wrap ul li .series-text {
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.panels .bar-wrap ul li .progress {
  height: 8px;
  width: 70%;
  float: left;
  margin: 4px 0px 8px;
  background-color: #e5e5e5;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 2px;
}
.panels .bar-wrap ul li .progress.pull {
  width: 100%;
}
.panels .bar-wrap ul li .legend {
  float: left;
  width: 15%;
  padding-left: 10px;
  text-align: right;
  white-space: nowrap;
}
.panels .bar-wrap ul li .legend.total .unit {
  font-size: 24px;
}
.panels .bar-wrap ul li .left {
  float: left;
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}
.panels .bar-wrap ul li .right {
  float: right;
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
}
.panels .pie-wrap {
  width: 50%;
  float: left;
  text-align: center;
  font-size: 24px;
  min-height: 1px;
  position: relative;
}
.panels .pie-wrap .pie_chart .background {
  fill: #4e80f5;
}
.panels .pie-wrap .pie_chart .foreground {
  fill: #51a3ff;
}
.panels .pie-wrap .pie {
  position: relative;
  width: 160px;
  height: 160px;
  display: inline-block;
  fill: #e5e5e5;
}
.panels .pie-wrap .pie-center {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 18px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.panels .pie-wrap .pie-center .num {
  font-size: 30px;
  line-height: 1;
}
.panels .pie-wrap .pie-center .text {
  font-size: 14px;
  color: #7991ab;
}
.panels .pie-wrap.nova-pie {
  width: 40%;
}
.panels .pie-legend {
  min-height: 160px;
  float: left;
  width: 50%;
  padding: 0 20px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}
.panels .pie-legend .dl-horizontal {
  color: #7991ab;
}
.panels .pie-legend .dl-horizontal dt {
  font-weight: normal;
  line-height: 40px;
  width: 80px;
  float: left;
  clear: left;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.panels .pie-legend .dl-horizontal dt i {
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 50%;
  float: left;
  margin: 14px 0;
  background-color: #51a3ff;
}
.panels .pie-legend .dl-horizontal dd {
  line-height: 40px;
  margin-left: 90px;
  font-size: 24px;
  white-space: nowrap;
}
.panels .pie-legend .dl-horizontal dd .total {
  color: #313949;
}
.panels .pie-legend .dl-horizontal dd .num {
  color: #51a3ff;
}
.panels .pie-legend .dl-horizontal .default {
  font-size: 16px;
}
.panels .pie-legend .dl-horizontal a {
  color: #7991ab;
}
.panels .pie-legend .dl-horizontal a:hover {
  color: #51a3ff;
  text-decoration: none;
  outline: 0 none;
}
.panels .pie-legend .dl-horizontal a:focus {
  color: #51a3ff;
  text-decoration: none;
  outline: 0 none;
}
.panels .pie-legend .dl-horizontal .popover {
  z-index: 99;
  margin-top: 10px;
}
.panels .pie-legend .dl-horizontal .popover .popover-content {
  padding: 10px;
}
.panels .pie-legend .dl-horizontal .popover .popover-content .table {
  margin-bottom: 0;
}
.panels .pie-legend .dl-horizontal .popover .popover-content .table th {
  padding: 2px 15px;
}
.panels .pie-legend .dl-horizontal .popover .popover-content .table td {
  text-align: center;
  padding: 2px 15px;
}
.panels .pie-legend .dl-horizontal .popover.top {
  margin-top: 0;
}
.panels .pie-legend .dl-horizontal .popover.top .arrow {
  margin-left: -70px;
}
.panels .pie-legend.category {
  padding: 0 15px 0 0;
  width: 60%;
}
.panels .pie-legend.category .dl-horizontal {
  overflow: hidden;
}
.panels .pie-legend.category .dl-horizontal dt {
  font-size: 12px;
  line-height: 22px;
  width: 110px;
  float: right;
}
.panels .pie-legend.category .dl-horizontal dd {
  font-size: 12px;
  line-height: 22px;
  margin-left: 10px;
  float: right;
}
.panels .pie-legend.category .dl-horizontal dd > span {
  min-width: 50px;
  display: inline-block;
}
.panels .pie-legend.category .dl-horizontal dd .normal {
  color: #1abc9c;
}
.panels .pie-legend.category .dl-horizontal dd .abnormal {
  color: #f39c12;
}
.panels .pie-ceph .dl-horizontal dt {
  width: 105px;
}
.panels .pie-ceph .dl-horizontal dd {
  margin-left: 110px;
}
.panels .area-chart-title {
  padding-left: 20px;
  color: #666666;
  font-size: 14px;
}
.panels .area-chart-wrap {
  text-align: center;
  /*透明矩形样式*/
}
.panels .area-chart-wrap .legend {
  text-align: left;
  padding-left: 60px;
}
.panels .area-chart-wrap .legend .series {
  display: inline-block;
  margin: 0 11px 0 0px;
  cursor: pointer;
}
.panels .area-chart-wrap .legend .series .legend-color {
  display: inline-block;
  height: 5px;
  width: 20px;
}
.panels .area-chart-wrap .legend .series .legend-text {
  display: inline-block;
  color: #7991ab;
  padding-left: 4px;
}
.panels .area-chart-wrap .axis path,
.panels .area-chart-wrap .axis line {
  fill: none;
  shape-rendering: crispEdges;
}
.panels .area-chart-wrap .axis text {
  font-size: 12px;
  fill: #7991ab;
}
.panels .area-chart-wrap .line {
  fill: none;
  stroke: steelblue;
  stroke-width: 1.2px;
}
.panels .area-chart-wrap .series .area {
  fill-opacity: 0.5;
}
.panels .area-chart-wrap .overlay {
  fill: none;
  pointer-events: all;
}
.panels .area-chart-wrap .focusLine {
  stroke: #d3e1ec;
  /*opacity: 0.5!important;*/
}
.panels .area-chart-wrap .grid .tick {
  stroke: #d3e1ec;
  opacity: 0.5 !important;
}
.panels.vertical {
  min-height: 200px;
  margin-right: 10px;
}
.panels.vertical .axis path,
.panels.vertical .axis line {
  display: none;
}
.panels.vertical .axis text {
  font-size: 12px;
  fill: #7991ab;
}
.panels.vertical .MyText {
  fill: #666;
  text-anchor: middle;
}
.panels.vertical .inner_line line {
  stroke: #d3e1ec;
  opacity: 0.5 !important;
}
.control-items2 .control-group {
  width: 49%;
  float: left;
}
.control-items2 .control-group .controls .checkbox {
  position: absolute;
  right: 0;
  top: 0;
}
.control-items2 .control-group:nth-child(even) {
  margin-left: 2%;
}
.control-items2 .control-group.half .control-label {
  width: 34.5%;
}
.control-items2 .control-group.half .controls {
  width: 65.5%;
}
.control-items2 .control-group.no-margin {
  margin-bottom: 0;
}
.control-items3 .control-group {
  width: 33.333%;
  padding: 0 20px;
  float: left;
}
.control-items3 .control-group .control-label {
  float: none;
  width: 100%;
  text-align: left;
}
.control-items3 .control-group .controls {
  margin-left: 0;
  padding-left: 0;
  float: none;
  width: 100%;
}
.control-items3 .control-group .controls .checkbox {
  position: absolute;
  right: 0;
  top: 0;
}
.control-items3.half .control-group {
  padding: 0 10px;
  margin-bottom: 0;
}
.control-items3.half .control-group:first-child,
.control-items3.half .control-group:last-child {
  padding: 0;
}
.noTransform-table.table thead tr th {
  text-transform: initial;
}
.help-tip {
  height: auto;
  line-height: 24px;
  background: #f1f6fd;
  border: 1px solid #c2d3eb;
  padding: 10px 20px;
  margin-bottom: 15px;
}
.import-rules-modal .control-group .controls {
  width: 100%;
  font-size: 14px;
}
.import-rules-modal .control-group .controls p {
  color: #999;
}
.import-rules-modal .control-group .controls .file-box .form-left {
  width: 78%;
}
.import-rules-modal .control-group .controls .file-box .file-item {
  width: 20%;
}
.config-page .operating-table tbody tr td .error {
  background-color: transparent;
  color: #e74c3c;
  line-height: 1.5;
  font-size: 12px;
  padding: 0;
}
.config-page .operating-table tbody tr td .checkbox {
  display: inline-block;
}
.config-page .operating-table tbody tr td .send-btn {
  margin-left: 10px;
  cursor: pointer;
}
.config-page .operating-table tbody tr td .send-btn.send-active {
  color: #0085FE;
}
.config-page .operating-table tbody tr td span.success {
  color: #0DD484;
}
.config-page .operating-table tbody tr td span.fail {
  color: #e74c3c;
}
.config-page .operating-table tbody tr td i.password-show {
  margin-left: -30px;
  font-size: 16px;
  padding: 5px;
}
.config-page .error-tip {
  color: #e74c3c;
  font-size: 12px;
}
.config-page .save-btn {
  width: 100%;
  margin: 0 auto;
  padding-top: 15px;
}
.multiplenet-box {
  margin: 50px auto 20px;
  padding: 80px 200px;
  border: solid 1px #E5E5E5;
}
.multiplenet-box .control-label {
  width: 30%;
}
.multiplenet-box .controls {
  width: 65%;
}
.multiplenet-box .alert {
  margin-bottom: 40px;
}
.mailServer-box .control-group .controls {
  width: 75% !important;
}
.mailServer-box.ladp-box .control-group .control-label {
  width: 25% !important;
}
.directive-action-wrap .time-label {
  display: inline-block;
  text-align: right;
  width: 80px;
}
.ad-bound-select {
  float: right;
}
.ad-bound-select .ui-select-container {
  width: 120px !important;
}
.backup-service .drag-info .title-p {
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
}
.backup-service .drag-info .checkedTdColor {
  background-color: #f6f9ff;
}
.backup-service .drag-info .ng-table-pagination {
  display: none;
}
.backup-service .progress-width {
  width: 40%;
}
.backup-service .padding-l-8 {
  padding-left: 8px;
}
.backup-service .error-info {
  border: 1px solid red;
}
.backup-service .run-time {
  text-align: center;
}
.backup-service .run-time .model-div-width {
  width: 120px;
}
.backup-service .run-time .model-div {
  border: 1px solid #e5e5e5;
  height: 28px;
  line-height: 28px;
  font-size: 14px;
}
.backup-service .run-time .model-div .span {
  float: right;
  padding-right: 5px;
}
.backup-service .run-time .div-li::-webkit-scrollbar {
  display: none;
  /*隐藏滚动条*/
}
.backup-service .run-time .div-li {
  float: left;
  height: 140px;
  width: 70px;
  overflow: auto;
}
.backup-service .run-time .div-li .lis {
  height: 30px;
  cursor: pointer;
  margin: 2px;
}
.backup-service .run-time .div-li .lis p {
  padding-top: 5px;
}
.backup-service .run-time .isrunTimeshowTrue {
  display: block;
}
.backup-service .run-time .runTimeshowFalse {
  display: none;
}
.backup-service .run-time .divs {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: #ccc 0px 0px 10px;
  -moz-box-shadow: #ccc 0px 0px 10px;
  box-shadow: #ccc 0px 0px 10px;
  position: absolute;
  z-index: 3;
  background-color: #FFFFFF;
  height: 140px;
  width: 140px;
}
.backup-service .run-time .lis:hover {
  background: #B2D5FF;
}
.backup-service .run-time .checked {
  background: #0075FF;
  color: #ffffff;
}
.backup-service .run-time-width {
  width: 150px;
}
.backup-service .tables-padding-bom {
  padding-bottom: 50px;
}
.backup-service .errorMessage {
  color: red;
}
.backup-service .option-backup-job {
  overflow: auto;
  max-height: 500px;
  min-height: 350px;
}
.backup-service .option-backup-job .week-li {
  float: left;
  padding-right: 10px;
}
.backup-service .option-backup-job .float-div {
  float: left;
}
.backup-service .option-backup-job .width-30 {
  width: 30px;
}
.backup-service .option-backup-job .width-128 {
  width: 128px;
}
.backup-service .option-backup-job .width-100 {
  width: 100%;
}
.backup-service .option-backup-job .div-word {
  padding-top: 5px;
}
.backup-service .option-backup-job .adv-setting {
  padding: 21px 0px 20px 60px;
  font-size: 10px;
}
.backup-service .option-backup-job .unitspan {
  float: right;
  margin-top: 5px;
  margin-left: -35px;
  z-index: 2;
  position: absolute;
  width: 50px;
  color: #E5E5E5;
}
.backup-service .option-backup-job .flow-control .float-div {
  float: left;
}
.backup-service .option-backup-job .flow-control .width-40 {
  width: 40px;
}
.backup-service .option-backup-job .flow-control .width-60 {
  width: 55px;
}
.backup-service .option-backup-job .flow-control .width-60-bfb {
  width: 60%;
}
.backup-service .bind-instance {
  overflow: hidden;
}
.backup-service .bind-instance .div-table {
  float: left;
  width: 48%;
  margin: 5px;
  height: 512px;
}
.backup-service .bind-instance .div-table .p {
  font-weight: bold;
  padding-bottom: 10px;
}
.backup-service .bind-instance .div-table .table-font-size {
  font-size: 12px;
  overflow: auto;
  height: 430px;
}
.backup-service .bind-instance .div-table .edit-name {
  width: 110px;
}
.backup-service .backup-data-clean .table tbody tr td .edit-name {
  width: 300px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.backup-service .backup-data-clean .table tbody tr td .edit-name > span {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 300px;
}
.backup-service .div-position-first {
  width: 10px;
  margin-left: 13px;
}
.backup-service .div-position-second {
  width: 10px;
  margin-left: 35px;
  margin-top: -10px;
}
.backup-service .div-margin {
  margin-left: 108px;
  margin-top: -35px;
}
.backup-service .create-backup-data-clean .checkbox-style {
  border: 1px solid #e5e5e5;
  height: 35px;
}
.backup-service .create-backup-data-clean .checkbox-style .iconfont-position {
  margin-left: 16px;
  margin-top: 5px;
}
.backup-service .create-backup-data-clean .select-style {
  width: 18%;
  margin-left: 38px;
  margin-top: -22px;
}
.backup-service .create-backup-data-clean .select-style .ui-select-container {
  width: 100%;
  height: 32px;
  line-height: 28px;
}
.backup-service .create-backup-data-clean .select-style .ui-select-container .ui-select-match .ui-select-toggle:hover {
  border: 0px;
}
.backup-service .create-backup-data-clean .select-style .ui-select-container .ui-select-match .btn {
  padding: 6px 10px;
  line-height: 16px;
  border: 0px;
}
.backup-service .create-backup-data-clean .select-style .ui-select-container .ui-select-search {
  width: 100%;
  min-width: 100%;
  background: #ffffff;
  box-shadow: none;
  padding: 8px 10px;
  outline: 0 none;
  border: 0px;
}
.backup-service .create-backup-data-clean .td-width-10px {
  width: 10px;
}
.backup-service .create-backup-data-clean .div-margin-left {
  margin-left: -22px;
}
.backup-service .add-backup-reocver-model .table-scroll {
  font-size: 12px;
  overflow: auto;
  max-height: 340px;
}
.backup-service .add-backup-reocver-model .edit-name {
  width: 100px;
}
.backup-service .add-backup-reocver-model .right-ports {
  float: left;
}
.complex-filter {
  height: 30px;
}
.complex-filter .filter-btn {
  width: 70px;
  cursor: pointer;
}
.complex-filter .isShowAll_true {
  display: block;
}
.complex-filter .isShowAll_false {
  display: none;
}
.complex-filter .z-div {
  box-shadow: #ccc 0px 0px 10px;
  position: absolute;
  z-index: 3;
  background-color: #FFFFFF;
  height: 135px;
  width: 330px;
  margin: 30px 0 0 130px;
}
.complex-filter .z-div .control-group-filter {
  width: 100%;
  overflow: hidden;
  line-height: 32px;
  padding-top: 12px;
}
.complex-filter .z-div .control-group-filter .span {
  float: left;
  width: 110px;
  padding-left: 35px;
}
.complex-filter .z-div .control-group-filter .controls {
  float: left;
  width: 180px;
}
.complex-filter .z-div .control-group-filter .controls .content {
  border: 1px solid #e5e5e5;
  height: 28px;
  line-height: 28px;
  font-size: 14px;
}
.complex-filter .z-div .control-group-filter .controls .content .span-info {
  padding-left: 10px;
}
.complex-filter .z-div .control-group-filter .controls .selected {
  box-shadow: #ccc 0px 0px 10px;
  position: absolute;
  z-index: 4;
  background-color: #FFFFFF;
  height: 190px;
  width: 180px;
}
.complex-filter .z-div .control-group-filter .controls .selected .lis:hover {
  background: #B2D5FF;
}
.complex-filter .z-div .control-group-filter .controls .selected .checkboxd {
  cursor: pointer;
}
.complex-filter .z-div .control-group-filter .controls .selected .checkboxd input[type='checkbox'] {
  display: none;
}
.complex-filter .z-div .control-group-filter .controls .selected .imgs {
  height: 15px;
  width: 15px;
  margin: 0 0 0 15px;
}
.complex-filter .z-div .control-group-filter .controls .selected .disabled {
  pointer-events: none;
  background: #e8e8e8;
}
.complex-filter .z-div .control-group-filter .controls .selected .img-1 {
  content: url(/img/select-1.png);
}
.complex-filter .z-div .control-group-filter .controls .selected .img-2 {
  content: url(/img/select-2.png);
}
.complex-filter .z-div .control-group-filter .controls .selected .img-3 {
  content: url(/img/select-3.png);
}
.complex-filter .z-div .control-group-filter .controls .isShow2_true {
  display: block;
}
.complex-filter .z-div .control-group-filter .controls .isShow2_false {
  display: none;
}
.complex-filter .z-div .control-group-filter .btn1 {
  min-width: 64px;
  padding: 6px 10px;
  line-height: 16px;
  box-shadow: none;
  text-shadow: none;
  display: inline-block;
  height: 28px;
  border-radius: 2px;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer;
  text-align: center;
}
.complex-filter .z-div .control-group-filter .btn-zy {
  margin-right: 5px;
  background-color: #3b78e7;
  border: 1px solid #0064b6;
  color: #fff;
}
.complex-filter .z-div .control-group-filter .btn-cy {
  background: #F6F9FF;
  border: 1px solid #5888F5;
  color: #3b78e7;
}
.server-config .font-normal {
  font-style: normal;
}
.server-config .wi-fo-pa {
  width: 15%;
  float: left;
  padding-left: 5%;
}
.server-config .size-dis-in {
  font-size: 2em;
  display: inline-block;
  left: 19%;
}
.server-config .wi-fo-pad {
  width: 85%;
  float: right;
  padding-top: 9px;
}
.server-config .dis-wi-fo-bo {
  display: inline-block;
  width: 100%;
  font-weight: bold;
  padding-bottom: 6px;
}
.alert-text-title {
  font-size: 12px;
  padding: 4px 0 4px 0;
}
.alert-text-title .div1 {
  line-height: 25px;
}
.alert-text-title a {
  color: #888888;
}
.alert-text-title a:hover {
  color: #51a3ff;
}
.alert-text-title .dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #e67e22;
  display: block;
  float: left;
  margin-top: 9px;
  margin-right: 5px;
}
/**
 * header
 */
header {
  opacity: 0;
  filter: alpha(opacity=0);
  margin-top: -20px;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  background: #262626;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
header .inner {
  min-width: 900px;
  padding: 0 15px;
}
header .inner.is-auth .logo a {
  min-width: 175px;
}
header .inner.is-auth .logo img {
  min-width: 175px;
}
header .inner .logo {
  float: left;
  width: 185px;
  height: 70px;
}
header .inner .logo img {
  display: block;
  width: 185px;
  height: 70px;
  line-height: 70px;
}
header .inner .logo a {
  font-weight: bold;
  text-indent: -9999px;
}
header .inner .logo a:hover,
header .inner .logo a:active,
header .inner .logo a:focus {
  color: #ffffff;
}
header .inner .dep-pro {
  float: left;
  margin: 7px 0 0 20px;
}
header .inner .dep-pro .dep-box,
header .inner .dep-pro .pro-box {
  width: 180px;
  float: left;
  margin-right: 15px;
}
header .inner .dep-pro .dep-box .dep-text,
header .inner .dep-pro .pro-box .dep-text {
  color: #e5e5e5;
  background: #404b5f;
  border: 1px solid #404b5f;
  text-overflow: ellipsis;
}
header .inner .cut-icon {
  font-size: 20px;
}
header .inner .user-mes-box {
  float: right;
  min-width: 292px;
}
header .inner .user-mes-box > li {
  float: right;
  position: relative;
}
header .inner .user-mes-box > li > a {
  line-height: 28px;
  height: 28px;
  display: block;
  padding: 0 15px;
  color: #ffffff;
}
header .inner .user-mes-box > li > a:hover {
  color: #51a3ff;
}
header .inner .user-mes-box > li .dropdown-layer {
  position: absolute;
  transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  /* Firefox 4 */
  -webkit-transition-duration: 0.5s;
  /* Safari 和 Chrome */
  -o-transition-duration: 0.5s;
  /* Opera */
  top: -730px;
  left: 0;
  z-index: -9;
  background: #191919;
}
header .inner .user-mes-box > li .dropdown-layer ul li {
  line-height: 20px;
  padding: 12px 0;
  border-bottom: 1px solid #e5e5e5;
}
header .inner .user-mes-box > li .dropdown-layer ul li:last-child {
  border-bottom: none;
  position: relative;
}
header .inner .user-mes-box > li .dropdown-layer .admin-center-menu li {
  padding: 2px 0;
  border-bottom: 0;
}
header .inner .user-mes-box > li.user-notice-panel .header-icon {
  font-size: 20px;
  color: #888888;
}
header .inner .user-mes-box > li.user-notice-panel > a {
  position: relative;
  text-align: center;
}
header .inner .user-mes-box > li.user-notice-panel > a .num {
  position: absolute;
  line-height: 15px;
  padding: 0 5px;
  color: #ffffff;
  background: #e74c3c;
  right: 6px;
  top: -1px;
  font-size: 12px;
  border-radius: 8px;
}
header .inner .user-mes-box > li.user-notice-panel > a > i {
  font-size: 24px;
}
header .inner .user-mes-box > li.user-notice-panel .notice-support {
  min-width: 150px;
  border: 1px solid #191919;
  border-top: 0 none;
  right: 0;
  left: auto;
  padding: 6px 20px;
}
header .inner .user-mes-box > li.user-notice-panel .notice-support li {
  border: 0;
  padding: 4px 0;
  font-size: 12px;
}
header .inner .user-mes-box > li.user-notice-panel .notice-support li .amount-unit {
  font-size: 12px;
  color: #ffffff;
}
header .inner .user-mes-box > li.user-notice-panel .notice-support li .amount {
  font-size: 16px;
  color: #ffffff;
}
header .inner .user-mes-box > li.user-notice-panel .notice-support li .consumption {
  color: #51a3ff;
  color: 12px;
}
header .inner .user-mes-box > li.user-notice-panel .notice-support li .consumption:hover {
  color: #4e80f5;
}
header .inner .user-mes-box > li.user-notice-panel .notice-support li.first {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 8px;
  margin-bottom: 4px;
}
header .inner .user-mes-box > li.user-notice-panel .notice-support li .title {
  font-weight: normal;
  overflow: hidden;
  display: block;
  white-space: nowrap;
}
header .inner .user-mes-box > li.user-notice-panel .notice-support li .title span {
  display: inline;
  float: none;
}
header .inner .user-mes-box > li.user-notice-panel .notice-support li a {
  color: #ffffff;
}
header .inner .user-mes-box > li.user-notice-panel .notice-support li a span {
  display: block;
  font-size: 12px;
  width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  float: left;
}
header .inner .user-mes-box > li.admin-center-info .header-icon {
  font-size: 20px;
  color: #888888;
  float: left;
}
header .inner .user-mes-box > li.admin-center-info .region-name {
  margin-left: 5px;
  color: #888888;
}
header .inner .user-mes-box > li.admin-center-info .region-change {
  width: 120px !important;
  padding: 10px 0;
}
header .inner .user-mes-box > li.admin-center-info .region-change .admin-center-menu li a {
  text-align: left;
  padding: 0 10px;
}
header .inner .user-mes-box > li.admin-center-info .spots {
  position: absolute;
  right: 30px;
  top: 12px;
  display: inline-block;
  height: 6px;
  width: 6px;
  background: red;
  border-radius: 50%;
}
header .inner .user-mes-box > li.admin-center-info > a {
  min-width: 50px;
  position: relative;
  text-align: center;
  font-size: 12px;
}
header .inner .user-mes-box > li.admin-center-info > a .num {
  position: absolute;
  line-height: 15px;
  padding: 0 5px;
  color: #ffffff;
  background: #e74c3c;
  right: 6px;
  top: -1px;
  font-size: 12px;
  border-radius: 8px;
}
header .inner .user-mes-box > li.admin-center-info > a > span {
  max-width: 140px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
}
header .inner .user-mes-box > li.admin-center-info > a > i {
  font-style: normal;
  font-size: 12px;
}
header .inner .user-mes-box > li.admin-center-info .help-info {
  min-width: 75px;
}
header .inner .user-mes-box > li.admin-center-info .admin-center {
  width: 120px;
  white-space: nowrap;
  right: 0;
  left: auto;
  border: 1px solid #191919;
  overflow: hidden;
}
header .inner .user-mes-box > li.admin-center-info .admin-center li a {
  color: #a5a5a5;
  display: block;
  text-align: left;
  padding-left: 10px;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
}
header .inner .user-mes-box > li.admin-center-info .admin-center li a i {
  margin-right: 5px;
  font-size: 16px;
  color: #51a3ff;
}
header .inner .user-mes-box > li.admin-center-info .admin-center li a:hover {
  background: #2a2a2a;
  text-decoration: none;
  color: #51a3ff;
}
header .inner .user-mes-box > li.admin-center-info .admin-center li a.active {
  background: #2a2a2a;
  text-decoration: none;
  color: #51a3ff;
}
header .inner .user-mes-box > li:after {
  content: "";
  position: absolute;
  left: 0;
  top: 19px;
  bottom: 19px;
  width: 1px;
  background-color: #000000;
}
header .inner .user-mes-box > li:last-child:after {
  content: initial;
}
header .inner .user-mes-box > li:hover {
  background: #191919;
}
header .inner .user-mes-box > li:hover:after {
  position: static;
}
header .inner .user-mes-box > li:hover a {
  color: #51a3ff;
}
header .inner .user-mes-box > li:hover .region-name,
header .inner .user-mes-box > li:hover .header-icon {
  color: #ffffff;
}
header .inner .user-mes-box > li:hover .dropdown-layer {
  top: 100%;
}
header .inner .translate-select {
  float: right;
  width: 100px;
  margin-top: 19px;
}
.ie-tips {
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -445px;
  margin-top: -245px;
  color: #4a6583;
  width: 890px;
  height: 490px;
  z-index: 1000;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 0 30px;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s;
  opacity: 1;
  filter: alpha(opacity=100);
}
.ie-tips h2 {
  line-height: 100px;
  font-size: 18px;
  text-align: center;
  position: relative;
  border-bottom: solid 1px #e5e5e5;
}
.ie-tips h2 .close {
  color: #999999;
  opacity: 1;
  filter: alpha(opacity=100);
  text-shadow: none;
  float: right;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  right: 0;
  top: 30px;
}
.ie-tips .img-list {
  padding-top: 50px;
}
.ie-tips .img-list li {
  width: 33.333333%;
  float: left;
  text-align: center;
  margin-bottom: 60px;
}
.ie-tips .img-list li a {
  width: 56px;
  height: 56px;
  display: inline-block;
}
.ie-tips .img-list li figcaption {
  text-transform: capitalize;
}
.ie-tips .img-list li p {
  color: #7991ab;
  font-size: 12px;
}
.ie-tips .img-list li p b {
  color: #51a3ff;
}
.ie-tips .img-list li:nth-child(1) a {
  background: url(/img/browsehappy-sprite.png) no-repeat -220px 0;
  background-size: auto 56px;
}
.ie-tips .img-list li:nth-child(2) a {
  background: url(/img/browsehappy-sprite.png) no-repeat 1px 0;
  background-size: auto 56px;
}
.ie-tips .img-list li:nth-child(3) a {
  background: url(/img/browsehappy-sprite.png) no-repeat -55px 0;
  background-size: auto 56px;
}
.ie-tips .img-list li:nth-child(4) a {
  background: url(/img/browsehappy-sprite.png) no-repeat -165px 0;
  background-size: auto 56px;
}
.ie-tips .img-list li:nth-child(5) a {
  background: url(/img/browsehappy-sprite.png) no-repeat -330px 0;
  background-size: auto 56px;
}
.ie-tips.ie-show {
  opacity: 0;
  z-index: 0;
}
/**
 * main
 */
.main {
  min-width: 1200px;
}
.main aside {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: 999;
  width: 50px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  padding-top: 70px;
  background-color: #fafafa;
  color: #ffffff;
  margin-left: -20px;
  border-right: 1px solid #e5e5e5;
  display: -webkit-flex;
  -webkit-flex-direction: column;
}
.main aside .menu-title {
  height: auto;
  position: relative;
  font-size: 16px;
  margin: 0 20px;
  line-height: 59px;
  border-bottom: 1px solid #e5e5e5;
  color: #565656;
}
.main aside .menu-title i {
  float: left;
  color: #888888;
  font-size: 32px;
  margin-right: 15px;
}
.main aside .menu-title .title-name {
  float: left;
}
.main aside h2 {
  display: none;
  border-bottom: solid 1px rgba(255, 255, 255, 0.2);
  margin: 0 10px;
  line-height: 54px;
  font-size: 22px;
  font-weight: bold;
  padding: 0 8px;
}
.main aside .toggle-icon {
  color: #888888;
  height: 30px;
  width: 30px;
  line-height: 30px;
  border-radius: 50%;
  text-align: center;
  display: block;
  cursor: pointer;
  position: fixed;
  left: 0;
  z-index: 999;
  bottom: 30px;
  transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  /* Firefox 4 */
  -webkit-transition-duration: 0.5s;
  /* Safari 和 Chrome */
  -o-transition-duration: 0.5s;
  /* Opera */
}
.main aside .toggle-icon:hover {
  height: 50px;
  width: 50px;
  line-height: 50px;
  background-color: #e6e6e6;
}
.main aside .menu-level-1.menu-level-p {
  padding-top: 10px;
}
.main aside .menu-level-1 .close-show-title {
  display: none;
}
.main aside .menu-level-1 .level2-active .icon-arrow:before {
  content: '\E813';
}
.main aside .menu-level-1 .menu-level-2 {
  background: #fafafa;
}
.main aside .menu-level-1 .menu-level-2 li a {
  padding-left: 15px;
  color: #888888;
}
.main aside .menu-level-1 .menu-level-2 li a:hover {
  background: #ecf1fa;
  color: #4e80f5;
}
.main aside .menu-level-1 .icon-arrow {
  display: none;
  font-size: 12px;
  position: absolute;
  right: 20px;
  top: 0;
}
.main aside .menu-level-1 .icon-arrow:before {
  content: '\E818';
}
.main aside .menu-level-1 > li {
  position: relative;
  margin-bottom: 1px;
}
.main aside .menu-level-1 > li a {
  color: #888888;
  display: block;
  height: 38px;
  line-height: 38px;
  position: relative;
  z-index: 100;
}
.main aside .menu-level-1 > li a span {
  padding: 0 15px;
  white-space: nowrap;
  height: 40px;
  line-height: 40px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  font-size: 14px;
}
.main aside .menu-level-1 > li a span b {
  display: none;
}
.main aside .menu-level-1 > li a span > i {
  margin-right: 20px;
  width: 16px;
  font-size: 16px;
  display: inline-block;
}
.main aside .menu-level-1 > li a i {
  color: #888888;
}
.main aside .menu-level-1 > li a.active {
  color: #888888;
  background: #fafafa;
}
.main aside .menu-level-1 > li a.active span > i {
  color: #3367d6;
}
.main aside .menu-level-1 > li a.active:hover {
  color: #3367d6;
  background: #ecf1fa;
}
.main aside .menu-level-1 > li a.activebg {
  background: #ecf1fa;
  color: #3367d6;
}
.main aside .menu-level-1 > li a:hover {
  color: #3367d6;
  background: #ecf1fa;
}
.main aside .menu-level-1 > li a:hover span {
  display: block;
  opacity: 1;
}
.main aside .menu-level-1 > li a.sub-active {
  color: #4e80f5;
  background: #ecf1fa;
}
.main .main-content {
  position: relative;
  opacity: 0;
  margin: 70px 0 0 16px;
  -webkit-transition: opacity 0.5s ease-out 0s;
  -moz-transition: opacity 0.5s ease-out 0s;
  transition: opacity 0.5s ease-out 0s;
}
.main .main-content .menu-manage {
  display: none;
}
.main .main-content .menu-manage .nav {
  line-height: 56px;
  margin-bottom: 15px;
}
.main .main-content .menu-manage .nav.top-menu {
  position: relative;
}
.main .main-content .menu-manage .nav > ul {
  border-bottom: solid 1px #e5e5e5;
  padding: 0 30px;
}
.main .main-content .menu-manage .nav > ul > li {
  position: relative;
  float: left;
  padding: 0 10px;
  margin-bottom: -1px;
}
.main .main-content .menu-manage .nav > ul > li > a {
  min-width: 100px;
  height: 56px;
  line-height: 47px;
  padding-top: 4px;
  border-bottom: solid 2px transparent;
  text-align: center;
  display: block;
  cursor: pointer;
  color: #666666;
}
.main .main-content .menu-manage .nav > ul > li > a i {
  -webkit-transition: all 0.1s linear 0s;
  -ms-transition: all 0.1s linear 0s;
  transition: all 0.1s linear 0s;
  margin-left: 2px;
  font-size: 12px;
}
.main .main-content .menu-manage .nav > ul > li .dropdown-ul {
  z-index: 300;
  display: none;
  position: absolute;
  min-width: 100%;
  left: 0;
  top: 55px;
  background: #ffffff;
  border: solid 1px #51a3ff;
  border-radius: 2px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.main .main-content .menu-manage .nav > ul > li .dropdown-ul li {
  display: block;
}
.main .main-content .menu-manage .nav > ul > li .dropdown-ul li:last-child a:hover {
  border-radius: 0 0 2px 2px;
}
.main .main-content .menu-manage .nav > ul > li .dropdown-ul a {
  color: #313949;
  line-height: 28px;
  display: block;
  padding: 0;
  padding: 0 30px;
  white-space: nowrap;
}
.main .main-content .menu-manage .nav > ul > li .dropdown-ul a:hover {
  color: #51a3ff;
  background: #fafafa;
}
.main .main-content .menu-manage .nav > ul > li .dropdown-ul a.active {
  color: #ffffff;
  background: #51a3ff;
}
.main .main-content .menu-manage .nav > ul > li.active > a {
  color: #51a3ff;
  border-bottom: solid 2px #51a3ff;
}
.main .main-content .menu-manage .nav > ul > li.active .dropdown-ul {
  border-top: solid 1px #e5e5e5;
}
.main .main-content .menu-manage .nav > ul > li.active .drop-offset {
  display: block;
}
.main .main-content .menu-manage .nav > ul > li.hover > a {
  color: #51a3ff;
  border-bottom: solid 2px #51a3ff;
}
.main .main-content .menu-manage .nav > ul > li.hover > a i {
  display: inline-block;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.main .main-content .menu-manage .nav > ul > li.hover .dropdown-ul {
  display: block;
}
.main .main-content .menu-manage .nav > ul > li:hover > a {
  color: #51a3ff;
  border-bottom: solid 2px #51a3ff;
}
.main .main-content .menu-manage .nav > ul > li:hover .dropdown-ul {
  display: block;
}
.main .main-content .menu-manage .nav .drop-offset {
  z-index: 1;
  display: none;
  height: 41px;
  border-bottom: 1px solid #e5e5e5;
}
.main .main-content .menu-manage .nav .drop-offset.active {
  display: block;
}
.main .main-content .menu-manage .nav .drop-offset.active.hover {
  position: static;
}
.main .main-content .menu-manage .nav .drop-offset.hover {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
}
.main .main-content .box {
  border-bottom: solid 1px #e5e5e5;
  padding-bottom: 20px;
  margin-bottom: 20px;
  margin-top: 0px;
  position: relative;
}
.main .main-content .box .physical-tips-content {
  position: absolute;
  right: 0;
  top: -8px;
}
.main .main-content .box .physical-tips-content .btn {
  font-size: 16px;
  min-width: 80px;
}
.main .main-content .box:last-child {
  border: none;
  margin-bottom: 0;
}
.main .main-content .box div[class^="col-"] {
  float: left;
  border-right: solid 1px #e5e5e5;
  min-height: 165px;
}
.main .main-content .box div[class^="col-"] > .title {
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  padding: 0 20px;
}
.main .main-content .box div[class^="col-"] > .title a {
  color: #999999;
  float: right;
  font-size: 12px;
}
.main .main-content .box div[class^="col-"] > .title a:hover {
  color: #51a3ff;
}
.main .main-content .box div[class^="col-"] > .title .tips {
  display: none;
  float: right;
  font-size: 10px;
  text-align: right;
  padding: 0 20px;
  color: #999999;
}
.main .main-content .box div[class^="col-"] > .title .tips > span {
  margin: 0 5px;
}
.main .main-content .box div[class^="col-"] > .title .tips em {
  font-family: simsun, "\5B8B\4F53";
}
.main .main-content .box div[class^="col-"] > .title .additional-tip {
  color: #7991ab;
  font-size: 14px;
  padding-left: 5px;
}
.main .main-content .box div[class^="col-"] > .title.dept {
  padding: 0;
}
.main .main-content .box div[class^="col-"]:last-child {
  border: none;
}
.main .main-content .box .col-2 {
  width: 50%;
}
.main .main-content .box .col-3 {
  width: 25%;
}
.main .main-content .box .col-4 {
  width: 33.33333333%;
}
.main .main-content .box .col-5 {
  width: 20%;
}
.main .main-content .box .col-6 {
  width: 50%;
}
.main .main-content .box .col-8 {
  width: 66.66666667%;
}
.main .main-content .box .col-9 {
  width: 75%;
}
.main .main-content .box .col-12 {
  width: 100%;
}
.main .main-content .box.areaChart .panels {
  min-height: 245px;
}
.main .main-content .box .vertical li {
  float: left;
  padding: 0 5px;
  cursor: pointer;
}
.main .main-content .box .vertical li i {
  height: 12px;
  width: 12px;
  display: inline-block;
  border-radius: 2px;
  margin-right: 5px;
}
.main .main-content .box .vertical li i.blueDark {
  background: #4e80f5;
}
.main .main-content .box .vertical li i.blue {
  background: #51a3ff;
}
.main .main-content .box .vertical li i.green {
  background: #1abc9c;
}
.main .main-content .chart-box {
  border-top: 1px solid #e5e5e5;
  border-bottom: none;
  padding: 20px 0 0;
}
.main .main-content .layout-grid {
  margin: 0;
  padding: 0;
  border: 0 none;
}
.main .main-content .layout-grid div[class^="col-"] {
  float: left;
  border: 0 none;
  border-bottom: solid 1px #e5e5e5;
  min-height: 200px;
  padding-bottom: 15px;
  margin-top: 15px;
  margin-bottom: -1px;
}
.main .main-content .layout-grid div[class^="col-"] .inner {
  border-right: solid 1px #e5e5e5;
}
.main .main-content .layout-grid div[class^="col-"]:last-child {
  border-bottom: solid 1px #e5e5e5;
}
.main .main-content .layout-grid .col-2 {
  width: 20%;
}
.main .main-content .layout-grid .col-3 {
  width: 25%;
}
.main .main-content .layout-grid .col-3:nth-child(4n) .inner {
  border-right: 0 none;
}
.main .main-content .layout-grid .col-4 {
  width: 33.33333333%;
}
.main .main-content .layout-grid .col-4:nth-child(3n) .inner {
  border-right: 0 none;
}
.main .main-content .layout-grid .col-6 {
  width: 50%;
}
.main .main-content .layout-grid .col-8 {
  width: 66.66666667%;
}
.main .main-content .layout-grid .col-9 {
  width: 75%;
}
.main .main-content .layout-grid .col-12 {
  width: 100%;
}
.main.open aside {
  width: 240px;
}
.main.open aside h2 {
  display: block;
}
.main.open aside .toggle-icon i:before {
  content: '\E87C';
}
.main.open aside .menu-title {
  display: block;
  margin-bottom: 10px;
}
.main.open aside .menu-level-1 {
  width: 100%;
  height: inherit;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}
.main.open aside .menu-level-1 li {
  color: #888;
}
.main.open aside .menu-level-1 li span {
  padding-left: 20px;
  position: static;
  text-align: left;
  background: none;
}
.main.open aside .menu-level-1 li span b {
  display: inline;
}
.main.open aside .menu-level-1 li span > i {
  margin: 0 20px 0 8px;
}
.main.open aside .menu-level-1 li .icon-arrow {
  display: block;
}
.main.open aside .menu-level-1 .level2-active .menu-level-2 {
  display: block;
}
.main.open aside .menu-level-1 .menu-level-2 {
  position: static;
  display: none;
}
.main.open aside .menu-level-1 .menu-level-2 a {
  padding-left: 70px;
}
.main.open aside .menu-level-1 .menu-level-2 a span {
  padding: 0;
}
.main.closed aside {
  width: 50px;
}
.main.closed aside .menu-title {
  display: none;
}
.main.closed aside .menu-level-1 .close-show-title {
  display: none;
}
.main.closed aside .menu-level-1 > li:hover .menu-level-2 {
  border-top: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  display: block;
}
.main.closed aside .menu-level-1 > li:hover > a {
  color: #ffffff;
  background: #4e80f5;
}
.main.closed aside .menu-level-1 > li:hover .close-show-title {
  display: block;
  position: absolute;
  left: 100%;
  top: 0;
  background: #4e80f5;
  white-space: nowrap;
  line-height: 40px;
  padding: 0 15px;
  min-width: 86px;
}
.main.closed aside .menu-level-1 .menu-level-2 {
  min-width: 140px;
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
}
.main.closed aside .menu-level-1 .menu-level-2 a {
  padding-left: 25px;
  line-height: 32px;
  height: 32px;
}
.main.closed aside .menu-level-1 .menu-level-2 a span {
  padding: 0;
  line-height: 32px;
  height: 32px;
}
.no-side-menu .main aside {
  display: none;
}
.no-side-menu .main .main-content {
  margin-left: 0!important;
}
.no-side-menu .page-inner {
  padding-top: 15px;
}
/* region-domain-project */
.region-domain-project {
  float: left;
  margin-left: 20px;
  /*
		time:2018/6/14
		editBy:zyl
	*/
}
.region-domain-project .control-group {
  float: left;
  width: 200px;
  margin-bottom: 0;
}
.region-domain-project .control-group .control-label {
  width: 50px;
}
.region-domain-project .control-group .controls {
  width: 150px;
}
.region-domain-project .control-group-region {
  width: 235px;
}
.region-domain-project .control-group-region .control-label {
  width: 85px;
}
.region-domain-project .control-group-region .controls {
  width: 150px;
}
.region-domain-project .ui-select-item-ellips {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
  overflow: hidden;
}
.region-domain-project .ui-select-bootstrap[disabled='disabled'] .ui-select-toggle > .caret {
  display: none;
}
.region-domain-project .domain-project-group {
  position: relative;
}
.region-domain-project .domain-project-group .group-display {
  line-height: 32px;
  color: #888888;
}
.region-domain-project .domain-project-group .group-display .icon-aw-xmgl {
  margin-right: 5px;
}
.region-domain-project .domain-project-group:hover .group-display {
  cursor: pointer;
  color: #3b78e7;
}
.region-domain-project .domain-project-group:hover .domain-list {
  display: block;
}
.region-domain-project .domain-project-group .domain-list {
  display: none;
  width: 150px;
  background: #000;
  position: absolute;
  z-index: 300;
  border-radius: 5px;
}
.region-domain-project .domain-project-group .domain-list .tip-arrow {
  display: block;
  width: 12px;
  height: 12px;
  position: absolute;
  left: 30%;
  margin-left: -6px;
  top: -6px;
  background: #000;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}
.region-domain-project .domain-project-group .domain-list .dp-title {
  margin: 0 8px;
  color: #ffffff;
  line-height: 30px;
  padding-top: 3px;
  font-size: 12px;
  border-bottom: 1px solid #7b7b7b;
}
.region-domain-project .domain-project-group .domain-list .domain-lists {
  font-size: 12px;
  padding: 10px 0;
}
.region-domain-project .domain-project-group .domain-list .domain-lists .length-limit {
  display: inline-block;
  width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.region-domain-project .domain-project-group .domain-list .domain-lists .domain-item {
  height: 30px;
  line-height: 30px;
  color: #888888;
  background: #000;
  position: relative;
  padding: 0 15px 0 20px;
}
.region-domain-project .domain-project-group .domain-list .domain-lists .domain-item .icon-aw-angle-right-1 {
  float: right;
}
.region-domain-project .domain-project-group .domain-list .domain-lists .domain-item .projects-list {
  max-height: 500px;
  overflow-y: auto;
  display: none;
  background: #000;
  position: absolute;
  top: -8px;
  padding: 8px 0;
  right: -150px;
  width: 150px;
}
.region-domain-project .domain-project-group .domain-list .domain-lists .domain-item .projects-list::-webkit-scrollbar {
  width: 6px;
}
.region-domain-project .domain-project-group .domain-list .domain-lists .domain-item .projects-list::-webkit-scrollbar-thumb {
  background: #51a3ff;
}
.region-domain-project .domain-project-group .domain-list .domain-lists .domain-item .projects-list::-webkit-scrollbar-track {
  border-radius: 0;
  background: #e5e5e5;
}
.region-domain-project .domain-project-group .domain-list .domain-lists .domain-item .projects-list li {
  height: 30px;
  color: #888888;
  padding: 0 12px;
  cursor: pointer;
}
.region-domain-project .domain-project-group .domain-list .domain-lists .domain-item .projects-list li:hover {
  background: #2a2a2a;
  color: #3b78e7;
}
.region-domain-project .domain-project-group .domain-list .domain-lists .domain-item:hover {
  background: #2a2a2a;
  color: #3b78e7;
}
.region-domain-project .domain-project-group .domain-list .domain-lists .domain-item:hover .projects-list {
  display: block;
}
/** 
 * footer
 */
footer {
  opacity: 0;
  filter: alpha(opacity=0);
  margin-bottom: -100px;
  position: fixed;
  z-index: 101;
  width: 100%;
  height: 42px;
  left: 0;
  bottom: 0;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
footer .copyright {
  padding: 14px 0 10px;
  font-size: 12px;
  color: #a5a5a5;
  text-align: center;
  margin: 0;
}
/**
 * logined style
 */
.logined header {
  opacity: 1;
  filter: alpha(opacity=100);
  margin: 0;
}
.logined .main aside {
  -webkit-transition: margin 0.5s ease-out 0s;
  -moz-transition: margin 0.5s ease-out 0s;
  transition: margin 0.5s ease-out 0s;
  opacity: 1;
  filter: alpha(opacity=100);
  margin-left: 0;
}
.logined .main .main-content {
  margin-left: 50px;
  opacity: 1;
  filter: alpha(opacity=100);
}
.logined .main .main-content .menu-manage {
  display: block;
}
.logined .main.open .main-content {
  margin-left: 240px;
}
/*
fast create vm
   */
.create-easy-vm {
  background: #f6f9ff;
}
.create-easy-vm .main aside {
  display: none;
}
.create-easy-vm .main .main-content {
  margin-left: 0!important;
}
.create-easy-vm .main-top-box {
  display: none;
}
.open .col-sm-4.menu {
  position: fixed;
  right: 0;
  top: 168px;
  width: 29%;
}
.table-action {
  position: relative;
  margin-bottom: 10px;
}
.table-action .btn-box {
  float: left;
}
.table-action .btn-box .dropdown {
  float: right;
}
.table-action .btn-renovat + .popover {
  min-width: 90px;
}
.table-action .filter-box {
  float: right;
}
.table-action .btn {
  float: left;
  margin-right: 5px;
}
.table-action .btn i {
  margin-right: 5px;
}
.table-action .btn i.icon-aw-search {
  cursor: text;
}
.table-action .dropdown {
  float: left;
  margin-right: 0px;
}
.table-action .dropdown .dropdown-toggle > i {
  font-size: 10px;
  margin-left: 5px;
}
.table-action .search-box {
  float: left;
  margin-right: 10px;
}
.table-action .control-group {
  width: 25%;
  float: left;
  display: inline-block;
  margin-bottom: 0px;
}
.table-action .control-group .ui-select-container .ui-select-match > .btn {
  width: 100%;
  min-width: 110px;
}
.table-action .control-group .control-label {
  width: 30%;
}
.table-action .control-group .controls {
  width: 70%;
  padding-left: 0;
}
.table-action .control-group .controls.ticket-con {
  width: 190px;
}
.table-action .control-group.time-picker {
  margin-right: 0;
}
.table-action .control-group.time-picker .form-control {
  width: 100%;
}
.table-action .form-group > .btn,
.table-action .form-group .dropdown {
  float: left;
  margin-right: 10px;
}
.table-action .form-group > .btn i,
.table-action .form-group .dropdown i {
  margin-right: 5px;
}
.table-action.time-error {
  margin-bottom: 30px;
}
.table-action .margin-right28 {
  margin-right: 28px;
}
/* dropdown */
.dropdown-menu {
  padding: 0px;
  border: solid 1px #4e80f5;
  min-width: 100%;
  font-size: 14px;
  z-index: 1200;
  border-radius: 2px;
}
.dropdown-menu li button {
  padding: 2px 20px;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  white-space: nowrap;
}
.dropdown-menu li button:hover {
  background: #51a3ff;
  color: #ffffff;
}
.dropdown-menu.double {
  width: 300px;
}
.dropdown-menu.double .left {
  width: 50%;
  float: left;
}
.dropdown-menu.double .right {
  width: 50%;
  float: right;
}
/* search-box */
.search-box {
  position: relative;
  width: 200px;
}
.search-box .btn {
  position: absolute;
  right: 0;
  top: 0;
  background: none;
  border: none;
  padding: 0;
  min-width: 0;
  margin: 0;
  width: 28px;
  color: #e5e5e5;
  cursor: text;
}
.search-box input.form-control {
  width: 100%;
}
/* advanced_search-box */
.advanced_search-box {
  color: #51a3ff;
}
.advanced_search-box .link {
  margin-right: 15px;
  cursor: pointer;
}
.advanced_search-choose {
  position: absolute;
  top: 43px;
  left: 0;
  width: 100%;
  border: solid 1px #c2d3eb;
  background-color: #ffffff;
  z-index: 1;
  padding: 15px 0;
  -webkit-display: flex;
  -moz-display: flex;
  -ms-display: flex;
  display: flex;
}
.advanced_search-choose li {
  float: left;
  width: 25%;
  border-right: dashed 1px #c2d3eb;
  padding: 0 20px;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.advanced_search-choose li:last-child {
  border-right: none;
}
.advanced_search-choose li h5 {
  text-transform: capitalize;
  font-weight: bold;
}
.advanced_search-choose li .checkbox {
  margin-bottom: 15px;
}
.advanced_search-choose li .checkbox label {
  width: 33.333333%;
  float: left;
}
.advanced_search-choose li .checkbox label + label {
  margin-left: 0;
}
.advanced_search-choose li .checkbox label:last-child {
  margin-bottom: 0;
}
.advanced_search-choose li .checkbox.vertical label {
  float: none;
  width: 100%;
  margin-bottom: 5px;
}
.advanced_search-choose li .checkbox:last-child {
  margin-bottom: 0;
}
/* input ui-select */
.ui-select-container {
  width: 100%;
  height: 28px;
  line-height: 28px;
}
.ui-select-container .ui-select-match {
  background: none;
  outline: 0 none;
  box-shadow: none;
}
.ui-select-container .ui-select-match .ui-select-toggle {
  font-size: 14px;
  padding: 8px 10px;
  border: solid 1px #e5e5e5;
  background: #ffffff;
  color: #666666;
  outline: 0 none;
}
.ui-select-container .ui-select-match .ui-select-toggle > .caret {
  height: 18px;
  width: 12px;
  right: 6px;
  font-size: 12px;
  margin-top: -9px;
  border: 0 none;
}
.ui-select-container .ui-select-match .ui-select-toggle > .caret:before {
  font-family: "iconfont";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  content: "\E814";
}
.ui-select-container .ui-select-match .ui-select-toggle .ui-select-match-text {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ui-select-container .ui-select-match .ui-select-toggle .btn-link {
  display: none;
}
.ui-select-container .ui-select-match .ui-select-toggle[disabled]:hover,
.ui-select-container .ui-select-match .ui-select-toggle.disabled:hover {
  color: #666666;
  background-color: #fafafa;
}
.ui-select-container .ui-select-match .ui-select-toggle:hover {
  color: #313949;
  background-color: #ffffff;
}
.ui-select-container .ui-select-match .btn {
  padding: 6px 10px;
  line-height: 16px;
}
.ui-select-container .ui-select-match .close {
  font-size: 16px;
}
.ui-select-container .ui-select-search {
  width: 100%;
  min-width: 100%;
  background: #ffffff;
  box-shadow: none;
  padding: 8px 10px;
  outline: 0 none;
}
.ui-select-container .ui-select-search:focus {
  box-shadow: none;
}
.ui-select-container .ui-select-choices {
  width: 100%;
  top: 33px;
  border-radius: 2px;
}
.ui-select-container .ui-select-choices .ui-select-choices-row > a {
  min-height: 25px;
  line-height: 26px;
  padding: 1px 10px;
  color: #666;
}
.ui-select-container .ui-select-choices .ui-select-choices-row > a:hover {
  background: #fafafa;
  color: #51A3FF;
}
.ui-select-container .ui-select-choices .ui-select-choices-row.active > a {
  color: #fff;
}
.ui-select-container .ui-select-choices .ui-select-choices-row.active > a:hover {
  background-color: #428bca;
  color: #fff;
}
.ui-select-container .ui-select-choices .ui-select-choices-group .dropdown-header {
  padding: 0;
}
.ui-select-container.ui-select-sm {
  width: 200px;
}
.ui-select-container.ui-select-multiple {
  position: relative;
  min-height: 28px;
  padding: 3px 4px 0;
  line-height: 20px;
}
.ui-select-container.ui-select-multiple .ui-select-match-item {
  height: 24px;
  line-height: 14px;
  margin: 0 4px 4px 0;
  min-width: auto;
  padding: 4px 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 150px;
}
.ui-select-container.ui-select-multiple input.ui-select-search {
  min-width: 96px;
  height: 24px;
  line-height: 24px;
  margin-bottom: 0!important;
  padding: 0 5px;
}
.ui-select-container.ui-select-multiple.open {
  border-color: #51a3ff;
}
.ui-select-container.ui-select-black .ui-select-match .ui-select-toggle {
  color: #c2d3eb;
  background: #404b5f;
  border-color: #404b5f;
}
.ui-select-container.ui-select-black .ui-select-match .ui-select-toggle:hover {
  color: #c2d3eb;
  background: #404b5f;
  border-color: #404b5f;
}
.ui-select-container.ui-select-black .ui-select-search {
  background: #404b5f;
  border-color: #404b5f;
  color: #c2d3eb;
}
.ui-select-container.ui-select-black .ui-select-search::-webkit-input-placeholder {
  color: #c2d3eb;
}
/* btn */
.btn {
  font-size: 12px;
  min-width: 64px;
  padding: 6px 10px;
  line-height: 16px;
  box-shadow: none;
  text-shadow: none;
  display: inline-block;
  height: 28px;
  border-radius: 2px;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer;
  text-align: center;
  border: solid 1px transparent;
  margin-right: 5px;
}
.btn-link {
  color: #313949;
  background: none;
}
.btn.btn-new {
  background-color: #3b78e7;
  border: 1px solid #0064b6;
  color: #fff;
}
.btn.btn-new:hover {
  background-color: #1886d9;
  border: 1px solid #1578c2;
  color: #fff;
}
.btn.btn-new:active {
  color: #fff;
  background: #006ba2;
  border: 1px solid #005da8;
}
.btn.btn-new[disabled],
.btn.btn-new.disabled {
  cursor: not-allowed;
  background: #fafafa;
  border: 1px solid #cdcdcd;
  color: #666666;
}
.btn.btn-normal {
  background: #fff;
  border: 1px solid #D5D5D5;
  color: #777777;
}
.btn.btn-normal:hover {
  background: #F6F9FF;
  border: 1px solid #5888F5;
  color: #3b78e7;
}
.btn.btn-normal:active {
  background: #fff;
  border: 1px solid #D5D5D5;
  color: #777777;
}
.btn.btn-normal[disabled],
.btn.btn-normal.disabled {
  cursor: not-allowed;
  background: #e8e8e8;
  border: 1px solid #D5D5D5;
  color: #777777;
}
.btn.btn-default {
  color: #313949;
  background: #ffffff;
  border: 1px solid #c2d3eb;
}
.btn.btn-default:hover {
  color: #4e80f5;
  background: #f6f9ff;
  border: 1px solid #51a3ff;
}
.btn.btn-default:active {
  color: #4e80f5;
  background: #fafafa;
  border: 1px solid #51a3ff;
}
.btn.btn-default[disabled],
.btn.btn-default.disabled {
  cursor: not-allowed;
  background: #fafafa;
  border: 1px solid #cdcdcd;
  color: #666666;
}
.btn.btn-primary {
  color: #ffffff;
  background: #3b78e7;
  border: 1px solid #0064b6;
}
.btn.btn-primary.new-project {
  background: #ffffff;
  border: 1px solid #16a085;
  color: #16a085;
}
.btn.btn-primary:hover {
  color: #ffffff;
  background: #2361d3;
  border: 1px solid #16a085;
}
.btn.btn-primary:active {
  color: #ffffff;
  background: #16a085;
  border: 1px solid #16a085;
}
.btn.btn-primary[disabled],
.btn.btn-primary.disabled {
  cursor: not-allowed;
  background: #fafafa;
  border: 1px solid #cdcdcd;
  color: #666666;
}
.btn.dropdown-toggle {
  padding: 6px 10px 6px 16px;
}
.btn.btn-none {
  display: none;
}
.btn.btn-info {
  color: #ffffff;
  background: #51a3ff;
  border: 1px solid #4e80f5;
}
.btn.btn-info:hover {
  color: #ffffff;
  background: #4e80f5;
  border: 1px solid #4e80f5;
}
.btn.btn-info:active {
  color: #ffffff;
  background: #4e80f5;
  border: 1px solid #4e80f5;
}
.btn.btn-info[disabled],
.btn.btn-info.disabled {
  cursor: not-allowed;
  background: #e8e8e8;
  border: 1px solid #cdcdcd;
  color: #666666;
}
.btn.btn-danger {
  color: #ffffff;
  background: #e74c3c;
  border: 1px solid #c0392b;
}
.btn.btn-danger:hover {
  color: #ffffff;
  background: #c0392b;
  border: 1px solid #c0392b;
}
.btn.btn-danger:active {
  color: #ffffff;
  background: #c0392b;
  border: 1px solid #c0392b;
}
.btn.btn-danger[disabled],
.btn.btn-danger.disabled {
  cursor: not-allowed;
  background: #fafafa;
  border: 1px solid #cdcdcd;
  color: #666666;
}
.btn.btn-warning {
  color: #ffffff;
  background: #f39c12;
  border: 1px solid #e67e22;
}
.btn.btn-warning:hover {
  color: #ffffff;
  background: #e67e22;
  border: 1px solid #e67e22;
}
.btn.btn-warning:active {
  color: #ffffff;
  background: #e67e22;
  border: 1px solid #e67e22;
}
.btn.btn-warning[disabled],
.btn.btn-warning.disabled {
  cursor: not-allowed;
  background: #e8e8e8;
  border: 1px solid #cdcdcd;
  color: #666666;
}
.btn.btn-sm {
  min-width: 28px;
  color: #51a3ff;
  background: #ffffff;
  border: 1px solid #c2d3eb;
}
.btn.btn-sm > i {
  margin-right: 0;
}
.btn.btn-sm:hover {
  color: #ffffff;
  background: #51a3ff;
  border: 1px solid #4e80f5;
}
.btn.btn-sm:active {
  color: #4e80f5;
  background: #fafafa;
  border: 1px solid #51a3ff;
}
.btn.btn-refresh {
  color: #313949;
  background: #ffffff;
  border: 1px solid #c2d3eb;
}
.btn.btn-refresh:hover {
  color: #4e80f5;
  background: #f6f9ff;
  border: 1px solid #4e80f5;
}
.btn.btn-refresh:active {
  color: #4e80f5;
  background: #c2d3eb;
  border: 1px solid #51a3ff;
}
.btn.btn-time.active {
  background: #51a3ff;
  color: #ffffff;
}
.btn:focus {
  outline: 0 none;
}
/* btn-item */
.btn-item-group {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.btn-item-group button,
.btn-item-group > a {
  color: #313949;
  float: left;
  border: 1px solid #c2d3eb;
  line-height: 14px;
  height: 28px;
  background: #ffffff;
  padding: 8px 10px;
  outline: none;
  min-width: 80px;
  text-align: center;
  border-radius: 2px;
}
.btn-item-group button.least-width,
.btn-item-group > a.least-width {
  min-width: 77px;
}
.btn-item-group button:not(:first-child):not(:last-child):not(.dropdown-toggle),
.btn-item-group > a:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0;
}
.btn-item-group button:first-child:not(.dropdown-toggle),
.btn-item-group > a:first-child:not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-item-group button:last-child:not(.dropdown-toggle),
.btn-item-group > a:last-child:not(.dropdown-toggle) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-item-group button + button,
.btn-item-group > a + button,
.btn-item-group button + a,
.btn-item-group > a + a {
  margin-left: -1px;
}
.btn-item-group button.active,
.btn-item-group > a.active {
  color: #fff;
  background: #51a3ff;
  border: 1px solid #51a3ff;
}
.btn-item-group button.active-1,
.btn-item-group > a.active-1 {
  color: #51a3ff;
  background: #ffffff;
  border: 1px solid #51a3ff;
}
.btn-item-group button.active-1 + button,
.btn-item-group > a.active-1 + button,
.btn-item-group button.active-1 + a,
.btn-item-group > a.active-1 + a {
  border-left: 1px solid #51a3ff;
}
.btn-item-group button.disabled,
.btn-item-group > a.disabled {
  background: #f0f0f0;
}
.btn-item-group .btn-small button {
  line-height: 28px;
  height: 28px;
  padding: 0 10px;
}
.btn-item-group .type-btn-large {
  padding: 10px;
  height: auto;
  width: 160px;
  height: 90px;
  margin-right: 15px;
  margin-bottom: 15px;
}
.btn-item-group .type-btn-large.active {
  color: #51a3ff;
  background: #fff;
}
.btn-item-group .type-btn-large span {
  display: block;
}
.btn-item-group .type-btn-large span.description {
  margin-top: 10px;
}
.btn-item-group.middle > button {
  min-width: 100px;
}
.btn-item-group > .dropdown {
  margin-left: -1px;
  margin-right: 0;
}
.btn-item-group > .dropdown > .dropdown-toggle {
  border: 1px solid #c2d3eb;
  background: #ffffff;
}
.btn-item-group > .dropdown > .dropdown-toggle.active {
  color: #4e80f5;
  background-color: #f6f9ff;
}
.btn-item-group > .dropdown + .btn,
.btn-item-group > .dropdown + button {
  margin-left: -1px;
}
.btn-item-group > .dropdown.active > .dropdown-toggle {
  background-color: #fafafa;
}
.btn-item-group > .dropdown:not(:first-child):not(:last-child) > .dropdown-toggle {
  border-radius: 0;
}
.btn-item-group > .dropdown:first-child > .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-item-group > .dropdown:last-child > .dropdown-toggle {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
/**
 * modal style
 */
.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.modal .modal-dialog {
  height: 100%;
}
.modal .modal-dialog .modal-common {
  width: 750px;
}
.modal .modal-dialog .modal-common.modal-smallest {
  width: 450px;
}
.modal .modal-dialog .modal-common.modal-smallest .modal-body {
  min-height: 60px;
}
.modal .modal-dialog .modal-common.modal-largest {
  width: 950px;
}
.modal .modal-dialog .modal-common.modal-maxlargest {
  width: 1150px;
}
.modal .modal-dialog .modal-smallest,
.modal .modal-dialog .modal-common {
  position: absolute;
  left: 50%;
  top: 45%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1050;
  background-color: #ffffff;
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  outline: 0;
  padding: 0 20px;
}
.modal .modal-dialog .modal-smallest .form-title,
.modal .modal-dialog .modal-common .form-title {
  color: #313949;
  font-weight: bold;
  margin: 0 0 20px;
  padding-bottom: 9px;
  border-bottom: 1px solid #e5e5e5;
  display: block;
  line-height: 20px;
}
.modal .modal-dialog .modal-smallest .form-title .question-tip,
.modal .modal-dialog .modal-common .form-title .question-tip {
  margin-left: 20px;
}
.modal .modal-dialog .modal-smallest.modal-sm,
.modal .modal-dialog .modal-common.modal-sm {
  width: 800px;
}
.modal .modal-dialog .modal-smallest.modal-lg,
.modal .modal-dialog .modal-common.modal-lg {
  width: 1000px;
}
.modal .modal-dialog .modal-smallest.modal-lg .modal-body,
.modal .modal-dialog .modal-common.modal-lg .modal-body {
  padding: 0 40px;
}
.modal .modal-dialog .modal-middle {
  width: 1100px;
}
.modal .modal-dialog .modal-middle .modal-body .left-modal {
  float: left;
  width: 70%;
}
.modal .modal-dialog .modal-middle .modal-body .right-modal {
  float: right;
  width: 30%;
  padding-left: 20px;
}
.modal .modal-dialog .modal-header {
  border-radius: 2px 2px 0 0;
  padding: 15px 0;
  border-bottom: 1px solid #e5e5e5;
  position: relative;
  margin-bottom: 20px;
  height: 60px;
}
.modal .modal-dialog .modal-header h3 {
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
}
.modal .modal-dialog .modal-header .close {
  position: absolute;
  right: 0;
  top: 15px;
  font-size: 30px;
  font-weight: normal;
  line-height: 1;
  color: #999999;
  text-shadow: none;
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  float: none;
  opacity: 1;
  z-index: 1;
  filter: alpha(opacity=100);
}
.modal .modal-dialog .modal-body {
  position: relative;
  margin-bottom: 30px;
}
.modal .modal-dialog .modal-body .tab-content {
  margin-bottom: 0;
}
.modal .modal-dialog .modal-body .tab-content .control-items2 .table {
  margin-bottom: 0;
}
.modal .modal-dialog .modal-body .alert {
  text-align: left;
  padding-left: 20px;
}
.modal .modal-dialog .modal-body .table-content .table tr td {
  padding: 7px 16px;
}
.modal .modal-dialog .modal-body .table-content .table tr td .radiobox {
  line-height: 19px;
  height: 19px;
}
.modal .modal-dialog .modal-body .table-content .ng-table-pager {
  position: static;
}
.modal .modal-dialog .modal-body .table-content .ng-table-pager .pagination {
  margin-bottom: 5px;
}
.modal .modal-dialog .modal-footer {
  height: 64px;
  margin: 0 -20px;
  padding: 15px;
  text-align: center;
  border-top: solid 1px #e5e5e5;
  background-color: #fafafa;
  border-radius: 0 0 2px 2px;
}
.modal .modal-dialog .modal-footer .btn:last-child {
  margin-right: 0;
}
.modal.in {
  opacity: 1;
  filter: alpha(opacity=100);
}
.modal.modal-absolute .modal-dialog .modal-smallest,
.modal.modal-absolute .modal-dialog .modal-common {
  top: 3%;
  margin-bottom: 20px;
  -webkit-transform: translate(-50%, 0%);
  -moz-transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
  -o-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
}
.modal .modal-loading {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
}
.modal .modal-loading .modal-loading-backup {
  background: #333;
  opacity: 0.3;
  width: 100%;
  height: 100%;
}
.modal .modal-loading i {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 34px;
  margin-left: -25px;
  margin-top: -29px;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #333333;
  filter: alpha(opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.modal-backdrop.in {
  filter: alpha(opacity=50);
  opacity: 0.5;
}
/**
 * alert
 */
.alert {
  padding: 8px 20px;
  border-radius: 2px;
  background: none;
  margin-bottom: 15px;
  position: relative;
  /* &.alert-info{
    		font-size: 12px;
    } */
}
.alert.alert-no-close.multi-mes {
  padding: 7px 10px;
}
.alert.multi-mes {
  margin: 0 auto 15px;
  padding: 5px 24px 5px 20px;
}
.alert.multi-mes .close {
  top: 7px;
}
.alert.multi-mes .alert-content .alert-text {
  line-height: 22px;
}
.alert.long-tips {
  margin: 0 auto 10px;
}
.alert.long-tips .close {
  top: 8px;
}
.alert .close,
.alert .alert-close {
  position: absolute;
  right: 10px;
  top: 15px;
  color: #666666;
  font-weight: normal;
  opacity: 0.5;
  filter: alpha(opacity=50);
  text-shadow: 0 0px 0 #ffffff;
  background: none;
  border: none;
  padding: 0;
}
.alert .alert-content .alert-text {
  display: inline-block;
  line-height: 28px;
}
.alert .alert-content .alert-item {
  display: inline-block;
  float: right;
  margin-right: 20px;
}
.alert .del-cont {
  text-align: center;
  min-height: 170px;
  line-height: 30px;
  font-size: 16px;
  padding: 70px 30px;
}
.alert .del-cont span {
  text-align: center;
}
.alert .del-cont .isolation {
  display: inline-block;
  width: 100%;
  font-size: 14px;
  color: #666;
  text-align: left;
}
.alert .del-cont .cancelDes {
  display: inline-block;
  width: 100%;
  font-size: 14px;
  color: #666;
  text-align: center;
}
.alert .del-cont .normal {
  color: #666;
}
.alert .btn-item {
  border-top: solid 1px #e5e5e5;
  padding: 15px 0 16px;
  margin: 0 20px;
  text-align: center;
}
.alert .btn-item .btn {
  margin: 0 5px;
}
.alert.alert-success {
  color: #1abc9c;
  background-color: #c5eee6;
  border-color: #1abc9c;
}
.alert.alert-success .close,
.alert.alert-success .alert-close {
  color: #1abc9c;
}
.alert.alert-building,
.alert.alert-info {
  color: #4e80f5;
  background-color: #f6f9ff;
  border-color: #c2d3eb;
}
.alert.alert-building .close,
.alert.alert-info .close,
.alert.alert-building .alert-close,
.alert.alert-info .alert-close {
  color: #4e80f5;
}
.alert.alert-gray-font {
  color: #666666;
  background-color: #f6f9ff;
  border-color: #c2d3eb;
}
.alert.alert-gray-font .close,
.alert.alert-gray-font .alert-close {
  color: #666666;
}
.alert.alert-warning {
  color: #e67e22;
  background-color: #fff4e0;
  border-color: #f3ca86;
}
.alert.alert-warning .close,
.alert.alert-warning .alert-close {
  color: #e67e22;
}
.alert.alert-tips {
  color: #666666;
  background-color: #f6f9ff;
  border-color: #d0d5e0;
}
.alert.alert-tips .close,
.alert.alert-tips .alert-close {
  color: #666666;
}
.alert.alert-danger,
.alert.alert-error {
  color: #e74c3c;
  background-color: #f6cfc8;
  border-color: #e74c3c;
}
.alert.alert-danger .close,
.alert.alert-error .close,
.alert.alert-danger .alert-close,
.alert.alert-error .alert-close {
  color: #e74c3c;
}
.delete-wrap {
  background: rgba(0, 0, 0, 0.5);
  z-index: 1050;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.delete-wrap .delete-alert {
  border-radius: 2px;
  background-color: #ffffff;
  position: fixed;
  z-index: 1050;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -55%);
  -ms-transform: translate(-50%, -55%);
  transform: translate(-50%, -55%);
  -webkit-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  opacity: 0;
  filter: alpha(opacity=0);
}
.delete-wrap .delete-alert.alert-lg .alert {
  width: 600px;
}
.delete-wrap .delete-alert.alert-TBDS {
  height: 130px;
  background-color: transparent;
}
.delete-wrap .delete-alert.alert-TBDS span {
  display: block;
  color: #fff;
}
.delete-wrap .delete-alert .alert {
  padding: 0;
  background: none;
  border: none;
  width: 450px;
  min-height: 234px;
  margin-bottom: 0;
}
.delete-wrap .delete-alert .alert .close {
  font-size: 24px;
  color: #999999;
}
.delete-wrap .delete-alert .alert.alert-info {
  color: #666666;
}
.delete-wrap .delete-alert.delete-show {
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 1;
  filter: alpha(opacity=100);
}
/**
 * alert-backdrop
 */
.alert-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
.alert-backdrop .alert {
  position: fixed;
  left: 50%;
  top: 15%;
  min-width: 500px;
  -webkit-transform: translateX(-50%) translateY(-15%);
  -moz-transform: translateX(-50%) translateY(-15%);
  -ms-transform: translateX(-50%) translateY(-15%);
  -o-transform: translateX(-50%) translateY(-15%);
  transform: translateX(-50%) translateY(-15%);
  margin-bottom: 0px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}
/**
 * alert-page
 */
.logined .page-alert-list.show-dash-alert {
  display: block;
}
.auth .page-alert-list.show-dash-alert {
  display: none;
}
.page-alert-list {
  width: 300px;
  display: none;
  margin-top: 70px;
  z-index: 1060;
  right: 0;
  top: 0;
  position: fixed;
}
.page-alert-list .alert {
  padding: 15px 20px 15px 10px;
}
.page-alert-list .alert .alert-content .alert-text {
  line-height: 24px;
}
.page-alert-list .alert-close {
  right: 5px;
}
.modal .modal-dialog .modal-step .steps-sytle-ver {
  width: 400px;
  float: left;
  margin-left: 80px;
  margin-top: 5px;
}
.modal .modal-dialog .modal-step .modal-title {
  float: left;
  margin-top: 28px;
  margin-left: 60px;
  height: auto;
  line-height: 20px;
  z-index: 1;
}
.modal .modal-dialog .modal-step .modal-header {
  background: #f6f9ff;
  border-bottom: 1px solid #c2d3eb;
  height: 80px;
  margin: 0 -20px 20px;
}
.modal .modal-dialog .modal-step .modal-header .close {
  right: 20px;
  z-index: 1;
}
.modal .modal-dialog .modal-step .modal-body {
  /*快捷创建网络*/
}
.modal .modal-dialog .modal-step .modal-body.net-body .alert.editNet-alert {
  text-align: left;
}
.modal .modal-dialog .modal-step .modal-body.net-body .control-group .control-label {
  width: 19%;
}
.modal .modal-dialog .modal-step .modal-body.net-body .control-group .controls {
  width: 81%;
}
.modal .modal-dialog .modal-step .modal-body.net-body .control-group.bind-group .control-label.bind-label {
  margin-top: 14px;
}
.modal .modal-dialog .modal-step .modal-body.net-body .control-group.bind-group .controls.bind-controls .bind-bar {
  float: left;
  width: 85%;
}
.modal .modal-dialog .modal-step .modal-body.net-body .control-group.bind-group .controls.bind-controls .bind-input {
  float: left;
  width: 13%;
  margin-top: 14px;
  margin-left: 2%;
}
.modal .modal-dialog .modal-step .modal-body .control-group.errorMsg-group {
  margin-bottom: 0px;
}
.modal .modal-dialog .modal-step .modal-body .control-group .controls.subnet-controls {
  width: 80%;
}
.modal .modal-dialog .modal-step .modal-body .control-group .controls.IPV6-Cidr .cidr-msg {
  float: left;
}
.modal .modal-dialog .modal-step .modal-body .control-group .controls.IPV6-Cidr .cidr-msg .v6error {
  line-height: 1.5;
  font-size: 12px;
  color: #888888;
}
.modal .modal-dialog .modal-step .modal-body .control-group .controls.IPV6-Cidr .cidr-msg .v6error.error {
  color: #e74c3c;
}
.modal .modal-dialog .modal-step .modal-body .control-group .controls.IPV6-Cidr .cidr-input {
  float: left;
  width: 9.5%;
  margin-right: 6px;
}
.modal .modal-dialog .modal-step .modal-body .control-group .controls.IPV6-Cidr .cidr-input input {
  padding: 0 6px;
}
.modal .modal-dialog .modal-step .modal-body .control-group .controls.IPV6-Cidr .cidr-input.cidr-line {
  width: 1%;
  line-height: 32px;
  font-size: 16px;
}
.modal .modal-dialog .modal-step .modal-body .control-group .controls.IPV6-Cidr .cidr-input.cidr-last {
  width: 9.5%;
}
.modal .modal-dialog .modal-step .modal-body .ip_pool .repeat {
  position: relative;
  width: 77%;
}
.modal .modal-dialog .modal-step .modal-body .ip_pool .group {
  position: relative;
  width: 80%;
}
.modal .modal-dialog .modal-step .modal-body .ip_pool .group.create-subnet {
  margin-left: 19%;
  padding-left: 30px;
}
.modal .modal-dialog .modal-step .modal-body .ip_pool .group .control-group .control-label {
  width: 16%;
}
.modal .modal-dialog .modal-step .modal-body .ip_pool .group .control-group .controls {
  width: 70%;
  padding-left: 10px;
}
.modal .modal-dialog .modal-step .modal-body .ip_pool .add-subpool {
  margin-left: 18%;
  float: left;
  margin-bottom: 10px;
}
.modal .modal-dialog .modal-step .modal-body .add {
  position: absolute;
  right: -5px;
  top: 4px;
  font-size: 16px;
}
.modal .modal-dialog .modal-step .modal-body .add a {
  color: #51a3ff;
  font-size: 12px;
}
.modal .modal-dialog .modal-step .modal-body .del {
  position: absolute;
  right: 16px;
  top: 7px;
}
.modal .modal-dialog .modal-step .modal-body .del a {
  color: #e74c3c;
}
.modal .modal-dialog .modal-step.lg-modal .modal-title {
  height: auto;
  line-height: 20px;
}
.modal .modal-dialog .easy-modal .steps-sytle-ver {
  width: 400px;
  float: left;
  margin-left: 80px;
  margin-top: 5px;
}
.modal .modal-dialog .easy-modal .modal-title {
  float: left;
  margin-top: 25px;
  margin-left: 22px;
}
.modal .modal-dialog .easy-modal .modal-header {
  height: 80px;
}
.modal .modal-dialog .easy-modal .modal-header.first-easy {
  height: auto;
  margin-bottom: 0;
  border-bottom: none;
}
.modal .modal-dialog .easy-modal .modal-header.first-easy .steps-sytle-ver {
  margin: -32px auto 18px;
  float: none;
}
.modal .modal-dialog .easy-modal .modal-header.first-easy .btn {
  position: absolute;
  right: 0;
  top: 20px;
  margin: 0;
}
.modal .modal-dialog .easy-modal .modal-header.first-easy .top1 .close {
  font-size: 10px;
  padding: 8px 16px;
  border: 1px solid #7ba0f8;
  color: #3a3a42;
  border-radius: 2px;
  right: -40px;
}
.modal .modal-dialog .easy-modal .modal-header.first-easy .top1 .modal-title {
  height: 60px;
}
.modal .modal-dialog .easy-modal .modal-header.first-easy .top2 {
  text-align: center;
  margin-bottom: 65px;
}
.modal .modal-dialog .easy-modal .modal-header.first-easy .top2 .title {
  font-size: 26px;
  font-weight: bold;
  color: #999999;
  margin-bottom: 12px;
  margin-top: 40px;
  display: block;
}
.modal .modal-dialog .easy-modal .modal-header.first-easy .top2 .tip {
  color: #999999;
}
.modal .modal-dialog .easy-modal .modal-body .control-group.errorMsg-group {
  margin-bottom: 0px;
}
.modal .modal-dialog .easy-modal .modal-body .control-group .controls.subnet-controls {
  width: 80%;
}
.modal .modal-dialog .easy-modal .modal-body .ip_pool .repeat {
  position: relative;
  width: 77%;
}
.modal .modal-dialog .easy-modal .modal-body .ip_pool .group {
  position: relative;
  width: 80%;
}
.modal .modal-dialog .easy-modal .modal-body .ip_pool .group.create-subnet {
  margin-left: 17%;
  padding-left: 30px;
}
.modal .modal-dialog .easy-modal .modal-body .ip_pool .group .control-group {
  width: 49%;
}
.modal .modal-dialog .easy-modal .modal-body .ip_pool .group .control-group .control-label {
  width: 16%;
}
.modal .modal-dialog .easy-modal .modal-body .ip_pool .group .control-group .controls {
  width: 70%;
  padding-left: 10px;
}
.modal .modal-dialog .easy-modal .modal-body .ip_pool .add-subpool {
  margin-left: 18%;
  float: left;
  margin-bottom: 10px;
}
.modal .modal-dialog .easy-modal .modal-body .add {
  position: absolute;
  right: 1px;
  top: 6px;
  font-size: 16px;
}
.modal .modal-dialog .easy-modal .modal-body .add a {
  color: #51a3ff;
}
.modal .modal-dialog .easy-modal .modal-body .del {
  position: absolute;
  right: 25px;
  top: 7px;
}
.modal .modal-dialog .easy-modal .modal-body .del a {
  color: #e74c3c;
}
.modal .modal-dialog .easy-modal .wel-tips {
  margin-bottom: 70px;
}
.modal .modal-dialog .easy-modal .wel-tips .steps-sytle-ver {
  width: 370px;
  margin: 0 auto;
  float: none;
}
.modal .modal-dialog .easy-modal .wel-tips .steps-sytle-ver li .inner i {
  width: 42px;
  height: 42px;
  line-height: 42px;
  font-size: 20px;
  margin-bottom: 18px;
}
.modal .modal-dialog .easy-modal .wel-tips .steps-sytle-ver li .inner span {
  color: #666666;
  font-size: 14px;
  font-weight: normal;
}
.modal .modal-dialog .easy-modal .wel-tips .steps-sytle-ver li .inner:before {
  margin-top: -20px;
}
.modal .modal-dialog .easy-modal .wel-tips .steps-sytle-ver li .inner:after {
  margin-top: -20px;
}
.modal .modal-dialog .easy-modal .wel-tips .steps-sytle-ver li.step-start .inner i {
  margin-left: -21px;
}
.modal .modal-dialog .easy-modal .wel-tips .steps-sytle-ver li.step-end .inner i {
  margin-right: -21px;
}
.steps {
  float: right;
  padding-right: 90px;
}
.steps li {
  width: 125px;
  float: left;
}
.steps li span {
  display: block;
}
.steps li i {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: #51a3ff;
  text-align: center;
  color: #fff;
  z-index: 1;
  position: relative;
}
.steps li.step-start:after {
  width: 105px;
  height: 5px;
  margin-left: 20px;
  position: relative;
  bottom: 13px;
  background: #cdcdcd;
}
.steps li.step-middle {
  width: 230px;
  text-align: center;
}
.steps li.step-middle:after {
  width: 230px;
  height: 5px;
  position: relative;
  bottom: 13px;
  background: #cdcdcd;
}
.steps li.step-end div {
  float: right;
}
.steps li.step-end:after {
  content: "";
  width: 105px;
  height: 5px;
  position: relative;
  bottom: 13px;
  background: #cdcdcd;
}
.steps li.bigger i {
  width: 46px;
  height: 46px;
  border-radius: 23px;
  padding: 8px;
  font-size: 20px;
}
.steps li.bigger:after {
  bottom: 22px;
}
.steps li.active:after {
  background: #51a3ff;
}
.rzslider-box {
  position: relative;
  height: 50px;
}
.rzslider-box .rzslider {
  width: 80%;
  float: left;
  height: 50px;
  margin: 0;
  padding: 10px 0 18px;
}
.rzslider-box .rzslider .rz-bar {
  background: #d6d6d6;
}
.rzslider-box .rzslider .rz-ticks {
  top: 7px;
}
.rzslider-box .rzslider .rz-ticks .rz-tick {
  background: #d6d6d6;
}
.rzslider-box .rzslider .rz-bubble {
  bottom: 0;
}
.rzslider-box .rzslider .rz-pointer {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: solid 1px #51a3ff;
  top: 2px;
}
.rzslider-box .rzslider .rz-pointer:after {
  top: 6px;
  left: 6px;
  width: 6px;
  height: 6px;
  background-color: #51a3ff;
}
.rzslider-box input {
  float: left;
  width: 10%;
  margin-left: 2%;
  padding: 0 8px;
}
.rzslider-box .unit {
  width: 8%;
  float: right;
  text-align: center;
  padding: 8px 0;
}
/* table */
.table {
  width: 100%;
  margin-bottom: 15px;
  border: 1px solid #e5e5e5;
  border-bottom: 0 none;
}
.table .table-header-filter .table-header-dropdown {
  position: absolute;
  display: none;
  top: 100%;
  left: 0;
  background: #fff;
  border: 1px solid #51A3FF;
  z-index: 1;
  min-width: 90px;
}
.table .table-header-filter .table-header-dropdown li {
  line-height: 32px;
  color: #666;
  padding: 0 10px;
  cursor: pointer;
  font-weight: normal;
  text-transform: none;
}
.table .table-header-filter .table-header-dropdown li:hover {
  background: #fafafa;
  color: #51A3FF;
}
.table .table-header-filter .table-header-dropdown.open {
  display: block;
}
.table .table-header-filter .table-header-icon {
  position: relative;
  display: inline-block;
  margin-left: 5px;
}
.table .table-header-filter .table-header-icon > i {
  color: #c2d3eb;
  cursor: pointer;
}
.table .checkbox {
  line-height: 20px;
  height: 20px;
  margin: 0;
  cursor: pointer;
  position: relative;
  font-weight: normal;
}
.table .checkbox i,
.table .checkbox .iconfont {
  margin-right: 5px;
  color: #c2d3eb;
  width: 20px;
  height: 18px;
  font-size: 16px;
  vertical-align: middle;
  font-style: normal;
}
.table .checkbox i:before,
.table .checkbox .iconfont:before {
  content: "\E6B1";
  font-weight: lighter;
}
.table .checkbox input {
  position: absolute;
  left: -9999px;
  opacity: 0;
}
.table .checkbox input:checked + i:before {
  content: "\E825";
  color: #4e80f5;
}
.table .checkbox input:indeterminate + i:before {
  content: "\E6B0";
  color: #4e80f5;
}
.table .question-tip {
  margin-right: 0;
}
.table caption {
  font-size: 16px;
  padding: 4px 8px;
  margin-bottom: 12px;
}
.table caption span {
  margin-right: 20px;
}
.table thead tr th {
  line-height: 23px;
  padding: 6px 15px;
  font-weight: bold;
  background-color: #fafafa;
  white-space: nowrap;
  border-bottom: solid 1px #e5e5e5;
  text-transform: capitalize;
}
.table thead tr th.sortable .sort-indicator {
  display: inline-block;
  position: relative;
  padding-right: 12px;
  cursor: pointer;
  min-width: 40px;
  white-space: nowrap;
  line-height: 20px;
}
.table thead tr th.sortable .sort-indicator:before {
  content: "";
  position: absolute;
  right: 0px;
  bottom: 4px;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-top: 4px solid #cdcdcd;
  border-right: 4px solid transparent;
}
.table thead tr th.sortable .sort-indicator:after {
  content: "";
  position: absolute;
  right: 0px;
  top: 4px;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-bottom: 4px solid #cdcdcd;
  border-right: 4px solid transparent;
}
.table thead tr th.sort-asc .sort-indicator:after {
  border-bottom-color: #313949;
}
.table thead tr th.sort-desc .sort-indicator:before {
  border-top-color: #313949;
}
.table thead tr th:first-child .checkbox > label > i {
  float: none;
  vertical-align: baseline;
}
.table thead tr th:last-child {
  border-right: none;
}
.table tbody tr {
  /* &:last-child {
                td {
                    border-bottom: none;
                }
            } */
}
.table tbody tr td {
  padding: 14px 16px;
  border-bottom: solid 1px #e5e5e5;
  vertical-align: middle;
  background-color: #ffffff;
  position: relative;
}
.table tbody tr td .nowrap {
  white-space: nowrap;
}
.table tbody tr td > a {
  color: #51a3ff;
}
.table tbody tr td .ars.all {
  border-left: 2px solid #c2d3eb;
  height: 100%;
  position: absolute;
  left: 30px;
  top: 0;
}
.table tbody tr td .ars.half-bottom {
  border-left: 2px solid #c2d3eb;
  position: absolute;
  left: 30px;
  height: 30%;
  bottom: 0;
}
.table tbody tr td .ars.half-top {
  border-left: 2px solid #c2d3eb;
  position: absolute;
  left: 30px;
  height: 50%;
  top: 0;
}
.table tbody tr td .ars.first-half-bottom {
  border-left: 2px solid #c2d3eb;
  position: absolute;
  left: 30px;
  height: 50%;
  bottom: 0;
}
.table tbody tr td .edit-name {
  width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.table tbody tr td .edit-name.show {
  padding-left: 50px;
}
.table tbody tr td .edit-name.show .arrow-right {
  position: absolute;
  top: 46%;
  left: 30px;
  width: 25px;
}
.table tbody tr td .edit-name.show .arrow-right .line {
  border-top: 2px solid #c2d3eb;
  width: 25px;
  position: absolute;
}
.table tbody tr td .edit-name.show .arrow-right .arr {
  width: 7px;
  height: 7px;
  background: transparent;
  border-top: 2px solid #c2d3eb;
  border-right: 2px solid #c2d3eb;
  position: absolute;
  right: 0;
  top: -3px;
  transform: rotate(45deg);
}
.table tbody tr td .edit-name.show .arrow-right .error {
  background: none;
  position: absolute;
  top: -5px;
  left: -6px;
  padding: 0;
}
.table tbody tr td .edit-name.show i {
  display: inline-block;
  color: #c2d3eb;
}
.table tbody tr td .edit-name.show:hover i {
  display: inline-block;
}
.table tbody tr td .edit-name.edit-sm {
  width: 100px;
}
.table tbody tr td .edit-name.edit-sm > span {
  max-width: 100px;
}
.table tbody tr td .edit-name.edit-lg {
  width: 200px;
}
.table tbody tr td .edit-name.edit-lg > span {
  max-width: 180px;
}
.table tbody tr td .edit-name:hover > i {
  display: none;
}
.table tbody tr td .edit-name > a {
  color: #51a3ff;
  width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}
.table tbody tr td .edit-name > a em {
  background-color: #f39c12;
  color: #ffffff;
  padding: 0 2px;
  margin-right: 2px;
}
.table tbody tr td .edit-name > span {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 130px;
  float: left;
}
.table tbody tr td .edit-name > i {
  display: none;
  cursor: pointer;
}
.table tbody tr td .des {
  width: 300px;
  display: block;
  word-break: break-all;
}
.table tbody tr td .des.limit {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.table tbody tr td .des.des-sm {
  width: 200px;
}
.table tbody tr td .des.des-break {
  word-wrap: break-word;
  word-break: break-all;
}
.table tbody tr td .des.des-lg {
  max-width: 500px;
}
.table tbody tr td .btn-item > .btn {
  min-width: auto;
  color: #51a3ff;
  margin-right: 10px;
  padding: 0;
  line-height: 15px;
  border: none;
  vertical-align: baseline;
  font-size: 13px;
  height: auto;
}
.table tbody tr td .btn-item > .btn:last-child {
  margin-right: 0;
}
.table tbody tr td .btn-item > .more {
  position: relative;
  color: #51a3ff;
  padding: 4px 6px 0;
}
.table tbody tr td .btn-item > .more .icons-more {
  position: relative;
  font-family: fontello;
  font-size: 10px;
  font-style: normal;
  padding: 0 2px;
}
.table tbody tr td .btn-item > .more .icons-more:before {
  content: "\E80A";
}
.table tbody tr td .btn-item > .more > .level1 {
  display: none;
  position: absolute;
  top: 18px;
  min-width: 72px;
  right: -1px;
  border: solid 1px #e5e5e5;
  background-color: #ffffff;
  z-index: 1;
  padding: 4px 0;
}
.table tbody tr td .btn-item > .more > .level1 > li .btn {
  display: block;
  padding: 4px 8px;
  line-height: 1.25;
  text-align: left;
  border-radius: 0;
  width: 100%;
}
.table tbody tr td .btn-item > .more > .level1 > li:hover .btn {
  background-color: #e5e5e5;
}
.table tbody tr td .btn-item > .more:hover {
  background-color: #ffffff;
  border: solid 1px #e5e5e5;
  padding: 3px 5px 0;
}
.table tbody tr td .btn-item > .more:hover > .level1 {
  display: block;
}
.table tbody tr td .btn-item > .more:hover .icons-more:before {
  content: "\E808";
}
.table tbody tr td .btn-item > .more:hover:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom: solid 1px #ffffff;
  z-index: 10;
}
.table tbody tr td a.disabled {
  color: #cdcdcd;
}
.table tbody tr td .sub-val {
  color: #cdcdcd;
}
.table tbody tr td .date {
  white-space: nowrap;
}
.table tbody tr td:first-child .checkbox > label > i {
  float: none;
  vertical-align: baseline;
}
.table tbody tr td:last-child {
  border-right: none;
}
.table tbody tr td .progress {
  height: 8px;
  margin: 6px 15px 6px 0;
  width: 70%;
  float: left;
  box-shadow: none;
  border-radius: 2px;
}
.table tbody tr td .progress.active {
  padding: 0;
  background-color: #e5e5e5;
}
.table tbody tr:hover td {
  background-color: #f6f9ff;
}
.table tbody tr.disabled {
  color: #cdcdcd;
}
.table tbody tr.disabled td {
  background-color: #fafafa;
}
.table tbody tr.disabled .checkbox label {
  cursor: default;
}
.table tbody tr.disabled .edit-name a {
  color: #cdcdcd;
}
.table tbody tr.disabled .edit-name em {
  background-color: #e5e5e5;
}
.table tbody tr.disabled .btn-item .btn,
.table tbody tr.disabled .btn-item .more {
  cursor: not-allowed;
  color: #cdcdcd;
}
.table tbody tr .td-fault {
  width: 10%;
}
.table tbody tr.dashed td {
  border-bottom: 1px dashed #e5e5e5;
}
.table-border {
  border: solid 1px #e5e5e5;
}
.table-border tbody tr td {
  border-right: solid 1px #e5e5e5;
}
.table-border tbody tr td:nth-child(even) {
  background-color: #ffffff;
}
.table-border tbody tr td:nth-child(odd) {
  background-color: #f6f9ff;
}
.table-striped tbody tr:nth-child(odd) td {
  background-color: #ffffff;
}
.table-striped tbody tr:nth-child(even) td {
  background-color: #f6f9ff;
}
.table-striped-column tbody tr td:nth-child(odd) {
  background-color: #f6f9ff;
}
.table-striped-column tbody tr td:nth-child(even) {
  background-color: #ffffff;
}
.table-check th:first-child {
  width: 44px;
  padding: 4px;
  text-align: center;
}
.table-check td:first-child {
  width: 44px;
  padding: 4px;
  text-align: center;
}
.detailInner table thead tr th {
  padding: 6px 15px;
}
.detailInner table tbody tr td {
  padding: 7px 16px;
}
.detailInner table tbody tr td .checkbox {
  line-height: 19px;
  height: 19px;
}
.dash-table {
  border: 1px solid #e5e5e5;
  padding: 0 10px;
}
.dash-table .table {
  border: none;
  margin-bottom: 0;
  border-bottom: 0;
}
.dash-table .table thead tr .all {
  padding: 0;
  border-bottom: 0;
}
.dash-table .table thead tr .all .in {
  display: block;
  padding: 6px 24px;
  background: #fafafa;
  border-bottom: 1px solid #e5e5e5;
}
.dash-table .table thead tr .all-left .in {
  margin-left: -10px;
}
.dash-table .table thead tr .all-right .in {
  margin-right: -10px;
}
.dash-table .table tbody tr:last-child td {
  border-bottom: none;
}
.dash-table .table tbody tr:last-child .all .in {
  border-bottom: 0;
}
.dash-table .table tbody tr .all {
  padding: 0;
  border-bottom: 0;
}
.dash-table .table tbody tr .all .in {
  display: block;
  padding: 14px 24px;
  height: 55px;
  border-bottom: 1px solid #e5e5e5;
}
.dash-table .table tbody tr .all-left .in {
  margin-left: -10px;
}
.dash-table .table tbody tr .all-right .in {
  margin-right: -10px;
}
.table-content.table-small.limit-table {
  height: 220px;
}
.table-content.table-small .table-wrap {
  max-height: 220px;
  overflow-y: auto;
  margin-bottom: 5px;
}
.table-content.table-small .table {
  border: 1px solid #e5e5e5;
  border-bottom: none;
}
.table-content.table-small .table thead {
  font-size: 12px;
}
.table-content.table-small .table thead tr th {
  height: 34px;
  padding: 5px 15px;
}
.table-content.table-small .table tbody {
  font-size: 12px;
}
.table-content.table-small .table tbody tr td {
  height: 34px;
  padding: 5px 15px;
}
.small-padding-table thead tr th {
  padding: 6px 10px;
}
.small-padding-table tbody tr td {
  padding: 7px 10px;
  max-width: 120px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
/* pagination */
.ng-table-pager,
.table-pager {
  text-align: right;
  position: absolute;
  right: 30px;
}
.page-wrap {
  display: inline-block;
}
.page-wrap a {
  display: inline-block;
  line-height: 24px;
  padding: 0 2px;
  color: #666666;
}
.page-wrap a:first-child {
  float: left;
}
.page-wrap a:last-child {
  float: right;
}
.page-wrap a:hover {
  color: #51a3ff;
}
.custom-page .ng-table-counts {
  vertical-align: top;
  width: 70px;
}
.custom-page .ng-table-counts .counts-select {
  width: 100%;
  margin-top: -2px;
}
.pagination {
  margin-bottom: 15px;
  display: inline-block;
}
.pagination li {
  float: left;
  margin: 0 2px;
}
.pagination li.total {
  height: 24px;
  min-width: 24px;
  line-height: 24px;
  margin-right: 15px;
}
.pagination li a {
  display: block;
  height: 24px;
  min-width: 24px;
  line-height: 24px;
  color: #666666;
  border-radius: 2px;
  text-align: center;
}
.pagination li a span {
  display: block;
}
.pagination li a:hover,
.pagination li a.on {
  background: #51a3ff;
  color: #ffffff;
}
.pagination li.active a span {
  background: #51a3ff;
  color: #ffffff;
  border-radius: 2px;
}
.pagination li:first-child,
.pagination li:last-child {
  font-size: 12px;
  font-family: simsun, "\5B8B\4F53";
}
.pagination li:first-child a,
.pagination li:last-child a {
  border: 1px solid #e5e5e5;
  color: #666666;
  line-height: 24px;
}
.pagination li:first-child a:hover,
.pagination li:last-child a:hover {
  background: none;
  border: solid 1px #51a3ff;
  color: #51a3ff;
}
.pagination li.disabled a {
  color: #e5e5e5;
}
.pagination li.disabled a:hover {
  color: #e5e5e5;
  background: #ffffff;
}
.operatelog-search .btn {
  width: 100px !important;
}
.operatelog-keyword-type {
  width: 130px;
}
.operatelog-keyword-type .btn {
  width: 130px !important;
}
.operatelog-dropdown {
  padding-left: 10px !important;
}
.sort-container {
  width: 10px;
  line-height: 24px;
  float: left;
  margin-left: 4px;
}
.sort-container .sort-up {
  float: left;
  border: 4px solid transparent;
  border-bottom-color: #cdcdcd;
  margin-bottom: 4px;
  cursor: pointer;
}
.sort-container .sort-up:hover {
  border-bottom-color: #333;
}
.sort-container .sort-down {
  float: left;
  border: 4px solid transparent;
  border-top-color: #cdcdcd;
  cursor: pointer;
}
.sort-container .sort-down:hover {
  border-top-color: #333;
}
.sort-text {
  float: left;
}
/** checkbox && radio **/
.checkbox,
.radio {
  display: block;
  margin: 0;
  position: relative;
  height: 20px;
}
.checkbox input[type="checkbox"][disabled] + i,
.radio input[type="checkbox"][disabled] + i,
.checkbox input[type="radio"][disabled] + i,
.radio input[type="radio"][disabled] + i,
.checkbox input[type="checkbox"][disabled] + .iconfont,
.radio input[type="checkbox"][disabled] + .iconfont,
.checkbox input[type="radio"][disabled] + .iconfont,
.radio input[type="radio"][disabled] + .iconfont {
  cursor: not-allowed;
}
.checkbox input[type="checkbox"][disabled] + i:before,
.radio input[type="checkbox"][disabled] + i:before,
.checkbox input[type="radio"][disabled] + i:before,
.radio input[type="radio"][disabled] + i:before,
.checkbox input[type="checkbox"][disabled] + .iconfont:before,
.radio input[type="checkbox"][disabled] + .iconfont:before,
.checkbox input[type="radio"][disabled] + .iconfont:before,
.radio input[type="radio"][disabled] + .iconfont:before {
  color: #e5e5e5;
}
.checkbox label,
.radio label {
  display: inline-block;
  cursor: pointer;
  font-weight: normal;
  margin-bottom: 0;
  min-height: 20px;
  line-height: 20px;
  padding-left: 0px;
  margin-right: 0px;
}
.checkbox label i,
.radio label i,
.checkbox label .iconfont,
.radio label .iconfont {
  font-size: 16px;
  width: 20px;
  height: 20px;
  font-style: normal;
}
.checkbox label .text,
.radio label .text {
  color: #999999;
  display: block;
  padding-left: 24px;
  font-size: 12px;
}
.checkbox label input:not([type="text"]),
.radio label input:not([type="text"]) {
  position: absolute;
  left: -9999px;
  opacity: 0;
  filter: alpha(opacity=0);
}
.checkbox label input:not([type="text"]):checked ~ .text,
.radio label input:not([type="text"]):checked ~ .text {
  color: #666666;
}
.checkbox label input[type="checkbox"] + i,
.radio label input[type="checkbox"] + i,
.checkbox label input[type="checkbox"] + .iconfont,
.radio label input[type="checkbox"] + .iconfont {
  margin-right: 2px;
  float: left;
}
.checkbox label input[type="checkbox"] + i:before,
.radio label input[type="checkbox"] + i:before,
.checkbox label input[type="checkbox"] + .iconfont:before,
.radio label input[type="checkbox"] + .iconfont:before {
  content: "\E6B1";
  color: #c2d3eb;
}
.checkbox label input[type="checkbox"]:checked + i:before,
.radio label input[type="checkbox"]:checked + i:before {
  content: "\E825";
  color: #4e80f5;
}
.checkbox label input[type="checkbox"]:indeterminate + i:before,
.radio label input[type="checkbox"]:indeterminate + i:before {
  content: "\E6B0";
  color: #4e80f5;
}
.checkbox label input[type="checkbox"][disabled] + i,
.radio label input[type="checkbox"][disabled] + i {
  cursor: not-allowed;
}
.checkbox label input[type="checkbox"][disabled] + i:before,
.radio label input[type="checkbox"][disabled] + i:before {
  color: #e5e5e5;
}
.checkbox label input[type="radio"] + i,
.radio label input[type="radio"] + i,
.checkbox label input[type="radio"] + .iconfont,
.radio label input[type="radio"] + .iconfont {
  margin-right: 2px;
  display: inline-block;
}
.checkbox label input[type="radio"] + i:before,
.radio label input[type="radio"] + i:before,
.checkbox label input[type="radio"] + .iconfont:before,
.radio label input[type="radio"] + .iconfont:before {
  content: "\E6B4";
  color: #c2d3eb;
}
.checkbox label input[type="radio"]:checked + i:before,
.radio label input[type="radio"]:checked + i:before {
  content: "\E6B3";
  color: #4e80f5;
}
.checkbox label input[type="radio"][disabled] + i,
.radio label input[type="radio"][disabled] + i {
  cursor: not-allowed;
}
.checkbox label input[type="radio"][disabled] + i:before,
.radio label input[type="radio"][disabled] + i:before {
  color: #e5e5e5;
}
.checkbox label + label,
.radio label + label {
  margin-left: 10px;
}
.checkbox label.disabled,
.radio label.disabled {
  cursor: not-allowed;
  color: #999999;
}
.checkbox label.disabled input[type="checkbox"] + i:before,
.radio label.disabled input[type="checkbox"] + i:before,
.checkbox label.disabled input[type="radio"] + i:before,
.radio label.disabled input[type="radio"] + i:before,
.checkbox label.disabled input[type="checkbox"] + .iconfont:before,
.radio label.disabled input[type="checkbox"] + .iconfont:before,
.checkbox label.disabled input[type="radio"] + .iconfont:before,
.radio label.disabled input[type="radio"] + .iconfont:before {
  color: #e5e5e5;
}
.checkbox.switch-btn,
.radio.switch-btn {
  display: inline-block;
  height: 28px;
  padding-top: 3px;
}
.checkbox.switch-btn label i,
.radio.switch-btn label i,
.checkbox.switch-btn label .iconfont,
.radio.switch-btn label .iconfont {
  width: 54px;
  height: 24px;
  background: #efefef;
  border: 1px solid #e5e5e5;
  position: relative;
  display: inline-block;
  border-radius: 12px;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -ms-transition: 0.4s;
  transition: 0.4s;
}
.checkbox.switch-btn label input + i,
.radio.switch-btn label input + i,
.checkbox.switch-btn label input + .iconfont,
.radio.switch-btn label input + .iconfont {
  margin-right: 0;
}
.checkbox.switch-btn label input + i:before,
.radio.switch-btn label input + i:before,
.checkbox.switch-btn label input + .iconfont:before,
.radio.switch-btn label input + .iconfont:before {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  text-align: center;
  border-radius: 100%;
  left: 3px;
  top: 2px;
  z-index: 2;
  background: #ffffff;
  box-shadow: 0 0 3px rgba(78, 128, 245, 0.25);
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -ms-transition: 0.4s;
  transition: 0.4s;
}
.checkbox.switch-btn label input + i:hover:before,
.radio.switch-btn label input + i:hover:before,
.checkbox.switch-btn label input + .iconfont:hover:before,
.radio.switch-btn label input + .iconfont:hover:before {
  box-shadow: 0 0 3px rgba(78, 128, 245, 0.35);
}
.checkbox.switch-btn label input:checked + .iconfont,
.radio.switch-btn label input:checked + .iconfont {
  background: #3b78e7;
  border: 1px solid #51a3ff;
}
.checkbox.switch-btn label input:checked + .iconfont:before,
.radio.switch-btn label input:checked + .iconfont:before {
  content: '';
  left: 31px;
  background: #ffffff;
}
.checkbox.vertical,
.radio.vertical {
  height: auto;
  display: inline-block;
}
.checkbox.vertical label,
.radio.vertical label {
  margin-bottom: 10px;
  display: block;
}
.checkbox.vertical label + label,
.radio.vertical label + label {
  margin-left: 0;
}
.checkbox.vertical label:last-child,
.radio.vertical label:last-child {
  margin-bottom: 0;
}
/* 进度条 */
.progress .progress-bar {
  box-shadow: none;
  -webkit-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  /* 96-100 */
  /* 86-95 */
  /* 76-85 */
  /* 66-75 */
  /* 51-65 */
  /* 31-50 */
  /* 0-30 */
}
.progress .progress-bar.progress-bar-redDark {
  background-color: #c0392b;
  fill: #c0392b;
}
.progress .progress-bar.progress-bar-redDark.value {
  color: #c0392b;
  fill: none;
  background: none;
}
.progress .progress-bar.progress-bar-red {
  background-color: #e74c3c;
  fill: #e74c3c;
}
.progress .progress-bar.progress-bar-red.value {
  color: #e74c3c;
  fill: none;
  background: none;
}
.progress .progress-bar.progress-bar-redLight {
  background-color: #ef8c82;
  fill: #ef8c82;
}
.progress .progress-bar.progress-bar-redLight.value {
  color: #ef8c82;
  fill: none;
  background: none;
}
.progress .progress-bar.progress-bar-orangeDark {
  background-color: #e67e22;
  fill: #e67e22;
}
.progress .progress-bar.progress-bar-orangeDark.value {
  color: #e67e22;
  fill: none;
  background: none;
}
.progress .progress-bar.progress-bar-orange {
  background-color: #f39c12;
  fill: #f39c12;
}
.progress .progress-bar.progress-bar-orange.value {
  color: #f39c12;
  fill: none;
  background: none;
}
.progress .progress-bar.progress-bar-orangeLight {
  background-color: #fce6c3;
  fill: #fce6c3;
}
.progress .progress-bar.progress-bar-orangeLight.value {
  color: #fce6c3;
  fill: none;
  background: none;
}
.progress .progress-bar.progress-bar-blueDark {
  background-color: #4e80f5;
  fill: #4e80f5;
}
.progress .progress-bar.progress-bar-blueDark.value {
  color: #4e80f5;
  fill: none;
  background: none;
}
.progress .progress-bar.progress-bar-blue {
  background-color: #51a3ff;
  fill: #51a3ff;
}
.progress .progress-bar.progress-bar-blue.value {
  color: #51a3ff;
  fill: none;
  background: none;
}
.progress .progress-bar.progress-bar-blueLight {
  background-color: #c2d3eb;
  fill: #c2d3eb;
}
.progress .progress-bar.progress-bar-blueLight.value {
  color: #c2d3eb;
  fill: none;
  background: none;
}
.progress .progress-bar.progress-bar-green {
  background-color: #1abc9c;
  fill: #1abc9c;
}
.progress .progress-bar.progress-bar-green.value {
  color: #1abc9c;
  fill: none;
  background: none;
}
.progress .progress-bar.progress-bar-DarkgreenLight {
  background-color: #6fcdba;
  fill: #6fcdba;
}
.progress .progress-bar.progress-bar-DarkgreenLight.value {
  color: #6fcdba;
  fill: none;
  background: none;
}
.progress.width {
  height: 8px;
  margin-bottom: 0;
  background: #e5e5e5;
  box-shadow: none;
  border-radius: 2px;
}
.progress.width .progress-bar {
  -webkit-transition: width 1s ease;
  -moz-transition: width 1s ease;
  -ms-transition: width 1s ease;
  transition: width 1s ease;
}
.progress.height {
  width: 30px;
  height: 200px;
  position: relative;
  margin: 0;
  float: left;
  background: #4e80f5;
}
.progress.height .progress-bar {
  width: 100%;
  height: 0%;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: height 1s ease;
  -moz-transition: height 1s ease;
  -ms-transition: height 1s ease;
  transition: height 1s ease;
}
/* 重复样式 */
.bar-group {
  padding: 7px 0;
}
.bar-group > .ng-isolate-scope {
  height: 28px;
  margin-bottom: 10px;
}
.bar-group .left {
  float: left;
  width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.bar-group .right {
  float: right;
}
.bar-group .right.legend {
  margin-left: 20px;
}
.bar-group .rate {
  margin-right: 10px;
}
.bar-group .progress.active {
  width: 100%;
  margin-top: 19px;
}
/* 重复样式 */
.ins-quota {
  min-height: 320px;
}
.ins-quota.addpInstanceQuota {
  min-height: 180px;
}
.ins-quota li {
  margin-bottom: 5px;
}
.ins-quota li .wrap-box .left {
  float: left;
}
.ins-quota li .wrap-box .right {
  float: right;
}
.ins-quota li .quota-total,
.ins-quota li .used {
  display: none;
}
.help-wrap {
  padding: 20px;
}
.help-wrap .search-box {
  width: 290px;
}
.help-wrap .title-item {
  padding-top: 20px;
  font-size: 14px;
  color: #999;
}
.help-wrap .help-type a span {
  font-size: 14px;
  color: #404b5f;
}
.help-wrap .help-type .line {
  font-size: 16px;
  display: inline-block;
  padding: 0 20px;
}
.help-wrap .text-display li {
  cursor: pointer;
  width: 270px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
}
.recovery-box .animateContent .detailInner {
  height: 860px;
}
.recovery-box .animateContent .detailInner .tab-content {
  height: 100%;
  position: relative;
}
.recovery-box .animateContent .detailInner .tab-content .detail-info {
  height: 100%;
}
.recovery-box .animateContent .detailInner .tab-content .ins-footer {
  position: absolute;
  padding: 20px;
  bottom: 0;
  border-top: 1px solid #c3d3ea;
  width: 100%;
  background: #f6f9fe;
}
.settings-box .control-group #control-40 {
  width: 40%;
}
.operating-box .logosetting {
  width: auto;
}
.logosetting .controls-title .control-label,
.logosetting .controls-title .logo-left-opr,
.logosetting .controls-title .logo-right-info {
  line-height: 40px;
  font-weight: 600;
}
.logosetting .control-label {
  text-align: left;
}
.controls-logo {
  display: flex;
}
.controls-logo .logo-left-opr {
  width: 480px;
  margin-right: 25px;
  position: relative;
}
.controls-logo .logo-left-opr .imgOuterBox {
  width: 30px;
  height: 30px;
  background-color: #262626;
  position: relative;
  display: inline-block;
}
.controls-logo .logo-left-opr .imgOuterBox.login-bg {
  width: 100%;
  height: auto;
  background-color: inherit;
}
.controls-logo .logo-left-opr .imgOuterBox.login-bg .inputFile {
  position: relative;
}
.controls-logo .logo-left-opr .imgOuterBox.login-bg .inputFile input[type='file'] {
  width: 100px;
  height: 30px;
}
.controls-logo .logo-left-opr .imgOuterBox.login-bg .login-bg-box {
  width: 140px;
  height: 88px;
  display: inline-block;
  margin-right: 12px;
  margin-bottom: 20px;
  border: 2px solid #ddd;
}
.controls-logo .logo-left-opr .imgOuterBox.login-bg .login-bg-box.select-bg {
  border-color: #65A6E8;
  box-sizing: border-box;
  position: relative;
}
.controls-logo .logo-left-opr .imgOuterBox.login-bg .login-bg-box.select-bg:before {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  border: 17px solid #65A6E8;
  border-top-color: transparent;
  border-left-color: transparent;
  color: #fff;
  z-index: 100;
}
.controls-logo .logo-left-opr .imgOuterBox.login-bg .login-bg-box.select-bg:after {
  content: '';
  display: block;
  width: 5px;
  height: 12px;
  position: absolute;
  right: 6px;
  bottom: 6px;
  border: 2px solid #fff;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
  z-index: 100;
}
.controls-logo .logo-left-opr .imgOuterBox.login-bg .login-bg-box .logoImg {
  width: 100%;
  height: 100%;
  position: inherit;
}
.controls-logo .logo-left-opr .imgOuterBox .logoImg {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.controls-logo .logo-left-opr .imgOuterBox input[type="file"] {
  width: 30px;
  height: 30px;
}
.controls-logo .logo-left-opr .imgOuterBox-80 {
  width: 60px;
  height: 60px;
}
.controls-logo .logo-left-opr .logo-tips {
  font-size: 12px;
  color: #999;
  height: 28px;
  display: inline-block;
  vertical-align: -webkit-baseline-middle;
}
.controls-logo .logo-left-opr input[type="file"] {
  width: 60px;
  height: 60px;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  font-size: 0;
  cursor: pointer;
}
.controls-logo .logo-right-info {
  padding: 5px 0;
  font-size: 12px;
  color: #999;
  line-height: 20px;
}
.textrea textarea {
  width: 52%;
  padding: 15px 20px;
  border: 1px solid #c2d3eb;
  background: #f6f9ff;
  border-radius: 2px;
  color: #666666;
  font-size: 12px;
  line-height: 16px;
}
.permit-overview .permit.box div[class^="col-"] .user-box .cluster-item-green {
  background: #75CA78;
}
.permit-overview .permit.box div[class^="col-"] .user-box .cluster-item-blue {
  background: #41C8E5;
}
.permit-overview .permit.box div[class^="col-"] .user-box .cluster-item-oringe {
  background: #FFBF59;
}
#secrecyRole .cluster-right-textCenter {
  text-align: center;
}
#secrecyRole .cluster-right-textCenter span,
#secrecyRole .cluster-right-textCenter div {
  color: #fff;
}
.auditRole .chart-title {
  font-size: 16px;
  padding-left: 15px;
}
.auditRole .info-box {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  -ms-justify-content: space-around;
  justify-content: space-around;
  align-items: center;
  -webkit-align-items: center;
}
.auditRole .info-box .infoTable ul {
  border-top: 1px solid #ccc;
}
.auditRole .info-box .infoTable ul li {
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  padding: 0 20px;
  border-bottom: 1px solid #ccc;
  position: relative;
}
.auditRole .info-box .infoTable ul li i {
  width: 12px;
  height: 12px;
  display: block;
  border-radius: 50%;
  background: #25b9d9;
  position: absolute;
  left: 10px;
  top: 50%;
  margin-top: -6px;
}
.auditRole .info-box .infoTable ul li .userName {
  width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  margin-left: 10px;
  float: left;
}
.auditRole .info-box .infoTable ul li span {
  float: left;
  margin-left: 15px;
}
.auditRole .info-box .infoTable ul li:nth-of-type(odd) {
  background: #FAFAFA;
}
.auditRole .info-box .infoTable ul li:nth-of-type(even) {
  background: #fff;
}
.auditRole .pieCommon {
  width: 300px;
  height: 300px;
}
.auditRole #line-log {
  min-width: 900px;
  max-width: 1580px;
  width: 100%;
  height: 300px;
  display: block;
}
.auditRole .view-title {
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  padding: 0 20px;
}
.tips_box_common .tips_span {
  max-width: 110px;
  word-wrap: break-word;
  display: block;
}
.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently not supported by any browser */
}
.modal .modal-dialog .modal-header.hide-bottom {
  border-bottom: 0;
}
.update-package {
  padding: 30px 0 75px;
  text-align: center;
  font-size: 16px;
}
.errors-polling {
  text-align: center;
  color: #e74c3c;
}
.control-items2 .control-group.formdata-view {
  width: 25%;
}
.control-items2 .control-group.formdata-time {
  width: 20%;
}
.control-items2 .control-group.formdata-time .formdata-item {
  width: 80%;
}
.page-inner .meihe-inner {
  width: 115%;
  height: 896px;
  margin-top: -48px;
  margin-left: -221px;
}
.page-inner .tsafe-inner {
  width: 100%;
  height: 896px;
}
.page-inner .TDsql-inner {
  width: 113%;
  height: 896px;
  margin-top: -6px;
  margin-left: -221px;
}
.page-inner .TSF-inner {
  width: 113%;
  height: 896px;
  margin-top: -58px;
  margin-left: -221px;
}
.page-inner .CTsdb-inner {
  width: 100%;
  height: 880px;
}
.page-inner .Alauda-inner {
  width: 100%;
  height: 880px;
  margin-top: -48px;
}
.page-inner .TBDS-inner {
  margin-top: -55px;
  width: 100%;
  height: 880px;
}
.page-inner .TBDS-inner.TBDS-header {
  margin-top: 0px;
}
.page-inner .COC-inner {
  width: 115%;
  height: 896px;
  margin-top: -50px;
  margin-left: -225px;
}
.page-inner .COC2-inner {
  width: 115%;
  height: 896px;
  margin-top: -100px;
  margin-left: -225px;
}
.page-inner .TBDS-header-left {
  position: absolute;
  width: 60px;
  height: 50px;
  background: #454865;
}
.page-inner .TBDS-header-right {
  position: absolute;
  right: 20px;
  width: 100%;
  height: 50px;
  background: #37394d;
}
.page-inner .TCS-inner {
  width: 115%;
  height: 896px;
  margin-top: -123px;
  margin-left: -221px;
}
header .short-cut li .dropdown-layer {
  background: #191919;
  color: #ffffff;
  transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  /* Firefox 4 */
  -webkit-transition-duration: 0.5s;
  /* Safari 和 Chrome */
  -o-transition-duration: 0.5s;
  /* Opera */
}
header .service-mask {
  height: 70px;
  width: 100%;
  background: #262626;
  position: absolute;
  z-index: -5;
  left: 0;
}
header .service-dropdown-layer {
  transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  /* Firefox 4 */
  -webkit-transition-duration: 0.5s;
  /* Safari 和 Chrome */
  -o-transition-duration: 0.5s;
  /* Opera */
  position: absolute;
  width: 100%;
  min-height: 190px;
  min-width: 1280px;
  left: 0;
  background: #191919;
  top: -1200px;
  z-index: -10;
  padding: 30px 0;
}
header .service-dropdown-layer aside {
  float: left;
  height: 100%;
  padding: 0 25px 0 45px;
  width: 160px;
}
header .service-dropdown-layer aside ul li {
  margin-bottom: 36px;
  font-size: 14px;
}
header .service-dropdown-layer aside ul li span {
  cursor: pointer;
  width: 59px;
  height: 20px;
  font-weight: bold;
  color: #a5a5a5;
}
header .service-dropdown-layer aside ul li span:hover {
  color: #51a3ff;
}
header .service-dropdown-layer aside ul li .active {
  color: #51a3ff;
}
header .service-dropdown-layer aside ul li i {
  float: right;
  color: #51a3ff;
}
header .service-dropdown-layer aside ul li:last-child {
  padding: 0;
}
header .service-dropdown-layer .submenu-limit {
  height: auto;
}
header .service-dropdown-layer .sub-menu {
  height: 100%;
  max-height: 705px;
  overflow-y: auto;
  margin-left: 160px;
  font-size: 14px;
  border-left: 1px dashed #3f4f60;
  /* .vm-items{
				width:100%;
				.sub-name{
					max-width: 100px;
					border-bottom: 0;
					float:left;
				}
				ul{
					line-height: 40px;
					float:left;
					li{
						float:left;
						margin-right: 20px;
					}
				}
			} */
}
header .service-dropdown-layer .sub-menu .dividing-line {
  height: 100%;
  margin-left: 10px;
}
header .service-dropdown-layer .sub-menu .all-menus {
  margin: 0 0 30px 10px;
  padding-bottom: 30px;
  border-bottom: 1px dashed #3f4f60;
}
header .service-dropdown-layer .sub-menu .all-menus:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}
header .service-dropdown-layer .sub-menu h3 {
  color: #A5A5A5;
  font-size: 14px;
  padding-left: 20px;
  display: inline-block;
  margin-bottom: 10px;
}
header .service-dropdown-layer .sub-menu h3 a {
  color: #A5A5A5;
}
header .service-dropdown-layer .sub-menu h3:hover a {
  color: #51a3ff;
}
header .service-dropdown-layer .sub-menu #menu-ul-box {
  column-count: 8;
  -webkit-column-count: 8;
  -moz-column-count: 8;
  column-gap: 0;
  -webkit-column-gap: 0;
  -moz-column-gap: 0;
}
header .service-dropdown-layer .sub-menu .menu-item {
  padding: 0 20px;
  width: 180px;
  box-sizing: border-box;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  margin-bottom: 30px;
  display: inline-block;
  width: 100%;
}
header .service-dropdown-layer .sub-menu .isChrome {
  display: block;
}
header .service-dropdown-layer .sub-menu .sub-name {
  line-height: 30px;
  color: #ffffff;
  margin-bottom: 5px;
  padding-left: 10px;
}
header .service-dropdown-layer .sub-menu .service-items a {
  line-height: 30px;
  color: #A5A5A5;
  display: block;
  padding-left: 10px;
}
header .service-dropdown-layer .sub-menu .service-items a:hover {
  color: #3c78e6;
  background: #2a2a2a;
}
.animateContent .detail-title .detail-title-item {
  font-size: 16px;
  font-weight: 600;
}
header .inner .line {
  float: left;
  width: 1px;
  height: 30px;
  overflow: hidden;
  border-left: 1px solid #000;
  margin-top: 19px;
}
header .inner .header-padding {
  padding: 21px 0;
}
header .service-title {
  margin: 0 0 0 20px;
  padding: 19px 0;
  height: 70px;
  float: left;
  -moz-user-select: none;
  /*火狐*/
  -webkit-user-select: none;
  /*webkit浏览器*/
  -ms-user-select: none;
  /*IE10*/
  -khtml-user-select: none;
  /*早期浏览器*/
  user-select: none;
}
header .service-title > a {
  display: block;
  padding: 0 28px;
  color: #ffffff;
}
header .service-title > a:hover {
  color: #51a3ff;
}
header .service-title.center-info > a > span {
  float: left;
  display: inline-block;
  max-width: 140px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  margin-right: 5px;
  white-space: nowrap;
  line-height: 32px;
}
header .service-title.center-info > a > i {
  line-height: 32px;
  font-style: normal;
  font-size: 20px;
}
header .service-title:hover {
  background: #191919;
}
header .service-title:hover:after {
  position: static;
}
header .service-title:hover > a {
  color: #51a3ff;
}
header .service-title:hover .service-dropdown-layer {
  top: 70px;
}
header .short-cut {
  float: left;
}
header .short-cut.short-p {
  padding-top: 20px;
}
header .short-cut > li {
  float: left;
  position: relative;
}
header .short-cut > li > a {
  line-height: 28px;
  height: 28px;
  display: block;
  padding: 0 15px;
  color: #ffffff;
}
header .short-cut > li > a:hover {
  color: #51a3ff;
}
header .short-cut > li .dropdown-layer {
  transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  /* Firefox 4 */
  -webkit-transition-duration: 0.5s;
  /* Safari 和 Chrome */
  -o-transition-duration: 0.5s;
  /* Opera */
  position: absolute;
  top: -130px;
  left: 0;
  z-index: -9;
  background: #ffffff;
}
header .short-cut > li .dropdown-layer ul li {
  line-height: 20px;
  padding: 0 12px;
  border-bottom: 1px solid #e5e5e5;
}
header .short-cut > li .dropdown-layer ul li:last-child {
  border-bottom: none;
}
header .short-cut > li.notice-panel > a {
  position: relative;
  text-align: center;
}
header .short-cut > li.notice-panel > a > i {
  font-size: 24px;
}
header .short-cut > li.notice-panel:hover {
  background: #191919;
}
header .short-cut > li.notice-panel .quick-type {
  cursor: pointer;
  width: 130px;
  min-width: 125px;
  left: 0;
  right: auto;
}
header .short-cut > li.notice-panel .quick-type .quick-item {
  background: #191919;
}
header .short-cut > li.notice-panel .quick-type .quick-item li {
  border: 0;
  line-height: 35px;
  padding: 0;
}
header .short-cut > li.notice-panel .quick-type .quick-item li:hover {
  background: #2a2a2a;
}
header .short-cut > li.notice-panel .quick-type .quick-item li:hover a,
header .short-cut > li.notice-panel .quick-type .quick-item li:hover span {
  color: #51a3ff;
}
header .short-cut > li.notice-panel .quick-type .quick-item li a {
  color: #a5a5a5;
  font-size: 12px;
  display: block;
  padding: 0 12px;
}
header .short-cut > li.notice-panel .quick-type .quick-item li span {
  color: #a5a5a5;
  font-size: 12px;
  padding: 0 12px;
}
header .short-cut > li.notice-panel .quick-type li a {
  color: #404b5f;
}
header .short-cut > li:after {
  content: "";
  position: absolute;
  right: 0;
  top: 9px;
  bottom: 9px;
  width: 1px;
  background-color: #404b5f;
}
header .short-cut > li:last-child:after {
  content: initial;
}
header .short-cut > li:hover {
  background: #ffffff;
}
header .short-cut > li:hover:after {
  position: static;
}
header .short-cut > li:hover a {
  color: #51a3ff;
}
header .short-cut > li:hover .dropdown-layer {
  top: 70px;
}
.title-item {
  line-height: 32px;
  float: left;
  color: #c2d3eb;
}
.space-item {
  display: inline-block;
  height: 25px;
}
.buffer-space {
  float: left;
  height: 32px;
  line-height: 32px;
  margin-left: 20px;
}
.limit-length {
  max-width: 140px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.modal-body .alert .alert-content .alert-text {
  line-height: 16px;
}
.modal-body .alert .close {
  top: 8px;
}
.cvm-view .resourcesInfo .panels .pie-wrap {
  width: 120px;
}
.cvm-view .resourcesInfo .panels .pie-wrap .pie {
  width: 120px;
  height: 120px;
}
.cvm-view .resourcesInfo .panels .pie-wrap .pie-center .num {
  font-size: 20px;
}
.cvm-view .resourcesInfo .panels .pie-legend .dl-horizontal dt {
  width: 45px;
  font-size: 12px;
  padding: 0;
}
.cvm-view .resourcesInfo .panels .pie-legend .dl-horizontal dd {
  margin-left: 50px;
  font-size: 18px;
}
/*易用性改造弹出框*/
.modal .modal-dialog .lg-modal {
  width: 750px;
  text-align: center;
}
.modal .modal-dialog .lg-modal .modal-body {
  min-height: 70px;
}
.modal .modal-dialog .lg-modal .modal-body .control-group .controls .error {
  text-align: left;
}
.modal .modal-dialog .lg-modal .modal-body .one-group .add-item,
.modal .modal-dialog .lg-modal .modal-body .two-group .add-item {
  float: left;
  width: 68%;
}
.modal .modal-dialog .lg-modal .modal-body .one-group .add-item .error,
.modal .modal-dialog .lg-modal .modal-body .two-group .add-item .error {
  text-align: left;
}
.modal .modal-dialog .lg-modal .modal-body .one-group .add-item-group,
.modal .modal-dialog .lg-modal .modal-body .two-group .add-item-group {
  width: 86%;
}
.modal .modal-dialog .lg-modal .modal-body .one-group .add-group,
.modal .modal-dialog .lg-modal .modal-body .two-group .add-group {
  float: left;
  line-height: 32px;
  margin-left: 30px;
  color: #51a3ff;
  font-size: 14px;
}
.modal .modal-dialog .lg-modal .modal-body .one-group .add-group i,
.modal .modal-dialog .lg-modal .modal-body .two-group .add-group i {
  margin-right: 10px;
}
.modal .modal-dialog .lg-modal .modal-body .one-group .add-group span,
.modal .modal-dialog .lg-modal .modal-body .two-group .add-group span {
  cursor: pointer;
}
.modal .modal-dialog .lg-modal .modal-body .one-group {
  margin-top: 60px;
}
.modal .modal-dialog .lg-modal .modal-body .two-group {
  margin: 20px 0 60px;
}
.modal .modal-dialog .lg-modal .modal-body .three-group {
  margin-top: 30px;
}
.modal .modal-dialog .lg-modal .modal-header .close {
  right: 20px;
}
.modal .modal-dialog .lg-modal .modal-title {
  float: left;
}
.modal .modal-dialog .lg-modal .steps-item {
  display: inline-block;
  margin: 10px 0 0 -75px;
}
.modal .modal-dialog .lg-modal .steps-item li {
  float: left;
}
.modal .modal-dialog .lg-modal .steps-item li span {
  display: block;
  margin-bottom: 4px;
  font-weight: 600;
  color: #999;
}
.modal .modal-dialog .lg-modal .steps-item li i {
  display: inline-block;
  font-style: normal;
  width: 22px;
  height: 22px;
  line-height: 22px;
  border-radius: 10px;
  background: #cdcdcd;
  text-align: center;
  z-index: 1;
  position: relative;
  color: #999;
}
.modal .modal-dialog .lg-modal .steps-item li i.active {
  color: #fff;
  background: #51a3ff;
}
.modal .modal-dialog .lg-modal .steps-item li.step-start div {
  float: left;
  text-align: left;
}
.modal .modal-dialog .lg-modal .steps-item li.step-start div span {
  margin-left: -16px;
}
.modal .modal-dialog .lg-modal .steps-item li.step-start:after {
  width: 110px;
  height: 7px;
  margin-left: 20px;
  position: relative;
  bottom: 13px;
  background: #cdcdcd;
}
.modal .modal-dialog .lg-modal .steps-item li.step-middle {
  width: 160px;
  text-align: center;
}
.modal .modal-dialog .lg-modal .steps-item li.step-middle:after {
  width: 220px;
  height: 7px;
  position: relative;
  bottom: 13px;
  background: #cdcdcd;
}
.modal .modal-dialog .lg-modal .steps-item li.step-end div {
  float: right;
  text-align: right;
}
.modal .modal-dialog .lg-modal .steps-item li.step-end div span {
  margin-right: -16px;
}
.modal .modal-dialog .lg-modal .steps-item li.step-end:after {
  content: "";
  width: 130px;
  height: 7px;
  position: relative;
  bottom: 13px;
  background: #cdcdcd;
}
.modal .modal-dialog .lg-modal .steps-item li.active:after {
  background: #51a3ff;
}
.modal .modal-dialog .lg-modal .steps-item li.active div span {
  color: #51a3ff;
}
.dropdown-menu {
  z-index: 999;
}
.popover {
  z-index: 100;
}
.panels .pie-legend .project-dl-horizontal > dt {
  width: 150px;
}
.project-detail .animateContent.animateIn {
  height: auto;
}
.ticket-detail .animateContent.animateIn {
  height: auto;
}
.main .main-content .detail-info .box .project-pie-item {
  min-height: 260px;
}
/*角色管理样式*/
.table-space {
  min-height: 310px;
}
.img-role {
  background: url(/img/role-img.svg) no-repeat center center;
  width: 100%;
  height: 980px;
  background-size: contain;
}
.animateContent.animateIn .resourcesInfo li dl dt {
  padding: 0 8px;
}
.function-box .text-tips {
  color: #999;
  line-height: 32px;
  font-size: 16px;
}
.function-box .error {
  color: #e74c3c;
}
.function-container .control-group .control-label {
  width: 26%;
}
.function-container .control-group .controls {
  width: 70%;
}
.function-container .control-group .ksnode-pass {
  position: relative;
}
.function-container .control-group .ksnode-pass .icon-aw-display,
.function-container .control-group .ksnode-pass .icon-aw-no-display {
  position: absolute;
  right: 5px;
  top: 2px;
  left: auto;
  font-size: 20px;
}
.refresh-btn {
  background: #fff;
  width: 32px;
  height: 32px;
  border: 1px solid #c2d3eb;
  color: #51a3ff;
  float: left;
}
.table-right {
  float: right;
}
.btn-item-group > button {
  padding: 5px 10px;
}
.btn-item-group > button.active {
  color: #51a3ff;
  background: #f6f9ff;
  border: 1px solid #c2d3eb;
}
.btn-item-group > button.type-btn-middle {
  padding: 10px 20px;
  height: auto;
  min-width: 100px;
  margin-right: 15px;
}
.btn-item-group > button.type-btn-middle.active {
  color: #51a3ff;
  background: #fff;
}
.btn-item-group > button i {
  margin-right: 10px;
}
.border-box {
  border: 1px solid #e5e5e5;
}
.border-box.controls {
  width: 70%;
  margin-left: 10px;
  padding: 10px 20px;
}
.flowManage-box {
  padding: 0 20px;
  height: 778px;
}
/*多数据中心详情*/
.cluster-box {
  width: 100%;
  position: relative;
  overflow-x: hidden;
}
.cluster-box .move-content {
  width: 200%;
  transition: margin-left 0.6s ease-in 0s;
}
.cluster-box .move-content .detail-title .region-title {
  font-size: 16px;
  font-weight: 600;
}
.cluster-box .move-content .detail-content {
  width: 50%;
  float: left;
}
.cluster-box .move-content .detail-content .node-detail {
  padding: 0 20px 30px;
  background: #fff;
}
.cluster-box .move-content .detail-content .node-detail .detail-title {
  padding-left: 0;
}
.cluster-box .move-content .detail-content .node-detail .node-detail-title {
  /*line-height: 50px;*/
}
.cluster-box .move-content .detail-content .node-detail .node-detail-title span {
  font-size: 16px;
  font-weight: 600;
}
.cluster-box .move-content .detail-content .node-detail .node-detail-title .title-cluster,
.cluster-box .move-content .detail-content .node-detail .node-detail-title .title-node {
  cursor: pointer;
}
.cluster-box .move-content .detail-content .node-detail .node-detail-title .title-cluster {
  color: #51a3ff;
}
.cluster-box .move-content .detail-content .node-detail .node-detail-title .point-to {
  margin: 0 10px;
}
.cluster-box .to-left {
  margin-left: -100%;
}
.nodeTab-detail .nav.nav-tabs .tab-content {
  padding-top: 10px;
}
.nodeTab-detail .nodeTabName {
  margin-bottom: 10px;
}
.install-region {
  text-align: left;
  padding: 0 70px;
}
.install-region .region-icon {
  float: left;
  font-size: 60px;
  color: #e3f0fe;
  display: inline-block;
}
.install-region .region-name {
  width: 190px;
  margin: 15px 0 0 10px;
  font-size: 14px;
  float: left;
  display: inline-block;
}
.imediate-install {
  text-align: center;
  font-size: 14px;
  line-height: 100px;
}
.ceph-tabs.nav-tabs li.active {
  background-color: #fff;
}
.btn-box .fieid-box {
  float: right;
  line-height: 32px;
  height: 32px;
}
.btn-box .fieid-box ul {
  display: inline-block;
  padding-left: 10px;
  position: relative;
}
.btn-box .fieid-box ul:after {
  content: "";
  width: 1px;
  height: 15px;
  position: absolute;
  top: 8px;
  right: -2px;
  background-color: #c2d3eb;
  display: inline-block;
}
.btn-box .fieid-box ul:last-child {
  border: none;
}
.btn-box .fieid-box ul:last-child:after {
  content: "";
  width: 0;
}
.btn-box .fieid-box ul:last-child li {
  padding-left: 64px;
}
.btn-box .fieid-box ul li {
  float: left;
  padding-right: 10px;
  padding-left: 20px;
  position: relative;
}
.btn-box .fieid-box ul li > i {
  color: #51a3ff;
  position: absolute;
  left: 0;
  margin-right: 6px;
}
.btn-box .fieid-box ul li > i.my-icon {
  width: 14px;
  height: 14px;
  display: inline-block;
  border-radius: 2px;
  top: 9px;
  border: 1px solid #7ba0f8;
}
.btn-box .fieid-box ul li > i.rect-half {
  width: 14px;
  height: 7px;
  top: 15px;
  display: inline-block;
  border: 1px solid #51a3ff;
  border-top: 0;
  background: #51a3ff;
}
.btn-box .fieid-box ul li > i.ssd {
  color: #466d74;
}
.btn-box .fieid-box ul li > i.up {
  border: 2px solid #0085FE;
}
.btn-box .fieid-box ul li > i.down {
  border: 2px solid #E74C3C;
}
.btn-box .fieid-box ul li > i.out {
  border: 2px solid #999999;
}
.btn-box .fieid-box ul li > i.io {
  width: 14px;
  height: 14px;
  display: inline-block;
  top: 9px;
}
.btn-box .fieid-box ul li > i.green {
  background: #97FEF0;
}
.btn-box .fieid-box ul li > i.orange {
  background: #FFED81;
  left: 20px;
}
.btn-box .fieid-box ul li > i.red {
  background: #FFB4A5;
  left: 40px;
}
body .popover.bottom > .arrow:after {
  border-bottom-color: #273142;
}
body .popover.right > .arrow:after {
  border-right-color: #273142;
}
body .popover.left > .arrow:after {
  border-left-color: #273142;
}
body .popover.top > .arrow:after {
  border-top-color: #273142;
}
.popover-inner .popover-content .table thead tr th {
  background-color: #273142;
}
.popover-inner .popover-content .table tbody tr td {
  background-color: #273142;
}
body .popover {
  color: #fff;
  background-color: #273142;
}
body .popover .popover-title {
  background-color: #273142;
  border-bottom: 1px solid #5d5555;
}
.main .main-content .ceph-grid .layout-grid {
  padding: 20px;
  box-shadow: 0px 2px 10px #e5e5e5;
}
.main .main-content .ceph-grid .layout-grid .layout-title {
  padding: 0 10px;
  margin-bottom: 20px;
}
.main .main-content .ceph-grid .layout-grid + .layout-grid {
  margin-top: 20px;
}
.main .main-content .ceph-grid div[class="col-3"] {
  min-height: auto;
  border-bottom: 0;
  margin-top: 0;
}
.main .main-content .ceph-grid div[class="col-3"] .title {
  padding: 10px 18px;
  margin: 0 8px;
  border: 1px solid #e5e5e5;
  position: relative;
  background: #fafafa;
}
.main .main-content .ceph-grid div[class="col-3"] .title span {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  width: 90%;
}
.main .main-content .ceph-grid div[class="col-3"] .title i {
  position: absolute;
  right: 15px;
  font-size: 20px;
  color: #bdbdbd;
}
.main .main-content .ceph-grid div[class="col-3"] .title i:hover {
  color: #598ff4;
}
.main .main-content .ceph-grid div[class="col-3"] .border-box {
  border: 1px dashed #e5e5e5;
  background: #fff;
  border-top: 0;
  padding: 10px 5px 0;
  margin: 0 8px;
  height: 200px;
  overflow-y: auto;
}
.main .main-content .ceph-grid div[class="col-3"] .border-box.border-box-large {
  height: 295px;
}
.main .main-content .ceph-grid div[class="col-3"] .border-box .col-2 {
  min-height: auto;
  border-bottom: 0;
  margin-top: 0;
  padding: 0;
}
.main .main-content .ceph-grid div[class="col-3"] .flex-box {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.main .main-content .ceph-grid div[class="col-3"] .flex-box .flex-item {
  margin: 5px 3px;
  cursor: pointer;
}
.main .main-content .ceph-grid div[class="col-3"]:last-child {
  border-bottom: 0;
}
.main .main-content .disk-info {
  position: absolute;
  padding: 10px 5px;
  border: 1px solid #e5e5e5;
  z-index: 2;
  width: 200px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 20px #e5e5e5;
  display: none;
}
.main .main-content .disk-info .angles {
  position: absolute;
  top: -2px;
  transform: rotate(-45deg);
}
.main .main-content .disk-info .angles .angle-top {
  border-top: 11px solid #e5e5e5;
  border-left: 11px solid transparent;
  position: absolute;
  top: 0;
}
.main .main-content .disk-info .angles .angle-top-opa {
  border-top: 10px solid #fff;
  border-left: 10px solid transparent;
  position: absolute;
  top: 1px;
}
.main .main-content .disk-info.middle {
  width: 250px;
}
.main .main-content .disk-info div {
  padding: 5px 20px;
}
.main .main-content .disk-info div span {
  color: #666;
}
.main .main-content .disk-info div span.sub-title {
  color: #7991ab;
  display: inline-block;
  min-width: 85px;
  text-align: right;
}
.main .main-content .disk-info div span.status {
  padding: 2px 10px;
  border-radius: 2px;
  background: #4e80f5;
  color: #fff;
}
.main .main-content .disk-info div span.status.up {
  background: #16a085;
  color: #fff;
}
.main .main-content .disk-info div span.status.down {
  background: #e74c3c;
  color: #fff;
}
.main .main-content .disk-info div span.status.out {
  background: #999999;
  color: #fff;
}
.main .main-content .disk-box {
  border: 1px solid #c2d3eb;
  margin: 0 5px 10px;
  height: 84px;
  max-width: 80px;
  text-align: center;
  background: #fff;
}
.main .main-content .disk-box.miss {
  border: 1px solid #cdcdcd;
}
.main .main-content .disk-box:hover {
  border: 2px solid #51a3ff;
  box-shadow: 0px 0px 8px rgba(81, 163, 255, 0.60784314);
}
.main .main-content .disk-box.checked {
  border: 2px solid #51a3ff;
  box-shadow: 0px 0px 8px rgba(81, 163, 255, 0.60784314);
}
.main .main-content .disk-box.unbar span {
  padding: 5px 10px 0;
  border-top: 1px solid #e5e5e5;
}
.main .main-content .disk-box.unbar .s-h.journal {
  color: #51a3ff;
}
.main .main-content .disk-box.unbar .s-h.available .circle {
  display: none;
}
.main .main-content .disk-box .hdd {
  padding: 0 5px;
}
.main .main-content .disk-box .hdd .progress {
  margin: 0;
  height: 11px;
  box-shadow: none;
  border-radius: 2px;
}
.main .main-content .disk-box .hdd.up .progress {
  border: 1px solid #16a085;
}
.main .main-content .disk-box .hdd.up .progress .progress-bar {
  background-color: #1abc9c;
}
.main .main-content .disk-box .hdd.down .progress {
  border: 1px solid #c0392b;
}
.main .main-content .disk-box .hdd.down .progress .progress-bar {
  background-color: #e74c3c;
}
.main .main-content .disk-box .hdd.out .progress {
  border: 1px solid #666666;
}
.main .main-content .disk-box .hdd.out .progress .progress-bar {
  background-color: #cdcdcd;
}
.main .main-content .disk-box .hdd.miss .progress {
  border: 1px solid #f5f5f5;
}
.main .main-content .disk-box .hdd.miss .progress .progress-bar {
  background-color: #f5f5f5;
}
.main .main-content .disk-box .hdd .DarkgreenLight {
  border: 1px solid #16a085;
}
.main .main-content .disk-box .hdd .green {
  border: 1px solid #1abc9c;
}
.main .main-content .disk-box .hdd .blueDark {
  border: 1px solid #4e80f5;
}
.main .main-content .disk-box .hdd .blue {
  border: 1px solid #51a3ff;
}
.main .main-content .disk-box .hdd .orangeDark {
  border: 1px solid #e67e22;
}
.main .main-content .disk-box .hdd .orange {
  border: 1px solid #f39c12;
}
.main .main-content .disk-box .hdd .redDark {
  border: 1px solid #c0392b;
}
.main .main-content .disk-box .hdd .red {
  border: 1px solid #e74c3c;
}
.main .main-content .disk-box .s-h {
  position: relative;
  padding: 14px;
}
.main .main-content .disk-box .s-h i {
  font-size: 23px;
}
.main .main-content .disk-box .s-h .circle {
  position: absolute;
  top: 3px;
  left: 5px;
  background: #ef8c82;
  border-radius: 12px;
  width: 12px;
  height: 12px;
}
.main .main-content .disk-box .s-h .circle.green {
  background: #1abc9c;
}
.main .main-content .disk-box .s-h .circle.orange {
  background: #f39c12;
}
.main .main-content .disk-box .s-h .circle.red {
  background: #c0392b;
}
.main .main-content .disk-box .s-h.bar {
  padding-bottom: 0;
}
.main .main-content .disk-box .s-h.bar .up,
.main .main-content .disk-box .s-h.bar .down,
.main .main-content .disk-box .s-h.bar .out {
  color: #51a3ff;
}
.main .main-content .disk-box .s-h.bar .miss {
  color: #cdcdcd;
}
.main .main-content .disk-box span {
  color: #666;
  font-size: 12px;
}
.main .main-content .disk-box span.ssd {
  display: inline-block;
  padding: 5px 10px;
  border-top: 1px solid #c2d3eb;
}
.main .main-content .disk-box .close {
  position: absolute;
  top: -6px;
  right: -6px;
  border-radius: 6px;
  color: #fff;
  background: #e74c3c;
  opacity: 1;
  font-size: 12px;
  width: 12px;
  height: 12px;
  line-height: 12px;
  text-shadow: none;
  text-align: center;
  display: block;
  display: none;
}
.main .main-content .ceppView-info .table-action {
  margin-bottom: 15px;
}
.main .main-content .ceppView-info .btn-box span {
  font-size: 17px;
  padding: 0 20px;
  font-weight: bold;
}
.main .main-content .ceppView-info .btn-box .btn {
  float: none;
}
.main .main-content .ceppView-info .title {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.main .main-content .ceppView-info .diskfield {
  position: relative;
  float: left;
  padding-right: 18px;
}
.main .main-content .ceppView-info .disk-info {
  top: 90px;
  left: 5px;
}
.main .main-content .ceppView-info .disk-box {
  width: 80px;
  height: 84px;
  float: left;
  position: relative;
  margin: 0;
  background: #fff;
}
.main .main-content .ceppView-info .disk-box.add {
  padding: 25px 0;
}
.main .main-content .ceppView-info .disk-box.add i {
  font-size: 22px;
  color: #c2d3eb;
}
.main .main-content .ceppView-info .disk-box.target {
  border: 1px dashed #e67e22;
  background: #fff4e0;
}
.main .main-content .ceppView-info .disk-box.target .s-h > i {
  color: #e67e22;
}
.main .main-content .ceppView-info .disk-box.target:hover {
  border: 2px dashed #e67e22;
  box-shadow: none;
}
.main .main-content .ceppView-info .disk-box:hover .close {
  display: block;
}
.main .main-content .ceppView-info .disk-box:hover .close.available {
  display: none;
}
.main .main-content .disk-groups {
  position: relative;
  border-bottom: 1px solid #e5e5e5;
  padding: 20px 0;
}
.main .main-content .disk-groups .title {
  padding-left: 20px;
}
.main .main-content .disk-groups .avail-boxs {
  margin-left: 95px;
  min-height: 84px;
}
.main .main-content .disk-groups .avail-boxs .diskfield {
  padding-left: 5px;
}
.main .main-content .disk-groups .field-box {
  margin-left: 100px;
  border: 1px dashed #e5e5e5;
}
.main .main-content .disk-groups .field-box .border-box {
  padding: 18px;
  border: none;
  min-height: 120px;
  background: #fafafa;
}
.ip_text {
  width: calc(25% - 6px);
  width: -moz-calc(25% - 6px);
  width: -webkit-calc(25% - 6px);
}
.modal .modal-dialog .modal-body .ip_cidr .subnet-controls .flex .ip-text {
  width: calc(20% - 6px);
  width: -moz-calc(20% - 6px);
  width: -webkit-calc(20% - 6px);
}
/*屏幕适配*/
@media screen and (min-width: 1680px) {
  .quotaInfo ul li .total .quotaNum .smallFont {
    font-size: 28px;
  }
  .auditRole .info-box .infoTable {
    width: 330px;
    margin-left: 0;
  }
}
@media screen and (max-width: 1680px) {
  .quotaInfo ul li .total .quotaNum .smallFont {
    font-size: 24px;
  }
  .auditRole .infoTable {
    width: 260px;
    margin-left: 20px;
  }
  .auditRole .pieCommon {
    width: 300px;
    height: 300px;
  }
  .auditRole .pieCommon canvas {
    width: 100%;
    height: 100%;
  }
  .main .main-content .layout-grid .col-3 {
    width: 33.3%;
  }
  .main .main-content .layout-grid .col-3 {
    width: 33.3%;
  }
  .ceph-grid .btn-box .fieid-box ul {
    padding-left: 6px;
  }
  .ceph-grid .btn-box .fieid-box ul:last-child li {
    padding-left: 50px;
  }
  .ceph-grid .btn-box .fieid-box ul li {
    padding-left: 16px;
    padding-right: 6px;
  }
  .ceph-grid .btn-box .fieid-box ul li i.my-icon {
    width: 12px;
    height: 12px;
  }
  .ceph-grid .btn-box .fieid-box ul li i.rect-half {
    width: 12px;
    height: 6px;
    top: 14px;
  }
  .ceph-grid .btn-box .fieid-box ul li i.io {
    width: 12px;
    height: 12px;
  }
  .ceph-grid .btn-box .fieid-box ul li i.orange {
    left: 16px;
  }
  .ceph-grid .btn-box .fieid-box ul li i.red {
    left: 32px;
  }
  .ceph-grid .table-action .pull-right .ui-select-container {
    width: 80px;
  }
  .ceph-grid .table-action .pull-right .ui-select-container .ui-select-search {
    width: 80px !important;
  }
  .ceph-grid .table-action .pull-right .search-list {
    width: 88px;
  }
  .ceph-grid .table-action .pull-right .search-box {
    width: 180px;
  }
}
@media screen and (max-width: 1440px) {
  .quotaInfo ul li .total .quotaNum .smallFont {
    font-size: 22px;
  }
  .auditRole .infoTable {
    width: 220px;
    margin-left: 20px;
  }
  .auditRole .pieCommon {
    width: 300px;
    height: 300px;
  }
  .auditRole .pieCommon canvas {
    width: 100%;
    height: 100%;
  }
  .operatelog-keyword-type {
    width: 120px;
  }
  .date-box .time-picker .form-control {
    width: 132px !important;
  }
  .main .main-content .layout-grid .col-3 {
    width: 33.3%;
  }
  .main .main-content .layout-grid .col-3 .border-box .col-2 {
    width: 25%;
  }
}
@media screen and (max-width: 1366px) {
  .quotaInfo ul li .total .quotaNum .smallFont {
    font-size: 20px;
  }
  .detail-info .panels .pie-legend .dl-horizontal dt {
    width: 125px;
  }
  .project-detail .panels .pie-legend .dl-horizontal dt,
  .department-detail .panels .pie-legend .dl-horizontal dt {
    width: 90px;
  }
  .dropdown-menu {
    font-size: 12px;
  }
  .auditRole .infoTable {
    width: 220px;
    margin-left: 20px;
  }
  .auditRole .pieCommon {
    width: 210px;
    height: 210px;
  }
  .auditRole .pieCommon canvas {
    width: 100%;
    height: 100%;
  }
  .operatelog-keyword-type {
    width: 120px;
  }
  .date-box .time-picker .form-control {
    width: 122px !important;
  }
  .main .main-content .layout-grid .col-3 {
    width: 33.3%;
  }
  .main .main-content .layout-grid .col-3 .border-box .col-2 {
    width: 25%;
  }
  .form-controls,
  .form-control,
  input[type="text"],
  input[type="password"] {
    font-size: 12px;
  }
}
@media screen and (max-width: 1280px) {
  .quotaInfo ul li .total .quotaNum .smallFont {
    font-size: 18px;
  }
  .dropdown-menu {
    font-size: 12px;
  }
  .auditRole .infoTable {
    width: 230px;
    margin-left: 20px;
  }
  .auditRole .pieCommon {
    width: 170px;
    height: 170px;
  }
  .auditRole .pieCommon canvas {
    width: 100%;
    height: 100%;
  }
  .operatelog-keyword-type {
    width: 110px;
  }
  .operatelog-keyword-type .btn {
    width: 110px!important;
  }
  .date-box .time-picker .form-control {
    width: 90px !important;
  }
  .main .main-content .layout-grid .col-3 {
    width: 50%;
  }
  .main .main-content .layout-grid .col-3 .border-box .col-2 {
    width: 20%;
  }
}
.circle-blue,
.circle-green,
.circle-orange,
.circle-waterblue {
  height: 80px;
  width: 80px;
  text-align: center;
  border-radius: 50%;
}
.circle-blue i,
.circle-green i,
.circle-orange i,
.circle-waterblue i {
  line-height: 64px;
  font-size: 32px;
  text-align: center;
  color: #ffffff;
}
.circle-blue {
  background: #42b5fe;
  border: 8px solid #2aabfd;
}
.circle-green {
  background: #7acf7d;
  border: 8px solid #71c774;
}
.circle-orange {
  background: #ffbf59;
  border: 8px solid #ffb136;
}
.circle-waterblue {
  background: #41c8e5;
  border: 8px solid #25b9d9;
}
/*TDSQL*/
.unit-tips {
  margin-left: 10px;
  line-height: 28px;
}
.tdsql-modal .help-tips {
  font-size: 12px;
  color: #999;
  margin-top: 10px;
}
.tdsql-modal .con-item {
  line-height: 28px;
}
.tdsql-modal .time-help {
  line-height: 28px;
  font-size: 12px;
  color: #999;
}
.tdsql-modal .bind-bar {
  width: 70%;
  float: left;
}
.tdsql-modal .bind-input {
  width: 30%;
  float: left;
  position: relative;
  padding: 16px;
}
.tdsql-modal .bind-input .input-tips {
  position: absolute;
  font-size: 12px;
  color: #999;
  left: 30px;
  bottom: -8px;
}
.tdsql-modal .bind-input .input-text {
  padding-right: 22px;
}
.tdsql-modal .bind-input .unit {
  position: absolute;
  font-size: 12px;
  color: #999;
  right: 50px;
  bottom: 21px;
}
.tdsql-modal .bind-input button {
  float: left;
  background: #f2f2f2;
  text-align: center;
  width: 28px;
  height: 28px;
  border: 1px solid #d7d7d7;
}
.tdsql-modal .bind-input input {
  float: left;
  width: 80px;
}
.tdsql .tdsql_name span {
  display: block;
  width: 100%;
}
.tdsql .first-part {
  border-bottom: 1px solid #e5e5e5;
}
.tdsql .shortcuts-ins-page {
  position: relative;
}
.tdsql .shortcuts-ins-page .foot-case {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 25px 85px;
  background: #fafafa;
}
.tdsql .shortcuts-ins-page .foot-case .btn {
  height: 42px;
  width: 118px;
}
.tdsql .second-part {
  padding-top: 30px;
}
.tdsql .second-part .bind-bar {
  width: 50%;
  float: left;
}
.tdsql .second-part .bind-input {
  position: relative;
  padding: 16px;
}
.tdsql .second-part .bind-input .input-tips {
  position: absolute;
  font-size: 12px;
  color: #999;
  left: 30px;
  bottom: -8px;
}
.tdsql .second-part .bind-input .input-text {
  padding-right: 22px;
}
.tdsql .second-part .bind-input .unit {
  position: absolute;
  font-size: 12px;
  color: #999;
  right: 50px;
  bottom: 21px;
}
.tdsql .second-part .tdsql-num {
  width: 100%;
  overflow: hidden;
}
.tdsql .second-part .bind-inputs input {
  margin: 0 6px;
}
.tdsql .second-part .bind-inputs,
.tdsql .second-part .bind-input {
  float: left;
}
.tdsql .second-part .bind-inputs button,
.tdsql .second-part .bind-input button {
  float: left;
  background: #f2f2f2;
  text-align: center;
  width: 28px;
  height: 28px;
  border: 1px solid #d7d7d7;
}
.tdsql .second-part .bind-inputs input,
.tdsql .second-part .bind-input input {
  float: left;
  width: 80px;
}
.tdsql .create-tdsql {
  padding: 20px 20px 0;
}
.tdsql .create-tdsql .control-group .control-label {
  width: 10%;
}
.tdsql .create-tdsql .create-tips {
  font-size: 12px;
  color: #999;
  padding: 6px 0;
}
.tdsql .create-tdsql .create-tip {
  font-size: 12px;
  color: #999;
  padding: 10px 0;
}
.tdsql .create-tdsql .control-creates {
  width: 100%;
}
.tdsql .create-tdsql .control-creates .controls {
  padding-left: 0;
}
.tdsql .create-tdsql .control-creates .control-group {
  display: inline-block;
  margin-bottom: 0;
}
.tdsql .create-tdsql .control-creates .control-group .control-label {
  width: 50px;
}
.tdsql .create-tdsql .control-creates .control-group .controls {
  width: 260px;
}
.tdsql .create-tdsql .control-creates .control-group .controls.base-net {
  width: 160px;
  margin-right: 10px;
}
.tdsql .create-tdsql .control-creates .control-group .con-tips {
  font-size: 12px;
  line-height: 28px;
  float: left;
  color: #999;
}
.tdsql .create-tdsql .control-creates .control-group .con-help {
  font-size: 12px;
  line-height: 28px;
  float: left;
  color: #3B78E7;
  margin-left: 10px;
}
/*多数据中心概况*/
.main .main-content .overview .initSetting .tips {
  cursor: pointer;
}
.main .main-content .overview .initSetting .setting {
  color: #51a3ff;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}
.main .main-content .overview .box.cluster-box {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}
.main .main-content .overview .box.cluster-box div[class^="col-"] {
  border: 0;
}
.main .main-content .overview .box.cluster-box div[class^="col-"] .cluster-item {
  width: 96%;
  padding: 20px 40px 20px 50px;
  margin-right: 20px;
  margin-top: 0;
  border: solid 1px #e5e5e5;
  position: relative;
}
.main .main-content .overview .box.cluster-box div[class^="col-"] .cluster-item .cluster-right {
  text-align: right;
}
.main .main-content .overview .box.cluster-box div[class^="col-"] .cluster-item:hover {
  border: solid 1px #51a3ff;
}
.main .main-content .overview .box.cluster-box div[class^="col-"] .cluster-item .single {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.main .main-content .overview .box div[class^="col-"] > .title {
  height: 32px;
  line-height: 32px;
  font-size: 14px;
}
.main .main-content .overview .box .inner .title {
  height: 20px;
  line-height: 20px;
  font-size: 16px;
  margin-left: 15px;
}
.main .main-content .overview .box .inner .title span {
  float: left;
  font-size: 14px;
}
.main .main-content .overview .box .inner .title .region-tips {
  float: left;
  display: inline-block;
  margin-left: 20px;
}
.main .main-content .overview .box .inner .title .region-tips li {
  float: left;
  line-height: 20px;
  margin-right: 20px;
  position: relative;
  cursor: default;
}
.main .main-content .overview .box .inner .title .region-tips li .icon-box {
  display: inline-block;
  height: 12px;
  width: 12px;
  margin: 4px 0;
  border-radius: 2px;
  float: left;
}
.main .main-content .overview .box .inner .title .region-tips li .region-name {
  max-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  margin-left: 10px;
  float: left;
}
.main .main-content .overview .box .inner .title .region-tips li:hover .tips {
  display: block;
}
.main .main-content .overview .box .inner .title .region-tips li .tips {
  position: absolute;
  top: 25px;
  left: -78px;
  width: 170px;
  border-radius: 2px;
  border: 1px solid #e5e5e5;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);
  font-size: 14px;
  background: #fff;
  padding-bottom: 15px;
  z-index: 99;
  display: none;
}
.main .main-content .overview .box .inner .title .region-tips li .tips em {
  display: block;
  width: 12px;
  height: 12px;
  position: absolute;
  left: 50%;
  margin-left: -6px;
  top: -6px;
  border-left: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
  background: #fff;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}
.main .main-content .overview .box .inner .title .region-tips li .tips .tips-title {
  font-size: 16px;
  color: #4a6583;
  line-height: 36px;
  padding-left: 10px;
}
.main .main-content .overview .box .inner .title .region-tips li .tips .tips-title .errors-tips {
  padding-top: 15px;
  font-size: 12px;
}
.main .main-content .overview .box .inner .title .region-tips li .tips .tips-title .title {
  float: left;
  width: 65px;
  margin: 0;
  height: 36px;
  line-height: 36px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
}
.main .main-content .overview .box .inner .title .region-tips li .tips .tips-title .unit {
  float: right;
  margin-right: 8px;
  font-size: 14px;
}
.main .main-content .overview .box .inner .title .region-tips li .tips .total-item,
.main .main-content .overview .box .inner .title .region-tips li .tips .used-item,
.main .main-content .overview .box .inner .title .region-tips li .tips .free-item {
  line-height: 32px;
  color: #7991ab;
  padding: 0 16px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
}
.main .main-content .overview .box .inner .title .region-tips li .tips .total-item i,
.main .main-content .overview .box .inner .title .region-tips li .tips .used-item i,
.main .main-content .overview .box .inner .title .region-tips li .tips .free-item i {
  margin-left: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 550;
}
.main .main-content .overview .box .inner .title .region-tips li .tips .used-item i {
  color: #4e90f8;
}
.main .main-content .overview .box .user-box {
  text-align: center;
}
.main .main-content .overview .box .user-box h2 {
  text-align: left;
}
.main .main-content .overview .box .user-box .cluster-item {
  width: 55%;
  display: inline-block;
  margin-top: 10px;
}
.main .main-content .overview .box .user-box .cluster-item .cluster-left {
  float: left;
}
.main .main-content .overview .box .user-box .cluster-item .cluster-right {
  float: right;
  padding: 10px 10px ;
  text-align: left;
}
.main .main-content .overview .box .user-box .cluster-item .cluster-right .cluster-warning {
  color: #f39c12;
}
.main .main-content .overview .box .user-box .cluster-item .cluster-right span {
  display: block;
  font-size: 36px;
  margin-left: -2px;
  color: #797979;
}
.main .main-content .overview .box .user-box .cluster-item .cluster-right div {
  color: #999;
}
.main .main-content .overview .box .content-rowtwo .right-con {
  float: left;
  width: 50%;
}
.main .main-content .overview .box .content-rowtwo .right-con .map-bg {
  width: 90%;
  position: relative;
}
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .legend-tip {
  margin-top: 20px;
}
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .legend-tip li {
  margin-right: 20px;
  float: left;
}
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .legend-tip li i {
  float: left;
  display: inline-block;
  height: 15px;
  width: 15px;
  border-radius: 3px;
}
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .legend-tip li span {
  margin-left: 10px;
  line-height: 15px;
  float: left;
}
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .legend-tip li:nth-child(1) i {
  background: #7dbbff;
}
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .legend-tip li:nth-child(2) i {
  background: #5888f5;
}
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .mv-name,
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .phys-name,
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .net-name,
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .net-bg,
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .nodemv-bg,
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .nodephys-bg {
  display: inline-block;
  width: 100%;
}
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .net-name {
  color: #90A3BB;
}
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .mv-name {
  color: #7dbbff;
}
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .mv-name.warn-bg {
  color: #FF9900;
}
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .phys-name {
  color: #5888f5;
}
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .net-ip {
  display: inline-block;
  padding: 0 5px;
  z-index: 100;
  display: none;
  position: absolute;
  border-radius: 4px;
  border: 1px solid #191919;
  height: 30px;
  min-width: 100px;
  line-height: 30px;
  left: 0;
  top: 70px;
  color: #90A3BB;
  background: #191919;
  font-size: 12px;
}
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .net-ip i {
  position: absolute;
  top: -5px;
  width: 10px;
  height: 10px;
  left: 45%;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  border-top: 1px solid #191919;
  border-left: 1px solid #191919;
  background: #191919;
}
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .nodes-type {
  max-height: 215px;
  min-height: 150px;
  border: 1px solid #c2d3eb;
  overflow-y: auto;
  padding-bottom: 35px;
}
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .nodes-type li {
  padding-top: 20px;
  float: left;
  width: 25%;
}
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .nodes-type li div {
  position: relative;
  text-align: center;
}
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .nodes-type li div .nodemv-bg {
  height: 45px;
  font-size: 42px;
  color: #2aabfd;
}
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .nodes-type li div .nodemv-bg:hover .net-ip {
  display: block;
}
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .nodes-type li div .nodemv-bg.warn-bg {
  color: #FF9900;
}
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .network-type li {
  float: left;
  width: 25%;
  height: 100px;
}
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .network-type li div {
  position: relative;
  text-align: center;
}
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .network-type li div .net-bg {
  height: 43px;
  font-size: 36px;
  color: #90a3bb;
}
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .network-type li div .net-bg:hover .net-ip {
  display: block;
}
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .network-type li div .vertical-line {
  border-right: 1px solid #c2d3eb;
  display: inline-block;
  position: relative;
  height: 40px;
}
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .network-type li div .vertical-line i {
  position: absolute;
  display: inline-block;
  height: 5px;
  width: 5px;
  left: -2px;
  background: #c2d3eb;
}
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .network-type li div .vertical-line i:nth-child(1) {
  top: 0;
}
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .network-type li div .vertical-line i:nth-child(2) {
  bottom: 0;
}
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .pre,
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .next {
  display: inline-block;
  position: absolute;
  top: 100px;
  line-height: 30px;
  text-align: center;
  font-size: 16px;
  color: #c2d3eb;
  height: 30px;
  width: 30px;
  border-radius: 4px;
  cursor: pointer;
}
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .pre:hover,
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .next:hover {
  color: #51a3ff;
}
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .pre {
  left: 0;
}
.main .main-content .overview .box .content-rowtwo .right-con .map-bg .next {
  right: -15px;
}
.main .main-content .overview .box .content-rowtwo .right-con .map-bg #container {
  height: 100%;
}
.main .main-content .overview .box .content-rowtwo .panels .pie-legend {
  width: 100%;
  display: block;
  min-height: 20px;
  text-align: center;
}
.main .main-content .overview .box .content-rowtwo .panels .pie-legend .dl-horizontal {
  display: inline-block;
  margin: 0 15px;
}
.main .main-content .overview .box .content-rowtwo .panels .pie-legend .dl-horizontal .pie-legend-name {
  line-height: 42px;
  max-width: 50px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  float: left;
  margin-left: 5px;
}
.main .main-content .overview .box .content-rowtwo .panels .pie-legend .dl-horizontal .pie-legend-value {
  line-height: 42px;
  font-size: 20px;
  float: left;
  margin-left: 10px;
}
.main .main-content .overview .box .content-rowtwo .panels .pie-legend .dl-horizontal dt {
  border-radius: 0;
  width: 15px;
}
.main .main-content .overview .box .content-rowtwo .panels .pie-legend .dl-horizontal i {
  border-radius: 4px;
  width: 15px;
  height: 15px;
}
.main .main-content .overview .box .content-rowtwo .panels .pie-wrap {
  width: 100%;
  padding-top: 20px;
}
.main .main-content .overview .box .content-rowtwo .panels .pie-wrap .pie {
  width: 220px;
  height: 220px;
}
.main .main-content .overview .box .content-rowtwo .tables {
  width: 45%;
  border-top: 1px solid #e5e5e5;
  float: left;
  font-size: 12px;
  padding: 10px;
  border-collapse: collapse;
}
.main .main-content .overview .box .content-rowtwo .tables .region-name {
  text-align: left;
  padding-left: 15px;
}
.main .main-content .overview .box .content-rowtwo .tables .region-name .icon-box {
  display: inline-block;
  height: 12px;
  width: 12px;
  margin: 2px 0;
  border-radius: 2px;
  float: left;
  margin-right: 5px;
}
.main .main-content .overview .box .content-rowtwo .tables thead tr {
  line-height: 32px;
  background: #f3f3f3;
}
.main .main-content .overview .box .content-rowtwo .tables thead tr th {
  font-weight: 600;
}
.main .main-content .overview .box .content-rowtwo .tables thead tr th.region-tb-name {
  text-align: left;
  padding-left: 15px;
}
.main .main-content .overview .box .content-rowtwo .tables tbody tr:hover {
  background: #f6f9ff;
}
.main .main-content .overview .box .content-rowtwo .tables tr {
  border-bottom: 1px solid #e5e5e5;
}
.main .main-content .overview .box .content-rowtwo .tables tr th,
.main .main-content .overview .box .content-rowtwo .tables tr td {
  padding: 6px 0px;
  width: 25%;
  text-align: center;
}
.main .main-content .overview .box .content-rowtwo .tables tr th a,
.main .main-content .overview .box .content-rowtwo .tables tr td a {
  width: 70px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
}
.main .main-content .overview .box .content-rowtwo .tables .datacenter-tips {
  line-height: 24px;
}
.main .main-content .overview .box .content-rowtwo .tables .phy-num,
.main .main-content .overview .box .content-rowtwo .tables .normal-num,
.main .main-content .overview .box .content-rowtwo .tables .abnormal-num {
  font-size: 20px;
  font-weight: 600;
}
.main .main-content .overview .box .content-rowtwo .tables .normal-num {
  color: #51a3ff;
}
.main .main-content .overview .box .content-rowtwo .tables .abnormal-num {
  color: #FF9900;
}
.main .main-content .overview .box .chart-tips {
  display: block;
  min-height: 245px;
  line-height: 245px;
  text-align: center;
}
.main .main-content .overview .box .area-chart-wrap {
  position: relative;
}
.main .main-content .overview .box .area-chart-wrap .legend {
  position: absolute;
  top: -28px;
  left: 70px;
}
.main .main-content .overview .box .area-chart-wrap .legend .series .legend-color {
  height: 12px;
  width: 12px;
}
/* CSS Document */
.control-row {
  margin: 0 -25px;
}
.text-center {
  text-align: center;
}
.redCursor {
  color: #e67e22;
  cursor: pointer;
}
.redCursor a {
  color: #e67e22;
}
.redCursor:after {
  content: " (\672A\8BFB)";
}
.redPointer {
  color: #e74c3c;
  cursor: pointer;
}
.bluePointer {
  color: #51a3ff;
  cursor: pointer;
}
.marginLeft {
  margin-left: 130px;
}
.input-group display:table .form-control {
  display: table-cell;
}
.input-group > span {
  width: 1%;
  display: table-cell;
  white-space: nowrap;
  vertical-align: middle;
  padding: 0px 16px;
}
.ellipsis {
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.floating-group {
  height: 20px;
}
.floating-group .floating-label {
  width: 25%;
  text-align: right;
  color: #333;
  font-weight: normal;
  font-size: 14px;
  float: left;
}
.floating-group .controls {
  padding-left: 5%;
  width: 65%;
  float: left;
  text-align: left;
}
.floating-group .controls .text-tips {
  color: #999;
}
.control-group.control-item-inner {
  padding: 0;
  width: 50%;
  float: left;
}
.control-group.control-item-inner .control-label {
  left: 40px;
  float: left;
  width: 38%;
  z-index: 1;
  margin-right: 10px;
}
.control-group.control-item-inner .controls {
  width: 50%;
  margin: 0;
  padding: 0;
  float: left;
}
.control-group.control-item-inner .controls-checkbox {
  width: 33%;
  margin: 0;
  padding: 0;
  float: left;
}
.control-group.control-item-inner .form-control {
  text-align: left;
}
.control-group.control-item-inner-width {
  padding: 0;
  width: 33%;
  float: left;
}
.control-group.control-item-inner-width .control-label {
  left: 40px;
  float: left;
  width: 38%;
  z-index: 1;
  margin-right: 10px;
}
.control-group.control-item-inner-width .controls {
  width: 50%;
  margin: 0;
  padding: 0;
  float: left;
}
.control-group.control-item-inner-width .controls-dq {
  width: 43%;
  margin: 0;
  padding: 0;
  float: left;
}
.control-group.control-item-inner-width .form-control {
  text-align: left;
}
.overview-bar .left {
  width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.control-horizontal .control-items2 .control-label,
.control-horizontal .control-items2 .controls {
  width: 100%;
  padding: 0;
  float: none;
  text-align: left;
}
.control-horizontal .control-items2 .control-label .checkbox,
.control-horizontal .control-items2 .controls .checkbox {
  float: right;
  height: 28px;
  line-height: 28px;
}
.control-choose .control-label {
  width: 5%;
}
.control-choose .controls {
  width: 95%;
}
.control-choose .controls .choose-item {
  width: 200px;
}
.quotas-type .control-group .control-label {
  width: 20%;
}
.quotas-type .control-group .controls {
  width: 65%;
}
.quotas-type .table-responsive tbody td {
  padding: 6px 16px;
}
.volumes-QoS-body .control-group .control-label {
  width: 22%;
}
.volumes-QoS-body .control-group .controls {
  width: 78%;
}
.error-start-box {
  padding: 0 50px;
}
.error-start-box .control-group-label .control-label {
  width: 120px;
}
.error-start-box .control-group-label .controls {
  width: 50%;
}
.error-start-box p {
  margin-top: 25px;
  font-size: 12px;
  color: #999;
  padding-left: 8px;
  line-height: 18px;
}
/* datetime */
.date-box > .control-group {
  width: 25%;
  display: inline-block;
  float: left;
}
.date-box > .form-group {
  margin-right: 10px;
}
.date-box .time-picker {
  position: relative;
}
.date-box .time-picker .form-control {
  width: 200px;
  margin-right: -1px;
}
.date-box .time-picker .input-group-addon {
  cursor: pointer;
  border: 1px solid #e5e5e5;
  border-left: none;
  background-color: #ffffff;
  border-radius: 0 2px 2px 0;
}
.date-box .time-picker .error {
  position: absolute;
  left: 0px;
  top: 38px;
  color: #e74c3c;
  font-size: 12px;
}
.datetimepicker,
.daterangepicker {
  border: solid 1px #e5e5e5;
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.2);
  min-width: 100px;
  z-index: 999;
}
.datetimepicker .datetimepicker-days,
.daterangepicker .datetimepicker-days {
  padding: 20px 15px 10px;
}
.datetimepicker .table-condensed,
.daterangepicker .table-condensed {
  color: #7991ab;
}
.datetimepicker .table-condensed thead tr th,
.daterangepicker .table-condensed thead tr th {
  font-weight: bold;
}
.datetimepicker .table-condensed thead tr th.dow,
.daterangepicker .table-condensed thead tr th.dow {
  padding-top: 20px;
}
.datetimepicker .table-condensed thead tr th.prev,
.daterangepicker .table-condensed thead tr th.prev,
.datetimepicker .table-condensed thead tr th.next,
.daterangepicker .table-condensed thead tr th.next {
  color: #c2d3eb;
}
.datetimepicker .table-condensed tbody tr .day,
.daterangepicker .table-condensed tbody tr .day {
  height: 25px 25px;
}
.datetimepicker .table-condensed tbody tr .day.old,
.daterangepicker .table-condensed tbody tr .day.old,
.datetimepicker .table-condensed tbody tr .day.new,
.daterangepicker .table-condensed tbody tr .day.new {
  color: #c2d3eb;
}
.datetimepicker .table-condensed tbody tr .day.active,
.daterangepicker .table-condensed tbody tr .day.active,
.datetimepicker .table-condensed tbody tr .day.today,
.daterangepicker .table-condensed tbody tr .day.today {
  background: none;
  color: #51a3ff;
  text-decoration: underline;
}
.datetimepicker .table-condensed tbody tr .day.active.active,
.daterangepicker .table-condensed tbody tr .day.active.active,
.datetimepicker .table-condensed tbody tr .day.today.active,
.daterangepicker .table-condensed tbody tr .day.today.active,
.datetimepicker .table-condensed tbody tr .day.active:hover.active,
.daterangepicker .table-condensed tbody tr .day.active:hover.active,
.datetimepicker .table-condensed tbody tr .day.today:hover.active,
.daterangepicker .table-condensed tbody tr .day.today:hover.active,
.datetimepicker .table-condensed tbody tr .day.active:hover,
.daterangepicker .table-condensed tbody tr .day.active:hover,
.datetimepicker .table-condensed tbody tr .day.today:hover,
.daterangepicker .table-condensed tbody tr .day.today:hover,
.datetimepicker .table-condensed tbody tr .day.active:hover:hover,
.daterangepicker .table-condensed tbody tr .day.active:hover:hover,
.datetimepicker .table-condensed tbody tr .day.today:hover:hover,
.daterangepicker .table-condensed tbody tr .day.today:hover:hover {
  background: #51a3ff;
  color: #ffffff;
  text-decoration: none;
}
.datetimepicker .table-condensed tbody tr .day:hover,
.daterangepicker .table-condensed tbody tr .day:hover {
  background: none;
  color: #51a3ff;
}
.datetimepicker .table-condensed tbody tr td.active,
.daterangepicker .table-condensed tbody tr td.active {
  background: #51a3ff;
}
.datetimepicker .table-condensed tbody tr td.active:hover,
.daterangepicker .table-condensed tbody tr td.active:hover {
  background: #51a3ff;
}
.datetimepicker .table-condensed tbody tr td.off.in-range,
.daterangepicker .table-condensed tbody tr td.off.in-range {
  background: #ffffff;
}
.datetimepicker .table-condensed tbody tr td.off.start-date,
.daterangepicker .table-condensed tbody tr td.off.start-date {
  background: #ffffff;
}
.datetimepicker .table-condensed tfoot .today,
.daterangepicker .table-condensed tfoot .today {
  background: #eee;
}
.daterangepicker.dropdown-menu {
  min-width: 0;
  padding: 10px;
  border: 1px solid #51a3ff;
  padding-bottom: 40px;
}
.daterangepicker.dropdown-menu .calendar .daterangepicker_input {
  display: none;
}
.daterangepicker.dropdown-menu .ranges {
  float: none;
  position: absolute;
  right: 0;
  bottom: 10px;
}
.daterangepicker.dropdown-menu .ranges .range_inputs {
  float: right;
}
.daterangepicker.dropdown-menu .ranges .range_inputs .btn.btn-sm {
  min-width: 32px;
  color: #fff;
  background: #51a3ff;
  border: 1px solid #c2d3eb;
}
.daterangepicker.dropdown-menu .ranges .range_inputs .btn.btn-sm:hover {
  color: #ffffff;
  background: #4e80f5;
  border: 1px solid #4e80f5;
}
.daterangepicker.dropdown-menu .ranges .range_inputs .btn.btn-sm[disabled] {
  cursor: not-allowed;
  background: #fafafa;
  border: 1px solid #e5e5e5;
  color: #666666;
}
.daterangepicker .calendar .daterangepicker_input .input-mini {
  width: 220px;
}
.daterangepicker .calendar .daterangepicker_input .input-mini.active {
  border: 1px solid #c2d3eb;
}
.daterangepicker .calendar .calendar-table .table-condensed th .glyphicon {
  height: 22px;
  background: #51a3ff;
  width: 22px;
  line-height: 22px;
  color: #fff;
  border-radius: 2px;
}
/*自定义分页下拉框样式*/
.table-pager.self-table-pager {
  position: unset;
}
.table-pager.self-table-pager .pagination {
  float: right;
  margin-right: 5px;
}
.table-pager.self-table-pager .pagination .selectchunk {
  width: 80px;
}
.table-pager.self-table-pager .pagination .ui-select-container .btn {
  width: 80px;
  padding: 3px 10px;
  height: 24px;
}
.table-pager.self-table-pager .limitpage {
  float: right;
  margin-right: 10px;
}
.table-pager.self-table-pager .limitpage > li {
  float: left;
  line-height: 24px;
  margin-left: 20px;
}
.table-pager.self-table-pager .limitpage > li.selectChunk {
  width: 80px;
}
.table-pager.self-table-pager .limitpage .ui-select-container .btn {
  width: 80px;
  padding: 3px 10px;
  height: 24px;
}
.iframeModel {
  width: 100%;
  min-height: 750px;
  height: auto;
}
.page-inner .table-action.ins-action .pull-right .search-box .btn,
.page-inner .table-action.privateNet-action .pull-right .search-box .btn {
  cursor: pointer;
}
.page-inner .table-action.phyNet-action .search-box .btn {
  cursor: pointer;
}
/* table */
.table .deleteNode {
  background-color: #ffefd5;
}
.table tbody tr td .row,
.table tbody tr td .date {
  white-space: nowrap;
  margin: 0;
}
.table tbody tr td.check-td {
  width: 50px;
}
.table tbody tr td .btn {
  height: 26px;
  line-height: 24px;
  min-width: 74px;
  font-size: 12px;
  padding: 0;
}
.table tbody tr td .btn.small {
  min-width: 63px;
}
.table tbody tr td .icon-item > a i {
  font-size: 24px;
}
.table tbody tr td .icon-item > a.blue {
  color: #51a3ff;
}
.table tbody tr td .icon-item > a.yellow {
  color: #f29c11;
}
.table tbody tr td .icon-item > a.green {
  color: #1abc9c;
}
.table tbody tr td .active,
.table tbody tr td .stopped,
.table tbody tr td .shutoff,
.table tbody tr td .error,
.table tbody tr td .paused,
.table tbody tr td .suspended {
  font-size: 12px;
  line-height: 1;
  padding: 3px 6px;
  color: #ffffff;
  border-radius: 2px;
}
.table tbody tr td .ui-select-choices-group .active {
  background-color: #428bca;
}
.table tbody tr td .active {
  background-color: #1abc9c;
}
.table tbody tr td .stopped,
.table tbody tr td .shutoff {
  background-color: #999999;
}
.table tbody tr td .error {
  background-color: #e74c3c;
}
.table tbody tr td .paused {
  background-color: #4e80f5;
}
.table tbody tr td .suspended {
  background-color: #f39c12;
}
.table tbody tr td .popover {
  z-index: 100;
}
.table tbody tr td .popover .popover-title {
  font-weight: bold;
}
.table tbody tr td .popover .popover-inner {
  max-height: 180px;
  overflow: auto;
}
.table.table-info tbody tr td {
  border-right: solid 1px #e5e5e5;
}
.table.table-info tbody tr td:nth-child(odd) {
  width: 20%;
  background-color: #f6f6f6;
}
.table.table-info tbody tr td:nth-child(even) {
  width: 30%;
}
.table.table-info tbody tr td:last-child {
  border-right: none;
}
/*云硬盘重置状态弹出框提示信息的左移*/
.alert .del-cont .resetVolume-info {
  text-align: left;
  height: 100px;
  line-height: 26px;
}
.tab-content-line {
  height: 0px;
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 15px;
}
.tab-content-line.tall {
  margin-bottom: 20px;
}
.main-top-box {
  margin: 0 20px 15px;
  border-bottom: 1px solid #e5e5e5;
  padding: 13px 0 14px;
  min-height: 48px;
}
.main-top-box.hide-bottom {
  border-bottom: 0;
  margin-bottom: 0;
}
.main-top-box.hide-top {
  min-height: 0;
  border-bottom: none;
  margin: 0 0 10px;
  padding: 0;
}
.main-top-box.hide-top .page-top-title {
  display: none;
}
.main-top-box .page-top-title {
  font-size: 16px;
  color: #666666;
  font-weight: bold;
  float: left;
  padding: 8px 0;
  line-height: 1;
}
.page-inner {
  padding: 0 20px;
  /* position: relative; */
  min-height: 775px;
}
.page-inner .ng-table-pager {
  position: static;
}
.page-inner .page-title {
  display: none;
}
.page-inner .tab-title {
  float: left;
  margin-right: 20px;
  height: 28px;
  line-height: 28px;
  color: #7991ab;
  min-width: 70px;
}
.vm-host-table .table tbody tr td {
  padding: 12px 16px;
}
.open.page-inner {
  overflow: visible;
}
.ins-table-action .dropdown-menu {
  min-width: 130px;
}
.res-vm-view .animateContent.animateIn {
  height: auto;
}
.vm-view .animateContent.animateIn {
  height: auto;
}
/* .res-vm-view .animateContent.animateIn{height:auto;width:85%;min-height:auto;} */
/* table-detail */
/* .animate-open{
	position:fixed;
	top:0;
	left:0;
	background:rgba(0,0,0,0.5);
	z-index:999;
	width:100%;
	height:100%;
} */
.animateContent {
  background-color: #ffffff;
  position: fixed;
  width: 85%;
  opacity: 0;
  top: 70px;
  height: 500px;
  min-height: calc(100% - 13px);
  overflow: hidden;
  /* -webkit-transition: all .3s linear 0s;
	-ms-transition: all .3s linear 0s;
	transition: all .3s linear 0s; */
  border: 1px solid #c2d3eb;
  z-index: -1;
}
.animateContent .tab-content {
  margin-bottom: 0;
}
.animateContent .inner-wrap {
  width: 300%;
  position: absolute;
}
.animateContent .inner-wrap.left0 {
  left: 0%;
  -webkit-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  transition: height 0s linear 0s, opacity 0.4s linear 0s, left 0.4s linear 0s;
}
.animateContent .inner-wrap.left1 {
  left: -100%;
  -webkit-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  transition: height 0s linear 0s, opacity 0.4s linear 0s, left 0.4s linear 0s;
}
.animateContent .inner-wrap.left2 {
  left: -200%;
  -webkit-transition: all 0.4s linear 0s;
  -ms-transition: all 0.4s linear 0s;
  transition: height 0s linear 0s, opacity 0.4s linear 0s, left 0.4s linear 0s;
}
.animateContent .inner-wrap .detailInner {
  float: left;
  width: 33.3%;
}
.animateContent .detail-header {
  display: none;
  padding: 0 30px;
  border-bottom: 1px solid #c2d3eb;
  background-color: #f6f9ff;
  min-height: 45px;
}
.animateContent .detail-header .table {
  border: none;
  margin-bottom: 0;
}
.animateContent .detail-header .table tr {
  border-bottom: none;
  background-color: #f6f9ff;
}
.animateContent .detail-title {
  padding: 21px 20px;
  line-height: 1;
  overflow: hidden;
}
.animateContent .detail-title .an-close {
  text-align: center;
  color: #4e80f5;
  border-radius: 2px;
  padding: 0;
  margin-right: 10px;
  line-height: 1;
  display: block;
  float: right;
  font-size: 18px;
}
.animateContent .detail-title .an-close i:before {
  content: "\E80E";
}
.animateContent .detail-title > span {
  font-size: 16px;
  display: inline-block;
  float: left;
  margin-right: 50px;
  margin-top: 2px;
}
.animateContent .detail-title .table-action {
  float: left;
  display: inline-block;
  margin-bottom: 0;
}
.animateContent .detail-info-two {
  position: relative;
  padding: 0 35px;
}
.animateContent .detail-info {
  min-height: 500px;
  padding: 0px 20px;
  position: relative;
}
.animateContent .detail-info .table-title {
  float: left;
  font-size: 16px;
  line-height: 28px;
}
.animateContent .detail-info .sub-title {
  margin-bottom: 10px;
}
.animateContent .detail-info .page-inner {
  padding: 0;
}
.animateContent .detail-info .detail-title-ex {
  margin-bottom: 10px;
  font-size: 16px;
}
.animateContent .detail-info .tab-content .tab-pane .scale-policy {
  border: 1px solid #e5e5e5;
}
.animateContent .detail-info .tab-content .tab-pane .scale-policy p {
  padding: 0 15px;
  height: 48px;
  line-height: 48px;
}
.animateContent .detail-info .tab-content .tab-pane .scale-policy p:hover {
  background-color: #f6f9ff;
}
.animateContent .detail-info.tab-offset-right {
  padding-top: 0;
  border-top: none;
}
.animateContent .detail-info.scroll {
  max-height: 600px;
  overflow-y: scroll;
}
.animateContent.animateIn {
  height: auto;
  overflow: hidden;
  right: 0;
  opacity: 1;
  top: 0px;
  position: absolute;
  filter: alpha(opacity=100);
  box-shadow: -1px 4px 11px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.3s ease-in 0s;
  -ms-transition: all 0.3s ease-in 0s;
  transition: height 0s ease-in 0s, opacity 0.3s ease-in 0s, right 0.3s ease-in 0s;
  z-index: 1002;
}
.animateContent.animateIn dt {
  text-align: left;
  width: 150px;
  padding: 0 25px;
  line-height: 34px;
  color: #999;
  font-weight: normal;
}
.animateContent.animateIn dd {
  color: #666;
  line-height: 34px;
  margin-left: 160px;
}
.animateContent.animateIn ~ .animate-backup {
  opacity: 1;
  z-index: 1001;
}
.animateContent.animateOut {
  opacity: 0;
  filter: alpha(opacity=0);
  right: -30%;
  transition: opacity 0.3s ease-in 0s, right 0.3s ease-in 0s, height 0s ease-in 0.3s, z-index 0s ease-in 0.3s;
}
.animateContent.animateOut ~ .animate-backup {
  opacity: 0;
}
.detail .animateContent {
  width: 70%;
}
.animate-backup {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1001;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -1;
}
.steps-sytle-ver.steps-sytle-ver-two .step-start,
.steps-sytle-ver.steps-sytle-ver-two .step-end {
  width: 50%;
}
.steps-sytle-ver li {
  width: 50%;
  text-align: center;
  position: relative;
  float: left;
}
.steps-sytle-ver li .inner {
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}
.steps-sytle-ver li .inner span {
  display: block;
  text-align: center;
  color: #999;
  margin-bottom: 4px;
}
.steps-sytle-ver li .inner i {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
  background: #e5e5e5;
  text-align: center;
  color: #999;
  font-style: normal;
  display: inline-block;
  position: relative;
  z-index: 1;
}
.steps-sytle-ver li .inner:before {
  content: "";
  height: 6px;
  width: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: 8px;
  background: #e5e5e5;
}
.steps-sytle-ver li .inner:after {
  content: "";
  height: 6px;
  width: 50%;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: 8px;
  background: #e5e5e5;
}
.steps-sytle-ver li.step-start {
  width: 25%;
}
.steps-sytle-ver li.step-start .inner {
  text-align: left;
}
.steps-sytle-ver li.step-start .inner span {
  transform: translate(-50%, 0);
}
.steps-sytle-ver li.step-start .inner i {
  margin-left: -10px;
}
.steps-sytle-ver li.step-start .inner:after {
  width: 100%;
  right: 0;
}
.steps-sytle-ver li.step-start .inner:before {
  content: "";
  width: 0;
  height: 0;
  position: static;
  overflow: hidden;
}
.steps-sytle-ver li.step-end {
  width: 25%;
}
.steps-sytle-ver li.step-end .inner {
  text-align: right;
}
.steps-sytle-ver li.step-end .inner span {
  transform: translate(50%, 0);
}
.steps-sytle-ver li.step-end .inner i {
  margin-right: -10px;
}
.steps-sytle-ver li.step-end .inner:before {
  width: 100%;
  left: 0;
}
.steps-sytle-ver li.step-end .inner:after {
  content: "";
  width: 0;
  height: 0;
  position: static;
  overflow: hidden;
}
.steps-sytle-ver li.active .inner span {
  color: #51a3ff;
}
.steps-sytle-ver li.active .inner i {
  color: #ffffff;
  background: #51a3ff;
}
.steps-sytle-ver li.active .inner:after {
  background: #51a3ff;
}
.steps-sytle-ver li.active .inner:before {
  background: #51a3ff;
}
.modal .modal-dialog {
  /* 云主机 */
  /* 分配权限 */
  /* 分配用户 */
}
.modal .modal-dialog .modal-common .step-box {
  margin-bottom: 30px;
  margin-left: 40px;
  height: 26px;
  font-size: 16px;
  color: #999999;
  position: relative;
}
.modal .modal-dialog .modal-common .step-box li {
  float: left;
  position: relative;
  width: 46%;
}
.modal .modal-dialog .modal-common .step-box li.active b {
  background-color: #1abc9c;
  color: #ffffff;
  border: solid 1px #16a085;
}
.modal .modal-dialog .modal-common .step-box li.active > span {
  color: #333333;
}
.modal .modal-dialog .modal-common .step-box li.active:before {
  background-color: #1abc9c;
}
.modal .modal-dialog .modal-common .step-box li:before {
  content: "";
  position: absolute;
  left: 35px;
  top: 11px;
  width: 99%;
  height: 4px;
  background-color: #cdcdcd;
}
.modal .modal-dialog .modal-common .step-box li:last-child {
  width: 8%;
}
.modal .modal-dialog .modal-common .step-box li:last-child:before {
  width: 0;
  height: 0;
}
.modal .modal-dialog .modal-common .step-box li > span {
  padding: 0 5px;
  background-color: #ffffff;
  display: inline-block;
}
.modal .modal-dialog .modal-common .step-box li b {
  width: 26px;
  height: 26px;
  margin-right: 5px;
  display: inline-block;
  background-color: #e5e5e5;
  border: solid 1px #e5e5e5;
  text-align: center;
  line-height: 24px;
  border-radius: 50%;
}
.modal .modal-dialog .modal-common .step-arrow .arrow {
  display: inline-block;
  padding-right: 50px;
  position: relative;
}
.modal .modal-dialog .modal-common .step-arrow .arrow:last-child {
  padding-right: 0;
}
.modal .modal-dialog .modal-common .step-arrow .arrow:last-child:before {
  width: 0;
  height: 0;
}
.modal .modal-dialog .modal-common .step-arrow .arrow:last-child:after {
  border: 0;
}
.modal .modal-dialog .modal-common .step-arrow .arrow:before {
  content: "";
  position: absolute;
  right: 4px;
  top: 16px;
  width: 40px;
  height: 2px;
  background: #999999;
}
.modal .modal-dialog .modal-common .step-arrow .arrow:after {
  content: "";
  position: absolute;
  right: 0;
  top: 11px;
  width: 0;
  height: 0;
  border-top: 2px solid transparent;
  border-left: 2px solid #999999;
  border-bottom: 2px solid transparent;
}
.modal .modal-dialog .modal-common.modal-img .modal-body {
  text-align: center;
}
.modal .modal-dialog .modal-body .ip-group .flex,
.modal .modal-dialog .modal-body .ip_cidr .flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.modal .modal-dialog .modal-body .ip-group .flex .ip-text,
.modal .modal-dialog .modal-body .ip_cidr .flex .ip-text {
  width: 100%;
}
.modal .modal-dialog .modal-body .ip-group .flex .ip-text .error,
.modal .modal-dialog .modal-body .ip_cidr .flex .ip-text .error {
  color: #e74c3c;
  line-height: 20px;
  font-size: 12px;
}
.modal .modal-dialog .modal-body .ip-group .flex .ip-spilt,
.modal .modal-dialog .modal-body .ip_cidr .flex .ip-spilt {
  height: 20px;
  margin: 12px 2px 0;
}
.modal .modal-dialog .modal-body .ip-group .flex .ip-spilt.ip6-tag,
.modal .modal-dialog .modal-body .ip_cidr .flex .ip-spilt.ip6-tag {
  margin-top: 5px;
}
.modal .modal-dialog .modal-body .deploy-group .cl-box {
  width: 20%;
  float: left;
}
.modal .modal-dialog .modal-body .deploy-group .choose-box {
  width: 78%;
  margin-left: 22%;
}
.modal .modal-dialog .modal-body .deploy-group .choose-box .choose {
  display: inline-block;
}
.modal .modal-dialog .modal-body .ip_pool .control-label {
  float: left;
  width: 17%;
  margin-bottom: 0;
  line-height: 28px;
  text-align: right;
  font-weight: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}
.modal .modal-dialog .modal-body .ip_pool .group {
  width: 83%;
  float: left;
}
.modal .modal-dialog .modal-body .ip_pool .group.repeat {
  margin-left: 17%;
}
.modal .modal-dialog .modal-body .ip_pool .group .control-group {
  width: 50%;
  float: left;
}
.modal .modal-dialog .instances-modal {
  width: 1000px;
}
.modal .modal-dialog .instances-modal .modal-body {
  padding: 0 20px 0 40px;
}
.modal .modal-dialog .instances-modal .modal-body > .left {
  width: 540px;
  float: left;
}
.modal .modal-dialog .instances-modal .modal-body > .left .title {
  margin-left: -20px;
}
.modal .modal-dialog .instances-modal .modal-body > .left .title .conf-icon {
  margin-left: 6px;
}
.modal .modal-dialog .instances-modal .modal-body > .left .title.systemInfoTitle span {
  cursor: pointer;
}
.modal .modal-dialog .instances-modal .modal-body > .left .title.systemInfoTitle span:hover {
  color: #51a3ff;
}
.modal .modal-dialog .instances-modal .modal-body > .left .control-label {
  width: 84px;
}
.modal .modal-dialog .instances-modal .modal-body > .left .controls {
  width: 456px;
  line-height: 1;
}
.modal .modal-dialog .instances-modal .modal-body > .left .control-items2 .control-group .control-label {
  width: 32%;
}
.modal .modal-dialog .instances-modal .modal-body > .left .control-items2 .control-group .controls {
  width: 68%;
}
.modal .modal-dialog .instances-modal .modal-body > .left .control-items2 .control-group .type-btn,
.modal .modal-dialog .instances-modal .modal-body > .left .control-items2 .control-group .set-btn {
  width: 50%;
}
.modal .modal-dialog .instances-modal .modal-body > .left .control-items2 .control-group .arch-btn {
  width: 100%;
}
.modal .modal-dialog .instances-modal .modal-body > .left .control-items2 .control-group .controls .btn-item-group {
  display: block;
}
.modal .modal-dialog .instances-modal .modal-body > .right {
  width: 330px;
  float: right;
}
.modal .modal-dialog .instances-modal .modal-body > .right .table {
  margin-bottom: 10px;
}
.modal .modal-dialog .instances-modal .modal-body > .right .table td {
  width: 50%;
  padding: 10px 15px;
  height: 42px;
  line-height: 22px;
}
.modal .modal-dialog .instances-modal .modal-body > .right .table td:nth-child(odd) {
  border-right: solid 1px #e5e5e5;
}
.modal .modal-dialog .instances-modal .modal-body > .right .table td > span {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  max-width: 100px;
  overflow: hidden;
  height: 16px;
  line-height: 16px;
}
.modal .modal-dialog .instances-modal .modal-body .title {
  border-bottom: solid 1px #e5e5e5;
  margin-bottom: 10px;
  height: 40px;
  line-height: 40px;
  font-weight: bold;
}
.modal .modal-dialog .instances-modal .modal-body .title > span {
  background-color: #ffffff;
  color: #666666;
  margin-bottom: -1px;
  width: 100px;
}
.modal .modal-dialog .instances-modal .modal-body .title > span i {
  cursor: pointer;
}
.modal .modal-dialog .modal-login {
  width: 500px;
}
.modal .modal-dialog .modal-login .modal-body {
  padding: 0 50px;
}
.modal .modal-dialog .modal-login .modal-body .control-group .control-label {
  width: 25%;
}
.modal .modal-dialog .modal-login .modal-body .control-group .controls {
  width: 70%;
}
.modal .modal-dialog .modal-login .modal-body span {
  height: 80px;
  line-height: 80px;
  display: block;
  text-align: center;
}
.modal .modal-dialog .modal-login .modal-body span a {
  color: #51a3ff;
}
.modal .modal-dialog .modal-login .modal-footer .btn {
  width: 180px;
}
.modal .modal-dialog .pvm-modal {
  width: 750px;
}
.modal .modal-dialog .pvm-modal fieldset .title.limit-part {
  height: 15px;
}
.modal .modal-dialog .importHardware-modal {
  width: 750px;
}
.modal .modal-dialog .importHardware-modal fieldset .IPMI_title {
  margin-bottom: 20px;
  font-weight: bold;
}
.modal .modal-dialog .privilegeTo-modal .modal-body {
  min-height: 370px;
  padding: 0 20px;
}
.modal .modal-dialog .privilegeTo-modal .modal-body h5 {
  margin-bottom: 8px;
}
.modal .modal-dialog .privilegeTo-modal .modal-body .table-responsive {
  height: 320px;
  border: solid 1px #e5e5e5;
  border-radius: 2px;
  overflow-y: auto;
}
.modal .modal-dialog .privilegeTo-modal .modal-body table {
  margin-bottom: 0;
  border: none;
}
.modal .modal-dialog .privilegeTo-modal .modal-body table tbody tr {
  height: 32px;
  padding: 6px 0;
  display: block;
  background: none;
}
.modal .modal-dialog .privilegeTo-modal .modal-body table tbody tr td {
  text-align: left;
  border: 0 none;
  padding: 0 0 0 15px;
}
.modal .modal-dialog .privilegeTo-modal .modal-body table tbody tr td .checkbox {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.modal .modal-dialog .privilegeTo-modal .modal-body .left-select-table,
.modal .modal-dialog .privilegeTo-modal .modal-body .right-select-table {
  width: 40%;
  float: left;
}
.modal .modal-dialog .privilegeTo-modal .modal-body .btnGroup {
  padding: 100px 20px;
  float: left;
  margin-top: 29px;
  width: 20%;
  height: 320px;
}
.modal .modal-dialog .privilegeTo-modal .modal-body .btnGroup button {
  display: block;
  font-size: 16px;
  margin: 20px auto;
  width: 32px;
  min-width: 32px;
  line-height: 28px;
  padding: 0 8px;
  color: #51a3ff;
  background: #ffffff;
  border: 2px solid #51a3ff;
}
.modal .modal-dialog .privilegeTo-modal .modal-body .btnGroup span {
  color: #e74c3c;
}
.modal .modal-dialog .roleToUser-modal .modal-body {
  min-height: 370px;
  padding: 0 20px;
}
.modal .modal-dialog .roleToUser-modal .modal-body h5 {
  margin-bottom: 8px;
}
.modal .modal-dialog .roleToUser-modal .modal-body .table-responsive {
  height: 320px;
  border: solid 1px #e5e5e5;
  border-radius: 2px;
  overflow-y: auto;
}
.modal .modal-dialog .roleToUser-modal .modal-body .left-select-table,
.modal .modal-dialog .roleToUser-modal .modal-body .right-select-table {
  width: 40%;
  float: left;
}
.modal .modal-dialog .roleToUser-modal .modal-body .left-select-table .dropdown,
.modal .modal-dialog .roleToUser-modal .modal-body .right-select-table .dropdown {
  border-top: none;
  height: 32px;
  position: relative;
  border-bottom: solid 1px #e5e5e5;
}
.modal .modal-dialog .roleToUser-modal .modal-body .left-select-table .dropdown > span,
.modal .modal-dialog .roleToUser-modal .modal-body .right-select-table .dropdown > span {
  display: inline-block;
  float: left;
  width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 32px;
  text-align: left;
  padding-left: 40px;
}
.modal .modal-dialog .roleToUser-modal .modal-body .left-select-table .dropdown > button,
.modal .modal-dialog .roleToUser-modal .modal-body .right-select-table .dropdown > button {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 32px;
  border: none;
  background: none;
  padding: 6px 12px;
}
.modal .modal-dialog .roleToUser-modal .modal-body .left-select-table .dropdown > button i,
.modal .modal-dialog .roleToUser-modal .modal-body .right-select-table .dropdown > button i {
  font-size: 16px;
  color: #c2d3eb;
}
.modal .modal-dialog .roleToUser-modal .modal-body .left-select-table .dropdown > button i:hover,
.modal .modal-dialog .roleToUser-modal .modal-body .right-select-table .dropdown > button i:hover {
  color: #51a3ff;
}
.modal .modal-dialog .roleToUser-modal .modal-body .left-select-table .dropdown > button i.icon-aw-check-square:before,
.modal .modal-dialog .roleToUser-modal .modal-body .right-select-table .dropdown > button i.icon-aw-check-square:before {
  content: "\E80F";
}
.modal .modal-dialog .roleToUser-modal .modal-body .left-select-table .dropdown > button i.icon-aw-check-square:hover:before,
.modal .modal-dialog .roleToUser-modal .modal-body .right-select-table .dropdown > button i.icon-aw-check-square:hover:before {
  content: "\E80F";
}
.modal .modal-dialog .roleToUser-modal .modal-body .left-select-table .dropdown > button i.icon-aw-indeterminate:before,
.modal .modal-dialog .roleToUser-modal .modal-body .right-select-table .dropdown > button i.icon-aw-indeterminate:before {
  content: "\E810";
}
.modal .modal-dialog .roleToUser-modal .modal-body .left-select-table .dropdown .select-role,
.modal .modal-dialog .roleToUser-modal .modal-body .right-select-table .dropdown .select-role {
  position: absolute;
  right: 10px;
  top: 0;
}
.modal .modal-dialog .roleToUser-modal .modal-body .left-select-table .dropdown .select-role .dropdown-toggle,
.modal .modal-dialog .roleToUser-modal .modal-body .right-select-table .dropdown .select-role .dropdown-toggle {
  padding: 0;
  border: none;
  background: none;
  color: #7ba0f8;
  text-align: right;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.modal .modal-dialog .roleToUser-modal .modal-body .left-select-table .dropdown .select-role .btn-group,
.modal .modal-dialog .roleToUser-modal .modal-body .right-select-table .dropdown .select-role .btn-group {
  height: 28px;
}
.modal .modal-dialog .roleToUser-modal .modal-body .left-select-table .dropdown .select-role .dropdown-menu,
.modal .modal-dialog .roleToUser-modal .modal-body .right-select-table .dropdown .select-role .dropdown-menu {
  overflow: auto !important;
  min-width: 140px;
  right: 0px;
  left: auto;
}
.modal .modal-dialog .roleToUser-modal .modal-body .left-select-table .dropdown .select-role .dropdown-menu li:nth-child(1),
.modal .modal-dialog .roleToUser-modal .modal-body .right-select-table .dropdown .select-role .dropdown-menu li:nth-child(1) {
  display: none;
}
.modal .modal-dialog .roleToUser-modal .modal-body .left-select-table .dropdown .select-role .dropdown-menu li:nth-child(2),
.modal .modal-dialog .roleToUser-modal .modal-body .right-select-table .dropdown .select-role .dropdown-menu li:nth-child(2) {
  display: none !important;
}
.modal .modal-dialog .roleToUser-modal .modal-body .left-select-table .dropdown .select-role .dropdown-menu li:nth-child(3),
.modal .modal-dialog .roleToUser-modal .modal-body .right-select-table .dropdown .select-role .dropdown-menu li:nth-child(3) {
  display: none;
}
.modal .modal-dialog .roleToUser-modal .modal-body .left-select-table .dropdown .select-role .dropdown-menu a,
.modal .modal-dialog .roleToUser-modal .modal-body .right-select-table .dropdown .select-role .dropdown-menu a {
  padding: 2px 10px;
}
.modal .modal-dialog .roleToUser-modal .modal-body .left-select-table .dropdown .control-group .controls,
.modal .modal-dialog .roleToUser-modal .modal-body .right-select-table .dropdown .control-group .controls {
  margin-left: 0;
  width: 90px;
  padding: 10px 0;
}
.modal .modal-dialog .roleToUser-modal .modal-body .left-select-table .dropdown .control-group .controls .dropdown,
.modal .modal-dialog .roleToUser-modal .modal-body .right-select-table .dropdown .control-group .controls .dropdown {
  border: none;
}
.modal .modal-dialog .roleToUser-modal .modal-body .btnGroup {
  padding: 100px 20px;
  float: left;
  margin-top: 29px;
  width: 20%;
  height: 320px;
}
.modal .modal-dialog .roleToUser-modal .modal-body .btnGroup .btn {
  display: block;
  font-size: 16px;
  margin: 20px auto;
  width: 32px;
  min-width: 32px;
  line-height: 28px;
  padding: 0 8px;
  color: #e5e5e5;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  cursor: inherit;
}
.modal .modal-dialog .flow-modal-lg {
  width: 1050px;
}
.modal .modal-dialog .flow-modal-lg.modal-step .steps-sytle-ver {
  width: 500px;
  margin-left: 140px;
}
/** overview **/
/* 用户 */
.user-box h2 {
  font-size: 20px;
  color: #999999;
  height: 40px;
  line-height: 40px;
}
.user-box h2 span {
  color: #313949;
}
.user-box h2 span.expiredMsg {
  color: #e74c3c;
  margin-left: 5px;
  font-size: 14px;
}
.user-box .text-list {
  padding: 10px 0;
  min-height: 160px;
}
.user-box .text-list .copy-li {
  border: none !important;
}
.user-box .text-list .copy-li span > i {
  font-size: 100px;
  color: #c2d3eb;
}
.user-box .text-list .copy-li span > i.small {
  float: right;
  font-size: 56px;
  margin-right: 18px;
}
.user-box .text-list .copy-text {
  text-align: left !important;
  font-size: 32px;
  color: #1f2a37;
  line-height: 123px;
}
.user-box .text-list .copy-text em {
  font-size: 14px;
  top: 60% !important;
}
.user-box .text-list li {
  height: 140px;
  line-height: 140px;
  width: 50%;
  float: left;
  border-right: solid 1px #c2d3eb;
  text-align: center;
  font-size: 48px;
}
.user-box .text-list li em {
  font-size: 14px;
  color: #7991ab;
  padding: 0 30px;
}
.user-box .text-list li:last-child {
  border: none;
}
/* 告警工单日志 */
.alarmInfo-wrap,
.workOrder-wrap,
.logsInfo-wrap {
  padding: 0 15px;
  min-height: 230px;
}
.alarmInfo-wrap .cont,
.workOrder-wrap .cont,
.logsInfo-wrap .cont {
  border-top: solid 1px #c2d3eb;
}
.alarmInfo-wrap .cont .no-data,
.workOrder-wrap .cont .no-data,
.logsInfo-wrap .cont .no-data {
  padding-top: 10px;
}
.alarmInfo-wrap .cont .table,
.workOrder-wrap .cont .table,
.logsInfo-wrap .cont .table {
  border: none;
  margin-bottom: 0;
}
.alarmInfo-wrap .cont .table tr td,
.workOrder-wrap .cont .table tr td,
.logsInfo-wrap .cont .table tr td {
  height: 44px;
  padding: 10px 15px;
  white-space: nowrap;
}
.alarmInfo-wrap .cont .table tr td:last-child,
.workOrder-wrap .cont .table tr td:last-child,
.logsInfo-wrap .cont .table tr td:last-child {
  text-align: right;
}
.alarm-event-new {
  width: 130px !important;
}
/* 部门云主机 */
.pview-phymac-num dt {
  width: 85px !important;
}
.quotaInfo {
  padding: 0 20px;
  position: relative;
}
.quotaInfo .more-qouta {
  position: absolute;
  bottom: -15px;
  right: 40px;
  font-size: 12px;
}
.quotaInfo ul li {
  color: #7991ab;
  float: left;
  width: 25%;
  padding: 18px;
}
.quotaInfo ul li .total {
  margin-bottom: 15px;
  position: relative;
}
.quotaInfo ul li .total i {
  font-size: 48px;
  float: left;
  width: 50px;
  color: #556e8a;
  margin-right: 20px;
}
.quotaInfo ul li .total .quota-total {
  font-size: 34px;
  color: #313949;
  display: inline-block;
  min-width: 10px;
  min-height: 50px;
  vertical-align: top;
  max-width: calc(100% + 20px);
}
.quotaInfo ul li .total .quotaNum {
  position: absolute;
  left: 65px;
  width: -moz-calc(100% - 70px);
  width: -webkit-calc(100% - 70px);
  width: calc(100% - 70px);
}
.quotaInfo ul li .quotaName,
.quotaInfo ul li .quota-total {
  display: block;
  white-space: nowrap;
}
.quotaInfo ul li .used {
  margin-top: 8px;
  text-align: left;
}
.quotaInfo ul li .used span {
  color: #51a3ff;
}
/* 主机状态 */
.resourcesInfo {
  padding: 0 40px;
}
.resourcesInfo .panels .pie-wrap {
  width: 160px;
}
.resourcesInfo .panels .pie-wrap .pie-center {
  margin-top: -10px;
}
.resourcesInfo .panels .pie-legend {
  width: 30%;
}
.resourcesInfo .panels .resource-name {
  font-size: 14px;
  color: #7991ab;
}
.resourcesInfo > ul > li {
  float: left;
  width: 25%;
  position: relative;
  padding: 20px;
}
.resourcesInfo > ul > li .resBox {
  float: left;
  position: absolute;
  bottom: 20px;
  left: 25%;
}
.resourcesInfo > ul > li .resBox .dl-horizontal {
  color: #90a2ba;
}
.resourcesInfo > ul > li .resBox dt {
  width: 100px;
  font-weight: normal;
  line-height: 40px;
}
.resourcesInfo > ul > li .resBox dd {
  line-height: 40px;
  margin-left: 110px;
  font-size: 24px;
}
.resourcesInfo > ul > li .resBox dd .total {
  color: #313949;
}
.resourcesInfo > ul > li .resBox dd .normal {
  color: #51a3ff;
}
.resourcesInfo > ul > li .resBox dd .abnormal {
  color: #f39c12;
}
.quotaInfo.seg3 ul li,
.resourcesInfo.seg3 ul li {
  width: 33.3%;
}
/** department **/
.department {
  position: relative;
}
.department .tab-title {
  padding: 11px 30px;
  margin-bottom: 15px;
  height: 56px;
  border-bottom: solid 1px #c2d3eb;
}
.department .hide-title {
  padding: 0;
}
.department .nav-tabs {
  height: 55px;
  line-height: 55px;
  border-bottom: solid 1px #c2d3eb;
  padding: 0 0 0 200px;
  margin-bottom: 15px;
}
.department .nav-tabs li {
  background: none;
  padding: 0 20px;
}
.department .nav-tabs li.active a {
  color: #51a3ff;
  border-bottom: solid 2px #51a3ff;
}
.department .nav-tabs li a {
  padding: 0;
  border-radius: 0;
  min-width: 100px;
  line-height: 47px;
  padding-top: 4px;
  border: none;
  border-bottom: solid 2px transparent;
  text-align: center;
  display: block;
  cursor: pointer;
  height: 55px;
}
.echarts-wrap-line {
  height: 300px;
  line-height: 300px;
}
.echarts-noData {
  line-height: 150px;
  font-size: 14px;
  color: #4a6583;
  text-align: center;
}
/** monitor **/
.monitor-view {
  /* 存储 */
}
.monitor-view .title {
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
}
.monitor-view .view-title {
  height: 32px;
  line-height: 32px;
}
.monitor-view .detail-info .pre-text {
  height: 180px;
  line-height: 180px;
  text-align: center;
  color: #7991ab;
}
.monitor-view .col-title {
  font-size: 16px;
  color: #313949;
  font-weight: bolder;
  line-height: 28px;
}
.monitor-view .box {
  margin-top: 5px;
}
.monitor-view .box.layout-grid.areaChart {
  border-bottom: solid 1px #c2d3eb;
  margin-bottom: 15px;
}
.monitor-view .box.layout-grid.areaChart div[class^="col-"] {
  border-bottom: none;
}
.monitor-view .box.layout-grid.areaChart div[class^="col-"]:last-child .inner {
  border-right: none;
}
.monitor-view .box.layout-grid.areaChart.repeat div[class^="col-"] {
  border-bottom: solid 1px #c2d3eb;
}
.monitor-view .box div[class^="col-"].bar-chart {
  min-height: 235px;
}
.monitor-view .box .value-decr-list {
  min-height: 235px;
}
.monitor-view .box.info .host-info .controls {
  word-break: break-all;
  float: none;
  margin: 0 20px 0 105px;
}
.monitor-view .paint-box {
  min-height: 235px;
  padding: 0 20px;
  width: 100%;
  display: -webkit-flex;
  display: -ms-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}
.monitor-view .paint-box .line-list li {
  line-height: 20px;
  float: left;
}
.monitor-view .paint-box .line-list li.name {
  width: 50%;
  color: #4a6583;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  float: left;
  line-height: 1;
}
.monitor-view .paint-box .line-list li.line {
  width: 100%;
  padding: 4px 0 8px;
}
.monitor-view .paint-box .line-list li.text {
  float: right;
  display: block;
  text-align: right;
  color: #7991ab;
}
.monitor-view .paint-box .btn {
  background-color: #ffffff;
  border: solid 1px #c2d3eb;
  margin-right: 10px;
  height: 24px;
  line-height: 24px;
  min-width: auto;
  padding: 0 20px;
}
.monitor-view .ceph-box .title div {
  width: 50%;
  float: left;
}
.monitor-view .ceph-box .title div:nth-child(2) {
  padding-left: 25px;
}
.monitor-view .ceph-box .wrap {
  width: 50%;
  text-align: center;
  float: left;
}
.monitor-view .ceph-box .wrap.health-wrap {
  border-right: 1px solid #c2d3eb;
}
.monitor-view .ceph-box .wrap .status-box {
  background-color: #f4f7fa;
  width: 120px;
  height: 120px;
  border-radius: 100px;
  line-height: 120px;
  font-size: 30px;
  display: inline-block;
  text-align: center;
}
.monitor-view .ceph-box .wrap .footer {
  margin-top: 20px;
  text-align: center;
}
.monitor-view .ceph-box .wrap .footer a {
  display: inline-block;
  line-height: 24px;
  height: 24px;
}
.monitor-view .ceph-box .wrap .footer a i {
  float: left;
  display: inline-block;
  background-color: #f39c12;
  border-radius: 50%;
  margin-right: 10px;
  text-align: center;
}
.monitor-view .ceph-box .wrap .footer .popover-content .table {
  margin-bottom: 0;
}
.monitor-view .ceph-box .wrap .footer .popover-content .table tr td {
  padding: 5px 10px;
}
.monitor-view .ceph-box .wrap .footer.warning-box a i.circle {
  float: left;
  width: 24px;
  height: 24px;
  line-height: 24px;
  color: #ffffff;
  font-style: normal;
}
.monitor-view .ceph-box .wrap .footer.warning-box .popover-content .table .point {
  color: #e74c3c;
}
.monitor-view .ceph-box .wrap .footer.warning-box .popover-content .table .text-break {
  word-break: break-all;
}
.monitor-view .ceph-box .wrap .footer.info-box a i.circle {
  width: 24px;
  height: 24px;
  background-color: #1abc9c;
}
.monitor-view .right-tabs .nav-tabs {
  float: right;
  margin-bottom: 0;
}
.monitor-view .host-list {
  border-bottom: solid 1px #c2d3eb;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.monitor-view .host-list .control-group {
  margin-bottom: 0;
}
.monitor-view .host-list .control-group.pull-left .detail {
  float: left;
  margin-left: 20px;
  line-height: 28px;
}
.monitor-view .control-label {
  width: 105px;
  line-height: 28px;
}
.monitor-view .controls {
  width: auto;
  line-height: 28px;
  padding-left: 5px;
}
.monitor-view .controls .ui-select-container {
  width: 190px;
  float: left;
  margin-right: 10px;
}
.monitor-view .controls .btn {
  margin-right: 10px;
}
.monitor-view .control-group .calendar-select {
  position: relative;
  min-width: 260px;
}
.monitor-view .control-group .calendar-select i.glyphicon-calendar {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.monitor-view h4 {
  padding: 10px 0;
  font-weight: 600;
}
.monitor-view .disk {
  position: relative;
}
.monitor-view .disk .col-title {
  position: absolute;
  left: 0;
  top: 0;
}
.monitor-view .disk > .control-items3 .control-group {
  margin-bottom: 0;
  padding: 0 25px;
}
.monitor-view .disk > .control-items3 .control-group:first-child .control-label {
  text-align: right;
}
.monitor-view .disk > .control-items3 .control-label {
  width: 105px;
  float: left;
}
.monitor-view .disk > .control-items3 .controls {
  width: auto;
  float: left;
}
.monitor-view .disk > .control-items3 .path .control-label,
.monitor-view .disk > .control-items3 .diskio .control-label {
  width: auto;
}
.monitor-view .disk > .control-items3 .path {
  padding-left: 50px;
}
.monitor-view .netcard {
  position: relative;
}
.monitor-view .netcard .controls {
  position: absolute;
  left: 70px;
  top: 0px;
}
.monitor-view .control-groups .control-label {
  width: 80px;
}
.monitor-view .control-groups .controls .ui-select-container {
  width: 140px;
  margin-right: 10px;
}
.monitor-view .titlt-option .btn {
  margin-right: 10px;
}
.monitor-view .table thead tr th.sortable .sort-indicator {
  min-width: auto;
}
.phy-view .box .host-info .control-group {
  margin-bottom: 0;
}
.phy-view .box .host-info .control-group .control-label {
  color: #90a2ba;
}
.phy-view .box .host-info .control-group.ip-group .controls {
  border-radius: 5px;
  word-wrap: break-word;
}
.phy-view .box .host-info .control-group.ip-group .controls a {
  color: #90a2ba;
}
.phy-view .box .host-info .control-group.ip-group .controls > div {
  display: inline-block;
}
.phy-view .box .bar-chart .title div {
  display: inline-block;
}
.phy-view .box .bar-chart .panels .bar-wrap {
  text-align: center;
}
.phy-view .box .beused {
  color: #51a3ff;
}
.phy-view .box .echarts-phy {
  height: 300px;
  line-height: 300px;
}
.rabbitmq-view .box .text-list li {
  width: 20%;
  float: left;
  border-right: solid 1px #c2d3eb;
  text-align: center;
  padding: 25px 0;
}
.rabbitmq-view .box .text-list li div {
  min-height: 67px;
  font-size: 48px;
}
.rabbitmq-view .box .text-list li p {
  font-size: 14px;
  color: #7991ab;
}
.rabbitmq-view .box .text-list li:last-child {
  border-right: none;
}
.memcached-view .title .name {
  font-size: 16px;
  font-weight: bold;
  padding-right: 20px;
  float: left;
  color: #313949;
  height: 40px;
  line-height: 40px;
  max-width: 500px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.memcached-view .title .name span {
  color: #51a3ff;
  font-size: 16px;
  font-weight: normal;
}
.openstackSrv-view .table-content .table tr td:first-child {
  border-right: 1px solid #e5e5e5;
}
.openstackSrv-view .table-content .table tr td:last-child {
  padding: 0 0;
}
.openstackSrv-view .table-content .table tr td:last-child ul {
  display: block;
  border-bottom: 1px solid #e5e5e5;
}
.openstackSrv-view .table-content .table tr td:last-child ul:last-child {
  border-bottom: none;
}
.openstackSrv-view .table-content .table tr td:last-child ul li {
  float: left;
  padding: 15px 15px;
}
.openstackSrv-view .table-content .table tr td:last-child ul li:nth-child(1) {
  width: 18.75%;
}
.openstackSrv-view .table-content .table tr td:last-child ul li:nth-child(2) {
  width: 18.75%;
}
.openstackSrv-view .table-content .table tr td:last-child ul li:nth-child(3) {
  width: 31.25%;
}
.openstackSrv-view .table-content .table tr td:last-child ul li:nth-child(4) {
  width: 31.25%;
}
.openstackSrv-view .table-content .table tr td:last-child ul li span.status {
  color: #eee;
  padding: 3px 10px;
  border-radius: 2px;
}
.openstackSrv-view .table-content .table tr td:last-child ul li span.status.normal {
  background-color: #1abc9c;
}
.openstackSrv-view .table-content .table tr td:last-child ul li span.status.abornamal {
  background-color: #f39c12;
}
.openstackSrv-view .table-content .table tr td:last-child ul li div.basic-bar .progress {
  height: 6px;
  width: 70%;
  float: left;
  margin: 7px 10px 7px 0;
  border-radius: 0;
}
.openstackSrv-view .table-content .table tr td:last-child ul li div.basic-bar .right {
  float: left;
}
.vmware-loading .delete-alert {
  background: none !important;
}
.vmware-loading .delete-alert .loading {
  background: url(/img/loading.gif) no-repeat center 0;
  width: 240px;
  padding-top: 90px;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}
.value-decr-list {
  overflow: hidden;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-right: 20px;
}
.value-decr-list .value-decr {
  text-align: left;
  text-align: center;
  width: 100%;
  min-height: 86px;
  float: left;
  margin-top: 45px;
}
.value-decr-list .value-decr:last-child {
  border-right: 0 none;
}
.value-decr-list .value-decr .loading {
  line-height: 86px;
}
.value-decr-list .value-decr .value-message {
  text-align: left;
  padding-left: 40px;
  margin-top: -10px;
}
.value-decr-list .value-decr .value-message .value-decr-num {
  margin-top: 5px;
}
.value-decr-list .value-decr .value-message .line {
  border-bottom: 1px solid #c2d3eb;
  padding-bottom: 25px;
}
.value-decr-num {
  font-size: 48px;
  color: #313949;
  margin-bottom: 5px;
}
.value-decr-num .text {
  color: #f39c12;
  width: 50%;
}
.value-decr-title {
  color: #7991ab;
}
.shortcuts-ins-page .defTipes {
  color: #e67e22;
  white-space: nowrap;
  margin-top: 10px;
}
/* modal */
.tmplRule-modal {
  width: 800px !important;
}
.tmplRule-modal .modal-body {
  min-height: 130px;
}
.tmplRule-modal .modal-body .noRuleTip {
  line-height: 60px;
}
.tmplRule-modal .modal-body .addRuleTip {
  color: #51a3ff;
  cursor: pointer;
}
.tmplRule-modal .modal-body .sup-title {
  font-size: 12px;
  font-weight: bold;
  line-height: 28px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
}
.tmplRule-modal .modal-body .control-group .control-label {
  width: 12%;
}
.tmplRule-modal .modal-body .control-group .controls .extension-uiselect {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 120px;
  overflow: hidden;
}
.tmplRule-modal .modal-body .control-group .controls.ruleTh {
  left: 12%;
  line-height: 30px;
}
.tmplRule-modal .modal-body .control-group .controls.ruleTh div {
  display: inline-block;
  float: left;
  text-align: center;
  border: 1px solid #e5e5e5;
  border-right: none;
  background: #f9f9f9;
}
.tmplRule-modal .modal-body .control-group .controls.ruleTh div:first-child {
  width: 25%;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}
.tmplRule-modal .modal-body .control-group .controls.ruleTh div:nth-child(2) {
  width: 46%;
}
.tmplRule-modal .modal-body .control-group .controls.ruleTh div:nth-child(3) {
  width: 10%;
}
.tmplRule-modal .modal-body .control-group .controls.ruleTh div:last-child {
  width: 11%;
  border-right: 1px solid #e5e5e5;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}
.tmplRule-modal .modal-body .control-group .controls > .ui-select-container.first {
  width: 27%;
}
.tmplRule-modal .modal-body .control-group .controls > .ui-select-container.second {
  width: 15%;
}
.tmplRule-modal .modal-body .control-group .controls > .ui-select-container {
  width: 10%;
  float: left;
  margin-right: 10px;
}
.tmplRule-modal .modal-body .control-group .controls > .ui-select-container .ui-select-search {
  width: 100% !important;
}
.tmplRule-modal .modal-body .control-group .controls > .ui-select-container .ui-select-match .btn {
  min-width: 50px;
}
.tmplRule-modal .modal-body .control-group .controls > .ui-select-container .btn[disabled] {
  background: #f5f5f5;
}
.tmplRule-modal .modal-body .control-group .controls > .ui-select-container .btn[disabled] i.caret {
  display: none;
}
.tmplRule-modal .modal-body .control-group .controls .rule {
  display: inline-block;
  float: left;
  line-height: 28px;
}
.tmplRule-modal .modal-body .control-group .controls .rule .form-controls {
  padding: 0 40px 0 10px;
  text-align: center;
}
.tmplRule-modal .modal-body .control-group .controls .rule.threshold {
  position: relative;
  width: 18%;
  margin-right: 5px;
}
.tmplRule-modal .modal-body .control-group .controls .rule.threshold input[readonly],
.tmplRule-modal .modal-body .control-group .controls .rule.threshold input[disabled] {
  background: #f5f5f5;
}
.tmplRule-modal .modal-body .control-group .controls .rule.threshold .error {
  text-align: left;
  font-size: 12px;
}
.tmplRule-modal .modal-body .control-group .controls .rule > input {
  height: 28px;
}
.tmplRule-modal .modal-body .control-group .controls .rule.unit {
  position: absolute;
  right: 7px;
  top: 0;
}
.tmplRule-modal .modal-body .control-group .controls .rule.add i {
  color: #51a3ff;
}
.tmplRule-modal .modal-body .control-group .controls .rule.del i {
  color: #e23b13;
}
.tmplRule-modal .modal-body .table-content .table tr td a.deleteRule {
  color: #999999;
  font-size: 16px;
}
.contactGroup-modal .modal-body .wechat-name-input {
  padding-right: 75px;
}
.contactGroup-modal .modal-body .control-group {
  float: left;
}
.contactGroup-modal .modal-body .control-group.name {
  width: 90%;
}
.contactGroup-modal .modal-body .control-group.email,
.contactGroup-modal .modal-body .control-group.phone {
  width: 44%;
}
.contactGroup-modal .modal-body .control-group.email .control-label,
.contactGroup-modal .modal-body .control-group.phone .control-label {
  width: 33%;
}
.contactGroup-modal .modal-body .control-group.email .controls,
.contactGroup-modal .modal-body .control-group.phone .controls {
  width: 67%;
}
.contactGroup-modal .modal-body .contact {
  float: left;
  line-height: 34px;
  margin-left: 10px;
}
.contactGroup-modal .modal-body .contact a {
  color: #51a3ff;
}
.contactGroup-modal .modal-body .contact a .icon-aw-wrong {
  color: #e74c3c;
}
.contactGroup-modal .modal-body .nickname {
  position: absolute;
  right: 100px;
  line-height: 32px;
}
.contactGroup-modal .modal-body .disableName a {
  color: #666666;
  font-weight: 400;
  font-style: oblique;
}
.modal-common .contactGroup-error {
  text-align: center;
  color: #e74c3c;
}
/*微信昵称验证*/
.nickname-modal .control-group {
  margin-left: 20px;
}
.nickname-modal .control-group .controls {
  width: 10%;
}
.nickname-modal .control-group .controls .radio-item {
  height: 72px;
  line-height: 72px;
  padding-top: 0;
}
.nickname-modal .control-group .wechatImg {
  width: 72px;
  height: 72px;
  float: left;
}
.nickname-modal .control-group .wechatInfo {
  width: auto;
  height: 72px;
  float: left;
  margin-left: 20px;
  padding-top: 5px;
}
.nickname-modal .control-group .wechatInfo .location {
  margin-top: 25px;
}
.nickname-modal .control-group .wechatInfo .wechatText {
  margin-left: 25px;
  font-weight: bold;
  font-style: normal;
  font-size: 14px;
  color: #666666;
}
.nickname-modal .control-group .borderLine {
  width: 70%;
  height: 10px;
  clear: both;
  border-bottom: 1px solid #ccc;
}
.nickname-modal .nickname-tips {
  line-height: 40px;
  text-align: center;
  padding-bottom: 10px;
}
/*初始化告警设置*/
.contactForm .control-group {
  float: left;
}
.contactForm .control-group.name {
  width: 90%;
}
.contactForm .control-group.email,
.contactForm .control-group.phone {
  width: 44%;
}
.contactForm .control-group.email .control-label,
.contactForm .control-group.phone .control-label {
  width: 33%;
}
.contactForm .control-group.email .controls,
.contactForm .control-group.phone .controls {
  width: 67%;
}
.contactForm .contact {
  float: left;
  line-height: 34px;
  margin-left: 10px;
}
.contactForm .contact a {
  color: #51a3ff;
}
.contactForm .contactGroup-error {
  text-align: center;
  color: #e74c3c;
}
.report {
  position: relative;
  overflow: visible;
}
.report .formAnimateOut {
  transform: translate3d(-100%, 0, 0);
  width: 50%;
  margin-left: 50%;
}
.report .formAnimateIn {
  transform: translate3d(100%, 0, 0);
  width: 100%;
  margin-left: 100%;
}
.report .deleteAnimateIn {
  transform: translate3d(100%, 0, 0);
  width: 100%;
  margin-left: 50%;
  background: #f5f5f5;
  text-align: center;
}
.report .taskform {
  width: 50%;
  position: absolute;
  top: 32px;
  transform: translate3d(-100%, 0, 0);
}
.report .taskform .table {
  background-color: #f5f5f5;
}
.report .formdetail {
  transform: translate3d(-100%, 0, 0);
}
.report .form_title {
  font-size: 20px;
  margin-left: 360px;
}
.report .form-footer {
  margin-bottom: 20px;
  margin-top: 500px;
  margin-right: 100px;
}
.report .form-footer .btn {
  display: block;
  margin: 0 auto;
  padding: 0 50px;
}
/**网卡安全配置**/
.networkCardConfig {
  /*.modal-body{
        max-height: 500px;
        overflow: auto;
        overflow-x: hidden;
    }*/
}
.networkCardConfig .addAddress {
  margin: calc(17% + 25px);
  margin: -moz-calc(17% + 25px);
  margin: -webkit-calc(17% + 25px);
  color: #51a3ff;
  cursor: pointer;
}
.networkCardConfig .leaderNum {
  float: left;
}
.networkCardConfig .leaderNum span {
  line-height: 32px;
  height: 32px;
  display: block;
  margin-left: 50px;
}
.networkCardConfig .ip,
.networkCardConfig .mac {
  float: left;
  width: 40%;
}
.networkCardConfig .ip .control-label,
.networkCardConfig .mac .control-label {
  width: 30%;
}
.networkCardConfig .ip .controls,
.networkCardConfig .mac .controls {
  width: 67%;
}
.networkCardConfig .ip .control-label {
  width: 23%;
}
.networkCardConfig .del {
  float: left;
  height: 32px;
  line-height: 32px;
  margin-left: 20px;
}
.networkCardConfig .del .icon-aw-wrong {
  color: #e74c3c;
}
.networkCardConfig .error {
  float: left;
  color: #e74c3c;
}
.networkCardConfig .ip-error {
  margin-left: 26%;
}
.networkCardConfig .mac-error {
  margin-left: 33%;
}
.networkCardConfig .clearfix {
  padding-top: 2%;
  border-top: 1px dashed #e5e5e5;
}
.networkCardConfig .repeat {
  color: #e74c3c;
  margin-left: 19%;
}
.create-volumes-QoS .modal-body .control-group .control-label {
  width: 18%;
}
.create-volumes-QoS .modal-body .control-group .controls {
  width: 82%;
}
.create-volumes-QoS .modal-body .mustOne {
  color: #e74c3c;
  margin-left: 20%;
}
/*防火墙*/
.modal .modal-dialog .modal-common.jumpRule-modal .modal-body .configInfo {
  padding: 50px 120px;
  overflow: hidden;
}
.modal .modal-dialog .modal-common.jumpRule-modal .modal-body .configInfo .circle {
  width: 33px;
  height: 33px;
  float: left;
  padding: 0 5px;
  border: 2px solid #1abc9c;
  border-radius: 50%;
  margin-right: 20px;
}
.modal .modal-dialog .modal-common.jumpRule-modal .modal-body .configInfo .circle i {
  font-size: 20px;
  color: #1abc9c;
  line-height: 33px;
}
.modal .modal-dialog .modal-common.jumpRule-modal .modal-body .configInfo .info {
  float: left;
  font-size: 16px;
  line-height: 33px;
}
.modal .modal-dialog .modal-common.relate-modal .modal-body .table tbody tr td {
  padding: 12px 16px;
}
.modal .modal-dialog .modal-common.networkFireRules-modal {
  width: 1200px;
}
.modal .modal-dialog .modal-common.networkFireRules-modal .modal-body:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.modal .modal-dialog .modal-common.networkFireRules-modal .modal-body .book-left {
  padding: 0 20px;
  width: 45%;
  border-right: 1px solid #e5e5e5;
  float: left;
  min-height: 445px;
}
.modal .modal-dialog .modal-common.networkFireRules-modal .modal-body .book-left h5 {
  padding-left: 30px;
  padding-bottom: 20px;
  font-weight: 900;
}
.modal .modal-dialog .modal-common.networkFireRules-modal .modal-body .book-left .control-group .control-label {
  width: 25%;
  float: left;
}
.modal .modal-dialog .modal-common.networkFireRules-modal .modal-body .book-left .control-group .choice-port {
  width: 32.5% !important;
  float: left;
}
.modal .modal-dialog .modal-common.networkFireRules-modal .modal-body .book-left .control-group .controls {
  width: 75%;
}
.modal .modal-dialog .modal-common.networkFireRules-modal .modal-body .book-left .control-group .tip {
  width: 10%;
  float: left;
  line-height: 32px;
  text-align: center;
  padding-left: 10px;
}
.modal .modal-dialog .modal-common.networkFireRules-modal .modal-body .book-left .control-group .part-p {
  width: 100%;
  float: left;
  margin-left: 27% !important;
}
.modal .modal-dialog .modal-common.networkFireRules-modal .modal-body .book-left .control-group .part-p p {
  color: #e9322d;
  font-size: 12px;
}
.modal .modal-dialog .modal-common.networkFireRules-modal .modal-body .book-left .establish {
  margin-top: 25px;
}
.modal .modal-dialog .modal-common.networkFireRules-modal .modal-body .book-right {
  padding: 0 20px;
  width: 55%;
  float: left;
  max-height: none;
  overflow-y: auto;
}
.modal .modal-dialog .modal-common.networkFireRules-modal .modal-body .book-right h5 {
  padding-bottom: 20px;
  font-weight: 900;
}
.modal .modal-dialog .modal-common.networkFireRules-modal .modal-body .book-right .order-box {
  padding-bottom: 2px;
}
.modal .modal-dialog .modal-common.networkFireRules-modal .modal-body .book-right .table-content {
  max-height: 380px;
  overflow-y: auto;
  margin-bottom: 15px;
}
.modal .modal-dialog .modal-common.networkFireRules-modal .modal-body .book-right .table-content .table .rule-list td {
  background-color: #fafafa;
  font-weight: bold;
  text-align: center;
  padding: 13px 5px;
}
.modal .modal-dialog .modal-common.networkFireRules-modal .modal-body .book-right .table-content .table .rele-data td {
  text-align: center;
  padding: 13px 5px;
}
.modal .modal-dialog .modal-common.networkFireRules-modal .modal-body .book-right .table-content .table tbody td span a.forbidden {
  color: #999999;
  cursor: not-allowed;
}
.modal .modal-dialog .modal-common.networkFireRules-modal .modal-body .table tbody tr td {
  padding: 12px 16px;
}
.table-action .pull-right .fireRuleSet .show-title .dropdown-menu li {
  min-width: 160px;
}
.table-action .pull-right .search-box {
  margin-right: 8px;
}
.table-action .pull-right .ui-select-container {
  width: 100px;
  margin-right: 8px;
}
.table-action .pull-right .ui-select-container .ui-select-search {
  width: 100px !important;
}
.table-action .pull-right .rs {
  float: left;
}
.table-action .pull-right .search-list {
  width: 110px;
  float: left;
}
.table-action .pull-right .search-list .ui-select-bootstrap > .ui-select-choices {
  max-height: none;
}
.table-action .pull-right .search-list-div {
  box-shadow: #51A3FF 0px 0px 10px;
  position: absolute;
  z-index: 4;
  background-color: #FFFFFF;
  width: 310px;
  margin-left: 110px;
  margin-top: 33px;
  border: solid 1px #51A3FF;
}
.table-action .pull-right .search-list-div li {
  line-height: 30px;
  margin: 0 2px 0 2px;
  padding-left: 10px;
  cursor: pointer;
}
.table-action .pull-right .search-list-div li:hover {
  background: #51A3FF;
  color: #ffffff;
}
.animateContent.animateIn .detail-row.firewall-row .detail-info .tabName {
  margin-bottom: 10px;
}
.animateContent.animateIn .detail-row.firewall-row .detail-info .line {
  border-top: 1px solid #c2d3eb;
  padding-top: 8px;
}
.animateContent.animateIn .detail-row.firewall-row .detail-info .showRuleOfDetail .table tr td .checkbox input[disabled="disabled"] + i:before {
  color: #c2d3eb;
}
.relieve-volume-QoS .modal-body .control-group .control-label,
.relative-volumes-QoS .modal-body .control-group .control-label {
  width: 30%;
}
.relieve-volume-QoS .modal-body .control-group .controls,
.relative-volumes-QoS .modal-body .control-group .controls {
  width: 70%;
}
.volumes-QoS .table tr td span {
  display: block;
}
.volumes-QoS-popup .modal-body {
  overflow: hidden;
}
.volumes-QoS-popup .modal-body .control-label {
  width: 20%;
}
.volumes-QoS-popup .modal-body .controls {
  width: 80%;
}
.volumes-QoS-popup .modal-body .volumesQoS-input .control-label {
  width: 20%;
}
.volumes-QoS-popup .modal-body .volumesQoS-input .controls {
  width: 80%;
}
.volumes-QoS-popup .modal-body .volumesQoS-input .controls .form-control {
  width: 100%;
  float: left;
}
.portForwarding .port .controls input {
  width: 40%;
  float: left;
}
.portForwarding .port .controls .markedWord {
  margin-left: 30px;
  float: left;
  line-height: 32px;
  font-size: 12px;
  color: #999999;
}
.portForwarding .port .controls .error {
  clear: both;
}
.portForwarding .modal-body {
  padding-right: 25px;
}
.portForwarding .modal-body p {
  color: #e9322d;
  font-size: 12px;
}
.portForwarding .modal-body p span a {
  color: #e9322d;
  font-size: 12px;
  cursor: auto;
}
.portForwarding .modal-body p span a:nth-child(3) {
  margin-right: 5px;
}
.tt-equipment-popup {
  width: 950px !important;
}
.tt-equipment-popup .modal-header {
  margin-bottom: 10px !important;
}
.tt-equipment-popup .modal-body {
  padding-bottom: 20px;
}
.tt-equipment-popup .modal-body .btn {
  margin-bottom: 10px;
}
.add-tt-equipment-popup .controls .port-left {
  height: auto;
}
.add-tt-equipment-popup .no_canLoad_equipment {
  line-height: 32px;
  color: #e9322d;
  float: left;
  margin-left: 10px;
}
.add-tt-equipment-popup .limit_drive_type {
  margin-left: 1.5%;
  line-height: 32px;
  color: #e9322d;
  float: left;
}
.create-ttequiment-magement .controls .port-left {
  height: auto;
  margin-bottom: 5px;
}
.create-ttequiment-magement .controls .port-left .radio i {
  float: left;
}
.create-ttequiment-magement .controls .port-left .radio span {
  float: left;
  width: 555px;
  line-height: 15px;
}
.create-ttequiment-magement p {
  line-height: 32px;
  color: #e9322d;
  float: left;
  margin-left: 10px;
}
.tip-class {
  display: none;
}
.router-detail .port-forwarding {
  float: right;
}
.router-detail .set-up {
  float: right;
}
.router-detail .set-up i {
  display: block !important;
  color: #51a3ff;
  font-size: 14px;
}
.router-detail .reset {
  float: right;
}
.router-detail .reset i {
  display: block !important;
  color: #51a3ff;
  font-size: 14px;
}
.batchRegister-modal .control-group {
  border-bottom: 1px dashed #e5e5e5;
  overflow: hidden;
  padding-bottom: 20px;
  padding-top: 10px;
}
.batchRegister-modal .control-group:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.batchRegister-modal .control-group:first-child {
  padding-top: 0;
}
.batchRegister-modal .lead-out span {
  color: #e74c3c;
}
.batchRegister-modal .doIt {
  margin-top: 40px;
}
.batchRegister-modal .doIt div {
  margin-left: 18.5%;
  float: left;
}
.batchRegister-modal .doIt .showResult {
  float: left;
  line-height: 32px;
}
.batchRegister-modal .doIt .showResult span {
  margin-left: 20px;
  color: #e74c3c;
}
/** permit **/
.operating-box {
  background: #ffffff;
  margin-top: -30px;
}
.operating-box .table-content {
  padding-right: 80px;
}
.operating-box .text-list {
  padding-left: 35px;
}
.operating-box .text-list li {
  padding: 2px 15px;
  position: relative;
}
.operating-box .text-list li:before {
  position: absolute;
  left: 0;
  top: 40%;
  content: "";
  width: 7px;
  height: 7px;
  background-color: #c2d3eb;
  border-radius: 50%;
}
.operating-box .text-list li:first-child {
  padding-left: 0;
  color: #666666;
  font-weight: bold;
}
.operating-box .text-list li:first-child:before {
  display: none;
}
.menu-tree {
  padding-bottom: 20px;
  width: 996px;
  margin: 0 auto;
}
.menu-tree .checkbox {
  display: inline-block;
  padding-right: 5px;
}
.menu-tree .tree-body {
  margin-bottom: 15px;
}
.menu-tree .tree-body .ascii-box span {
  width: 14px;
  height: 14px;
  position: relative;
  color: #4e80f5;
  float: left;
  margin: 2px 5px 0 0;
  cursor: pointer;
}
.menu-tree .tree-body .ascii-box span:before {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
}
.menu-tree .tree-body .ascii-box .icon-aw-minus2:before {
  content: "\E861";
}
.menu-tree .tree-body .ascii-box .icon-aw-square {
  color: #c2d3eb;
}
.menu-tree .tree-body ul.ivh-treeview ul.ivh-treeview {
  padding: 0 20px;
}
.menu-tree .tree-body > .ivh-treeview {
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 20px;
  font-size: 14px;
}
.menu-tree .tree-body > .ivh-treeview > li {
  padding: 10px;
  border-bottom: 1px solid #e5e5e5;
}
.menu-tree .tree-body > .ivh-treeview > li:last-child {
  border-bottom: none;
}
.menu-tree .tree-body > .ivh-treeview > li > div > .ivh-treeview-node-label {
  color: #51a3ff;
}
.menu-tree .tree-body > .ivh-treeview > li > div > div > ul > li {
  border-bottom: 1px dashed #e5e5e5;
  padding: 10px 0;
}
.menu-tree .tree-body > .ivh-treeview > li > div > div > ul > li:last-child {
  border-bottom: none;
}
.menu-tree .tree-body > .ivh-treeview > li > div > div > ul > li > div > div > ul {
  padding: 10px 20px !important;
}
.menu-tree .tree-body > .ivh-treeview > li > div > div > ul > li > div > div > ul > li {
  padding-right: 15px;
  margin-bottom: 10px;
}
.menu-tree .tree-body > .ivh-treeview > li > div > div > ul > li > div > div > ul > li:last-child {
  margin-bottom: 0;
}
.menu-tree .tree-body > .ivh-treeview > li > div > div > ul > li > div > div > ul > li > div > div > ul {
  padding: 10px 20px 0px 20px !important;
}
.menu-tree .tree-body > .ivh-treeview > li > div > div > ul > li > div > div > ul > li > div > div > ul > li {
  display: inline-block;
  padding-right: 15px;
}
.menu-tree-new {
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
}
.menu-tree-new .menu-tree-head {
  overflow: hidden;
  background: #fafafa;
  color: #666;
  padding-right: 18px;
}
.menu-tree-new .menu-tree-head .choose-all {
  float: left;
  min-width: 135px;
  width: 20%;
  margin: 10px 0 0;
  padding-left: 10px;
}
.menu-tree-new .menu-tree-head .choose-all span {
  float: left;
  font-weight: bold;
}
.menu-tree-new .menu-tree-head .choose-all label {
  float: right;
  margin-right: 5px;
}
.menu-tree-new .menu-tree-head .text {
  float: left;
  width: 80%;
  font-weight: bold;
  padding: 10px;
  border-left: 1px solid #e5e5e5;
}
.menu-tree-new .menu-tree-pre {
  height: 600px;
  overflow-y: auto;
}
.menu-tree-new .checkbox-instan {
  min-width: 135px;
  border-right: 1px solid #e5e5e5;
  width: 20%;
  overflow: hidden;
  padding-top: 12px;
  padding-bottom: 12px;
}
.menu-tree-new .checkbox-instan .collapse-btn {
  float: left;
  padding-left: 10px;
}
.menu-tree-new .checkbox-instan .collapse-btn i {
  font-size: 16px;
}
.menu-tree-new .checkbox-instan .collapse-btn .icon-aw-folder1 {
  color: #ff9900;
}
.menu-tree-new .checkbox-instan .collapse-btn .icon-aw-folder1.no-child:before {
  content: "\E6A9";
}
.menu-tree-new .checkbox-instan .collapse-btn span {
  padding-left: 3px;
}
.menu-tree-new .checkbox-instan .checkbox {
  float: right;
  margin-right: 5px;
}
.menu-tree-new .menu-tree-node {
  border-top: 1px solid #e5e5e5;
}
.menu-tree-new .menu-child .checkbox-instan {
  padding-left: 20px;
}
.menu-tree-new .menu-child .menu-child .checkbox-instan {
  padding-left: 40px;
}
.menu-tree-new .menu-level-no-child .checkbox-instan .icon-aw-folder1 {
  display: none;
}
.menu-tree-new .last-menu-level {
  overflow: hidden;
}
.menu-tree-new .last-menu-level .checkbox-instan {
  float: left;
}
.menu-tree-new .last-menu-level .checkbox-instan .icon-aw-folder1 {
  display: none;
}
.menu-tree-new .last-menu-level .menu-tree-node {
  border: 0 none;
}
.menu-tree-new .last-menu-level .menu-child {
  float: left;
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  padding: 12px 0 0 20px;
  border-left: 1px solid #e5e5e5;
  margin-left: -1px;
}
.menu-tree-new .last-menu-level .menu-child .checkbox {
  float: none;
  width: 100%;
  min-width: 100px;
  border: 0 none;
  margin: 0 0 12px;
}
.menu-tree-new .last-menu-level .menu-child .checkbox label {
  float: left;
}
.menu-tree-new .last-menu-level .menu-child .checkbox span {
  float: left;
}
.menu-tree-new .last-menu-level .menu-child li {
  float: left;
  width: 16%;
}
.menu-tree-new .menu-tree-level1-li > .menu-tree-node > .checkbox-instan .icon-aw-folder1 {
  display: inline-block;
}
.menu-tree-new .menu-tree-level1-li > .last-menu-level > .checkbox-instan .icon-aw-folder1:before {
  content: "\E6A9";
}
.create-role-form {
  width: 996px;
}
.create-role-form label {
  width: 7%;
}
.create-role-form .controls {
  width: 50%;
}
.angular-ui-tree {
  border: solid 1px #e5e5e5;
}
.angular-ui-tree .angular-ui-tree-header {
  background: #fafafa;
  border-bottom: solid 1px #e5e5e5;
}
.angular-ui-tree .angular-ui-tree-header li {
  padding: 10px 20px;
  line-height: 20px;
  font-weight: bold;
}
.angular-ui-tree .angular-ui-tree-header .tree-header-name {
  float: left;
}
.angular-ui-tree .angular-ui-tree-header .tree-header-handle {
  float: right;
  width: 150px;
  text-align: center;
}
.angular-ui-tree .angular-ui-tree-header .tree-header-desc {
  float: right;
  width: 600px;
}
.angular-ui-tree > ul.angular-ui-tree-nodes {
  line-height: 20px;
}
.angular-ui-tree > ul.angular-ui-tree-nodes .btn {
  font-size: 16px;
  min-width: auto;
  display: inline-block;
  height: 20px;
  padding: 0 2px;
  color: #4e80f5;
}
.angular-ui-tree > ul.angular-ui-tree-nodes .btn.btn-success {
  font-size: 14px;
  color: #666666;
  padding: 0;
}
.angular-ui-tree > ul.angular-ui-tree-nodes .btn.btn-success:hover {
  color: #4e80f5;
}
.angular-ui-tree > ul.angular-ui-tree-nodes > li.angular-ui-tree-node {
  border-bottom: solid 1px #e5e5e5;
}
.angular-ui-tree > ul.angular-ui-tree-nodes > li.angular-ui-tree-node:last-child {
  border-bottom: none;
}
.angular-ui-tree > ul.angular-ui-tree-nodes > li.angular-ui-tree-node .angular-ui-tree-handle .tree-name {
  float: left;
  padding: 10px 20px;
}
.angular-ui-tree > ul.angular-ui-tree-nodes > li.angular-ui-tree-node .angular-ui-tree-handle .tree-item {
  float: right;
  width: 150px;
  padding: 10px 20px;
  text-align: center;
}
.angular-ui-tree > ul.angular-ui-tree-nodes > li.angular-ui-tree-node .angular-ui-tree-handle .tree-desc {
  float: right;
  width: 600px;
  padding: 10px 20px;
}
.angular-ui-tree > ul.angular-ui-tree-nodes > li.angular-ui-tree-node ul.angular-ui-tree-nodes {
  padding-left: 20px;
}
.angular-ui-tree > ul.angular-ui-tree-nodes > li.angular-ui-tree-node ul.angular-ui-tree-nodes .angular-ui-tree-handle {
  position: relative;
}
.angular-ui-tree > ul.angular-ui-tree-nodes > li.angular-ui-tree-node ul.angular-ui-tree-nodes .angular-ui-tree-handle:before {
  content: "";
  height: 20px;
  width: 10px;
  left: 5px;
  position: absolute;
  display: inline-block;
  border-left: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}
.mprogress-bar-info {
  background-color: #ff8c00;
}
.mprogress-bar-default {
  background-color: #ff4500;
}
.mprogress-bar-warning {
  background-color: #ff6347;
}
.progress-bar-info {
  background-color: #51a3ff;
}
.progress-bar-default {
  background-color: #b6a2de;
}
.progress-bar-success {
  background-color: #1abc9c;
}
.progress-bar-warning {
  background-color: #f39c12;
}
.toggle {
  position: relative;
  width: 100%;
  padding-left: 10%;
}
.toggle input {
  display: none;
}
.toggle input:checked + i:before {
  right: 36px;
}
.toggle input:checked + i:after {
  content: attr(swchon-text);
  text-align: right;
}
.toggle i {
  position: absolute;
  top: 4px;
  right: 0;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  width: 50px;
  height: 20px;
}
.toggle i:before {
  background-color: #3276b1;
  content: "";
  position: absolute;
  z-index: 1;
  top: 4px;
  right: 4px;
  display: block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  opacity: 1;
}
.toggle i:after {
  content: attr(swchoff-text);
  position: absolute;
  top: 3px;
  right: 8px;
  left: 8px;
  font-style: normal;
  font-size: 10px;
  line-height: 13px;
  font-weight: 700;
  text-align: left;
  color: #5f5f5f;
}
.showWarning {
  color: #e74c3c;
  margin-left: 27%;
  font-size: 12px;
}
/** configure **/
.node-page .node-info {
  margin-bottom: 15px;
}
.node-page .node-info .field {
  padding: 0 285px;
  border-bottom: solid 1px #c2d3eb;
  position: relative;
  margin-bottom: 15px;
}
.node-page .node-info .field.key .control-group {
  width: 78%;
}
.node-page .node-info .field.key .control-group .controls {
  width: 100%;
}
.node-page .node-info .field h5 {
  position: absolute;
  left: 0;
  top: 20px;
  font-size: 16px;
}
.node-page .node-info .net-field {
  border-bottom: 1px #c2d3eb dashed;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  padding: 13px 0;
}
.node-page .node-info .net-field:last-child {
  border: none;
}
.node-page .node-info .net-field .filed-title {
  text-align: left;
  padding: 0 20px;
  width: 10%;
  float: left;
  line-height: 28px;
}
.node-page .node-info .control-group {
  width: 26%;
  float: left;
  margin-bottom: 0;
}
.node-page .node-info .control-group .control-label {
  width: 30%;
  color: #7991ab;
}
.node-page .node-info .control-group .controls {
  width: 70%;
}
.node-page .node-info .control-group .controls .value {
  line-height: 28px;
  color: #51a3ff;
}
.node-page .node-info .control-group .controls .ssh-text {
  color: #51a3ff;
  line-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
.node-detail-info .detail-title.node-title span {
  margin-right: 0px;
  max-width: 500px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
/** 参数设置 **/
.settings-box {
  margin: 30px auto 20px;
  padding: 20px;
  border: solid 1px #c2d3eb;
}
.settings-box .add-shut {
  float: right;
  font-size: 24px;
}
.settings-box .add-shut .icon-aw-1shut-down {
  color: #e74c3c;
}
.settings-box .add-shut .icon-aw-add-to {
  color: #1abc9c;
}
.settings-box .add-shut i {
  cursor: pointer;
}
.settings-box .separation-line {
  border-bottom: dashed 1px #c2d3eb;
  margin-bottom: 20px;
}
.settings-box .control-choose {
  border-bottom: dashed 1px #c2d3eb;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.settings-box .control-choose .control-label {
  width: 17%;
}
.settings-box.cloud-setting .control-group:last-child {
  margin-top: 0px;
}
.settings-box .control-group .controls {
  width: 75%;
}
.settings-box .control-group .controls .radio-item {
  height: 30px;
}
.settings-box .control-group .controls .radio-item .tip {
  margin: 10px 0;
  color: #4e80f5;
}
.settings-box .control-group .control-tips {
  width: 100%;
  color: #1abc9c;
  float: left;
  padding-left: 5%;
}
.settings-box .control-group.top {
  margin-bottom: 85px;
}
.error-tips {
  color: #e74c3c;
  line-height: 1.5;
  font-size: 12px;
}
.normal-tips {
  color: #888;
  line-height: 1.5;
  font-size: 12px;
}
/** 工单 **/
.apply-detail {
  border-bottom: 1px dashed #e5e5e5;
  margin-bottom: 15px;
}
.apply-detail h5 {
  font-weight: bold;
  border-bottom: 1px dashed #e5e5e5;
  padding-bottom: 10px;
  margin-bottom: 15px;
}
.detail-row .detail-inner {
  width: 50%;
  float: left;
  padding: 0 15px;
  margin-bottom: 15px;
}
.detail-row .detail-inner .text {
  float: left;
}
.detail-row .detail-inner .decr {
  float: right;
  color: #51a3ff;
}
.ticket {
  border: 1px solid #e5e5e5;
}
.ticket .title {
  border-bottom: 1px solid #e5e5e5;
  border-left: 2px solid #51a3ff;
  height: 35px;
  line-height: 35px;
  padding-left: 15px;
  background: #fafafa;
}
.ticket .data-list {
  overflow: hidden;
  padding: 25px 0;
}
.ticket .data-list li {
  width: 25%;
  float: left;
  padding: 0 25px;
}
.ticket .data-list li .inner {
  overflow: hidden;
}
.ticket .data-list li .icon-box {
  float: left;
  width: 60px;
  height: 60px;
  line-height: 45px;
  font-size: 48px;
  color: #c2d3eb;
}
.ticket .data-list li .text {
  height: 100%;
  margin-left: 60px;
  overflow: hidden;
}
.ticket .data-list li .text h5 {
  font-size: 16px;
}
.ticket .data-list li .text p {
  color: #999999;
  line-height: 20px;
}
.ticket .data-list li .text .action {
  color: #51a3ff;
  cursor: pointer;
}
.ticket .data-list li .text .action:hover {
  text-decoration: underline;
}
/** mailServer **/
.mailServer-tips {
  text-align: center;
  color: #f39c12;
  padding: 20px 0;
}
.mailServer-tips i {
  font-size: 20px;
  margin-right: 10px;
}
.mailServer-box {
  margin: 30px auto 20px;
  border: 1px solid #c3d3ec;
  padding: 0px 0 30px;
}
.mailServer-box .alert {
  text-align: center;
  margin: 20px;
}
.mailServer-box .alert .alert-content {
  line-height: 18px;
}
.mailServer-box .alert .alert-content .alert-text {
  line-height: 18px;
}
.mailServer-box .mail-group {
  overflow: hidden;
}
.mailServer-box .mail-group .host .control-label {
  width: 107px;
}
.mailServer-box .mail-group .host .controls {
  width: 300px;
}
.mailServer-box .mail-group .port .control-label {
  width: 60px;
}
.mailServer-box .mail-group .port .controls {
  width: 152px;
}
.mailServer-box .mail-group .error {
  color: #e9322d;
}
.mailServer-box .control-group .control-label {
  width: 17%;
}
.mailServer-box .control-group .controls {
  width: 83%;
}
.mailServer-box .mail-srv {
  width: 630px;
  margin: 0 auto;
  position: relative;
}
.mailServer-box .mail-srv .add-shut {
  font-size: 24px;
  position: absolute;
  top: 0;
  right: -120px;
}
.mailServer-box .mail-srv .add-shut .icon-aw-add-to {
  color: #1abc9c;
  cursor: pointer;
}
.mailServer-box .mail-srv .add-shut .icon-aw-1shut-down {
  color: #e74c3c;
  cursor: pointer;
}
.mailServer-box .separation-line {
  border-bottom: dashed 1px #c2d3eb;
  margin-bottom: 20px;
}
.save-btn {
  width: 1022px;
  margin: 0 auto;
  text-align: center;
}
.management-box {
  margin-top: 50px;
  text-align: center;
}
.management-box .img-box {
  margin: 0 auto;
  width: 440px;
  height: 440px;
  background: url(/img/about3.png) no-repeat center;
  background-size: 100%;
}
.management-box h4 {
  margin-bottom: 8px;
  font-size: 18px;
  color: #666;
}
.management-box p {
  margin-bottom: 70px;
  color: #51a3ff;
  font-size: 16px;
}
.management-box .copyright {
  color: #999;
}
.node-help-block {
  color: #e9322d;
  display: block;
  line-height: 22px;
  padding-left: 5px;
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 0;
}
/** passwordModify **/
.modal .modal-dialog .modal-common.modifyPwd-modal .hideIcon {
  display: none;
}
.modal .modal-dialog .modal-common.modifyPwd-modal .pwdModify-box .controls {
  padding-left: 15px;
}
.modal .modal-dialog .modal-common.modifyPwd-modal .pwdModify-box .controls span.form-controls {
  border: none;
}
.modal .modal-dialog .modal-common.modifyPwd-modal .pwdModify-box .help-block {
  color: #e9322d;
  display: block;
  line-height: 22px;
  padding-left: 5px;
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 0;
}
.modal .modal-dialog .modal-common.modifyPwd-modal .pwdModify-box .modifyFail {
  color: #e9322d;
  text-align: center;
}
.modal .modal-dialog .modal-common.modifyPwd-modal .modal-footer .btn.btn-primary {
  color: #ffffff;
  background: #3b78e7;
  border: 1px solid #16a085;
}
.modal .modal-dialog .modal-common.modifyPwd-modal .modal-footer .btn.btn-primary span {
  margin-left: 5px;
}
.save-btn {
  width: 1022px;
  margin: 0 auto;
  text-align: center;
}
/** handleLog **/
.operatelog-main .table-action .form-group:not(:first-child) {
  margin-left: -4px;
}
.operatelog-main .table-action .form-group {
  margin-right: 8px;
}
.operatelog-main .table-action .form-group .btn {
  margin-right: 8px;
}
.operatelog-main .table-action .form-group .dropdown {
  margin-right: 0px;
}
.operatelog-main .table-action .form-help {
  float: right;
}
/** lisence **/
.common-table tr td {
  border-right: solid 1px #e5e5e5;
}
.license-wrap {
  width: 600px;
  margin: 0 auto;
}
.license-wrap .license {
  font-size: 14px;
  margin-top: 30px;
}
.license-wrap .license h2 {
  color: #7890a8;
}
.license-wrap .license .control-group {
  margin-bottom: 0;
}
.license-wrap .license .control-group .control-label {
  text-align: left;
  width: auto;
  color: #7890a8;
  font-size: 16px;
}
.license-wrap .license .control-group .control-label span {
  color: #333333;
}
.license-wrap .license .control-group .control-label span.isvalid {
  color: #51a3ff;
}
.license-wrap .license .control-group .control-label span.isinvalid {
  color: #e74c3c;
}
.license-wrap .license .control-group .control-label span.avalible {
  font-size: 20px;
}
.license-wrap .license .node-list span {
  display: inline-block;
  border: 1px solid #c2d3eb;
  padding: 5px 15px;
  border-radius: 2px;
  margin-right: 15px;
}
.license-wrap .license .box-wrap {
  margin-bottom: 6px;
}
.license-wrap .license .box-wrap .left {
  float: left;
  width: 49%;
}
.license-wrap .license .box-wrap .right {
  float: right;
  width: 49%;
}
.license-wrap .license fieldset {
  padding: 15px 0;
  border-bottom: 1px dashed #c2d3eb;
}
.license-wrap .license fieldset .quotaNum,
.license-wrap .license fieldset .used,
.license-wrap .license fieldset .resBox {
  display: none;
}
.license-wrap .table-action {
  margin-top: 20px;
}
.copybtn {
  font-size: 16px;
  position: absolute;
  right: 25px;
  bottom: 8px;
  background: none;
  border: none;
  padding: 0;
  min-width: 0;
  height: auto;
  margin: 0;
  color: #666666;
}
.copybtn:hover {
  color: #51a3ff;
}
.copytext {
  border: 0;
  resize: none;
  padding: 0 35px 0 20px;
}
.licensetext {
  resize: none;
}
/** 回收站 **/
.alert-tip {
  position: relative;
}
.alert-tip .close {
  border: 0;
  background: transparent;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 16px;
}
/* 组织结构 */
.org-modal .directName {
  line-height: 32px;
}
/* 负载均衡 */
.balance .detail-info .nav-tabs {
  float: right;
  position: relative;
  z-index: 1;
}
.balance .detail-info .title {
  line-height: 28px;
}
/* vpn */
.modal .modal-dialog .vpn-modal .modal-header .steps-item li.step-start:after,
.modal .modal-dialog .vpn-modal .modal-header .steps-item li.step-end:after {
  width: 240px;
}
.modal .modal-dialog .vpn-modal .modal-body .title {
  border-bottom: solid 1px #e5e5e5;
  margin-bottom: 10px;
  height: 40px;
  line-height: 40px;
  font-weight: normal;
  text-align: left;
}
.modal .modal-dialog .vpn-modal .modal-body .title.site-title {
  width: 95%;
}
.modal .modal-dialog .vpn-modal .modal-body .title > span {
  background-color: #ffffff;
  color: #666666;
  margin-bottom: -1px;
  width: 100px;
}
.modal .modal-dialog .vpn-modal .modal-body .title > span i {
  cursor: pointer;
  margin: 0 5px;
  color: #51a3ff;
}
.modal .modal-dialog .vpn-modal .modal-body .title.target-title {
  border-bottom: none;
  margin-bottom: 0px;
  font-weight: normal;
}
.modal .modal-dialog .vpn-modal .modal-body .control-group .control-label .error {
  color: #e74c3c;
}
.modal .modal-dialog .vpn-modal .modal-body .control-group .ip-group:not(:last-child) {
  margin-bottom: 10px;
}
.modal .modal-dialog .vpn-modal .modal-body .control-group .ip-group .ip-text {
  float: left;
  width: 17.5%;
}
.modal .modal-dialog .vpn-modal .modal-body .control-group .ip-group .ip-spilt {
  display: inline-block;
  float: left;
  margin: 12px 2px 0;
}
.modal .modal-dialog .vpn-modal .modal-body .control-group .del {
  float: left;
  margin-left: 10px;
  line-height: 28px;
  color: #999999;
  right: 10px;
  top: 0px;
}
.modal .modal-dialog .vpn-modal .modal-body .control-group .del button {
  font-size: 14px;
  font-weight: normal;
  text-shadow: none;
  cursor: pointer;
  border: 0;
  background: transparent;
  color: #e74c3c;
}
.modal .modal-dialog .vpn-modal .modal-body .control-group .controls.password-controls > .btn {
  position: absolute;
  right: 1px;
  top: 0;
  background: #fff;
  border: none;
  padding: 0;
  min-width: 0;
  margin: 0;
  width: 32px;
  height: 24px;
  margin-top: 2px;
  font-size: 14px;
}
.modal .modal-dialog .vpn-modal .modal-body .control-group .controls.password-controls > .btn > i {
  cursor: pointer;
}
.modal .modal-dialog .vpn-modal .modal-body .control-group .controls.text-controls {
  text-align: left;
}
.modal .modal-dialog .vpn-modal .modal-body .control-group .controls.text-controls > .btn {
  position: absolute;
  right: 7%;
  top: 0;
  background: #fff;
  border: none;
  padding: 0;
  min-width: 0;
  margin: 0;
  width: 32px;
  height: 24px;
  margin: 1px 1px 0 0;
  font-size: 14px;
}
.modal .modal-dialog .vpn-modal .modal-body .control-group .controls.text-controls > .btn > i {
  cursor: pointer;
}
.modal .modal-dialog .vpn-modal .modal-body .control-group .controls.text-controls .form-controls {
  width: 93%;
}
.modal .modal-dialog .vpn-modal .modal-body .control-group .controls.site-controls > .ui-select-container {
  width: 93%;
}
.modal .modal-dialog .vpn-modal .modal-body .control-group .controls .localSub-box:after {
  content: "";
  display: block;
  clear: both;
}
.modal .modal-dialog .vpn-modal .modal-body .control-group .controls .localSub-box:not(:last-child) {
  margin-bottom: 10px;
}
.modal .modal-dialog .vpn-modal .modal-body .control-group .controls .localSub-box .localSub-controls {
  width: 93%;
  float: left;
}
.modal .modal-dialog .vpn-modal .modal-body .control-group .controls .left-period {
  width: 80%;
  float: left;
}
.modal .modal-dialog .vpn-modal .modal-body .control-group .controls .rigth-unit {
  width: 18%;
  float: left;
  margin-left: 2%;
}
.modal .modal-dialog .vpn-modal .modal-body .addSub {
  padding: 0px 0 10px 10px;
  margin-left: 17%;
  text-align: left;
}
.modal .modal-dialog .vpn-modal .modal-body .addSub.noSelect a {
  cursor: not-allowed;
  color: #999999;
}
.modal .modal-dialog .modal-common.delLocalSubnet-modal .modal-body .error-msg {
  color: #e74c3c;
  margin-bottom: 5px;
}
.modal .modal-dialog .modal-common.delLocalSubnet-modal .modal-body .table-content .pull-right {
  margin-bottom: 10px;
}
.modal .modal-dialog .modal-common.delLocalSubnet-modal .modal-body .table-content .pull-right .btn {
  float: right;
}
.modal .modal-dialog .modal-common.delLocalSubnet-modal .modal-body .table-content .pull-right .btn.btn-renovat {
  min-width: 32px;
  color: #51a3ff;
  background: #ffffff;
  border: 1px solid #c2d3eb;
  font-size: 16px;
  padding: 8px 0;
  margin-left: 8px;
  margin-right: 0px;
}
.modal .modal-dialog .modal-common.delLocalSubnet-modal .modal-body .table-content .pull-right .search-box {
  float: right;
}
.vpn-detail .tab-content .detail-row .detail-title {
  padding: 21px 0;
  margin: 0 20px 15px;
  border-bottom: 1px solid #e5e5e5;
}
.vpn-detail .tab-content .detail-row .detail-info .tabName {
  margin-bottom: 15px;
}
.vpn-detail .tab-content .detail-row .detail-info .table tbody tr td .password-box {
  max-height: 19px;
}
.vpn-detail .tab-content .detail-row .detail-info .table tbody tr td .password-box > span {
  display: inline-block;
  margin-top: 3px;
  height: 16px;
}
.vpn-detail .tab-content .detail-row .detail-info .table tbody tr td .password-box .btn {
  position: absolute;
  right: 0;
  top: 0;
  background: none;
  border: none;
  padding: 0;
  min-width: 0;
  margin: 0;
  width: 20px;
  height: 100%;
  font-size: 14px;
}
.vpn-detail .tab-content .detail-row .detail-info .table tbody tr td .password-box .btn > i {
  cursor: pointer;
}
.vpn-detail .tab-content .detail-row .detail-info .table tbody tr:hover.tr-title td {
  background-color: #ffffff;
}
.vpn-detail .tab-content .detail-row .detail-info .table tbody tr.tr-title td {
  border-right: none;
}
.vpn-detail .tab-content .detail-row .detail-info .table tbody tr.tr-title td:nth-child(odd) {
  background-color: #ffffff;
}
.phy-mac span {
  display: block;
  margin-bottom: 10px;
}
.phy-mac td .btn {
  height: 31px !important;
  line-height: 31px !important;
}
.phy-mac td .btn.addDiskBtn {
  margin-left: 10px;
}
.phy-mac td .active {
  background-color: #428bca !important;
}
.phy-mac td .control-group {
  margin-bottom: 0px;
}
.phy-mac td .control-group span {
  margin-bottom: 0px;
}
.phy-mac td .control-group .ui-select-container .ui-select-match .btn {
  padding-top: 0px;
}
.phy-mac td .control-group .ui-select-container .ui-select-match .caret {
  top: 35%;
}
.phy-mac td .control-group .controls {
  width: 72%;
  padding-left: 0px;
}
/* 云主机绑定公网IP */
.modal .modal-dialog .modal-common .modal-body .vpn-msg.alert {
  text-align: left;
}
/* elasticexpansion */
.expansion-detail .animateContent.animateIn {
  height: 1100px;
}
.expansion-detail .animateContent.animateIn .tab-content .tab-pane .outline {
  margin-bottom: 5px;
}
.expansion-detail .animateContent.animateIn .tab-content .tab-pane .table {
  margin-bottom: 30px;
}
.expansion-detail .animateContent.animateIn .tab-content .tab-pane .table tbody tr:hover.tr-title td {
  background-color: #ffffff;
}
.expansion-detail .animateContent.animateIn .tab-content .tab-pane .table tbody tr:hover.monitor-rule > td:nth-child(3) {
  background-color: #f6f9ff;
}
.expansion-detail .animateContent.animateIn .tab-content .tab-pane .table tbody tr.tr-title td {
  border-right: none;
}
.expansion-detail .animateContent.animateIn .tab-content .tab-pane .table tbody tr.tr-title td:nth-child(odd) {
  background-color: #ffffff;
}
.expansion-detail .animateContent.animateIn .tab-content .tab-pane .table tbody tr.monitor-rule > td:nth-child(2) {
  border-right: none;
}
.expansion-detail .animateContent.animateIn .tab-content .tab-pane .table tbody tr.monitor-rule > td:nth-child(3) {
  border-right: none;
  background-color: #fff;
}
.modal-common.expansion-modal .modal-body .block-title {
  padding-bottom: 5px;
  border-bottom: 1px solid #e5e5e5;
  font-weight: bold;
}
.modal-common.expansion-modal .modal-body .condition-box {
  margin: 10px 0 20px 0;
}
.modal-common.expansion-modal .modal-body .condition-box > .control-group .control-label {
  width: 15%;
}
.modal-common.expansion-modal .modal-body .condition-box > .control-group .extensional-controls {
  width: 82%;
}
.modal-common.expansion-modal .modal-body .condition-box > .control-group .extensional-controls .logic-label,
.modal-common.expansion-modal .modal-body .condition-box > .control-group .extensional-controls .cicle-label {
  width: 15%;
  float: left;
}
.modal-common.expansion-modal .modal-body .condition-box > .control-group .extensional-controls .logic-controls,
.modal-common.expansion-modal .modal-body .condition-box > .control-group .extensional-controls .cicle-controls {
  width: 33%;
  float: left;
}
.modal-common.expansion-modal .modal-body .condition-box > .control-group .extensional-controls .cicle-label {
  margin-left: 4%;
}
/* 外部网络(主要是详情tab页下的抬头信息) */
.animateContent.animateIn .detailInner .tab-content .detail-row .detail-info .lincense-detail {
  padding-bottom: 10px;
  font-size: 16px;
}
.animateContent.animateIn .detailInner .tab-content .detail-row.net-row .detail-title {
  padding: 21px 0px;
  margin: 0 20px;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 15px;
}
.animateContent.animateIn .detailInner .tab-content .detail-row.net-row .detail-info {
  padding-top: 0px;
}
.animateContent.animateIn .detailInner .tab-content .detail-row.net-row .detail-info .tabName {
  margin-bottom: 15px;
}
.animateContent.animateIn .detailInner .tab-content .detail-row.net-row .detail-info .table-content.port-content {
  max-height: 300px;
  overflow-y: auto;
}
.animateContent.animateIn .detailInner .tab-content .detail-row.net-row .detail-info .table-content.sub-content {
  margin-bottom: 20px;
  overflow-y: auto;
}
.tab-content .tab-pane h4.tabName {
  font-weight: normal;
  padding-top: 0px;
  margin-bottom: 10px;
}
.tab-content .tab-pane .table-action .tabName {
  float: left;
  font-size: 16px;
  line-height: 30px;
}
.modal .modal-dialog {
  /*checkbox禁用样式*/
  /*删除子网样式*/
}
.modal .modal-dialog .phynet-modal.easy-modal .modal-header {
  height: 60px;
}
.modal .modal-dialog .phynet-modal.easy-modal .modal-header .modal-title {
  float: none;
  margin: 0px;
}
.modal .modal-dialog .phynet-modal.easy-modal .modal-body .control-group.errorMsg-group {
  margin-bottom: 0px;
}
.modal .modal-dialog .phynet-modal.easy-modal .modal-body .control-group .controls .checkbox {
  display: inline-block;
}
.modal .modal-dialog .phynet-modal.easy-modal .modal-body .control-group .controls .checkbox input[disabled="disabled"]:checked + i:before {
  color: #c2d3eb;
}
.modal .modal-dialog .phynet-modal.easy-modal .modal-body .control-group .controls.IPV6-Cidr .cidr-msg {
  float: left;
}
.modal .modal-dialog .phynet-modal.easy-modal .modal-body .control-group .controls.IPV6-Cidr .cidr-msg .v6error {
  line-height: 1.5;
  font-size: 12px;
  color: #888888;
}
.modal .modal-dialog .phynet-modal.easy-modal .modal-body .control-group .controls.IPV6-Cidr .cidr-msg .v6error.error {
  color: #e74c3c;
}
.modal .modal-dialog .phynet-modal.easy-modal .modal-body .control-group .controls.IPV6-Cidr .cidr-input {
  float: left;
  width: 9.5%;
  margin-right: 6px;
}
.modal .modal-dialog .phynet-modal.easy-modal .modal-body .control-group .controls.IPV6-Cidr .cidr-input input {
  padding: 0 6px;
}
.modal .modal-dialog .phynet-modal.easy-modal .modal-body .control-group .controls.IPV6-Cidr .cidr-input.cidr-line {
  width: 1%;
  line-height: 32px;
  font-size: 16px;
}
.modal .modal-dialog .phynet-modal.easy-modal .modal-body .control-group .controls.IPV6-Cidr .cidr-input.cidr-last {
  width: 13%;
  margin-right: 0;
}
.modal .modal-dialog .createPhy-modal .steps-sytle-ver li.step-start {
  width: 50%;
}
.modal .modal-dialog .createPhy-modal .steps-sytle-ver li.step-end {
  width: 50%;
}
.modal .modal-dialog .createPhy-modal .modal-body .ip_pool.repeat .group.create-subnet {
  margin-left: 17%;
}
.modal .modal-dialog .createPhy-modal .modal-body .control-group.errorMsg-group {
  margin-bottom: 0px;
}
.modal .modal-dialog .createPhy-modal .modal-body .control-group .controls .checkbox {
  display: inline-block;
}
.modal .modal-dialog .createPhy-modal .modal-body .control-group .controls .checkbox input[disabled="disabled"]:checked + i:before {
  color: #c2d3eb;
}
.modal .modal-dialog .createPhy-modal .modal-body .control-group .controls .globle-shared {
  float: left;
  margin-right: 15px;
}
.modal .modal-dialog .createPhy-modal .modal-body .control-group .controls .depart-shared {
  float: left;
}
.modal .modal-dialog .createPhy-modal .modal-body .control-group .controls.IPV6-Cidr .cidr-msg {
  float: left;
}
.modal .modal-dialog .createPhy-modal .modal-body .control-group .controls.IPV6-Cidr .cidr-msg .v6error {
  line-height: 1.5;
  font-size: 12px;
  color: #888888;
}
.modal .modal-dialog .createPhy-modal .modal-body .control-group .controls.IPV6-Cidr .cidr-msg .v6error.error {
  color: #e74c3c;
}
.modal .modal-dialog .createPhy-modal .modal-body .control-group .controls.IPV6-Cidr .cidr-input {
  float: left;
  width: 9.5%;
  margin-right: 6px;
}
.modal .modal-dialog .createPhy-modal .modal-body .control-group .controls.IPV6-Cidr .cidr-input input {
  padding: 0 6px;
}
.modal .modal-dialog .createPhy-modal .modal-body .control-group .controls.IPV6-Cidr .cidr-input.cidr-line {
  width: 1%;
  line-height: 32px;
  font-size: 16px;
}
.modal .modal-dialog .createPhy-modal .modal-body .control-group .controls.IPV6-Cidr .cidr-input.cidr-last {
  width: 13%;
  margin-right: 0;
}
.modal .modal-dialog .createPhy-modal .modal-body.net-body .alert.editNet-alert {
  text-align: left;
}
.modal .modal-dialog .createPhy-modal .modal-body.net-body .control-group.bind-group .control-label.bind-label {
  margin-top: 14px;
}
.modal .modal-dialog .createPhy-modal .modal-body.net-body .control-group.bind-group .controls.bind-controls i {
  top: 15px;
}
.modal .modal-dialog .createPhy-modal .modal-body.net-body .control-group.bind-group .controls.bind-controls .bind-bar {
  float: left;
  width: 85%;
  padding-left: 10px;
}
.modal .modal-dialog .createPhy-modal .modal-body.net-body .control-group.bind-group .controls.bind-controls .bind-input {
  float: left;
  width: 13%;
  margin-top: 14px;
  margin-left: 2%;
}
.modal .modal-dialog .createPhy-modal .modal-body.net-body .control-group .control-label {
  width: 19%;
}
.modal .modal-dialog .createPhy-modal .modal-body.net-body .control-group .controls {
  width: 81%;
}
.modal .modal-dialog .delsub-modal .modal-body .table-content.delsub-content {
  max-height: 216px;
  overflow-y: auto;
  margin-bottom: 30px;
}
.nodes-userful-modal {
  width: 980px !important;
}
.nodes-userful-modal .steps-sytle-ver {
  width: 480px !important;
}
.nodes-userful-modal .steps-sytle-ver .step-middle {
  width: 50%;
}
.nodes-userful-modal .steps-sytle-ver .step-start,
.nodes-userful-modal .steps-sytle-ver .step-end {
  width: 25%;
}
.nodes-userful-modal .modal-body p {
  color: #e74c3c;
  float: left;
  line-height: 32px;
}
.dockerCluster-content .cluster-box {
  width: 100%;
  position: relative;
}
.dockerCluster-content .cluster-box .move-content {
  width: 300%;
  transition: margin-left 0.6s ease-in 0s;
}
.dockerCluster-content .cluster-box .move-content .detail-content {
  width: 33.3%;
  float: left;
}
.dockerCluster-content .cluster-box .move-content .detail-content .detail-row .detail-title span {
  font-size: 16px;
  font-weight: 600;
  margin-right: 10px;
}
.dockerCluster-content .cluster-box .move-content .detail-content .detail-row .detail-title .title-cluster {
  color: #51a3ff;
  cursor: pointer;
}
.dockerCluster-content .cluster-box .to-left {
  margin-left: -100%;
}
.dockerCluster-content .cluster-box .to-move-left {
  margin-left: -200%;
}
/* 数据中心 */
.page-inner .table-action.datacluster-action .popover.right {
  top: 0px !important;
}
.page-inner .table-action.datacluster-action .popover.right .arrow {
  top: 35%;
}
/* ports */
.table-action .show-title {
  float: right;
}
.table-action .show-title .btn-renovat {
  margin-left: 8px;
}
.table-action .show-title .dropdown-toggle > i {
  font-size: 16px;
  margin-left: 0px;
}
.table-action .show-title .dropdown-menu {
  left: auto;
  right: 0px;
  padding: 5px 10px;
}
.table-action .show-title .dropdown-menu li {
  min-width: 140px;
}
.table-action .show-title .dropdown-menu li .checkbox {
  padding: 4px 0px;
  height: 28px;
}
.table-action .show-title .dropdown-menu li .checkbox label > i {
  margin-right: 3px;
}
.table-action .show-title .dropdown-menu li .check-left {
  display: inline-block;
}
.table-action .show-title .dropdown-menu li .check-left .checkbox {
  padding-top: 7px;
  line-height: 20px;
  height: 28px;
  margin: 0;
  cursor: pointer;
  position: relative;
  font-weight: normal;
}
.table-action .show-title .dropdown-menu li .check-left .checkbox i,
.table-action .show-title .dropdown-menu li .check-left .checkbox .iconfont {
  margin-right: 5px;
  color: #c2d3eb;
  width: 20px;
  height: 18px;
  font-size: 16px;
  vertical-align: middle;
  font-style: normal;
}
.table-action .show-title .dropdown-menu li .check-left .checkbox i:before,
.table-action .show-title .dropdown-menu li .check-left .checkbox .iconfont:before {
  content: "\E6B1";
  font-weight: lighter;
}
.table-action .show-title .dropdown-menu li .check-left .checkbox input {
  position: absolute;
  left: 18px;
  top: 6px;
  opacity: 0;
  width: 22px;
  height: 16px;
}
.table-action .show-title .dropdown-menu li .check-left .checkbox input:checked + i:before {
  content: "\E825";
  color: #4e80f5;
}
.table-action .show-title .dropdown-menu li .check-left .checkbox input:indeterminate + i:before {
  content: "\E6B0";
  color: #4e80f5;
}
.table-action .show-title .dropdown-menu li .check-left .checkbox input[disabled="disabled"]:checked + i:before {
  content: "\E825";
  color: #c2d3eb;
}
.table-action .show-title .dropdown-menu li .check-left .checkbox.switch-btn {
  display: inline-block;
  height: 34px;
  padding-top: 3px;
}
.table-action .show-title .dropdown-menu li .check-left .checkbox.switch-btn label i,
.table-action .show-title .dropdown-menu li .check-left .checkbox.switch-btn label .iconfont {
  width: 54px;
  height: 24px;
  background: #ccc;
  position: relative;
  display: inline-block;
  border-radius: 50px;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -ms-transition: 0.4s;
  transition: 0.4s;
}
.table-action .show-title .dropdown-menu li .check-left .checkbox.switch-btn label input + i,
.table-action .show-title .dropdown-menu li .check-left .checkbox.switch-btn label input + .iconfont {
  margin-right: 0;
}
.table-action .show-title .dropdown-menu li .check-left .checkbox.switch-btn label input + i:before,
.table-action .show-title .dropdown-menu li .check-left .checkbox.switch-btn label input + .iconfont:before {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  top: 3px;
  left: 4px;
  text-align: center;
  border-radius: 100%;
  z-index: 2;
  background: #ffffff;
  box-shadow: 0 0 3px rgba(78, 128, 245, 0.25);
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -ms-transition: 0.4s;
  transition: 0.4s;
}
.table-action .show-title .dropdown-menu li .check-left .checkbox.switch-btn label input + i:hover:before,
.table-action .show-title .dropdown-menu li .check-left .checkbox.switch-btn label input + .iconfont:hover:before {
  box-shadow: 0 0 3px rgba(78, 128, 245, 0.35);
}
.table-action .show-title .dropdown-menu li .check-left .checkbox.switch-btn label input:checked + .iconfont {
  background: #1abc9c;
}
.table-action .show-title .dropdown-menu li .check-left .checkbox.switch-btn label input:checked + .iconfont:before {
  content: "";
  left: 34px;
  background: #ffffff;
}
.table-action .right-ports {
  float: right;
}
.table-action .help-tips {
  margin-right: 28px !important;
}
.table-action .ticket-report-tips .help-tips {
  padding: 3px 0 !important;
}
.table-action .detail-help-tips {
  margin-right: 30px !important;
}
.table-action .status-list {
  width: 110px;
  float: right;
  margin-right: 10px;
}
.table-action .btn.btn-renovat {
  min-width: 32px;
  color: #999;
  background: #ffffff;
  font-size: 16px;
  padding: 6px 0;
  margin: 0px;
}
.table-action .btn.btn-renovat i {
  margin-right: 0;
}
.table-action .btn.btn-renovat:hover {
  color: #4e80f5;
}
.table-action .btn.btn-renovat:active {
  color: #4e80f5;
}
.port-modal .modal-body .table-content .search-box {
  float: right;
  margin: -4px 0 10px 0;
}
.modal .modal-dialog .modal-common.loadMsg-modal {
  width: 550px;
}
.modal .modal-dialog .modal-common.loadMsg-modal .modal-body .msg-box {
  padding: 0 15px;
}
.modal .modal-dialog .modal-common.loadMsg-modal .modal-body .msg-box div {
  line-height: 25px;
}
.modal .modal-dialog .modal-common.create-port-modal .modal-body .control-group .controls .checkbox {
  display: inline-block;
}
.modal .modal-dialog .modal-common.os-snap-rollback-modal .modal-body .snap-error {
  text-align: center;
  color: #e74c3c;
}
/*导入主机别名文件*/
.modal-common.hostname-modal .modal-body .alert div a {
  color: #e67e22;
}
/*备份恢复*/
.page-inner.rbackups-inner .table-content table tr td .edit-name.realtime-backup,
.page-inner.recovery-inner .table-content table tr td .edit-name.realtime-backup,
.page-inner.strategy-inner .table-content table tr td .edit-name.realtime-backup {
  cursor: pointer;
}
.page-inner.rbackups-inner .table-content table tr td .backup-strategy-name,
.page-inner.recovery-inner .table-content table tr td .backup-strategy-name,
.page-inner.strategy-inner .table-content table tr td .backup-strategy-name {
  max-width: 250px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}
.page-inner.rbackups-inner .table-scroll,
.page-inner.recovery-inner .table-scroll,
.page-inner.strategy-inner .table-scroll {
  min-height: 600px;
}
/*常规设置云管参数*/
.saas-param.table tbody tr td .errorTip {
  color: #e74c3c;
  line-height: 1.5;
  font-size: 12px;
}
/*云主机冷迁移弹出框样式*/
.coldMigrate-modal .modal-body .control-group.table-group {
  overflow: auto;
}
.coldMigrate-modal .modal-body .control-group.table-group tr td .edit-name {
  width: 100px;
}
/*云硬盘卷迁移弹出框样式*/
.disk-transfer-modal .modal-body .control-group .controls .max-text,
.ins-transfer-modal .modal-body .control-group .controls .max-text {
  width: 250px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
/*添加删除input框联合样式*/
.control-group .controls .add-input-del-group {
  float: left;
  overflow: hidden;
}
.control-group .controls .bind-inputs {
  float: left;
}
.control-group .controls .bind-inputs input {
  margin: 0 6px;
  float: left;
  width: 80px;
}
.control-group .controls .bind-inputs button {
  float: left;
  background: #f2f2f2;
  text-align: center;
  width: 28px;
  height: 28px;
  border: 1px solid #d7d7d7;
}
.control-group .controls .create-tip {
  float: left;
  margin-left: 6px;
  line-height: 28px;
}
/*备份策略*/
.createBackupStrategy-modal .modal-body .control-group .control-label {
  width: 22%;
}
.createBackupStrategy-modal .modal-body .control-group .controls {
  width: 78%;
}
.createBackupStrategy-modal .modal-body .control-group .controls.interval input {
  width: 60%;
}
/*tdsql*/
.modal .modal-dialog .modal-common.createAccount-modal .modal-body .control-group .controls .error.readonly-msg {
  color: #999999;
  margin-top: 5px;
}
.modal .modal-dialog .modal-common.modifyAuthority-modal .modal-body .control-group .controls.authority-controls {
  float: left;
  width: 27%;
}
.modal .modal-dialog .modal-common.modifyAuthority-modal .modal-body .control-group .controls.authority-controls .ui-select-container .modify-uiselect {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
}
.modal .modal-dialog .modal-common.modifyAuthority-modal .modal-body .control-group .controls .checkbox .iconfont {
  margin-right: 10px;
}
.modal .modal-dialog .modal-common.modifyAuthority-modal .modal-body .selected-container {
  overflow: hidden;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin-top: 15px;
  margin-bottom: 10px;
  padding: 15px 25px 15px 45px;
}
.modal .modal-dialog .modal-common.modifyAuthority-modal .modal-body .selected-container > .control-group .controls {
  padding-left: 0px;
}
.modal .modal-dialog .modal-common.modifyAuthority-modal .modal-body .selected-container .control-group {
  margin-bottom: 5px;
}
.modal .modal-dialog .modal-common.modifyAuthority-modal .modal-body .selected-container .control-group.select-group {
  float: left;
  width: 30%;
  margin-right: 1%;
}
.modal .modal-dialog .modal-common.modifyAuthority-modal .modal-body .selected-container .control-group.select-group .controls {
  width: 100%;
  padding-left: 0px;
  font-size: 12px;
}
.modal .modal-dialog .modal-common.modifyAuthority-modal .modal-body .selected-container .control-group.select-group .controls .checkbox {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
}
.modal .modal-dialog .modal-common.modifyAuthority-modal .modal-body .bottom-msg {
  padding: 0px 25px;
}
.modal .modal-dialog .modal-common.resetPassword-modal .modal-body .control-group .controls.phone-controls > input {
  width: 40%;
  margin-right: 5px;
}
.modal .modal-dialog .modal-common.dataSync-modal .modal-body .control-group .controls .readonly-msg {
  margin-top: 10px;
}
.modal .modal-dialog .modal-common.addPiece-modal .modal-body .control-group.bind-group .control-label.bind-label {
  margin-top: 14px;
}
.modal .modal-dialog .modal-common.addPiece-modal .modal-body .control-group.bind-group .bind-controls .bind-bar {
  width: 70%;
  float: left;
}
.modal .modal-dialog .modal-common.addPiece-modal .modal-body .control-group.bind-group .bind-controls .bind-input {
  width: 25%;
  margin-top: 15px;
  float: right;
  height: 28px;
}
.modal .modal-dialog .modal-common.addPiece-modal .modal-body .control-group.bind-group .bind-controls .bind-input .step-trans {
  color: #7d7d7d;
  font-size: 12px;
}
.modal .modal-dialog .modal-common.addPiece-modal .modal-body .control-group.balance-group .controls.balance-controls {
  height: 28px;
  margin-bottom: 5px;
}
.modal .modal-dialog .modal-common.addPiece-modal .modal-body .control-group.balance-group .controls.balance-controls .step-control-component {
  width: 25%;
  height: 28px;
}
.modal .modal-dialog .modal-common.addPiece-modal .modal-body .control-group.balance-group .controls.balance-controls .step-control-component > input {
  border: 1px solid #d7d7d7;
  width: 50%;
  margin: 0px 5%;
}
.modal .modal-dialog .modal-common.addPiece-modal .modal-body .control-group.balance-group .controls.balance-controls .step-control-component > span {
  right: 30%;
}
.modal .modal-dialog .modal-common.addPiece-modal .modal-body .control-group.balance-group .step-trans {
  color: #7d7d7d;
  font-size: 12px;
  margin-left: 21%;
}
.modal .modal-dialog .modal-common.addPiece-modal .modal-body .control-group .controls.text-controls {
  height: 28px;
  line-height: 28px;
}
.modal .modal-dialog .modal-common.addPiece-modal .modal-body .control-group .controls .piece-select {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
}
.modal .modal-dialog .modal-common.addPiece-modal .modal-body .control-group .controls .time-trans {
  color: #7d7d7d;
  font-size: 12px;
}
.control-group .controls .text-group .form-controls {
  width: 50%;
}
.insMonitor-container .time-box .control-group.noNode {
  width: 335px;
  margin-left: 75%;
  float: right;
}
.insMonitor-container .time-box .control-group.noNode .controls .time-controls {
  width: 100%;
}
.insMonitor-container .time-box .control-group .controls {
  padding-left: 15px;
}
.insMonitor-container .time-box .control-group .controls .time-controls {
  float: left;
  width: 45%;
}
.insMonitor-container .time-box .control-group .controls .time-controls:after {
  content: "";
  display: block;
  clear: both;
}
.insMonitor-container .time-box .control-group .controls .time-controls .form_date {
  float: left;
  height: 28px;
  width: 140px;
}
.insMonitor-container .time-box .control-group .controls .time-controls .form_date input {
  border: 1px solid #c2d3eb;
}
.insMonitor-container .time-box .control-group .controls .time-controls .form_date .input-group-addon {
  border: 1px solid #c2d3eb;
  border-left: none;
}
.insMonitor-container .time-box .control-group .controls .node-controls {
  cursor: pointer;
  float: right;
}
.insMonitor-container .time-box .control-group .controls .node-controls:after {
  content: "";
  display: block;
  clear: both;
}
.insMonitor-container .time-box .control-group .controls .time-tab {
  cursor: pointer;
  float: left;
  border: 1px solid #c2d3eb;
  line-height: 28px;
  height: 28px;
  padding: 0 15px;
  background: #fff;
  margin-right: -1px;
}
.insMonitor-container .time-box .control-group .controls .time-tab.active {
  color: #51a3ff;
}
.insMonitor-container .monitor-box:after {
  content: "";
  display: block;
  clear: both;
}
.insMonitor-container .monitor-box .monitor-items {
  float: left;
  width: 18%;
}
.insMonitor-container .monitor-box .monitor-items .menu-level-one {
  background: #efefef;
  max-height: 500px;
  overflow: hidden;
}
.insMonitor-container .monitor-box .monitor-items .menu-level-one > li.levelOneActive > span i:before {
  content: "\E81C";
}
.insMonitor-container .monitor-box .monitor-items .menu-level-one > li > span {
  display: inline-block;
  width: 100%;
  padding: 10px;
  padding-left: 30px;
  color: #333;
  font-size: 14px;
  position: relative;
  cursor: pointer;
}
.insMonitor-container .monitor-box .monitor-items .menu-level-one > li > span i.icon-arrrow {
  position: absolute;
  top: 10px;
  left: 10px;
}
.insMonitor-container .monitor-box .monitor-items .menu-level-one > li > span i:before {
  content: "\E817";
}
.insMonitor-container .monitor-box .monitor-items .menu-level-one > li ul.menu-level-two > li.activeMonitor > span {
  color: #4e80f5;
}
.insMonitor-container .monitor-box .monitor-items .menu-level-one > li ul.menu-level-two > li > span {
  display: inline-block;
  width: 100%;
  padding: 10px;
  padding-left: 30px;
  color: #888;
  font-size: 12px;
  cursor: pointer;
}
.insMonitor-container .monitor-box .monitor-chart {
  float: right;
  width: 80%;
}
.insMonitor-container .monitor-box .monitor-chart .alarm-name {
  font-weight: 600;
}
.insMonitor-container .monitor-box .monitor-chart .alert.alert-info {
  color: #888;
}
.host-name-limit {
  width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}
.host-name-limit.limit-length {
  display: none;
}
.host-name-limit.insdetail-name {
  width: 200px;
}
/*步长控件样式*/
.step-control-component {
  height: 100%;
  position: relative;
}
.step-control-component:after {
  content: "";
  display: block;
  clear: both;
}
.step-control-component > button,
.step-control-component > input {
  float: left;
  height: 100%;
}
.step-control-component > button {
  width: 20%;
  border: 1px solid #d7d7d7;
  background: #f2f2f2;
}
.step-control-component > input {
  width: 60%;
  border: 1px solid #d7d7d7;
  border-left: none;
  border-right: none;
  padding-left: 20px;
}
.step-control-component > span {
  position: absolute;
  top: 5px;
  right: 26%;
}
.delete-wrap .delete-alert .alert .del-cont .tdsql-delAccount {
  color: #333333;
}
.tdsql-detail-inner .tab-content .table-content .table tr td .modifyDataSync {
  float: right;
  margin-right: 50px;
}
.tdsql-detail-inner .tab-content .table-content .table tr td.param-handle .error {
  background: transparent;
  color: #e74c3c;
}
.tdsql-detail-inner .tab-content .table-content .table tr td .default-param,
.tdsql-detail-inner .tab-content .table-content .table tr td .current-param,
.tdsql-detail-inner .tab-content .table-content .table tr td .availableParam {
  display: block;
  width: 220px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
/*时序性数据库*/
.page-inner.sql-database-inner {
  padding: 0px 20px;
}
.page-inner.sql-database-inner .sql-title {
  font-size: 16px;
  border-bottom: 1px solid #e5e5e5;
  line-height: 59px;
  padding: 12px 20px;
  margin-bottom: 20px;
}
.page-inner.sql-database-inner .sql-title h2 {
  font-size: 16px;
  font-weight: 700;
  color: #000;
}
.page-inner.sql-database-inner .echarts-phy {
  height: 300px;
  line-height: 300px;
}
.page-inner.sql-database-inner .col {
  width: 70%;
  margin: 0px auto;
  padding: 40px 0px;
}
.page-inner.sql-database-inner .col:not(:last-child) {
  border-bottom: 1px solid #e5e5e5;
}
.page-inner.sql-database-inner .control-group.time-select {
  width: 60%;
}
.page-inner.sql-database-inner .control-group.time-select .control-label {
  width: 7%;
}
.page-inner.sql-database-inner .control-group.time-select .controls {
  width: 90%;
  padding-left: 10px;
}
.page-inner.sql-database-inner .control-group.time-select .controls .tab-group,
.page-inner.sql-database-inner .control-group.time-select .controls .btn-group {
  float: left;
}
.page-inner.sql-database-inner .control-group.time-select .controls .directive-action-wrap {
  float: left;
}
.page-inner.sql-database-inner .control-group.circle-select {
  width: 15%;
}
.page-inner.sql-database-inner .control-group.circle-select .control-label {
  width: 22%;
}
.page-inner.sql-database-inner .control-group.circle-select .controls {
  width: 70%;
}
.page-inner.sql-database-inner .control-group.circle-select .controls .uiSelect-cluster {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 120px;
  overflow: hidden;
}
/*云系统服务*/
.page-inner .service-heade .node-box {
  float: left;
  width: 20%;
}
.page-inner .service-heade .node-box .control-group .control-label {
  width: 17%;
}
.page-inner .service-heade .node-box .control-group .controls {
  width: 45%;
}
.page-inner .service-heade .node-help {
  float: right;
}
.page-inner .service-heade .node-help .btn-renovat {
  min-width: 32px;
  color: #999999;
  background: #ffffff;
  font-size: 16px;
  margin-left: 0;
}
.table tbody tr td .edit-name > a {
  width: 100%;
}
.table tbody tr td .edit-name.edit-huge {
  width: 300px;
}
.table tbody tr td .edit-name.edit-huge > span {
  max-width: 300px;
}
/*资源监控-云主机*/
.res-vm-view.page-inner .box .panels .pie-legend.vm-host-distribution .dl-horizontal dt {
  width: 100px;
}
/*私有网络中的编辑子网*/
.modal .modal-dialog .modal-common.modal-largest.phynet-modal .modal-body.editSub-body .table-content.sub-content {
  max-height: 216px;
  overflow-y: auto;
  margin-bottom: 30px;
}
.modal .modal-dialog .modal-common.modal-largest.phynet-modal .modal-body.editSub-body .editSub {
  margin-bottom: 15px;
}
.modal .modal-dialog .modal-common.modal-largest.phynet-modal .modal-body.editSub-body .control-group .controls.subnet-controls {
  width: 60%;
}
.modal .modal-dialog .modal-common.modal-largest.phynet-modal .modal-body.editSub-body .ip_pool.editSub_pool {
  max-height: 336px;
  overflow-y: auto;
}
.modal .modal-dialog .modal-common.modal-largest.phynet-modal .modal-body.editSub-body .ip_pool.editSub_pool .group {
  width: 60%;
  padding-left: 30px;
}
.modal .modal-dialog .modal-common.modal-largest.phynet-modal .modal-body.editSub-body .ip_pool.editSub_pool .group .del {
  right: -25px;
  font-size: 16px;
}
.modal .modal-dialog .modal-common.modal-largest.phynet-modal .modal-body.editSub-body .ip_pool.editSub_pool .group .add-subpool {
  position: absolute;
  right: -50px;
  top: 7px;
  font-size: 14px;
}
.modal .modal-dialog .modal-common.modal-largest.phynet-modal .modal-body.editSub-body .ip_pool.editSub_pool .group .control-group {
  width: 48%;
}
.modal .modal-dialog .modal-common.modal-largest.phynet-modal .modal-body.editSub-body .ip_pool.editSub_pool .group .control-group:nth-child(2) {
  margin-left: 4%;
}
.modal .modal-dialog .modal-common.modal-largest.phynet-modal .modal-body.editSub-body .ip_pool.editSub_pool .group .control-group .control-label {
  width: 14%;
}
.modal .modal-dialog .modal-common.modal-largest.phynet-modal .modal-body.editSub-body .ip_pool.editSub_pool .group .control-group .controls {
  margin-top: 2px;
  width: 86%;
  padding-left: 10px;
}
/* 节点管理 */
.kubernets-container .page-inner {
  padding: 0;
}
.modal .modal-dialog .deletNode-modal .modal-body .alert {
  text-align: left;
}
.page-inner .tab-offset-right .tab-content .table-action.node-action .dropdown .dropdown-menu li {
  position: relative;
}
.page-inner .tab-offset-right .tab-content .table-action.node-action .dropdown .dropdown-menu li .btn-info {
  position: absolute;
  min-width: 270px;
  top: 0px;
  left: 112px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  padding: 9px 14px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.175);
}
.page-inner .tab-offset-right .tab-content .table-action.node-action .dropdown .dropdown-menu li .btn-info .arrow {
  position: absolute;
  background: #fff;
  width: 15px;
  height: 15px;
  border: 1px solid #eee;
  left: -9px;
  -webkit-transform: rotate(45deg);
  border-top: none;
  border-right: none;
}
/* 新建云主机特别设置*/
.modal-common.flavorExtras-modal .modal-body .control-group .controls .dedicated-msg {
  margin-top: 10px;
}
/* 系统巡检（inspection） */
.inspection-container {
  overflow-y: auto;
  max-height: 710px;
}
.inspection-box {
  overflow: hidden;
  padding: 20px 0;
}
.inspection-box .totalMsg {
  margin-right: 20px;
  padding-right: 20px;
  border-right: 1px solid #eee;
  float: left;
}
.inspection-box .totalDetail {
  display: inline-block;
}
.inspection-box .totalDetail .detail {
  float: left;
  margin-right: 30px;
}
.inspection-box .totalDetail .detail .normal-item {
  color: #1abc9c;
}
.inspection-box .totalDetail .detail .abnormal-item > a {
  color: #f39c12;
}
.inspectionResult-box .checkTitle {
  padding-bottom: 15px;
}
.inspection-load {
  text-align: center;
}
.inspection-load i {
  margin-left: 5px;
}
.router-subnet-add .control-label {
  width: 15% !important;
}
.router-subnet-add .controls {
  width: 85% !important;
}
.detail-body.systemHelp-box .animateContent {
  width: 350px;
}
.detail-body.systemHelp-box .animateContent .detailInner .tab-content .detail-row .detail-title .help-title {
  padding-bottom: 15px;
  border-bottom: 1px solid #e5e5e5;
}
.detail-body.systemHelp-box .animateContent .detailInner .tab-content .detail-row .detail-title .help-title > span {
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  float: left;
  margin-top: 2px;
}
.detail-body.systemHelp-box .animateContent .detailInner .tab-content .detail-row .detail-info {
  height: 720px;
  overflow-y: auto;
  padding-top: 0;
}
.detail-body.systemHelp-box .animateContent .detailInner .tab-content .detail-row .detail-info div {
  line-height: 24px;
}
.detail-body.systemHelp-box .animateContent .detailInner .tab-content .detail-row .detail-info .help-title {
  font-size: 14px;
  margin-bottom: 5px;
}
.detail-body.systemHelp-box .animateContent .detailInner .tab-content .detail-row .detail-info .subdes {
  padding-left: 40px;
}
.detail-body.systemHelp-box .animateContent .detailInner .tab-content .detail-row .more {
  text-align: center;
}
/*告警事件*/
.alarmevent-inner .table-content .table-action .alarm-event-new .alarmEvent-uiselect {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 120px;
  overflow: hidden;
}
/**
 * 弹性扩展
 * 2017/04/06
 * daijing
 **/
.control-group .controls .node-select-limit {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
}
.control-group .controls .node-select-limit.aggregates-select {
  max-width: 250px;
}
.control-group .controls .node-select-limit.createins-zone-select {
  max-width: 450px;
}
.control-group .controls .node-select-limit.migrate-limit {
  max-width: 250px;
}
.control-group .controls .node-select-limit.create-backup-disk {
  max-width: 500px;
}
.control-group .controls .node-select-limit.image-manage-transfer {
  max-width: 450px;
}
.newExtension-modal .selectWidth {
  width: 19%;
  float: left;
  margin-right: 5px;
}
.newExtension-modal .control-items2 {
  width: 45%;
  float: left;
}
.newExtension-modal .control-items2 .control-label {
  width: 25%;
}
.newExtension-modal .control-items2 .controls {
  width: 75%;
}
.newExtension-modal .control-items2 .controls .extension-uiselect {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
}
.newExtension-modal .action {
  width: 5%;
  height: 34px;
  line-height: 34px;
  text-align: center;
  float: left;
}
.newExtension-modal .modal-body .tab-content .errormsg {
  color: #e74c3c;
  line-height: 1.5;
  font-size: 12px;
  margin-left: 10px;
}
.modal-common.addInstances-modal .modal-body .alert {
  text-align: left;
}
.modal-common.addInstances-modal .modal-body .control-group .controls .form-group {
  margin-bottom: 0px;
}
.modal-common.addInstances-modal .modal-body .control-group .controls.date-controls {
  width: 40%;
  margin-right: 10px;
}
.modal-common.addInstances-modal .modal-body .control-group .controls.hour-controls {
  width: 12%;
  padding-left: 0px;
}
.modal-common.addInstances-modal .modal-body .control-group .controls.hour-controls span.frequency {
  line-height: 32px;
}
.modal-common.addInstances-modal .modal-body .control-group .controls.colon-controls {
  width: 1%;
  margin: 0 6px;
  line-height: 32px;
  font-weight: bold;
  padding-left: 0px;
}
.modal-common.addInstances-modal .modal-body .control-group .controls.week-controls .interval-btn {
  height: 28px;
  line-height: 4px;
  float: left;
  border: 1px solid #c2d3eb;
  padding: 12px 8px;
  margin-right: 8px;
  background: #fff;
}
.modal-common.addInstances-modal .modal-body .control-group .controls.week-controls .interval-btn.active {
  background: #f6f9ff;
  color: #51a3ff;
}
.modal-common.addInstances-modal .modal-body .control-group .controls.week-controls .interval-description {
  float: left;
  margin-right: 15px;
  line-height: 28px;
}
.modal-common.addInstances-modal .modal-body .control-group .controls .btn-item-group .type-btn.active {
  background: #f6f9ff;
  color: #51a3ff;
  border: 1px solid #c2d3eb;
}
.modal-common.addInstances-modal .modal-body .error-msg {
  color: #e74c3c;
  line-height: 1.5;
  font-size: 12px;
  padding-left: 25px;
  margin-left: 17%;
}
.modal-common.attachVolume-modal .modal-header {
  margin-bottom: 10px;
}
.modal-common.attachVolume-modal .modal-body {
  margin-bottom: 55px;
}
.tab-content .table-content .scale-policy {
  border: 1px solid #e5e5e5;
}
.tab-content .table-content .scale-policy p {
  padding: 0 15px;
  height: 48px;
  line-height: 48px;
}
.tab-content .table-content .scale-policy p:hover {
  background-color: #f6f9ff;
}
.page-inner .table-action .btn.btn-createExtension + .popover {
  top: 0px !important;
}
.page-inner .table-action .btn.btn-createExtension + .popover .arrow {
  top: 25%;
}
/*多region部署*/
.deploy-box .step-box {
  background-color: #f0f6f6;
  border-bottom: solid 2px #51a3ff;
  height: 123px;
  margin: -15px -30px 30px;
}
.deploy-box .step-box .text-list {
  padding: 20px 0;
  height: 123px;
}
.deploy-box .step-box .text-list > li {
  float: left;
  width: 33.3333%;
  text-align: center;
  color: #999999;
  position: relative;
  height: 103px;
  border-bottom: solid 2px #c2d3eb;
}
.deploy-box .step-box .text-list > li i {
  font-size: 36px;
}
.deploy-box .step-box .text-list > li p {
  font-size: 18px;
  line-height: 1.5;
}
.deploy-box .step-box .text-list > li .state-items {
  position: absolute;
  left: -75px;
  bottom: 25px;
  color: #dae6f1;
  width: 150px;
  height: 24px;
  line-height: 24px;
  background: url(/img/icon-spot.png) no-repeat center center;
}
.deploy-box .step-box .text-list > li .state-items span {
  font-size: 16px;
}
.deploy-box .step-box .text-list > li .state-items .icon-aw_correct {
  display: none;
  color: #1abc9c;
}
.deploy-box .step-box .text-list > li.active {
  color: #51a3ff;
  border-bottom: solid 2px #51a3ff;
}
.deploy-box .step-box .text-list > li.active .state-items {
  color: #51a3ff;
}
.deploy-box .step-box .text-list > li.active .state-items .icon-aw_angle-right {
  display: none;
}
.deploy-box .step-box .text-list > li.active .state-items .icon-aw_correct {
  display: block;
  font-size: 20px;
}
.deploy-box .internet-box {
  width: 1000px;
  margin: 0 auto;
}
.deploy-box .internet-box .form-group {
  padding: 0;
  margin: 0;
  float: left;
  min-height: 48px;
}
.deploy-box .internet-box .form-group .control-label {
  width: 90px;
  padding: 0 10px 0 0;
  height: 28px;
  line-height: 28px;
  float: left;
}
.deploy-box .internet-box .form-group .control-item {
  width: 260px;
  float: left;
}
.deploy-box .internet-box .form-group .control-item .help-block {
  font-size: 12px;
  color: #e74c3c;
  margin: 5px 0;
}
.deploy-box .internet-box .row {
  padding-left: 108px;
  min-height: 48px;
}
.deploy-box .internet-box .col-1 {
  width: 100px;
  padding: 0 0 0 15px;
  float: left;
}
.deploy-box .internet-box .col-10 {
  width: 900px;
  padding: 0;
  float: left;
}
.deploy-box .internet-box .add-ip {
  float: left;
  margin-top: 7px;
  margin-left: 30px;
  color: #51a3ff;
  cursor: pointer;
}
.deploy-box .internet-box .del-ip {
  float: left;
  margin-top: 2px;
  margin-left: 50px;
  color: #51a3ff;
  cursor: pointer;
  font-size: 20px;
}
.deploy-box .internet-box .form-horizontal fieldset {
  border-bottom: dashed 1px #e5e5e5;
  margin-bottom: 15px;
}
.deploy-box .internet-box .title {
  border-bottom: dashed 1px #e5e5e5;
  overflow: hidden;
  margin-bottom: 15px;
  padding-bottom: 13px;
}
.deploy-box .internet-box .row-head {
  overflow: hidden;
  border-bottom: dashed 1px #e5e5e5;
  margin-bottom: 10px;
  padding: 0 11px 18px;
}
.deploy-box .internet-box .row-head .radio-inline i {
  left: -2px;
}
.deploy-box .internet-box .font-h {
  height: 28px;
  line-height: 28px;
}
.deploy-box .internet-box .font-h .checkbox {
  margin: 0;
  padding-top: 0;
}
.deploy-box .internet-box .font-h .checkbox label {
  margin-right: 0;
}
.deploy-box .internet-box .font-h .text {
  font-size: 14px;
  text-transform: uppercase;
}
.deploy-box .infor-box {
  width: 1000px;
  margin: 0 auto;
}
.deploy-box .infor-box .pool-size {
  overflow: hidden;
}
.deploy-box .infor-box .pool-size .form-group {
  width: 33.333%;
  float: left;
}
.deploy-box .infor-box .pool-size .form-group .control-label {
  float: left;
  width: 150px;
  text-align: right;
  font-weight: normal;
  padding: 8px 20px 0 0;
  margin: 0;
}
.deploy-box .infor-box .pool-size .form-group .control-item {
  margin-left: 150px;
}
.deploy-box .text-center .btn + .btn {
  margin-left: 8px;
}
.alarmSetting-modal .mult .choice-contact-control {
  position: relative;
  width: 79%;
  float: left;
  padding-left: 25px;
}
.alarmSetting-modal .mult .choice-contact-control > i {
  position: absolute;
  left: 4px;
  line-height: 32px;
}
.alarmSetting-modal .mult .choice-contact-control .question-tip {
  color: #bdbdbd;
  margin-right: 5px;
  font-size: 16px;
}
.alarmSetting-modal .mult .add-contact-control {
  width: 3%;
  float: right;
  line-height: 32px;
  cursor: pointer;
}
.alarmSetting-modal .mult .add-contact-control i {
  float: right;
  color: #51a3ff;
}
.alarmSetting-modal .modal-body .control-group .controls .cpu {
  line-height: 28px;
  overflow: hidden;
}
.alarmSetting-modal .modal-body .control-group .controls .cpu .checkbox {
  float: left;
}
.alarmSetting-modal .modal-body .control-group .controls .cpu > span {
  float: left;
  margin-right: 20px;
}
.alarmSetting-modal .modal-body .control-group .controls .cpu .control-group {
  width: 70%;
  float: left;
  margin-bottom: 0px;
}
.alarmSetting-modal .modal-body .control-group .controls .cpu .control-group .control-label {
  width: 10%;
}
.alarmSetting-modal .modal-body .control-group .controls .cpu .control-group .controls {
  padding-left: 10px;
}
.alarmSetting-modal .modal-body .control-group .controls .cpu .control-group .controls .threshold-box {
  float: left;
  width: 45%;
  margin-right: 20px;
  position: relative;
}
.alarmSetting-modal .modal-body .control-group .controls .cpu .control-group .controls .threshold-box > input {
  width: 100%;
  margin-right: 20px;
}
.alarmSetting-modal .modal-body .control-group .controls .cpu .control-group .controls .threshold-box > i {
  position: absolute;
  top: 0;
  right: 5px;
  font-style: normal;
}
.alarmSetting-modal .modal-body .control-group .controls .cpu .control-group .controls > span {
  float: left;
}
.account-box {
  width: 400px;
  margin: 0 auto;
  color: #666666;
}
.account-box .control-group .control-label {
  width: 25%;
}
.account-box .control-group .controls {
  width: 75%;
}
.progress-box {
  width: 680px;
  margin: 0 auto 30px;
  margin-top: 70px;
  box-shadow: 0 1px 9px #d3e1ec;
}
.progress-box > p {
  margin-bottom: 15px;
  color: #666666;
}
.progress-box > .btn {
  width: 200px;
  height: 42px;
  line-height: 40px;
}
.progress-text {
  color: #ffffff;
  height: 300px;
  background: url(/img/progress-bg.jpg) no-repeat 0 0;
}
.progress-text > li {
  overflow: hidden;
  position: relative;
}
.progress-text > li .checkbox input:checked + i:before {
  color: #c2d3eb;
}
.progress-text > li .warp {
  padding: 90px 50px 0;
}
.progress-text > li .warp.done {
  padding: 40px 170px 0;
  text-align: center;
}
.progress-text > li .warp.done i {
  font-size: 60px;
}
.progress-text > li .checkbox {
  position: absolute;
  left: 42px;
  bottom: 5px;
  margin: 0;
}
.progress-text > li .checkbox .active {
  color: #20de4f;
}
.progress-text > li .checkbox .active input:checked + i:before {
  color: #20de4f;
}
.progress-text .img-box {
  position: relative;
  float: left;
  width: 30%;
  height: 100px;
  margin-bottom: 40px;
}
.progress-text .img-box i:first-child {
  font-size: 100px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.progress-text .img-box i + i {
  font-size: 50px;
  position: absolute;
  left: 50%;
  top: 30%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.progress-text .text-box {
  float: left;
  width: 70%;
}
.progress-text .animation {
  position: relative;
  height: 100%;
  width: 300%;
  -webkit-animation: warning 9s infinite linear;
  -moz-animation: warning 9s infinite linear;
  animation: warning 9s infinite linear;
}
.progress-text .animation li {
  width: 680px;
  height: 300px;
  text-align: center;
  float: left;
}
.progress-text .animation .slide-bg-1 {
  background: url(/img/progress-img.png) no-repeat center;
}
.progress-text .animation .slide-bg-2 {
  background: url(/img/progress-img2.png) no-repeat center;
}
.progress-text .animation .slide-bg-3 {
  background: url(/img/progress-img3.png) no-repeat center;
}
.progress-text p {
  text-align: left;
  color: #c2d3eb;
  margin-bottom: 0;
}
.progress-text p.time {
  font-size: 36px;
  color: #ffffff;
}
@-webkit-keyframes warning {
  0% {
    margin-left: 0;
  }
  23% {
    margin-left: 0;
  }
  33% {
    margin-left: -680px;
  }
  56% {
    margin-left: -680px;
  }
  66% {
    margin-left: -1360px;
  }
  90% {
    margin-left: -1360px;
  }
  99% {
    margin-left: 0;
  }
}
@keyframes warning {
  0% {
    margin-left: 0;
  }
  23% {
    margin-left: 0;
  }
  33% {
    margin-left: -680px;
  }
  56% {
    margin-left: -680px;
  }
  66% {
    margin-left: -1360px;
  }
  90% {
    margin-left: -1360px;
  }
  99% {
    margin-left: 0;
  }
}
.websocket-tips {
  border: solid 1px #c2d3eb;
  border-top: none;
  border-radius: 0 0 5px 5px;
  height: 120px;
  overflow: hidden;
  position: relative;
  padding: 30px 45px;
}
.websocket-tips > ul {
  width: 100%;
  margin-bottom: 10px;
}
.websocket-tips > ul > li {
  line-height: 22px;
}
.websocket-tips .btn {
  height: 42px;
  font-size: 16px;
  line-height: 1;
  padding: 10px 20px;
}
.progress-step {
  position: relative;
  background: #f3f3f3;
  border-radius: 2px;
  width: 620px;
  height: 20px;
  margin: 0 auto;
}
.progress-step .an-to {
  width: 0;
  height: 100%;
}
.progress-step .progress-base {
  transition: all 1s linear 0s;
  height: 100%;
  left: 0;
  top: 0;
  width: 2%;
  background-color: #0387ce;
  border-radius: 4px;
  -webkit-animation: progress-bar-ws 2s linear infinite;
  -o-animation: progress-bar-ws 2s linear infinite;
  animation: progress-bar-ws 2s linear infinite;
  background-image: -webkit-linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 40px 40px;
}
.progress-step .color-0 {
  background-color: #0387ce;
  width: 5%;
}
.progress-step .color-1 {
  background-color: #0597e8;
  width: 15%;
}
.progress-step .color-2 {
  background-color: #2db5f1;
  width: 20%;
  box-shadow: 0 0 2px rgba(192, 57, 43, 0.5);
}
.progress-step .color-3 {
  background-color: #55c5f2;
  width: 30%;
  box-shadow: 0 0 2px rgba(192, 57, 43, 0.5);
}
.progress-step .color-4 {
  background-color: #5ed1ff;
  width: 35%;
  box-shadow: 0 0 2px rgba(192, 57, 43, 0.5);
}
.progress-step .color-5 {
  background-color: #7be8ff;
  width: 40%;
  box-shadow: 0 0 2px rgba(192, 57, 43, 0.5);
}
.progress-step .color-6 {
  background-color: #58edd0;
  width: 50%;
  box-shadow: 0 0 2px rgba(192, 57, 43, 0.5);
}
.progress-step .color-7 {
  background-color: #43e2c3;
  width: 65%;
  box-shadow: 0 0 2px rgba(192, 57, 43, 0.5);
}
.progress-step .color-8 {
  background-color: #33d2b3;
  width: 80%;
  box-shadow: 0 0 2px rgba(192, 57, 43, 0.5);
}
.progress-step .color-9 {
  background-color: #1abd9d;
  width: 100%;
  box-shadow: 0 0 2px rgba(192, 57, 43, 0.5);
}
.no-float {
  float: none !important;
}
.bottom-action {
  text-align: right;
  width: 680px;
  margin: 0 auto;
}
.modal-box {
  width: 685px;
  margin: 0 auto;
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -webkit-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
}
.modal-box.show {
  height: auto;
  opacity: 1;
  padding: 20px 0 60px;
}
.modal-box .close {
  font-size: 16px;
  color: #666666;
}
.playing-animate {
  animation: playing 2s linear infinite;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  height: 24px;
}
.playing-animate .one {
  width: 2px;
  height: 2px;
  display: inline-block;
  border-radius: 50%;
  background: #666;
  vertical-align: middle;
  margin: 0 2px;
}
.cursor {
  cursor: pointer;
}
.playing {
  margin-bottom: 5px;
  display: none;
}
.playing.show {
  display: block;
}
.slide {
  display: none;
}
.slide.show {
  display: block;
}
.ending {
  display: none;
}
.ending.show {
  display: block;
}
.failed {
  margin-bottom: 5px;
  display: none;
}
.failed.show {
  display: block;
}
.checkbox.ha label {
  padding-left: 0;
}
@-webkit-keyframes progress-bar-ws {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 40px 0;
  }
}
@keyframes progress-bar-ws {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 40px 0;
  }
}
@-webkit-keyframes playing {
  0% {
    width: 6px;
  }
  50% {
    width: 12px;
  }
  100% {
    width: 22px;
  }
}
@keyframes playing {
  0% {
    width: 6px;
  }
  50% {
    width: 12px;
  }
  100% {
    width: 22px;
  }
}
.text-wfname {
  line-height: 28px;
}
.ticket-detail .detail-title .tab-content-line {
  clear: both;
  height: 14px;
  margin-bottom: 0px;
}
.workflowStatus {
  padding: 0 20px 30px 20px;
  background: #ffffff;
}
.workflowStatus .workflowStatus {
  padding-left: 0px;
}
.workflowStatus .detail-title {
  padding: 10px 0 15px;
}
.workflowStatus .detail-info {
  padding-left: 0px;
  padding-bottom: 0px;
}
.workflowStatus .table.table-info tbody tr td {
  width: 25% !important;
}
.workflowStatus .back {
  height: 50px;
  display: flex;
  align-items: center;
}
.workflowStatus .back .an-close {
  border: 1px solid #51a3ff;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 40px;
  margin-right: 15px;
}
.workflowStatus .boxw {
  padding-bottom: 15px;
  border-bottom: solid 1px #c2d3eb;
  margin-bottom: 15px;
}
.workflowStatus .assiginTiltle {
  line-height: 20px;
}
.workflowStatus .assiginTiltle span {
  font-size: 14px;
  display: inline-block;
  margin-right: 30px;
  color: #999999;
}
.workflowStatus .assiginTiltle span.info {
  color: #666666;
}
.workflowStatus .assiginTiltle span.status {
  color: white;
}
.workflowStatus .assiginTiltle p {
  margin-top: 10px;
}
.workflowStatus .assiginTiltle p span {
  margin-right: 0px;
}
.workflowStatus .assiginResource {
  line-height: 20px;
  padding-bottom: 20px;
}
.workflowStatus .assiginResource span {
  color: #9d9ea0;
  font-size: 14px;
  display: inline-block;
  margin-right: 30px;
}
.workflowStatus .discussBox {
  width: 100%;
  padding-right: 20px;
}
.workflowStatus h4 {
  line-height: 50px;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 10px;
}
.workflowStatus h4.resInfo-title {
  border-bottom: 0;
}
.workflowStatus dl {
  height: 100%;
}
.workflowStatus dl .dt_ty {
  float: left;
  height: 100%;
  width: 70px;
  padding: 0;
}
.workflowStatus dl .dt_ty i {
  display: inline-block;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 3px solid #f4f5f7;
  color: #5fa0f6;
  text-align: center;
  line-height: 46px;
  font-size: 26px;
}
.workflowStatus dl .dd_ty {
  margin-left: 65px;
  height: 100%;
  border-bottom: 1px dashed #e5e5e5;
  line-height: 25px;
}
.workflowStatus dl .dd_ty .Handlename {
  height: 50px;
  line-height: 50px;
}
.workflowStatus dl .dd_ty .Handlename .name {
  font-size: 18px;
  display: inline-block;
  padding-right: 20px;
}
.workflowStatus dl .dd_ty .Handlename .time {
  color: #9d9ea0;
  font-size: 12px;
}
.workflowStatus .replyForm {
  display: block;
  margin-top: 30px;
}
.workflowStatus .replyForm .action-btn-box {
  background: #e74c3c;
}
.workflowStatus .replyForm .btn {
  width: 100px;
  margin-left: 6px;
  float: right;
}
.workflowStatus .control-label {
  width: 5%;
  text-align: left;
}
.workflowStatus .controls {
  line-height: 32px;
  font-size: 100%;
  width: 100%;
  height: 130px;
  padding-left: 0px;
}
.workflowStatus .controls textarea.form-controls {
  height: 130px;
}
.workflowStatus .controls textarea.nowInput {
  background-color: #999;
  cursor: not-allowed;
}
.workflowStatus .controls i {
  display: inline-block;
  padding-right: 10px;
}
.workflowStatus .refuseColor {
  color: #e74c3c;
}
.workflowStatus .approvalColor {
  color: #51a3ff;
}
.workflowStatus table {
  background: #fff;
}
.workflowStatus table thead {
  background: #fafafa;
}
.workflowStatus table thead tr td {
  padding: 12px;
  font-weight: 600;
  font-szie: 20px;
  box-sizing: border-box;
}
.workflowStatus table tbody tr td {
  padding: 12px;
  border-right: 1px solid #e5e5e5 !important;
  box-sizing: border-box;
}
.workflowStatus table tbody tr td:nth-child(4) {
  border-right: none !important;
}
.space-item {
  white-space: pre-wrap;
}
.alert .del-cont .configure {
  text-align: left;
  display: block;
}
.alert .del-cont .configure {
  text-align: left;
  display: block;
}
.nomessage {
  padding: 12px 0;
}
.displayIndex .area-chart-title {
  float: left;
}
.displayIndex .control-group {
  float: right;
  width: 260px;
}
.displayIndex .control-group .control-label {
  width: 80px;
}
.displayIndex .control-group .controls {
  width: 180px;
}
.storage_tb ul li {
  width: 50%;
}
.download-soft {
  text-align: center;
  position: relative;
}
.download-soft .alert {
  text-align: center !important;
  border: 1px solid #cdcdcd;
  font-size: 12px;
  background: #f6f9ff;
  color: #7ba0f8;
}
.download-soft .alert span {
  cursor: pointer;
  color: #e67e22;
}
.download-soft .download-patch {
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 50px;
  background: #ffffff;
  border: 1px solid #cdcdcd;
  padding: 35px 10px;
  width: 100%;
  z-index: 10;
}
.download-soft .download-patch .item {
  float: left;
  width: 50%;
}
.download-soft .download-patch .item p {
  margin-bottom: 15px;
  color: #4a6583;
}
.download-soft .download-patch .item .btn {
  min-width: 95px;
}
/*带宽限速*/
.limitBand {
  margin: 0px auto 30px;
  width: 1022px;
  padding: 40px 0 30px;
}
.limitBand .limit-label {
  float: left;
  width: 10%;
  margin-bottom: 0;
  line-height: 32px;
  text-align: right;
  font-weight: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}
.limitBand .switch-btn {
  float: left;
}
.limitBand .tips-checkbox {
  color: #f39c12;
  line-height: 32px;
  height: 32px;
  display: inline-block;
  float: left;
  margin-left: 30px;
}
.limitBand .tips-checkbox i {
  font-size: 20px;
  margin-right: 3px;
}
.limitBand .settings-box .limit-srv {
  width: 500px;
  margin: 0 auto;
}
.limitBand .settings-box .limit-srv .control-label {
  width: 150px;
}
.limitBand .settings-box .limit-srv .controls {
  width: 350px;
}
.limitBand .settings-box .limit-srv .fixed-item {
  width: 501px;
}
.limitBand .settings-box .limit-srv .fixed-item .controls {
  width: 400px;
}
.limitBand .settings-box .limit-srv .custom-item {
  width: 100%;
  margin-top: 0px;
}
.limitBand .settings-box .limit-srv .custom-item .controls {
  width: 351px;
}
.limitBand .settings-box .limit-srv .custom-item .controls .tips-btn {
  display: inline-block;
  line-height: 32px;
  margin-left: 10px;
  color: #cdcdcd;
}
.limitBand .settings-box .limit-srv .custom-item .controls .input-group {
  width: 154px;
  float: left;
}
.limitBand .settings-box .limit-srv .custom-item .controls .input-group .form-control {
  width: 100px;
}
.limitBand .settings-box .limit-srv .custom-item .controls .input-group .link-item {
  min-width: 6px;
}
.limitBand .settings-box .limit-srv .custom-item .controls .input-group .unit {
  display: inline-block;
  margin-left: 10px;
  float: left;
  line-height: 32px;
}
.limitBand .settings-box .limit-srv .custom-item .error {
  color: #e74c3c;
  padding-left: 160px;
}
.update-check {
  position: fixed;
  z-index: 1040;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.update-check .backup-bg {
  background: #333;
  opacity: 0.5;
  width: 100%;
  height: 100%;
}
.update-check .update-box {
  width: 600px;
  border-radius: 5px;
  position: absolute;
  left: 50%;
  margin-left: -300px;
  top: 50%;
  margin-top: -200px;
  background: #ffffff;
  padding: 85px 0;
}
.update-check .update-box p {
  margin: 0 auto 10px;
  width: 280px;
  height: 200px;
  border-radius: 3px;
  border: 6px solid #edf2fe;
  background: url(/img/grade.png) no-repeat center;
}
.update-check .update-box span {
  display: block;
  text-align: center;
}
.update-list > li {
  border-bottom: 1px solid #e5e5e5;
  padding: 18px 0;
  overflow: hidden;
  display: table;
  position: relative;
  width: 100%;
}
.update-list > li.active i {
  color: #51a3ff;
}
.update-list > li.active .icon-box span {
  border-color: #51a3ff;
}
.update-list > li.success i {
  color: #16a085;
}
.update-list > li.success .icon-box span {
  border-color: #16a085;
}
.update-list > li > div {
  display: table-cell;
  text-align: center;
  min-height: 50px;
  vertical-align: middle;
}
.update-list > li .status {
  width: 50px;
}
.update-list > li .icon-box {
  width: 52px;
}
.update-list > li .icon-box span {
  display: block;
  border-radius: 25px;
  border: 1px solid #e5e5e5;
  color: #999999;
  height: 50px;
  width: 50px;
  line-height: 50px;
  font-size: 24px;
}
.update-list > li .update-detail-box {
  width: 550px;
  text-align: left;
  padding: 0 25px;
}
.update-list > li .detail-message-more {
  overflow: hidden;
}
.update-list > li .version-info {
  width: 210px;
}
.update-list > li .version-info span {
  display: block;
  line-height: 24px;
}
.update-list > li .version-info span b {
  padding-left: 5px;
}
.update-list > li .action {
  width: 98px;
}
.update-list > li .action .success-text {
  display: none;
}
.update-list > li .action .btn {
  display: none;
  margin: 0 auto;
}
.update-list > li .progress-present {
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  transition: all 0.3s linear;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  background: linear-gradient(to left, rgba(207, 235, 255, 0.7), rgba(255, 255, 255, 0) 30%);
}
.update-list > li .success-text {
  color: #16a085;
}
.update-list .messages {
  width: 430px;
  float: left;
}
.update-list .messages li {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.update-list .blue {
  float: right;
  cursor: pointer;
  position: relative;
  z-index: 101;
}
/* 系统升级 */
.updatepack-modal {
  width: 1000px !important;
}
.updatepack-modal .modal-header {
  margin-bottom: 0 !important;
  height: auto !important;
}
.updatepack-modal .alert {
  margin: 28px 0 0;
  text-align: center;
}
.updatepack-modal .no-patch {
  padding-top: 20px;
}
.updatepack-modal .title-num {
  display: block;
  margin-top: 10px;
}
.updatepack-modal .title-num b {
  padding: 0 3px;
}
.updatepack-modal .update-detail-info {
  display: none;
  margin: 0 -20px;
}
.updatepack-modal .update-detail-info-head {
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid #c2d3eb;
  padding: 15px 0 15px 40px;
}
.updatepack-modal .update-detail-info-head .img-box {
  border-radius: 25px;
  border: 1px solid #e5e5e5;
  color: #999999;
  height: 50px;
  width: 50px;
  line-height: 50px;
  font-size: 24px;
  float: left;
  text-align: center;
  margin-top: 8px;
}
.updatepack-modal .update-detail-info-head .text-box {
  float: left;
  margin-left: 32px;
}
.updatepack-modal .update-detail-info-head .text-box h5 {
  margin-bottom: 5px;
}
.updatepack-modal .update-detail-info-head .text-box p {
  font-size: 12px;
  line-height: 24px;
}
.updatepack-modal .update-detail-info-head .text-box p b {
  padding-left: 4px;
  color: #999999;
}
.updatepack-modal .update-detail-info-head .text-box p .green {
  color: #1abc9c;
}
.updatepack-modal .update-detail-info-head .text-box p span {
  padding-right: 10px;
}
.updatepack-modal .update-detail-info-head .close {
  font-size: 30px;
  position: absolute;
  right: 12px;
  top: 5px;
  font-weight: normal;
  line-height: 1;
  color: #999999;
  opacity: 1;
}
.updatepack-modal .update-detail-info-body {
  position: relative;
  padding: 30px;
  max-height: 400px;
  overflow-y: auto;
  background: #fafafa;
}
.updatepack-modal .update-detail-info-body > span {
  position: absolute;
  top: 30px;
  left: 40px;
}
.updatepack-modal .update-detail-info-body > ul {
  padding-left: 90px;
}
.updatepack-modal .update-detail-info-body > ul li {
  margin-bottom: 10px;
}
.login-timeout {
  display: none;
  position: fixed;
  z-index: 2001;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.login-timeout .backup-bg {
  background: #333;
  opacity: 0.5;
  width: 100%;
  height: 100%;
}
.login-timeout .text {
  background: #ffffff;
  width: 550px;
  padding: 40px 0;
  position: absolute;
  left: 50%;
  margin-left: -250px;
  top: 50%;
  margin-top: -50px;
  text-align: center;
  border-radius: 5px;
}
/*总资源超限*/
.resource-total {
  height: 100%;
}
.resource-total dt {
  float: left;
  height: 100%;
  width: 17%;
}
.resource-total dt i {
  line-height: 70px;
  font-size: 70px;
}
.resource-total dd {
  float: left;
  height: 100%;
  width: 83%;
  text-align: left;
  color: #535353;
}
.resource-total dd span {
  color: #f39c12;
}
.logined .alert-p.alarm-show {
  display: block;
}
.alert-p {
  display: none;
  width: 300px;
  margin-top: 48px;
  z-index: 1060;
  right: 10px;
  position: fixed;
  bottom: 0;
}
.alert-p .alert-closeres {
  position: absolute;
  right: 5px;
  top: 5px;
  font-weight: normal;
  opacity: 0.5;
  filter: alpha(opacity=50);
  text-shadow: 0 0px 0 #ffffff;
  background: none;
  border: none;
  padding: 0;
}
.alert-p .alertres {
  padding: 6px 15px 6px 6px;
  border-radius: 2px;
  background: none;
  margin-bottom: 15px;
  background-color: #f6cfc8;
  position: relative;
}
.warningSet {
  position: absolute;
  top: 58px;
  left: 30px;
  right: 20px;
  height: 36px;
  z-index: 999;
  background: #f2dede;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: #eda29b;
  border-radius: 2px;
  box-shadow: none;
  padding-left: 50px;
  line-height: 36px;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
}
.warningSet .tips {
  color: #c0392b;
  cursor: pointer;
}
.warningSet .setting {
  color: #51a3ff;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}
.logined .initSettingEnter .initSettingShow {
  display: block;
}
.initSettingEnter {
  margin: 0 30px;
  padding-top: 1px;
}
.initSettingEnter .initSettingDiv {
  display: none;
}
.initSettingEnter .initSettingAlert .tips {
  cursor: pointer;
}
.initSettingEnter .initSettingAlert .setting {
  color: #51a3ff;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}
.initSettingEnter .alert {
  min-height: 37px;
  margin: 10px 0;
}
.controls .ui-select-bootstrap .ui-select-choices-row.active > a {
  background-color: #51a3ff;
}
.ui-select-tips .ui-select-choices .ui-select-choices-row > a {
  min-height: 32px;
  line-height: 32px;
}
.ui-select-tips .ui-select-choices .ui-select-choices-row > a:hover {
  color: #4e80f5;
  background: #f6f9ff;
}
.ui-select-tips .ui-select-choices .ui-select-choices-row > a:hover .ui-select-tips-detail {
  display: block;
}
.ui-select-tips .ui-select-choices .ui-select-choices-row > a .popover {
  z-index: 99999;
}
.ui-select-tips .ui-select-choices .ui-select-choices-row.active a:hover {
  color: #4e80f5;
  background: #f6f9ff;
}
.ui-select-tips .ui-select-choices .ui-select-choices-row .ui-select-tips-detail {
  color: #c2d3eb;
  display: none;
  position: relative;
  border: 1px solid #c2d3eb;
  margin-bottom: 10px;
  border-radius: 4px;
}
.ui-select-tips .ui-select-choices .ui-select-choices-row .ui-select-tips-detail .arrow-up {
  position: absolute;
  left: 35px;
  top: -10px;
  border: 5px solid transparent;
  border-bottom: 5px solid #c2d3eb;
}
.ui-select-tips .ui-select-choices .ui-select-choices-row .ui-select-tips-detail .arrow-up:after {
  content: "";
  overflow: hidden;
  position: absolute;
  border: 4px solid transparent;
  border-bottom: 4px solid #f6f9ff;
  left: -4px;
  top: -2px;
}
.ui-select-tips .ui-select-choices .ui-select-choices-row .ui-select-tips-detail ul > li {
  padding: 0 20px;
  font-size: 12px;
  color: #7991ab;
  line-height: 20px;
}
.ui-select-tips .ui-select-choices .ui-select-choices-row .ui-select-tips-detail span {
  white-space: pre-line;
  padding: 5px 10px;
  display: inline-block;
  color: #51a3ff;
  font-size: 12px;
  line-height: 18px;
}
.cloud-box {
  margin: 0 auto;
  width: 1022px;
}
.cloud-box .page-inner {
  overflow-x: visible;
  min-height: 746px;
}
.cloud-box .settings-box .controls.group {
  text-align: center;
}
.cloud-box .settings-box .controls .switch-btn {
  float: left;
}
.cloud-box .settings-box .controls .chk-tip {
  display: inline-block;
  height: 34px;
  line-height: 34px;
}
.cloud-box .settings-box .controls .chk-tip .succ {
  color: #1abc9c;
}
.cloud-box .settings-box .controls .chk-tip .fail {
  color: #e74c3c;
}
.cloud-box .settings-box .controls .chk-btn {
  display: inline-block;
  float: right;
  line-height: 34px;
}
.grade-tip {
  cursor: pointer;
}
.question-tip {
  color: #bdbdbd;
  margin-right: 5px;
  font-size: 16px;
}
.new-windows {
  height: 100%;
}
.new-windows dt {
  float: left;
  height: 100%;
  width: 17%;
}
.new-windows dt i {
  line-height: 40px;
  font-size: 40px;
  color: #1abc9c;
}
.new-windows dd {
  float: left;
  height: 100%;
  width: 83%;
  font-size: 14px;
  text-align: left;
  color: #535353;
}
.new-windows dd label {
  float: left;
  margin-top: 10px;
}
.permit-overview .permit.box {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 15px;
}
.permit-overview .permit.box div[class^="col-"] {
  border: 0 !important;
  min-height: 100px;
}
.permit-overview .permit.box div[class^="col-"] a {
  display: block;
}
.permit-overview .permit.box div[class^="col-"] .user-box .cluster-item {
  width: 96%;
  padding: 20px 30px;
  margin-right: 20px;
  margin-top: 0;
  border: solid 1px #e5e5e5;
}
.permit-overview .permit.box div[class^="col-"] .user-box .cluster-item:hover {
  border: solid 1px #51a3ff;
}
.permit-overview .permit.box div[class^="col-"] .graff-permit {
  width: 98%;
  padding: 20px 30px;
  margin-right: 20px;
  border: solid 1px #e5e5e5;
}
.permit-overview .permit.box .left-b {
  width: 50%;
  float: left;
}
.permit-overview .permit.box .right-b {
  width: 50%;
  float: left;
}
.permit-overview .permit.box .right-b .user-box .text-list li:last-child {
  border-right: 0 none;
}
.permit-overview .permit.box .user-box .text-list {
  padding: 5px 0;
}
.permit-overview .permit.box .user-box .text-list > li {
  height: 160px;
  line-height: 160px;
  padding: 10px 0;
  border-right: solid 1px #c2d3eb;
}
.permit-overview .permit.box .user-box .text-list > li .small {
  position: relative;
}
.permit-overview .permit.box .user-box .text-list > li .small em {
  line-height: 1;
  padding: 0 5px;
  position: absolute;
  top: 70%;
  left: 0;
}
.permit-overview .box .title {
  font-weight: bold;
}
.permit-overview .box .title .vertical li {
  font-size: 14px;
  font-weight: normal;
  padding: 0 10px;
  line-height: 1;
}
.permit-overview .box .title .tip {
  font-size: 14px;
  color: #999999;
  font-weight: normal;
}
.permit-overview .no-border-box {
  border-bottom: none !important;
}
.role-box .page-inner {
  padding: 0;
}
.role-box .page-inner .table-content {
  padding: 0;
}
.role-page {
  margin-top: 0;
  padding-top: 30px;
}
.choose-all {
  margin-bottom: 8px;
}
.choose-all span {
  float: right;
}
.menu-tree-level1 {
  border-top: 1px solid #e5e5e5;
  margin-bottom: 40px;
}
.menu-tree-level1 .level1-checkbox {
  background: #fafafa;
  border-bottom: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
  padding: 8px 10px !important;
}
.menu-tree-level1 > li {
  width: 100%;
}
.menu-tree-level1 .menu-tree-level2 {
  border-right: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
  overflow: hidden;
  width: 100%;
  padding-left: 42px;
  display: none;
  border-bottom: 1px solid #e5e5e5;
}
.menu-tree-level1 .menu-tree-level2.show {
  display: block;
}
.menu-tree-level1 .menu-tree-level2 .level2-checkbox {
  width: 105px;
  float: left;
  margin: 8px 10px 8px 0;
  padding: 0px;
  border-right: 1px solid #e5e5e5;
}
.menu-tree-level1 .menu-tree-level2 .level2-checkbox span {
  color: #666666;
  font-weight: bolder;
}
.menu-tree-level1 .menu-tree-level2 > li {
  width: 100%;
  overflow: hidden;
  border-bottom: 1px dashed #e5e5e5;
}
.menu-tree-level1 .menu-tree-level2 > li:last-child {
  border-bottom: 0 none;
}
.menu-tree-level1 .menu-tree-level3 {
  width: 87%;
  display: none;
  overflow: hidden;
}
.menu-tree-level1 .menu-tree-level3.show {
  display: block;
}
.menu-tree-level1 .menu-tree-level3 .level3-checkbox {
  margin: 8px 10px 8px 0;
  padding: 0px;
}
.menu-tree-level1 .menu-tree-level3 .level3-checkbox span {
  color: #666666;
}
.menu-tree-level1 .menu-tree-level3 > li {
  min-width: 100px;
  float: left;
}
.menu-tree-level1 .menu-tree-level3 > li:last-child {
  border-bottom: 0 none;
}
.menu-tree-level1 .checkbox {
  padding: 10px;
  height: auto;
  display: block;
}
.menu-tree-level1 .checkbox label {
  float: left;
}
.menu-tree-level1 .checkbox span {
  line-height: 20px;
  float: left;
  color: #51a3ff;
}
.menu-tree-level1 .cursor {
  cursor: pointer;
}
/*云主机中查看规则详情样式控制*/
.modal-dialog .modal-content .modal-common.ruledetail-modal .ng-table-pager {
  position: initial;
}
/*微信告警*/
.weChatAlarm-box {
  margin: 30px auto 20px;
  border: 1px solid #c3d3ec;
  padding: 0px 0 30px;
}
.weChatAlarm-box .alert {
  margin: 20px;
  text-align: center;
}
.weChatAlarm-box .alert .alert-content {
  line-height: 18px;
}
.weChatAlarm-box .alert .alert-content .alert-text {
  line-height: 16px;
}
.weChatAlarm-box .control-group .control-label {
  width: 20%;
}
.weChatAlarm-box .control-group .control-label .weChat-tips {
  color: #51a3ff;
  margin-right: 5px;
  cursor: pointer;
}
.weChatAlarm-box .control-group .control-label .notes {
  font-size: 12px;
  color: #a1a1a1;
  margin-top: -15px;
  margin-right: 14px;
}
.weChatAlarm-box .control-group .controls {
  width: 80%;
}
.weChatAlarm-box .control-group .controls .QRCodeText {
  color: #51a3ff;
  cursor: pointer;
  line-height: 32px;
}
.weChatAlarm-box .weChat-srv {
  width: 600px;
  margin: 0 auto;
}
.weChatAlarm-box .weChat-srv .weChatDesc {
  margin-bottom: 15px;
}
.weChatAlarm-box .weChat-srv .weChatDesc p {
  font-size: 14px;
  color: #999999;
  line-height: 28px;
}
.QRCode-box {
  top: 40% !important;
  width: 300px !important;
  height: 300px;
  line-height: 300px;
}
.QRCode-box .modal-header {
  margin: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  height: auto !important;
}
.QRCode-box .modal-header .close {
  top: 5px !important;
  right: -7px !important;
}
.QRCode-box .QRCodeImg {
  text-align: center;
}
/*镜像库管理*/
.imageManage-box {
  margin: 10px auto;
  border: 1px solid #c3d3ec;
  padding: 60px 0 40px;
}
.imageManage-box .imageManage-srv {
  width: 600px;
  margin: 0 auto;
}
.imageManage-box .imageManage-srv .progess-bar {
  margin-top: 10px;
  overflow: hidden;
}
.imageManage-box .imageManage-srv .progess-bar .failText {
  color: #ff9900;
}
.imageManage-box .imageManage-srv .progess-bar .progress-step {
  margin-top: 25px;
  background: #f3f3f3;
  border-radius: 2px;
  width: auto;
  height: 15px;
}
.imageManage-box .imageManage-srv .progess-bar .progress-step .progress-base {
  background-color: #51a3ff;
  box-shadow: 0 0 2px rgba(192, 57, 43, 0.5);
}
.imageManage-box .imageManage-srv .progess-bar .progress-step .progress-base.pregress-100 {
  background-image: none;
}
.imageManage-box .imageManage-srv .progess-bar .progress-step .progress-base.fail-color {
  background-color: #ff9900;
}
.box .table-action.transferImage-info {
  margin-right: 15px;
}
/*主机集合设置元数据*/
.modal .modal-dialog .modal-common .modal-body.metdata-body .alert {
  text-align: left;
}
.modal .modal-dialog .modal-common .modal-body.metdata-body .control-group .control-label {
  width: 15%;
}
.modal .modal-dialog .modal-common .modal-body.metdata-body .control-group .controls {
  width: 85%;
}
.modal .modal-dialog .modal-common .modal-body.manageHost-body .alert {
  text-align: left;
}
/*网络管理*/
.netManage-box {
  width: 1000px;
  margin: 30px auto 20px;
  border: 1px solid #c2d3eb;
  padding: 40px 25px 60px;
}
.netManage-box .physicalNet-box {
  padding: 25px 65px;
}
.netManage-box .physicalNet-box:not(:last-child) {
  border-bottom: 1px dashed #c2d3eb;
}
.netManage-box .physicalNet-box > h4 {
  font-weight: bold;
  margin-bottom: 15px;
}
.netManage-box .physicalNet-box .control-group .control-label {
  width: 15%;
}
.netManage-box .physicalNet-box .control-group .controls {
  width: 70%;
}
.netManage-box .physicalNet-box .control-group .controls .netName {
  line-height: 32px;
}
.netManage-box .physicalNet-box .control-group .add {
  width: 2%;
  float: left;
  line-height: 32px;
  margin-left: 12px;
}
.netManage-box .physicalNet-box .control-group .add a {
  color: #e74c3c;
}
.netManage-box .physicalNet-box .control-group .add a i {
  margin-right: 5px;
}
.netManage-box .physicalNet-box .control-group .add.inuse a {
  cursor: not-allowed;
  color: #cdcdcd;
}
.netManage-box .physicalNet-box .addExternal {
  margin-left: 50px;
}
.netManage-box .physicalNet-box .addExternal.inuse a {
  cursor: not-allowed;
  color: #cdcdcd;
}
.netManage-box .physicalNet-box .addExternal a .icon-aw-add-to2 {
  padding-right: 5px;
}
/*常规设置*/
.general-box {
  margin: 0 auto;
  width: 1022px;
}
.general-box .settings-title {
  font-size: 16px;
  font-weight: bold;
  color: #666666;
}
.general-box .settings-box {
  margin-top: 5px;
}
.general-box .settings-box .generalDesc p {
  color: #999;
  margin-top: 10px;
  line-height: 25px;
}
.general-box .settings-box .control-group:last-child {
  margin-top: 10px;
}
.general-box .settings-box .control-choose .control-label {
  width: 20%;
}
.general-box .settings-box .ups-choose {
  border: 0;
  padding: 0;
}
.general-box .settings-box .ups-choose .ups-item {
  float: left;
  margin-right: 8px;
}
.general-box .settings-box .table-action {
  margin-bottom: 0;
}
.general-box .settings-box .table-action .btn {
  font-size: 14px;
  min-width: 32px;
  padding: 0;
  color: #51a3ff;
}
.general-box .settings-box .table-action .btn:active {
  background: #51a3ff;
  border: 1px solid #4e80f5;
  color: #ffffff;
}
.general-box .settings-box .table-action .form-controls {
  float: left;
  width: 120px;
  margin-right: 8px;
}
.general-box .settings-box .error {
  margin-left: 50px;
}
.general-box .settings-box .loadingText {
  line-height: 32px;
}
.general-box .settings-box .tipText {
  line-height: 32px;
  color: #e74c3c;
}
.general-box .settings-box .rangeText {
  margin-right: 10px;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  color: #999999;
}
/*存储管理*/
.modal .modal-dialog .modal-common.createStorage-modal {
  width: 930px;
}
.modal .modal-dialog .modal-common.createStorage-modal .drag-disk-footer {
  margin-top: 20px;
  margin-bottom: -30px;
}
.storagement-box .global-loading .loadingTip {
  width: 100%;
  font-size: 16px;
  padding: 20px;
  width: 560px;
  margin-left: -280px;
  background: #f6f9ff;
  color: #3b78e7;
  border: 1px solid #3b78e7;
}
.storagement-box .backupMaxUsed {
  color: #ff9900;
  font-weight: 400;
  font-style: normal;
}
.initSetting-modal .modal-body .control-group .controls .checkbox,
.storage-monitor-common .modal-body .control-group .controls .checkbox {
  float: left;
}
.initSetting-modal .modal-body .control-group .controls .controls-url,
.storage-monitor-common .modal-body .control-group .controls .controls-url {
  width: 85%;
}
.initSetting-modal .modal-body .control-group .controls .check-url,
.storage-monitor-common .modal-body .control-group .controls .check-url {
  float: right;
  line-height: 28px;
  color: #51a3ff;
  cursor: pointer;
}
.initSetting-modal .modal-body .control-group .controls .check-url i,
.storage-monitor-common .modal-body .control-group .controls .check-url i {
  font-size: 18px;
  margin-right: 2px;
  color: #199ed8;
}
.initSetting-modal .modal-body .control-group .controls .delete-url,
.storage-monitor-common .modal-body .control-group .controls .delete-url {
  float: right;
  line-height: 28px;
  margin-left: 4px;
  cursor: pointer;
  color: #f39c12;
}
.initSetting-modal .modal-body .control-group .controls .ceph-tips,
.storage-monitor-common .modal-body .control-group .controls .ceph-tips {
  display: inline-block;
  color: #e67e22;
  font-size: 12px;
  line-height: 24px;
  padding-top: 4px;
  margin-left: 15px;
}
.initSetting-modal .modal-body .control-group .controls .monitor-tips,
.storage-monitor-common .modal-body .control-group .controls .monitor-tips {
  color: #999;
  font-size: 12px;
  line-height: 24px;
}
.initSetting-modal .modal-body .control-group .controls .monitor-tips .result-success,
.storage-monitor-common .modal-body .control-group .controls .monitor-tips .result-success {
  margin-left: 20px;
  color: #1abc9c;
}
.initSetting-modal .modal-body .control-group .controls .monitor-tips .result-fail,
.storage-monitor-common .modal-body .control-group .controls .monitor-tips .result-fail {
  margin-left: 20px;
  color: #f00;
}
.storage-table .table tbody tr td .edit-name .storage-name {
  float: left;
}
.storage-table .table tbody tr td .edit-name .storage-name.limit-w {
  width: 60px;
}
.storage-table .table tbody tr td .edit-name .outCeph-btn {
  float: right;
  color: #999;
  font-size: 12px;
}
.storage-table .table tbody tr td .edit-name .outCeph-btn i {
  color: #2fa6dc;
  font-size: 16px;
  margin-right: 2px;
  cursor: pointer;
}
.ceph-modal .control-items2 .control-group {
  width: 100%;
}
.storage-modal {
  /*存储管理磁盘配置*/
  /*存储管理下分页样式*/
}
.storage-modal .cephVersion {
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  color: #a1a1a1;
}
.storage-modal .ratio-group .controls {
  width: 60%;
}
.storage-modal .ratio-group .ratio-label {
  float: left;
  line-height: 32px;
  margin-left: 15px;
}
.storage-modal .ksnode-pass {
  position: relative;
}
.storage-modal .ksnode-pass i {
  position: absolute;
  left: auto;
  right: 5px;
  top: 2px;
  font-size: 20px;
  cursor: pointer;
}
.storage-modal .step-title {
  margin-top: 10px;
  margin-bottom: 24px;
  margin-left: 30px;
  margin-right: 30px;
}
.storage-modal .step-title .inner span {
  font-size: 14px;
}
.storage-modal .step-title .storageStep {
  width: 50%;
}
.storage-modal .stepThree-title {
  margin-right: 50px;
}
.storage-modal .iscsiServer-group .ip-controls {
  width: 45%;
}
.storage-modal .iscsiServer-group .port-label {
  width: 15%;
}
.storage-modal .iscsiServer-group .port-controls {
  width: 23%;
}
.storage-modal .grant-group {
  padding-bottom: 7px;
  margin-bottom: 4px;
  border-bottom: 1px solid #e5e5e5;
}
.storage-modal .grant-group .controls i {
  cursor: pointer;
  position: relative;
  line-height: 1;
}
.storage-modal .grant-group .controls i span {
  color: #51a3ff;
  font-style: normal;
}
.storage-modal .controls-text {
  padding-left: 10px;
}
.storage-modal .target-table .target-name {
  width: 80px;
}
.storage-modal .target-table .control-group {
  margin-bottom: 0;
  width: 350px;
}
.storage-modal .target-table .control-group .controls {
  width: 90%;
  padding-left: 0;
}
.storage-modal .target-table .control-group .radio-item {
  padding-top: 0;
}
.storage-modal .target-table .control-group .iqn-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.storage-modal .target-table .device-group {
  width: 515px;
}
.storage-modal .target-table .first-group {
  margin-bottom: 10px;
}
.storage-modal .target-table .radioTarget {
  height: auto !important;
  padding-top: 0 !important;
}
.storage-modal .target-table .radioTarget label {
  border-top: 1px dashed #e5e5e5;
  min-height: 40px !important;
  line-height: 40px !important;
  display: block !important;
}
.storage-modal .target-table .radioTarget label + label {
  margin-left: 0 !important;
}
.storage-modal .target-table .iqn-icon {
  float: right;
  cursor: pointer;
  margin-top: 1px;
}
.storage-modal .target-table .noData {
  text-align: center;
  color: #999999;
}
.storage-modal .tool-tips {
  color: #e74c3c;
  text-align: center;
  margin-bottom: 10px;
}
.storage-modal .tool-tips.tool-success {
  color: #1abc9c;
}
.storage-modal .nodeName-box {
  overflow: hidden;
  position: relative;
  margin-bottom: 15px;
}
.storage-modal .nodeName-box .congfigMsg {
  float: left;
}
.storage-modal .nodeName-box .config-node {
  float: left;
  width: 86%;
  font-size: 12px;
  max-height: 53px;
  overflow-y: auto;
}
.storage-modal .nodeName-box .config-node .node {
  float: left;
  padding: 3px 0px;
  margin: 0px 14px 5px 0;
  border: 1px solid #c2d3eb;
  text-align: center;
  width: 15%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 24px;
}
.storage-modal .host-box .disks-list {
  background: none;
  border-top: 1px solid #c2d3eb;
  border-left: none;
  border-right: none;
  border-bottom: none;
  padding-top: 20px;
  margin-bottom: 0px;
}
.storage-modal .host-box .disks-list .available-disk {
  float: left;
  width: 10%;
  font-weight: bold;
  line-height: 105px;
}
.storage-modal .host-box .disks-list .no-use-disks {
  min-height: 105px;
  max-height: 210px;
  margin-left: 10%;
  border: 1px dashed #c2d3eb;
}
.storage-modal .host-box .disks-list .no-use-disks li {
  width: 15%;
}
.storage-modal .host-box .disks-list .no-use-disks li .disks-item {
  height: 79px;
  width: 100%;
  border: 1px solid #c2d3eb;
}
.storage-modal .host-box .disks-list .no-use-disks li .disks-item .disk-hhd-bg,
.storage-modal .host-box .disks-list .no-use-disks li .disks-item .disk-ssd-bg {
  height: 45px;
  padding-top: 15px;
}
.storage-modal .host-box .disks-list .no-use-disks li .disks-item .disk-hhd-bg i,
.storage-modal .host-box .disks-list .no-use-disks li .disks-item .disk-ssd-bg i {
  margin-top: 0px;
}
.storage-modal .host-box .disks-list .no-use-disks li .disks-item .disk-out {
  position: relative;
}
.storage-modal .host-box .disks-list .no-use-disks li .disks-item .disk-out .disk-circle {
  position: absolute;
  width: 13px;
  height: 13px;
  top: 4px;
  left: 4px;
  border-radius: 50%;
  background: #f5becc;
}
.storage-modal .host-box .disks-list .no-use-disks li .disks-item .disk-out .disk-capacity {
  width: 69px;
  padding-top: 3px;
  font-size: 14px;
  display: block;
  margin: 8px auto 0;
  border-top: 1px solid #c3cfdb;
}
.storage-modal .host-box .disks-list .no-use-disks li:hover .disks-detail {
  top: 100px;
  display: block;
  border-radius: 4px;
  box-shadow: 0 0 20px #e5e5e5;
}
.storage-modal .host-box .disks-list .no-use-disks li:hover .disks-detail .detail-item {
  margin: 3px 0;
  line-height: 16px;
}
.storage-modal .host-box .disks-list .no-use-disks li:hover .disks-detail .detail-item .title {
  font-weight: normal;
  font-size: 14px;
  padding: 0;
  color: #7991ab;
  border: none;
  width: 45%;
}
.storage-modal .host-box .disks-list .no-use-disks li:hover .disks-item {
  border: 2px solid #51a3ff;
  box-shadow: 0 0 8px rgba(81, 163, 255, 0.60784314);
}
.storage-modal .host-box .disks-list .no-use-disks li:hover .disks-item .disk-out .disk-circle {
  top: 3px;
  left: 3px;
}
.storage-modal .host-box .disks-list .no-use-disks li:hover .disks-item .disk-out .disk-hhd-bg,
.storage-modal .host-box .disks-list .no-use-disks li:hover .disks-item .disk-out .disk-ssd-bg {
  padding-top: 14px;
}
.storage-modal .host-box .disks-list .no-use-disks li:hover .disks-item .disk-out .disk-hhd-bg i,
.storage-modal .host-box .disks-list .no-use-disks li:hover .disks-item .disk-out .disk-ssd-bg i {
  margin-top: 0px;
}
.storage-modal .host-box .disks-list .no-use-disks li:hover .disks-item .disk-out .disk-capacity {
  width: 69px;
  padding-top: 3px;
  font-size: 14px;
  display: block;
  margin: 7px auto 0;
  border-top: 1px solid #c3cfdb;
}
.storage-modal .host-box .disks-content {
  padding: 0px;
}
.storage-modal .host-box .disks-content .disks-tab {
  overflow: hidden;
}
.storage-modal .host-box .disks-content .disks-tab .disk-name {
  cursor: pointer;
  border: 1px solid #c2d3eb;
  border-bottom: none;
  float: left;
  margin-left: 20px;
  padding: 10px 25px;
  font-weight: bold;
}
.storage-modal .host-box .disks-content .disks-tab .disk-name.active {
  background: #f0f3f5;
}
.storage-modal .host-box .disks-content .disks-group > li .group-content {
  background: none;
  border: none;
  padding: 0;
}
.storage-modal .host-box .disks-content .disks-group > li .group-content .data-content {
  padding: 20px 0px 10px 0px;
}
.storage-modal .host-box .disks-content .disks-group > li .group-content .data-content:last-child {
  padding-bottom: 20px;
}
.storage-modal .host-box .disks-content .disks-group > li .group-content .data-content.fc-content {
  border: none;
}
.storage-modal .host-box .disks-content .disks-group > li .group-content .data-content .disk-type {
  float: left;
  width: 10%;
  font-weight: bold;
  line-height: 107px;
}
.storage-modal .host-box .disks-content .disks-group > li .group-content .data-content .disk-data {
  float: left;
  width: 90%;
  border: 1px dashed #c3cfdb;
  background: #f0f3f5;
}
.storage-modal .host-box .disks-content .disks-group > li .group-content .data-content .disk-data > ul {
  width: 100%;
  min-height: 105px;
  max-height: 210px;
  background: #fff;
}
.storage-modal .host-box .disks-content .disks-group > li .group-content .data-content .disk-data > ul .tips {
  line-height: 105px;
}
.storage-modal .host-box .disks-content .disks-group > li .group-content .data-content .disk-data > ul li {
  width: 15%;
}
.storage-modal .host-box .disks-content .disks-group > li .group-content .data-content .disk-data > ul li .disks-item {
  height: 79px;
  width: 100%;
}
.storage-modal .host-box .disks-content .disks-group > li .group-content .data-content .disk-data > ul li .disks-item .disk-out {
  position: relative;
}
.storage-modal .host-box .disks-content .disks-group > li .group-content .data-content .disk-data > ul li .disks-item .disk-out .disk-circle {
  position: absolute;
  width: 13px;
  height: 13px;
  top: 4px;
  left: 4px;
  border-radius: 50%;
  background: #f5becc;
}
.storage-modal .host-box .disks-content .disks-group > li .group-content .data-content .disk-data > ul li .disks-item .disk-out .disk-hhd-bg,
.storage-modal .host-box .disks-content .disks-group > li .group-content .data-content .disk-data > ul li .disks-item .disk-out .disk-ssd-bg {
  height: 45px;
  padding-top: 15px;
}
.storage-modal .host-box .disks-content .disks-group > li .group-content .data-content .disk-data > ul li .disks-item .disk-out .disk-hhd-bg i,
.storage-modal .host-box .disks-content .disks-group > li .group-content .data-content .disk-data > ul li .disks-item .disk-out .disk-ssd-bg i {
  margin-top: 0px;
}
.storage-modal .host-box .disks-content .disks-group > li .group-content .data-content .disk-data > ul li .disks-item .disk-out .disk-capacity {
  width: 68px;
  font-size: 14px;
  display: block;
  margin: 0px auto 0;
}
.storage-modal .host-box .disks-content .disks-group > li .group-content .data-content .disk-data > ul li .disks-item .disks-remove {
  width: 15px;
  height: 15px;
  right: -10px;
  top: -8px;
  background: #e74c3c;
  line-height: 17px;
}
.storage-modal .host-box .disks-content .disks-group > li .group-content .data-content .disk-data > ul li:hover .disks-detail {
  top: 100px;
  display: block;
  border-radius: 4px;
  box-shadow: 0 0 20px #e5e5e5;
}
.storage-modal .host-box .disks-content .disks-group > li .group-content .data-content .disk-data > ul li:hover .disks-detail .detail-item {
  line-height: 16px;
  margin: 3px 0;
}
.storage-modal .host-box .disks-content .disks-group > li .group-content .data-content .disk-data > ul li:hover .disks-detail .detail-item .title {
  font-weight: normal;
  font-size: 14px;
  padding: 0;
  color: #7991ab;
  border: none;
  width: 45%;
}
.storage-modal .host-box .disks-content .disks-group > li .group-content .data-content .disk-data > ul li:hover .disks-detail .detail-item .title-name {
  width: 80px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.storage-modal .host-box .disks-content .disks-group > li .group-content .data-content .disk-data > ul li:hover .disks-detail .detail-item .title-name.pool-name {
  width: 70px;
}
.storage-modal .host-box .disks-content .disks-group > li .group-content .data-content .disk-data > ul li:hover .disks-item {
  border: 2px solid #51a3ff;
  box-shadow: 0 0 8px rgba(81, 163, 255, 0.60784314);
}
.storage-modal .host-box .disks-content .disks-group > li .group-content .data-content .disk-data > ul li:hover .disks-item .disk-out .disk-circle {
  top: 3px;
  left: 3px;
}
.storage-modal .host-box .disks-content .disks-group > li .group-content .data-content .disk-data > ul li:hover .disks-item .disk-out .disk-hhd-bg,
.storage-modal .host-box .disks-content .disks-group > li .group-content .data-content .disk-data > ul li:hover .disks-item .disk-out .disk-ssd-bg {
  padding-top: 14px;
}
.storage-modal .host-box .disks-content .disks-group > li .group-content .data-content .disk-data > ul li:hover .disks-item .disk-out .disk-hhd-bg i,
.storage-modal .host-box .disks-content .disks-group > li .group-content .data-content .disk-data > ul li:hover .disks-item .disk-out .disk-ssd-bg i {
  margin-top: 0px;
}
.storage-modal .host-box .disks-content .disks-group > li .group-content .data-content .disk-data > ul li:hover .disks-item .disk-out .disk-capacity {
  width: 68px;
  font-size: 14px;
  display: block;
  margin: -1px auto 0;
}
.storage-modal .host-box .disks-content .disks-group > li .group-content .data-content .disk-data > ul li:hover .disks-item .disks-remove {
  width: 15px;
  height: 15px;
  right: -11px;
  top: -9px;
  background: #e74c3c;
  line-height: 17px;
}
.storage-modal .node-table.node-table-line {
  margin-top: 30px;
  padding-top: 15px;
  border-top: 1px solid #e5e5e5;
}
.storage-modal .node-table .table-content .ng-table-pager {
  position: initial;
}
.storage-modal .node-table .table-content tr td .edit-name.name-sm {
  width: 80px;
}
/*镜像编辑*/
.images-modal .file-box .form-controls {
  border-right: none;
}
.images-modal .file-box .file-choose {
  color: #4e80f5 !important;
  background: #fafafa !important;
  border: 1px solid #e5e5e5 !important;
  border-left: 0 !important;
  cursor: pointer !important;
}
.images-modal .title i {
  color: #51a3ff;
  cursor: pointer;
}
.images-modal .title i span {
  font-style: normal;
}
.images-modal .seniorAttr {
  border-top: dashed 1px #e5e5e5;
  padding-top: 20px;
}
.images-modal .radio-item label + label {
  margin-left: 30px !important;
}
.affinity-detail-page h3 {
  float: left;
}
.affinity-detail-page .detail-info {
  clear: all;
}
.affinity-detail-page .rightBtns {
  margin-bottom: 20px;
  float: right;
}
.affinity-detail-page .rightBtns .search-box {
  display: inline-block;
  margin-right: 8px;
}
.affinity-detail-page .rightBtns button {
  margin-right: 8px;
}
.build-modal {
  width: 854px !important;
}
/*光盘管理*/
.modalCD .radioCD {
  height: auto !important;
  max-height: 270px;
  overflow: auto;
}
.modalCD .radioCD label {
  min-height: 25px !important;
  line-height: 25px !important;
  display: block !important;
}
.modalCD .radioCD label + label {
  margin-left: 0 !important;
}
.QRCode-modal {
  width: 500px !important;
}
.QRCode-modal .modal-header {
  border-bottom: none !important;
}
.QRCode-modal .modal-header .modal-title {
  margin-top: 20px;
}
.QRCode-modal .modal-footer {
  background: #fff !important;
  border: none !important;
}
.QRCode-modal .modal-body {
  margin-top: 44px;
}
.QRCode-modal .QRCodeImg {
  text-align: center;
}
.QRCode-modal .QRCodeImg img {
  width: 200px;
  height: 200px;
}
.QRCode-modal .notBindTips {
  margin: 0 10px;
  line-height: 25px;
}
.QRCode-modal .control-group {
  color: #f39c12;
}
.QRCode-modal .control-group .control-label {
  line-height: 19px;
}
/*遮罩层*/
.shade-box .shade-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  opacity: 0.5;
}
.shade-box .loadingTip {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 30px;
  color: #e9230f;
  line-height: 30px;
  margin-top: -74px;
  font-size: 20px;
  text-align: center;
}
.shade-box .loadingImg {
  position: absolute;
  width: 88px;
  height: 88px;
  top: 50%;
  left: 50%;
  margin-top: -44px;
  margin-left: -44px;
  background: url(/img/loading.gif) no-repeat center center;
}
.shade-box-CD .global-loading {
  top: 58px;
  bottom: 63px;
  height: auto;
}
.shade-box-initStorage .global-loading {
  top: 0;
  bottom: 0;
  height: auto;
}
.shade-box-initStorage .global-loading span {
  width: 250px;
  margin-left: -125px;
}
.shade-box-iscsi .global-loading {
  top: 0;
  bottom: 0;
  height: auto;
}
/* 计费管理 */
.pie-center-message {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  font-size: 14px;
}
.billing {
  min-width: 1550px;
}
.billing .animateContent.animateIn {
  height: auto;
}
.billing .setting-box {
  margin: 125px auto;
  width: 1022px;
}
.billing .setting-box .set-wrap {
  border: 1px solid #d9e3f2;
  padding: 20px;
  min-height: 240px;
}
.billing .setting-box .set-wrap .control-group {
  padding-bottom: 20px;
  border-bottom: dashed 1px #c2d3eb;
  margin-bottom: 15px;
}
.billing .setting-box .btn-wrap {
  height: 100px;
  line-height: 100px;
}
.billing.setting-show .control-group.top {
  top: 65px;
}
.billing.setting-show .top-nav {
  top: 57px;
}
.billing .control-group.top {
  position: absolute;
  top: 10px;
  right: 0px;
  width: 550px;
}
.billing .control-group.top .control-label {
  line-height: 39px;
}
.billing .control-group.top .controls {
  padding-left: 10px;
}
.billing .control-group.top .controls .switch-btn label {
  float: left;
}
.billing .control-group.top .controls .switch-btn span {
  height: 28px;
  line-height: 28px;
  padding-left: 16px;
  float: left;
  cursor: default;
}
.billing .tab-content .box div[class^="col-"] {
  border-left: 1px solid #c2d3eb !important;
}
.billing .tab-content .box div[class^="col-"]:first-child {
  border: none !important;
}
.billing .tab-content .pie-legend {
  padding: 0 30px 0 0;
}
.billing .tab-content .pie-legend .table {
  margin-bottom: 0;
  border: none;
}
.billing .tab-content .pie-legend .table tr th {
  text-align: left;
  padding: 10px 10px;
  border-bottom: 1px solid #c2d3eb;
  vertical-align: middle;
  background-color: #ffffff;
  color: #607d8b;
  white-space: nowrap;
}
.billing .tab-content .pie-legend .table tr th:nth-child(1) {
  padding: 0;
}
.billing .tab-content .pie-legend .table tr td {
  padding: 0 10px;
  border-bottom: 1px solid #c2d3eb;
  height: 46px;
}
.billing .tab-content .pie-legend .table tr td i {
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 30%;
  float: left;
  margin: 12px 0;
}
.billing .tab-content .pie-legend .table tr td .num {
  font-size: 18px;
}
.billing .tab-content .pie-legend .table tr td:nth-child(1) {
  width: 12px;
  padding: 0;
}
.billing .tab-content .pie-legend .table tr td .blue {
  display: inline-block;
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: middle;
}
.billing .tab-content .pie-legend .table tr:last-child td,
.billing .tab-content .pie-legend .table tr.last-tr td {
  border-bottom: none;
}
.billing .tab-content .panels .pie-wrap {
  width: 35%;
  height: 230px;
  margin-left: 0;
}
.billing .tab-content .panels .pie-wrap .pie {
  width: 160px;
  height: 160px;
  position: absolute;
  margin: -80px 0 0 -80px;
  left: 50%;
  top: 50%;
}
.billing .tab-content .panels .pie-wrap .pie-center {
  top: 100%;
}
.billing .tab-content .panels .pie-wrap .pie-center .num {
  font-size: 20px;
  color: #333333;
}
.billing .tab-content .panels .pie-wrap .pie-center .text {
  color: #7991ab;
}
.billing .tab-content .panels .pie-legend {
  width: 65%;
  height: auto;
  min-height: 250px;
}
.billing .tab-content .panels.res.twocol .pie-wrap {
  width: 26%;
}
.billing .tab-content .panels.res.twocol .pie-legend {
  width: 37%;
}
.billing .tab-content .panels.res.twocol .pie-legend .table tr th {
  padding: 5px 5px;
}
.billing .tab-content .panels.res.twocol .pie-legend .table tr td {
  padding: 0 5px;
}
.billing .tab-content .panels.res.twocol .pie-legend .table tr td i {
  margin: 10px 0;
}
.billing .tab-content .panels.res.twocol .pie-legend:last-child {
  padding: 0px;
}
.billing .tab-content .panels.res.twocol.res-media .pie-wrap {
  width: 35%;
}
.billing .tab-content .panels.res.twocol.res-media .pie-legend {
  width: 65%;
  padding: 0;
  margin-left: 0;
  min-height: 250px;
}
.billing .tab-content .panels.res-media .roll {
  height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.billing .tab-content .panels.res-media .roll::-webkit-scrollbar {
  width: 10px;
}
.billing .tab-content .panels.res-media .roll::-webkit-scrollbar-thumb {
  background: #51a3ff;
}
.billing .tab-content .panels.res-media .roll::-webkit-scrollbar-track {
  border-radius: 0;
  background: #e5e5e5;
}
.billing .tab-content .panels.res-media .roll .table {
  padding-right: 10px;
}
.billing .tab-content .panels.res-media .pie-wrap {
  width: 40%;
}
.billing .tab-content .panels.res-media .pie-wrap .pie-center {
  top: 100%;
}
.billing .tab-content .panels.res-media .pie-wrap .pie-center .text {
  font-size: 12px;
  padding-top: 5px;
}
.billing .tab-content .panels.res-media .pie-legend {
  width: 60%;
  margin-left: 0;
  min-height: 250px;
}
.billing .tab-content .panels.res-media .pie-legend .table tr td {
  height: 36px;
  font-size: 12px;
}
.billing .tab-content .panels.res-media .pie-legend .table tr td .num {
  font-size: 12px;
}
.billing .tab-content .panels.res-media .pie-legend .table tr td .blue {
  max-width: 65px;
}
.billing .detailInner .detail-title {
  padding: 13px 0 13px;
  margin: 5px 20px 5px 20px;
}
.billing .detailInner .detail-info .control-box {
  padding: 16px 20px 16px 0;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 10px;
}
.billing .detailInner .detail-info .control-box a.an-close {
  text-align: center;
  color: #4e80f5;
  border-radius: 2px;
  padding: 0;
  margin-right: 10px;
  line-height: 28px;
  display: block;
  float: left;
  font-size: 18px;
}
.billing .detailInner .detail-info .control-box .control-group {
  float: left;
  margin-right: 10px;
  margin-bottom: 0px;
}
.billing .detailInner .detail-info .control-box .control-group .control-label {
  width: auto;
}
.billing .detailInner .detail-info .control-box .control-group .controls {
  width: 150px;
  padding-left: 10px;
}
.billing .detailInner .detail-info .control-box .control-group .controls .bill-select {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 120px;
  overflow: hidden;
}
.billing .detailInner .detail-info .timeType-box {
  margin-top: 20px;
}
.billing .detailInner .detail-info .panels .pie-wrap {
  width: 30%;
}
.billing .detailInner .detail-info .panels .pie-wrap .pie {
  width: 140px;
  height: 140px;
  margin: -70px 0 0 -70px;
}
.billing .detailInner .detail-info .panels .pie-wrap .pie-center {
  top: 95%;
}
.billing .detailInner .detail-info .panels .pie-wrap .pie-center .num {
  font-size: 18px;
}
.billing .detailInner .detail-info .panels .pie-wrap .pie-center .text {
  font-size: 12px;
}
.billing .detailInner .detail-info .panels .pie-legend {
  width: 70%;
  padding: 0 20px;
}
.billing .detailInner .detail-info .panels .pie-legend .roll {
  height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.billing .detailInner .detail-info .panels .pie-legend .roll::-webkit-scrollbar {
  width: 10px;
}
.billing .detailInner .detail-info .panels .pie-legend .roll::-webkit-scrollbar-thumb {
  background: #51a3ff;
}
.billing .detailInner .detail-info .panels .pie-legend .roll::-webkit-scrollbar-track {
  border-radius: 0;
  background: #e5e5e5;
}
.billing .detailInner .detail-info .panels .pie-legend .table {
  padding-right: 10px;
}
.billing .detailInner .detail-info .panels .pie-legend .table tr th:nth-child(1) {
  padding: 0;
}
.billing .detailInner .detail-info .panels .pie-legend .table tr td {
  height: 36px;
  font-size: 14px;
}
.billing .detailInner .detail-info .panels .pie-legend .table tr td:nth-child(1) {
  width: 12px;
  padding: 0;
}
.billing .detailInner .detail-info .panels .pie-legend .table tr td .num {
  font-size: 14px;
}
.billing .detailInner .detail-info .panels .pie-legend .table tr td .blue {
  max-width: 80px;
}
.billing .detailInner .detail-info .panels.res-media .pie-legend .table tr td {
  font-size: 12px;
}
.billing .detailInner .detail-info .panels.res-media .pie-legend .table tr td .num {
  font-size: 12px;
}
.billing .detailInner .detail-info .panels.res-media .pie-legend .table tr td .blue {
  max-width: 65px;
}
.billing .table-action.price .control-group {
  width: 130px;
  margin-right: 10px;
}
.billing .table-action.price .control-group .control-label {
  width: 80px;
}
.billing .table-action.price .control-group .controls {
  width: 100%;
}
.billing .table-action.price .control-group .controls .ui-select-container {
  width: 100%;
}
.billing .table-action.price .control-group.control-group-filter {
  width: 80px;
  text-align: center;
}
.billing .table-action.price .control-group.control-group-filter-text {
  min-width: 200px;
  margin-top: 5px;
}
.billing .table-content .table tr th .dropdown i {
  display: inline-block;
  line-height: 14px;
  height: 14px;
  top: 2px;
  position: relative;
}
.billing .table-content .table tr th .dropdown i.icon-aw-angle-down:before {
  font-size: 12px;
}
.detail-title.text-nav .an-close,
.detail-title.text-nav span {
  font-size: 16px;
  height: 22px;
  line-height: 22px;
  font-weight: bold;
}
.detail-title.text-nav span {
  margin-top: 0;
}
.directive-action-wrap.table-action form {
  display: inline-block;
}
.directive-action-wrap.table-action .control-group {
  width: 240px;
  margin-right: 20px;
}
.directive-action-wrap.table-action .control-group .control-label {
  width: 80px;
}
.directive-action-wrap.table-action .control-group .controls {
  width: 160px;
}
.directive-action-wrap.table-action .control-group .controls .ui-select-container {
  width: 160px;
}
.directive-action-wrap.table-action.price .control-group {
  width: 10%;
}
.directive-action-wrap.table-action.price .control-group .controls {
  width: 100%;
}
.directive-action-wrap.table-action .date-box .time-picker .form-control {
  width: 160px;
}
.directive-action-wrap.table-action .tab-group {
  display: inline-block;
  vertical-align: middle;
}
.directive-action-wrap.table-action .tab-group .time-tab {
  border: 1px solid #c2d3eb;
  border-radius: 0px;
  height: 28px;
  margin-right: 15px;
}
.directive-action-wrap.table-action .tab-group .time-tab .btn-time {
  border-right: 1px solid #c2d3eb;
  margin-right: 0;
  padding: 6px;
  min-width: 80px;
  float: none;
}
.directive-action-wrap.table-action .tab-group .time-tab .btn-time.active {
  background: none;
  color: #51a3ff;
}
.directive-action-wrap.table-action .tab-group .time-tab .calendar-select {
  position: relative;
  display: inline-block;
  min-width: 100px;
  height: 30px;
}
.directive-action-wrap.table-action .tab-group .time-tab .calendar-select span {
  padding: 6px 16px;
  margin-right: 20px;
}
.directive-action-wrap.table-action .tab-group .time-tab .calendar-select span.active {
  background: none;
  color: #51a3ff;
}
.directive-action-wrap.table-action .tab-group .time-tab .calendar-select .time-input {
  display: none;
}
.directive-action-wrap.table-action .tab-group .time-tab .calendar-select .glyphicon-calendar {
  position: absolute;
  right: 10px;
  top: 2px;
  cursor: pointer;
}
.table-content .table tr th .dropdown {
  display: inline-block;
  margin-left: 5px;
}
.table-content .table tr th .dropdown i {
  color: #313949;
  background: #ffffff;
  border: 1px solid #c2d3eb;
  padding: 0 1px;
  cursor: pointer;
}
.table-content .table tr th .dropdown i:hover {
  color: #4e80f5;
  background: #f6f9ff;
  border: 1px solid #51a3ff;
}
.table-content .table tr th .dropdown .dropdown-menu li a:hover {
  color: #51a3ff;
}
.res-metering .control-groups .date-box > .control-group {
  width: auto;
}
.res-metering .control-groups .date-box > .control-group .control-label {
  width: auto;
}
.res-metering .control-groups .date-box > .control-group .controls .ui-select-container {
  width: 120px;
}
.res-metering .control-groups .date-box > .control-group .controls .ui-select-container .btn {
  width: 100%;
}
.res-metering .control-groups .date-box > .control-group .controls .ui-select-container .count-uiselect {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 120px;
  overflow: hidden;
}
.res-metering .control-groups .date-box .time-picker {
  margin-bottom: 10px;
}
.res-metering .control-groups .date-box .time-picker .input-group.date {
  width: 10%;
}
.res-metering .control-groups .date-box .time-picker .form-control {
  width: 160px;
}
.res-metering .control-groups.time-error {
  padding-bottom: 20px;
}
.res-metering .ul-text-box {
  margin-bottom: 15px !important;
}
.res-metering .ul-text-box ul {
  min-height: 160px;
}
.res-metering .ul-text-box ul li {
  min-height: 160px;
  float: left;
  border-right: solid 1px #c2d3eb;
  text-align: center;
  padding: 35px 0;
}
.res-metering .ul-text-box ul li:last-child {
  border-right: none;
}
.res-metering .ul-text-box ul li .icon,
.res-metering .ul-text-box ul li .text {
  width: 50%;
  float: left;
  padding-left: 10px;
}
.res-metering .ul-text-box ul li .icon {
  padding-right: 10px;
  text-align: right;
}
.res-metering .ul-text-box ul li .icon i {
  font-size: 64px;
  color: #c2d3eb;
}
.res-metering .ul-text-box ul li .text {
  text-align: left;
  margin-top: 6px;
}
.res-metering .ul-text-box ul li .text .num {
  font-size: 36px;
}
.res-metering .ul-text-box ul li .text p {
  font-size: 14px;
  color: #999;
}
.res-metering .ul-text-box.items4 ul li {
  width: 25%;
}
.res-metering .ul-text-box.items3 ul li {
  width: 33.3%;
}
.res-metering .ul-text-box.items2 ul li {
  width: 50%;
}
.res-metering .table-action .show-title .dropdown-menu {
  height: 200px;
  overflow-y: scroll;
}
.res-metering .table-action .show-title .dropdown-menu li {
  min-width: 200px;
}
.res-metering .table-cont.scrolly {
  overflow-x: scroll;
  min-height: 600px;
}
.res-metering .panels .pie-legend .dl-horizontal dd {
  color: #666666;
}
.res-metering .panels .pie-legend .dl-horizontal dd .unitText {
  font-size: 14px;
  vertical-align: top;
  width: 50%;
}
.res-metering .panels .pie-legend .dl-horizontal dt {
  text-align: left;
  width: 50%;
}
.res-metering .panels .pie-legend .dl-horizontal dt i {
  margin-right: 5px;
}
.res-metering .table-content .table tr th .dropdown i {
  display: inline-block;
  line-height: 14px;
  height: 14px;
  top: 2px;
  position: relative;
}
.res-metering .table-content .table tr th .dropdown i.icon-aw-angle-down:before {
  font-size: 12px;
}
/*删除有依赖的增量备份*/
.delDep-box .delTips {
  margin: 0 50px 20px;
}
.delDep-box .del-group .delName {
  width: 465px;
  height: 32px;
  line-height: 32px;
  margin-left: 100px;
  padding-left: 20px;
  border: 1px solid #c3d3ea;
  border-radius: 2px;
  background-color: #fff;
  font-size: 12px;
  color: #313949;
  float: left;
}
.delDep-box .del-group .delImg {
  line-height: 32px;
  float: left;
  margin-left: 10px;
}
.delDep-box .del-group .delImg span {
  cursor: pointer;
}
.delDep-box .del-group .arrow {
  line-height: 32px;
  margin-left: 325px;
  clear: both;
}
/*负载均衡*/
.table-action .select-box {
  width: 150px;
  margin-right: 10px;
}
.modal-body .des-info {
  height: 48px;
  line-height: 48px;
  margin-top: 18px;
  font-size: 14px;
  border: 1px solid #c3d3ea;
  border-radius: 2px;
  background-color: #f6f9fe;
  text-align: center;
}
.modal-body .des-info strong {
  color: #ff9b11;
  font-size: 16px;
}
.modal-body .applyIpText {
  color: #51a3ff;
  cursor: pointer;
}
.modal-body .listener-group .protocol .control-label {
  width: 120.7px;
}
.modal-body .listener-group .protocol .controls {
  width: 150px;
}
.modal-body .listener-group .protocol .controls i.question-tip {
  margin: 0px;
}
.modal-body .listener-group .port .control-label {
  width: 24px;
}
.modal-body .listener-group .port .controls {
  width: 160px;
  padding-left: 5px;
}
.modal-body .listener-group .port .port-label {
  width: 140px;
  font-size: 14px;
  font-weight: 400;
  color: #aeaeae;
}
.modal-body .keepSession .sessionLabel {
  text-align: left;
  padding-left: 25px;
  width: 14%;
}
.modal-body .healthcheck.paddingTop {
  padding-top: 10px;
}
.modal-body .healthcheck label + label {
  width: 18%;
}
.modal-body .healthcheck label + label i.question-tip {
  margin-right: 10px;
}
.modal-body .healthcheck .controls {
  width: calc(65% - 20px);
}
.modal-body .healthcheck .question-tip {
  margin-right: 0;
}
.lbDetail-row {
  overflow: inherit;
}
.lbDetail-row .detail-title {
  padding-left: 0;
}
.lbDetail-row .basicInfo-title {
  color: #666666;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  margin-bottom: 11px;
}
.lbDetail-row .lisTable {
  height: 360px;
}
.lbDetail-row .lisTable .radio-group {
  margin-bottom: 0;
}
.lbDetail-row .lisTable .radio-group .controls {
  line-height: 1;
  padding-left: 0;
}
.lbDetail-row .lisTable .radio-group .controls .radio-item {
  height: auto;
  padding-top: 0;
}
.lbDetail-row .memTable {
  max-height: 210px;
  min-height: 180px;
  overflow: auto;
}
.lbDetail-row .bindIns {
  border-top: 1px solid #c2d3eb;
  padding-top: 15px;
}
.lbDetail-row .bindIns .notChooseLisTips {
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  color: #666666;
}
.lbDetail-row .listenerDetail .detail-title {
  margin-bottom: 10px;
  border-bottom: 1px solid #c2d3eb;
}
.lbDetail-row .listenerDetail .detail-title span {
  margin-right: 15px;
}
.lbDetail-row .listenerDetail .detail-title span:nth-child(1) {
  color: #51a3ff;
  cursor: pointer;
}
.lbDetail-row .listenerDetail .titleTxt {
  margin-left: 10px;
}
.lbDetail-row .listenerDetail .healthTxt {
  color: #1abc9c;
}
.lbDetail-row .listenerDetail .showAll {
  float: right;
  color: #51a3ff;
  cursor: pointer;
}
.lbDetail-row .listenerDetail .ldRow {
  height: 25px;
  line-height: 25px;
}
.lbDetail-row .listenerDetail .table-action {
  margin-top: 20px;
}
.lbDetail-row .listenerDetail .table-action .btn-renovat {
  margin-right: 0;
}
.lbSuccess-modal .successTips {
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: #666666;
  text-align: center;
  height: 32px;
  line-height: 30px;
  margin-top: 60px;
  margin-bottom: 60px;
}
.lbSuccess-modal .successTips .icon-aw-correct {
  color: #1abc9c;
  font-size: 25px;
  margin-right: 15px;
}
.lbSuccess-modal .successTips span {
  vertical-align: top;
  line-height: 29px;
}
.modal-common .steps-sytle-ver .lbStep {
  width: 45%;
}
.modal-body .createIns .table-action .table-label {
  float: left;
  height: 32px;
  line-height: 32px;
}
.modal-body .createIns .table-action .table-label .notes {
  color: #949494;
  margin-top: -15px;
  margin-left: 25px;
}
.modal-body .createIns .table-action .search-box {
  width: 150px;
}
.modal-body .createIns .table-action .btn {
  margin-right: 0px;
}
.modal-body .createIns .radio-group .controls {
  padding-left: 0;
}
.modal-body .createIns .radio-group .controls .radioIns {
  height: auto !important;
}
.modal-body .createIns .radio-group .controls .radioIns label {
  min-height: 25px !important;
  line-height: 25px !important;
  display: block !important;
}
.modal-body .createIns .radio-group .controls .radioIns label + label {
  margin-left: 0 !important;
}
.modal-body .portWeight .table-label {
  width: 17%;
  height: 32px;
  line-height: 32px;
  float: left;
  text-align: right;
}
.modal-body .portWeight .table-content {
  /*float: left;
		    width: 83%;
		    padding-left: 20px;*/
}
.modal-body .portWeight .table-content .table tbody tr td {
  height: 44px;
}
.modal-body .portWeight .errors {
  color: #e74c3c;
  line-height: 1.5;
  font-size: 12px;
}
/*初始设置*/
.initSetting-modal {
  width: 1000px !important;
}
.initSetting-modal .modal-body {
  min-height: 601px;
}
.initSetting-modal .close {
  position: absolute;
  right: 0;
  top: -10px;
  font-size: 30px;
  font-weight: normal;
  line-height: 1;
  color: #999999;
  text-shadow: none;
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  float: none;
  opacity: 1;
  filter: alpha(opacity=100);
}
.initSetting-modal .settingBody {
  margin-top: 25px;
  display: flex;
}
.initSetting-modal .settingBody .settingList {
  width: 27%;
  padding-right: 15px;
  border-right: 1px solid #e5e5e5;
}
.initSetting-modal .settingBody .settingList .listTitle {
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  color: #666666;
  margin-bottom: 10px;
}
.initSetting-modal .settingBody .settingList .listItem {
  margin-bottom: 20px;
  padding-bottom: 5px;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
}
.initSetting-modal .settingBody .settingList .listItem .title {
  margin-bottom: 10px;
}
.initSetting-modal .settingBody .settingList .listItem .title span {
  color: #333333;
  cursor: pointer;
}
.initSetting-modal .settingBody .settingList .listItem .title span:hover {
  color: #51a3ff;
}
.initSetting-modal .settingBody .settingList .listItem .description {
  margin-left: 20px;
  margin-bottom: 8px;
  color: #999999;
}
.initSetting-modal .settingBody .settingList .listItem .status {
  margin-left: 20px;
  cursor: pointer;
  color: #51a3ff;
}
.initSetting-modal .settingBody .settingList .listItem .statusTrue {
  cursor: text;
  color: #1abc9c;
}
.initSetting-modal .settingBody .settingList .listItem + .listItem {
  border-top: 1px solid #e5e5e5;
  padding-top: 25px;
}
.initSetting-modal .settingBody .settingInfo {
  padding-left: 25px;
  width: 73%;
}
.initSetting-modal .settingBody .settingInfo .title {
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  color: #666666;
  padding-bottom: 8px;
  border-bottom: 1px solid #e5e5e5;
}
.initSetting-modal .settingBody .settingInfo .step-title {
  margin-top: 11px;
  margin-left: 40px;
}
.initSetting-modal .settingBody .settingInfo .step-title .inner span {
  font-size: 14px;
}
.initSetting-modal .settingBody .settingInfo .vpc-initSetting .welcome {
  text-align: center;
  color: #aeaeae;
  margin-top: 10px;
}
.initSetting-modal .settingBody .settingInfo .vpc-initSetting .welcome .welcome-title {
  font-weight: 700;
  font-style: normal;
  font-size: 32px;
  margin-bottom: 8px;
}
.initSetting-modal .settingBody .settingInfo .vpc-initSetting .welcome .welcome-tips {
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
}
.initSetting-modal .settingBody .settingInfo .vpc-initSetting .step-title {
  width: 70%;
  margin-left: 15%;
}
.initSetting-modal .settingBody .settingInfo .vpc-initSetting .vpc-box {
  width: 90%;
  margin-top: 20px;
}
.initSetting-modal .settingBody .settingInfo .vpc-initSetting .vpc-box .control-group .control-label {
  width: 22%;
}
.initSetting-modal .settingBody .settingInfo .vpc-initSetting .vpc-box .control-group .controls {
  width: 78%;
}
.initSetting-modal .settingBody .settingInfo .vpc-initSetting .vpc-box .control-group.bind-group .control-label.bind-label {
  margin-top: 14px;
}
.initSetting-modal .settingBody .settingInfo .vpc-initSetting .vpc-box .control-group.bind-group .controls.bind-controls .bind-bar {
  float: left;
  width: 85%;
}
.initSetting-modal .settingBody .settingInfo .vpc-initSetting .vpc-box .control-group.bind-group .controls.bind-controls .bind-input {
  float: left;
  width: 13%;
  margin-top: 14px;
  margin-left: 2%;
}
.initSetting-modal .settingBody .settingInfo .vpc-initSetting .vpc-box .control-group.errorMsg-group {
  margin-bottom: 0px;
}
.initSetting-modal .settingBody .settingInfo .vpc-initSetting .vpc-box .ip_pool .repeat {
  position: relative;
  width: 77%;
}
.initSetting-modal .settingBody .settingInfo .vpc-initSetting .vpc-box .ip_pool .group {
  position: relative;
  width: 80%;
}
.initSetting-modal .settingBody .settingInfo .vpc-initSetting .vpc-box .ip_pool .group.create-subnet {
  margin-left: 22%;
  padding-left: 30px;
}
.initSetting-modal .settingBody .settingInfo .vpc-initSetting .vpc-box .ip_pool .group .control-group .control-label {
  width: 15%;
}
.initSetting-modal .settingBody .settingInfo .vpc-initSetting .vpc-box .ip_pool .group .control-group .controls {
  width: 78%;
  padding-left: 15px;
}
.initSetting-modal .settingBody .settingInfo .vpc-initSetting .vpc-box .ip_pool .add-subpool {
  margin-left: 23%;
  float: left;
  margin-bottom: 10px;
}
.initSetting-modal .settingBody .settingInfo .vpc-initSetting .vpc-box .add {
  position: absolute;
  right: -30px;
  font-size: 16px;
  top: 6px;
}
.initSetting-modal .settingBody .settingInfo .vpc-initSetting .vpc-box .add a {
  color: #51a3ff;
}
.initSetting-modal .settingBody .settingInfo .vpc-initSetting .vpc-box .del {
  position: absolute;
  right: -7px;
  top: 7px;
}
.initSetting-modal .settingBody .settingInfo .vpc-initSetting .vpc-box .del a {
  color: #e74c3c;
}
.initSetting-modal .settingBody .settingInfo .alarm-initSetting .step-title .alarmStep {
  width: 45%;
}
.initSetting-modal .settingBody .settingInfo .alarm-initSetting .setting-box {
  margin-top: 20px;
}
.initSetting-modal .settingBody .settingInfo .alarm-initSetting .setting-box .setting-title {
  font-weight: 700;
  font-size: 14px;
  color: #666666;
  margin-left: 25px;
}
.initSetting-modal .settingBody .settingInfo .alarm-initSetting .setting-box .setting-tips {
  line-height: 30px;
  text-align: center;
  color: #f39c12;
  margin-bottom: 5px;
}
.initSetting-modal .settingBody .settingInfo .alarm-initSetting .setting-box .control-group .control-label {
  width: 22%;
}
.initSetting-modal .settingBody .settingInfo .alarm-initSetting .setting-box .control-group .controls {
  width: 78%;
}
.initSetting-modal .settingBody .settingInfo .alarm-initSetting .setting-box .active-group .controls {
  width: 90px;
}
.initSetting-modal .settingBody .settingInfo .alarm-initSetting .setting-box .active-group .active-label {
  color: #e74c3c;
  width: auto;
}
.initSetting-modal .settingBody .settingInfo .alarm-initSetting .setting-box .mailServer-box {
  margin: 0;
  padding: 0;
  border: 0;
  width: 570px;
}
.initSetting-modal .settingBody .settingInfo .alarm-initSetting .setting-box .mailServer-box .mail-group .host .control-label {
  width: 125px;
}
.initSetting-modal .settingBody .settingInfo .alarm-initSetting .setting-box .mailServer-box .mail-group .host .controls {
  width: 230px;
}
.initSetting-modal .settingBody .settingInfo .alarm-initSetting .setting-box .mailServer-box .mail-group .port .control-label {
  width: 60px;
}
.initSetting-modal .settingBody .settingInfo .alarm-initSetting .setting-box .mailServer-box .mail-group .port .controls {
  width: 150px;
}
.initSetting-modal .settingBody .settingInfo .alarm-initSetting .setting-box .weChatAlarm-box {
  margin: 0;
  padding: 0;
  border: 0;
  width: 570px;
}
.initSetting-modal .settingBody .settingInfo .alarm-initSetting .setting-box .contract-box {
  width: 570px;
}
.initSetting-modal .settingBody .settingInfo .alarm-initSetting .setting-box .contract-box .control-group {
  float: left;
}
.initSetting-modal .settingBody .settingInfo .alarm-initSetting .setting-box .contract-box .control-group.name {
  width: 90%;
}
.initSetting-modal .settingBody .settingInfo .alarm-initSetting .setting-box .contract-box .contact {
  float: left;
  line-height: 34px;
  margin-left: 10px;
}
.initSetting-modal .settingBody .settingInfo .alarm-initSetting .setting-box .contract-box .nickname-input {
  padding-right: 75px;
}
.initSetting-modal .settingBody .settingInfo .alarm-initSetting .setting-box .contract-box .nickname {
  position: absolute;
  right: 170px;
  line-height: 34px;
}
.initSetting-modal .settingBody .settingInfo .alarm-initSetting .setting-box .contract-box .disableName a {
  color: #666666;
  font-weight: 400;
  font-style: oblique;
}
.initSetting-modal .settingBody .settingInfo .alarm-initSetting .setting-box .setting-save-btn {
  position: absolute;
  width: 575px;
  text-align: right;
  margin-top: -45px;
}
.initSetting-modal .settingBody .settingInfo .alarm-initSetting .setting-box + .setting-box {
  border-top: 1px solid #e5e5e5;
  padding-top: 15px;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting {
  /*新增云管初始化硬盘配置样式*/
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .nodeNameShow {
  font-size: 16px;
  margin-top: 10px;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .step-title .storageStep {
  width: 45%;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .vlan-tips {
  line-height: 32px;
  color: #e74c3c;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .stepThree-title {
  margin-right: 85px;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .storage-box {
  margin-top: 20px;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .storage-box .createStorage {
  width: 90%;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .storage-box .ksnode-pass {
  position: relative;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .storage-box .ksnode-pass i {
  position: absolute;
  left: auto;
  right: 5px;
  top: 2px;
  font-size: 20px;
  cursor: pointer;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .storage-box .control-group .controls .file-box .form-controls {
  width: 82%;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .storage-box .control-group .controls .file-box .file-item {
  width: 18%;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .storage-box .control-group .controls .cephVersion {
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  color: #a1a1a1;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .storage-box .iscsiServer-group .ip-controls {
  width: 45%;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .storage-box .iscsiServer-group .port-label {
  width: 15%;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .storage-box .iscsiServer-group .port-controls {
  width: 23%;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .storage-box .grant-group {
  padding-bottom: 7px;
  margin-bottom: 3px;
  border-bottom: 1px solid #e5e5e5;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .storage-box .grant-group .controls i {
  cursor: pointer;
  position: relative;
  line-height: 1;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .storage-box .grant-group .controls i span {
  color: #51a3ff;
  font-style: normal;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .storage-box .controls-text {
  padding-left: 10px;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .storage-box .target-table .target-name {
  width: 80px;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .storage-box .target-table .control-group {
  margin-bottom: 0;
  width: 350px;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .storage-box .target-table .control-group .controls {
  width: 90%;
  padding-left: 0;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .storage-box .target-table .control-group .radio-item {
  padding-top: 0;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .storage-box .target-table .control-group .iqn-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .storage-box .target-table .device-group {
  width: 515px;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .storage-box .target-table .first-group {
  margin-bottom: 10px;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .storage-box .target-table .radioTarget {
  height: auto !important;
  padding-top: 0 !important;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .storage-box .target-table .radioTarget label {
  border-top: 1px dashed #e5e5e5;
  min-height: 40px !important;
  line-height: 40px !important;
  display: block !important;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .storage-box .target-table .radioTarget label + label {
  margin-left: 0 !important;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .storage-box .target-table .iqn-icon {
  float: right;
  cursor: pointer;
  margin-top: 1px;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .storage-box .target-table .noData {
  text-align: center;
  color: #999999;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .tool-tips {
  color: #e74c3c;
  text-align: center;
  margin-top: 10px;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .nodeName-config {
  padding: 10px 0;
  font-size: 16px;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .nodeName-box {
  overflow: hidden;
  position: relative;
  margin: 15px 0px;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .nodeName-box .congfigMsg {
  float: left;
  width: 15%;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .nodeName-box .config-node {
  float: left;
  width: 85%;
  font-size: 12px;
  max-height: 53px;
  overflow-y: auto;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .nodeName-box .config-node .node {
  float: left;
  padding: 3px 0px;
  margin: 0px 14px 5px 0;
  border: 1px solid #c2d3eb;
  text-align: center;
  width: 10%;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-list {
  background: none;
  border: 1px solid #c2d3eb;
  border-left: none;
  border-right: none;
  border-bottom: none;
  padding-top: 20px;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-list .available-disk {
  float: left;
  width: 10%;
  font-weight: bold;
  line-height: 107px;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-list .no-use-disks {
  min-height: 105px;
  max-height: 210px;
  margin-left: 10%;
  border: 1px dashed #c2d3eb;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-list .no-use-disks li {
  width: 17%;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-list .no-use-disks li .disks-item {
  height: 79px;
  width: 100%;
  border: 1px solid #c2d3eb;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-list .no-use-disks li .disks-item .disk-hhd-bg,
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-list .no-use-disks li .disks-item .disk-ssd-bg {
  height: 45px;
  padding-top: 15px;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-list .no-use-disks li .disks-item .disk-hhd-bg i,
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-list .no-use-disks li .disks-item .disk-ssd-bg i {
  margin-top: 0px;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-list .no-use-disks li .disks-item .disk-out {
  position: relative;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-list .no-use-disks li .disks-item .disk-out .disk-circle {
  position: absolute;
  width: 13px;
  height: 13px;
  top: 4px;
  left: 4px;
  border-radius: 50%;
  background: #f5becc;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-list .no-use-disks li .disks-item .disk-out .disk-capacity {
  width: 69px;
  padding-top: 3px;
  font-size: 14px;
  display: block;
  margin: 8px auto 0;
  border-top: 1px solid #c3cfdb;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-list .no-use-disks li:hover .disks-detail {
  top: 100px;
  display: block;
  border-radius: 4px;
  box-shadow: 0 0 20px #e5e5e5;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-list .no-use-disks li:hover .disks-detail .detail-item {
  margin: 3px 0;
  line-height: 16px;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-list .no-use-disks li:hover .disks-detail .detail-item .title {
  font-weight: normal;
  font-size: 14px;
  padding: 0;
  color: #7991ab;
  border: none;
  width: 45%;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-list .no-use-disks li:hover .disks-item {
  border: 2px solid #51a3ff;
  box-shadow: 0 0 8px rgba(81, 163, 255, 0.60784314);
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-list .no-use-disks li:hover .disks-item .disk-out .disk-circle {
  top: 3px;
  left: 3px;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-list .no-use-disks li:hover .disks-item .disk-out .disk-hhd-bg,
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-list .no-use-disks li:hover .disks-item .disk-out .disk-ssd-bg {
  padding-top: 14px;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-list .no-use-disks li:hover .disks-item .disk-out .disk-hhd-bg i,
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-list .no-use-disks li:hover .disks-item .disk-out .disk-ssd-bg i {
  margin-top: 0px;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-list .no-use-disks li:hover .disks-item .disk-out .disk-capacity {
  width: 69px;
  padding-top: 3px;
  font-size: 14px;
  display: block;
  margin: 7px auto 0;
  border-top: 1px solid #c3cfdb;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-content {
  padding: 0px;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-content .disks-tab {
  overflow: hidden;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-content .disks-tab .disk-name {
  border: 1px solid #c2d3eb;
  border-bottom: none;
  float: left;
  margin-left: 20px;
  padding: 10px 25px;
  font-weight: bold;
  cursor: pointer;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-content .disks-tab .disk-name.active {
  background: #f0f3f5;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-content .disks-group > li .group-content {
  background: none;
  border: none;
  padding: 0;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-content .disks-group > li .group-content .data-content {
  padding: 20px 0px 10px 0;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-content .disks-group > li .group-content .data-content:last-child {
  padding-bottom: 20px;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-content .disks-group > li .group-content .data-content.fc-content {
  border: none;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-content .disks-group > li .group-content .data-content .disk-type {
  float: left;
  width: 10%;
  font-weight: bold;
  line-height: 107px;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-content .disks-group > li .group-content .data-content .disk-data {
  float: left;
  width: 90%;
  border: 1px dashed #c3cfdb;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-content .disks-group > li .group-content .data-content .disk-data > ul {
  width: 100%;
  min-height: 105px;
  max-height: 210px;
  background: #fff;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-content .disks-group > li .group-content .data-content .disk-data > ul .tips {
  line-height: 105px;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-content .disks-group > li .group-content .data-content .disk-data > ul li {
  width: 17%;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-content .disks-group > li .group-content .data-content .disk-data > ul li .disks-item {
  height: 79px;
  width: 100%;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-content .disks-group > li .group-content .data-content .disk-data > ul li .disks-item .disk-out {
  position: relative;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-content .disks-group > li .group-content .data-content .disk-data > ul li .disks-item .disk-out .disk-circle {
  position: absolute;
  width: 13px;
  height: 13px;
  top: 4px;
  left: 4px;
  border-radius: 50%;
  background: #f5becc;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-content .disks-group > li .group-content .data-content .disk-data > ul li .disks-item .disk-out .disk-hhd-bg,
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-content .disks-group > li .group-content .data-content .disk-data > ul li .disks-item .disk-out .disk-ssd-bg {
  height: 45px;
  padding-top: 15px;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-content .disks-group > li .group-content .data-content .disk-data > ul li .disks-item .disk-out .disk-hhd-bg i,
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-content .disks-group > li .group-content .data-content .disk-data > ul li .disks-item .disk-out .disk-ssd-bg i {
  margin-top: 0px;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-content .disks-group > li .group-content .data-content .disk-data > ul li .disks-item .disk-out .disk-capacity {
  width: 68px;
  font-size: 14px;
  display: block;
  margin: 0px auto 0;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-content .disks-group > li .group-content .data-content .disk-data > ul li .disks-item .disks-remove {
  width: 15px;
  height: 15px;
  right: -10px;
  top: -8px;
  background: #e74c3c;
  line-height: 17px;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-content .disks-group > li .group-content .data-content .disk-data > ul li:hover .disks-detail {
  top: 100px;
  display: block;
  border-radius: 4px;
  box-shadow: 0 0 20px #e5e5e5;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-content .disks-group > li .group-content .data-content .disk-data > ul li:hover .disks-detail .detail-item {
  line-height: 19px;
  margin: 3px 0;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-content .disks-group > li .group-content .data-content .disk-data > ul li:hover .disks-detail .detail-item .title {
  font-weight: normal;
  font-size: 14px;
  padding: 0;
  color: #7991ab;
  border: none;
  width: 45%;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-content .disks-group > li .group-content .data-content .disk-data > ul li:hover .disks-detail .detail-item .title-name {
  width: 80px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-content .disks-group > li .group-content .data-content .disk-data > ul li:hover .disks-item {
  border: 2px solid #51a3ff;
  box-shadow: 0 0 8px rgba(81, 163, 255, 0.60784314);
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-content .disks-group > li .group-content .data-content .disk-data > ul li:hover .disks-item .disk-out .disk-circle {
  top: 3px;
  left: 3px;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-content .disks-group > li .group-content .data-content .disk-data > ul li:hover .disks-item .disk-out .disk-hhd-bg,
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-content .disks-group > li .group-content .data-content .disk-data > ul li:hover .disks-item .disk-out .disk-ssd-bg {
  padding-top: 14px;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-content .disks-group > li .group-content .data-content .disk-data > ul li:hover .disks-item .disk-out .disk-hhd-bg i,
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-content .disks-group > li .group-content .data-content .disk-data > ul li:hover .disks-item .disk-out .disk-ssd-bg i {
  margin-top: 0px;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-content .disks-group > li .group-content .data-content .disk-data > ul li:hover .disks-item .disk-out .disk-capacity {
  width: 68px;
  font-size: 14px;
  display: block;
  margin: -1px auto 0;
}
.initSetting-modal .settingBody .settingInfo .storage-initSetting .disks-content .disks-group > li .group-content .data-content .disk-data > ul li:hover .disks-item .disks-remove {
  width: 15px;
  height: 15px;
  right: -11px;
  top: -9px;
  background: #e74c3c;
  line-height: 17px;
}
.initSetting-modal .settingBody .settingInfo .transfer-initSetting .transfer-box {
  width: 90%;
  margin-top: 30px;
}
.initSetting-modal .settingBody .settingInfo .transfer-initSetting .progess-bar {
  margin-top: 10px;
  overflow: hidden;
}
.initSetting-modal .settingBody .settingInfo .transfer-initSetting .progess-bar .failText {
  color: #ff9900;
}
.initSetting-modal .settingBody .settingInfo .transfer-initSetting .progess-bar .progress-step {
  margin-top: 25px;
  background: #f3f3f3;
  border-radius: 2px;
  width: 495px;
  height: 15px;
}
.initSetting-modal .settingBody .settingInfo .transfer-initSetting .progess-bar .progress-step .progress-base {
  background-color: #51a3ff;
  box-shadow: 0 0 2px rgba(192, 57, 43, 0.5);
}
.initSetting-modal .settingBody .settingInfo .transfer-initSetting .progess-bar .progress-step .progress-base.pregress-100 {
  background-image: none;
}
.initSetting-modal .settingBody .settingInfo .transfer-initSetting .progess-bar .progress-step .progress-base.fail-color {
  background-color: #ff9900;
}
.initSetting-modal .settingBody .settingInfo .regionSetting .region {
  text-align: center;
  color: #aeaeae;
  margin-top: 60px;
}
.initSetting-modal .settingBody .settingInfo .regionSetting .region .region-title {
  font-weight: 700;
  font-style: normal;
  font-size: 32px;
  margin-bottom: 8px;
}
.initSetting-modal .settingBody .settingInfo .regionSetting .region .region-tips {
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
}
.initSetting-modal .settingBody .settingInfo .regionSetting .step-title {
  margin-top: 30px;
}
.initSetting-modal .settingBody .settingInfo .regionSetting .step-title .regionStepOne {
  width: 10%;
}
.initSetting-modal .settingBody .settingInfo .regionSetting .step-title .regionStepTwo {
  width: 20%;
}
.initSetting-modal .settingBody .settingInfo .regionSetting .step-title .regionStepThree {
  width: 30%;
}
.initSetting-modal .settingBody .settingInfo .regionSetting .region-btn {
  margin-top: 30px;
  text-align: center;
}
.initSetting-modal .settingBody .notAdmin {
  width: 100%;
  padding-left: 0;
}
.initSetting-modal .modal-footer .lastBtn {
  margin-right: 0;
}
/*数据中心初始化设置*/
.modal-dialog .modal-common.initSetting-modal.initConfig-modal {
  width: 1200px !important;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .close {
  top: -2px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingList {
  width: 20%;
  min-height: 500px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingList .listTitle {
  margin-bottom: 20px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingList .listItem {
  padding-bottom: 0;
  margin-bottom: 15px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingList .listItem + .listItem {
  padding-top: 15px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingList .listItem .title {
  margin: 0;
  overflow: hidden;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingList .listItem .title span {
  color: #51a3ff;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingList .listItem .title span:nth-child(1) {
  float: left;
  color: #666666;
  cursor: pointer;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingList .listItem .title span:nth-child(1):hover {
  color: #51a3ff;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingList .listItem .title .status {
  float: right;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingList .listItem .title .status.statusTrue {
  color: #1abc9c;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo {
  width: 78%;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .config-header {
  position: relative;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .config-header .headTitle {
  padding-bottom: 5px;
  border-bottom: 1px solid #e5e5e5;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .config-header .headTitle span {
  font-size: 16px;
  font-weight: bold;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .disk-congfig .cardConfigMsg {
  padding: 5px 0;
  text-align: center;
  color: #f00;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .disk-congfig .node-title {
  padding: 15px 0;
  font-size: 16px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .disk-congfig .net-banner {
  height: 150px;
  background: url(/img/host_card.png) no-repeat center bottom;
  margin: 10px 100px 25px;
  background-size: contain;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .disk-congfig .net_cardCheck {
  margin: 120px 10px 10px -100px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .disk-congfig .net-show {
  height: 300px;
  max-height: 330px;
  overflow-y: scroll;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .disk-congfig .net-tip {
  height: 38px;
  border: 1px solid #b7cef1;
  border-radius: 4px;
  background: #ebf1fa;
  color: #51a3ff;
  text-align: center;
  line-height: 38px;
  margin-bottom: 32px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .disk-congfig .net-tip i {
  font-size: 16px;
  color: #404b5f;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .disk-congfig .add-node {
  text-align: center;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .node-config {
  margin: 10px 0 10px 0;
  font-size: 16px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .text-center {
  margin-top: 10px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .nics-list {
  background: none;
  border: 1px solid #e5e5e5;
  border-radius: 0px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .nics-list .no-use {
  min-height: 94px;
  max-height: 188px;
  background: #fafafa;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .nics-list .no-use li {
  padding: 10px;
  background: #fafafa;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .nics-list .no-use li .addr .nic-out {
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 3px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .nics-list .no-use li .addr .nic-out .nic-info {
  margin: 5px 0 0 10px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .nics-bonds {
  margin-bottom: 30px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .nics-bonds .bond-merged .bond .bond-inner .bond-desc {
  padding: 0px;
  margin-bottom: 10px;
  border: 1px solid #e5e5e5;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .nics-bonds .bond-merged .bond .bond-inner .bond-desc .select2-box {
  width: 90%;
  left: 5%;
  bottom: 10px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .nics-bonds .bond-merged .bond .bond-inner:hover .bond-desc {
  background-color: transparent;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .nics-bonds .bond-merged .bond .bond-inner:hover .bond-desc .num {
  color: #51a3ff;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .nics-bonds .bond-merged .bond .bond-inner:hover .bond-desc .text {
  color: #7991ab;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .nics-bonds .bond-merged .bond .bond-inner .caret {
  top: 15px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .nics-bonds .bond-merged .bond.active .bond-desc {
  background: transparent;
  border: 1px solid #e5e5e5;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .nics-bonds .bond-merged .bond.active .bond-desc .num {
  color: #51a3ff;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .nics-bonds .bond-merged .bond.active .bond-desc .text {
  color: #7991ab;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .nics-choose .inner .addr .nic-out {
  padding: 10px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .nics-choose .inner .addr .nic-out .nic-info {
  margin: 5px 0 0 8px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-list {
  background: none;
  border: 1px solid #c2d3eb;
  border-left: none;
  border-right: none;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-list .available-disk {
  float: left;
  width: 11%;
  text-align: center;
  font-weight: bold;
  line-height: 105px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-list .no-use-disks {
  min-height: 105px;
  margin-left: 11%;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-list .no-use-disks li {
  width: 14%;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-list .no-use-disks li .disks-item {
  height: 79px;
  width: 100%;
  border: 1px solid #c2d3eb;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-list .no-use-disks li .disks-item .disk-hhd-bg,
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-list .no-use-disks li .disks-item .disk-ssd-bg {
  height: 45px;
  padding-top: 15px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-list .no-use-disks li .disks-item .disk-hhd-bg i,
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-list .no-use-disks li .disks-item .disk-ssd-bg i {
  margin-top: 0px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-list .no-use-disks li .disks-item .disk-out {
  position: relative;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-list .no-use-disks li .disks-item .disk-out .disk-circle {
  position: absolute;
  width: 13px;
  height: 13px;
  top: 4px;
  left: 4px;
  border-radius: 50%;
  background: #f5becc;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-list .no-use-disks li .disks-item .disk-out .disk-capacity {
  width: 90%;
  padding-top: 3px;
  font-size: 14px;
  display: block;
  margin: 8px auto 0;
  border-top: 1px solid #c3cfdb;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-list .no-use-disks li:hover .disks-detail {
  top: 100px;
  display: block;
  border-radius: 4px;
  box-shadow: 0 0 20px #e5e5e5;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-list .no-use-disks li:hover .disks-detail .detail-item {
  margin: 3px 0;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-list .no-use-disks li:hover .disks-detail .detail-item .title {
  font-weight: normal;
  font-size: 14px;
  padding: 0;
  color: #7991ab;
  border: none;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-list .no-use-disks li:hover .disks-detail .detail-item .title-name {
  width: 80px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-list .no-use-disks li:hover .disks-item {
  width: 100%;
  border: 2px solid #51a3ff;
  box-shadow: 0 0 8px rgba(81, 163, 255, 0.60784314);
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-list .no-use-disks li:hover .disks-item .disk-out .disk-circle {
  top: 3px;
  left: 3px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-list .no-use-disks li:hover .disks-item .disk-out .disk-hhd-bg,
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-list .no-use-disks li:hover .disks-item .disk-out .disk-ssd-bg {
  padding-top: 14px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-list .no-use-disks li:hover .disks-item .disk-out .disk-hhd-bg i,
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-list .no-use-disks li:hover .disks-item .disk-out .disk-ssd-bg i {
  margin-top: 0px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-list .no-use-disks li:hover .disks-item .disk-out .disk-capacity {
  width: 90%;
  padding-top: 3px;
  font-size: 14px;
  display: block;
  margin: 7px auto 0;
  border-top: 1px solid #c3cfdb;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-content {
  padding: 0px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-content .disks-tab {
  overflow: hidden;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-content .disks-tab .disk-name {
  border: 1px solid #c2d3eb;
  border-bottom: none;
  float: left;
  margin-left: 20px;
  padding: 10px 25px;
  font-weight: bold;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-content .disks-tab .disk-name.active {
  background: #f0f3f5;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-content .disks-group {
  border: 1px solid #c3cfdb;
  background: #f0f3f5;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-content .disks-group > li {
  padding: 15px 20px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-content .disks-group > li .group-content {
  background: none;
  border: none;
  padding: 0;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-content .disks-group > li .group-content .data-content.noCephTab ul {
  width: 100%;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-content .disks-group > li .group-content .data-content:nth-child(2) {
  margin-top: 20px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-content .disks-group > li .group-content .data-content.cephTab ul > li {
  width: 14%;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-content .disks-group > li .group-content .data-content .disk-type {
  float: left;
  width: 10%;
  font-weight: bold;
  line-height: 105px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-content .disks-group > li .group-content .data-content > ul {
  float: left;
  width: 90%;
  min-height: 105px;
  border: 1px solid #c3cfdb;
  background: #fff;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-content .disks-group > li .group-content .data-content > ul li {
  width: 13%;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-content .disks-group > li .group-content .data-content > ul li .disks-item {
  width: 100%;
  height: 79px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-content .disks-group > li .group-content .data-content > ul li .disks-item .disk-out {
  position: relative;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-content .disks-group > li .group-content .data-content > ul li .disks-item .disk-out .disk-circle {
  position: absolute;
  width: 13px;
  height: 13px;
  top: 4px;
  left: 4px;
  border-radius: 50%;
  background: #f5becc;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-content .disks-group > li .group-content .data-content > ul li .disks-item .disk-out .disk-hhd-bg,
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-content .disks-group > li .group-content .data-content > ul li .disks-item .disk-out .disk-ssd-bg {
  height: 45px;
  padding-top: 15px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-content .disks-group > li .group-content .data-content > ul li .disks-item .disk-out .disk-hhd-bg i,
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-content .disks-group > li .group-content .data-content > ul li .disks-item .disk-out .disk-ssd-bg i {
  margin-top: 0px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-content .disks-group > li .group-content .data-content > ul li .disks-item .disk-out .disk-capacity {
  width: 90%;
  font-size: 14px;
  display: block;
  margin: 0px auto 0;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-content .disks-group > li .group-content .data-content > ul li .disks-item .disks-remove {
  width: 15px;
  height: 15px;
  right: -10px;
  top: -8px;
  background: #e74c3c;
  line-height: 17px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-content .disks-group > li .group-content .data-content > ul li:hover .disks-detail {
  top: 100px;
  display: block;
  border-radius: 4px;
  box-shadow: 0 0 20px #e5e5e5;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-content .disks-group > li .group-content .data-content > ul li:hover .disks-detail .detail-item {
  margin: 3px 0;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-content .disks-group > li .group-content .data-content > ul li:hover .disks-detail .detail-item .title {
  font-weight: normal;
  font-size: 14px;
  padding: 0;
  color: #7991ab;
  border: none;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-content .disks-group > li .group-content .data-content > ul li:hover .disks-detail .detail-item .title-name {
  width: 80px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-content .disks-group > li .group-content .data-content > ul li:hover .disks-item {
  width: 100%;
  border: 2px solid #51a3ff;
  box-shadow: 0 0 8px rgba(81, 163, 255, 0.60784314);
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-content .disks-group > li .group-content .data-content > ul li:hover .disks-item .disk-out .disk-circle {
  top: 3px;
  left: 3px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-content .disks-group > li .group-content .data-content > ul li:hover .disks-item .disk-out .disk-hhd-bg,
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-content .disks-group > li .group-content .data-content > ul li:hover .disks-item .disk-out .disk-ssd-bg {
  padding-top: 14px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-content .disks-group > li .group-content .data-content > ul li:hover .disks-item .disk-out .disk-hhd-bg i,
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-content .disks-group > li .group-content .data-content > ul li:hover .disks-item .disk-out .disk-ssd-bg i {
  margin-top: 0px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-content .disks-group > li .group-content .data-content > ul li:hover .disks-item .disk-out .disk-capacity {
  width: 90%;
  font-size: 14px;
  display: block;
  margin: -1px auto 0;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .host-box .disks-content .disks-group > li .group-content .data-content > ul li:hover .disks-item .disks-remove {
  width: 15px;
  height: 15px;
  right: -11px;
  top: -9px;
  background: #e74c3c;
  line-height: 17px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .info-box {
  overflow: hidden;
  padding: 100px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .info-box .left-icon {
  float: left;
  width: 20%;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .info-box .left-icon .icon-aw-virtual-error:before {
  font-size: 80px;
  padding-top: 10px;
  color: #f39c12;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .info-box .right-msg {
  float: left;
  line-height: 30px;
  width: 80%;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .info-box .right-msg ul li .control-group {
  position: relative;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .info-box .right-msg ul li .control-group .control-label {
  width: 1%;
  padding-top: 12px;
  color: #e74c3c;
  line-height: 15px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .info-box .right-msg ul li .control-group .controls {
  padding-left: 15px;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .info-box .right-msg ul li .control-group .controls .radio-item {
  height: 26px;
  width: 27%;
  float: left;
}
.modal-dialog .modal-common.initSetting-modal.initConfig-modal .modal-body .settingBody .settingInfo .info-box .right-msg ul li .control-group .control-msg {
  position: absolute;
  left: 26%;
  line-height: 16px;
  padding-top: 10px;
  color: #f39c12;
}
.alert-modal {
  width: 450px !important;
}
.alert-modal .close {
  position: absolute;
  right: 13px;
  top: 10px;
  z-index: 1;
  font-size: 30px;
  font-weight: normal;
  line-height: 1;
  color: #999999;
  text-shadow: none;
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  float: none;
  opacity: 1;
  filter: alpha(opacity=100);
}
.alert-modal .transferTips {
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: #f39c12;
  text-align: center;
  margin-top: 75px;
  margin-bottom: 65px;
}
.alert-modal .netConfigureTitle {
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: #000;
  text-align: center;
  margin-top: 15px;
}
.alert-modal .progess-bar {
  height: 100px;
  margin-top: 55px;
  overflow: hidden;
}
.alert-modal .progess-bar .statusText {
  color: #1abc9c;
}
.alert-modal .progess-bar .failText {
  color: #ff9900;
}
.alert-modal .progess-bar .progress-step {
  margin-top: 25px;
  background: #f3f3f3;
  border-radius: 2px;
  width: 410px;
  height: 15px;
}
.alert-modal .progess-bar .progress-step .progress-base {
  background-color: #51a3ff;
  box-shadow: 0 0 2px rgba(192, 57, 43, 0.5);
}
.alert-modal .progess-bar .progress-step .progress-base.pregress-100 {
  background-image: none;
}
.alert-modal .progess-bar .progress-step .progress-base.fail-color {
  background-color: #ff9900;
}
.k8s-modal .cluster-first {
  margin-top: 20px;
}
.k8s-modal .cluster-first .control-group .controls {
  width: 81%;
}
.k8s-modal .cluster-first .control-group .controls .add {
  float: left;
  line-height: 34px;
  margin-left: 10px;
  color: #666666;
}
.k8s-modal .cluster-first .control-group .controls .add i {
  color: #51a3ff;
}
.k8s-modal .cluster-first .node-selectors-group .label-name {
  text-align: left;
  margin-left: 20%;
  width: 17%;
  float: left;
  margin-bottom: 10px;
  line-height: 32px;
  font-size: normal;
}
.k8s-modal .cluster-first .node-selectors-group .select-key {
  width: 25%;
}
.k8s-modal .cluster-first .node-selectors-group .select-type {
  width: 9%;
}
.k8s-modal .cluster-first .node-selectors-group .select-value {
  width: 25%;
}
.k8s-modal .cluster-first .node-selectors-group .delete {
  right: 10px;
}
.k8s-modal .cluster-first .labels-group .left {
  width: 37%;
  margin-left: 22%;
}
.k8s-modal .cluster-first .labels-group .right {
  width: 35%;
}
.k8s-modal .cluster-first .labels-group .delete {
  right: 10px;
}
.k8s-modal .cluster-next .labels-group .label-name {
  margin-left: 3%;
  line-height: 32px;
}
.k8s-modal .cluster-next .labels-group .add-lable {
  float: left;
}
.k8s-modal .cluster-next .container-group {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.k8s-modal .cluster-next .control-ext .controls {
  width: 82%;
}
.no-page-absolute .ng-table-pager {
  position: static;
}
.no-page-absolute .ng-table-pager .pagination {
  margin-bottom: 0;
}
.bar-percent {
  width: 60%;
  display: inline-block;
}
.bar-percent .progress-bar {
  transition: none;
}
.plugin-detail {
  height: 100%;
}
.plugin-detail .log-wrap {
  border: 1px solid #e5e5e5;
}
.plugin-detail .log-wrap .table {
  border: none;
}
.plugin-detail .log-wrap .table tbody tr td {
  background: #d0f2dd;
  border-bottom: 1px solid #fff;
}
.plugin-detail .log-wrap .table tbody tr th {
  font-weight: 600;
  height: 30px;
}
.plugin-detail .log-wrap pre {
  height: auto;
  max-height: 600px;
  overflow: auto;
  background-color: #eeeeee;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: normal;
}
.nowrap {
  white-space: nowrap !important;
}
.logined .global-loading,
.auth .global-loading {
  display: none;
}
.modal-open .global-loading {
  z-index: 1055;
  position: fixed;
}
.auth .shows.global-loading {
  display: none;
}
.animate-open .global-loading {
  z-index: 2000;
}
.global-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.1);
  display: none;
  z-index: 999;
}
.global-loading.shows {
  display: block;
}
.global-loading span {
  position: fixed;
  display: block;
  text-align: center;
  top: 50%;
  left: 50%;
  margin-left: -35px;
  margin-top: 40px;
  color: #666;
}
.global-loading .loading-icon {
  position: fixed;
}
.loading-icon {
  width: 64px;
  height: 64px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -32px;
  margin-top: -32px;
  border: 4px solid #fff;
  border-radius: 50%;
  background: #bbbbbb;
}
.loading-icon:after,
.loading-icon:before {
  content: "";
  top: 28px;
  left: 28px;
  width: 4px;
  height: 16px;
  margin-top: -2px;
  margin-left: -2px;
  border-radius: 4px;
  -webkit-transform-origin: 2px 2px 0;
  -moz-transform-origin: 2px 2px 0;
  -ms-transform-origin: 2px 2px 0;
  -o-transform-origin: 2px 2px 0;
  transform-origin: 2px 2px 0;
  display: block;
  position: absolute;
  background: #fff;
  -webkit-animation: timer-loader 1250ms infinite linear;
  -moz-animation: timer-loader 1250ms infinite linear;
  -o-animation: timer-loader 1250ms infinite linear;
  animation: timer-loader 1250ms infinite linear;
  -webkit-animation-delay: -625ms;
  -moz-animation-delay: -625ms;
  -o-animation-delay: -625ms;
  animation-delay: -625ms;
}
.loading-icon:before {
  height: 24px;
}
.loading-icon:after {
  animation-delay: -7.5s;
  animation-duration: 15s;
}
@-webkit-keyframes timer-loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes timer-loader {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes timer-loader {
  0% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes timer-loader {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.flowManage-view .detail-info h4 {
  font-weight: normal;
}
.flowManage-view .detail-info .img-detail {
  border: 1px solid #e5e5e5;
  text-align: center;
}
.flow-moadl .departSelect {
  text-align: left !important;
}
.flow-moadl .createFlow-step3-view {
  height: 250px;
}
.flow-moadl .createFlow-step3-view .error {
  color: #e74c3c;
}
.flow-moadl .createFlow-step3-view .flow-img-box {
  width: 500px;
  float: left;
}
.flow-moadl .createFlow-step3-view .flow-img-box img {
  width: 100%;
  height: 100%;
}
.flow-moadl .createFlow-step3-view .flow-approver-box {
  box-sizing: border-box;
  padding: 0 10px;
  width: 210px;
  float: left;
  height: 300px;
  border-left: 1px solid #c2d3eb;
  position: relative;
  overflow: auto;
}
.flow-moadl .createFlow-step3-view .flow-approver-box.approver-list-box {
  width: 240px;
}
.flow-moadl .createFlow-step3-view .flow-approver-box .error {
  color: #e74c3c;
}
.flow-moadl .createFlow-step3-view .flow-approver-box .tree-nav-box {
  text-align: left;
}
.flow-moadl .createFlow-step3-view .flow-approver-box .tree-nav-box span {
  color: #4e80f5;
  cursor: pointer;
}
.flow-moadl .createFlow-step3-view .flow-approver-box .tree-nav-box span i {
  color: #666666;
}
.flow-moadl .createFlow-step3-view .flow-approver-box .approver-checked-box {
  height: 25px;
  border: 1px solid #c2d3eb;
  line-height: 25px;
  text-align: left;
  width: 75px;
  margin-top: 5px;
  font-size: 12px;
  margin-left: 21px;
}
.flow-moadl .createFlow-step3-view .flow-approver-box .approver-checked-box .approver-show-box {
  display: inline-block;
  width: 55px;
  padding-left: 5px;
  line-height: 25px;
  text-align: left;
  height: 25px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.flow-moadl .createFlow-step3-view .flow-approver-box .approver-checked-box i {
  padding: 0 5px;
  float: right;
  cursor: pointer;
}
.flow-moadl .createFlow-step3-view .flow-approver-box:nth-child(3) {
  border-left: 1px dashed #c2d3eb;
}
.flow-moadl .createFlow-step3-view .flow-approver-box li {
  text-align: left;
  line-height: 40px;
  height: 40px;
  border-bottom: 1px solid #c2d3eb;
}
.flow-moadl .createFlow-step3-view .flow-approver-box li div {
  float: left;
  width: 66%;
}
.flow-moadl .createFlow-step3-view .flow-approver-box li div .radiobox {
  line-height: 20px;
  height: 20px;
  margin: 0;
  cursor: pointer;
  position: relative;
  font-weight: normal;
}
.flow-moadl .createFlow-step3-view .flow-approver-box li div .radiobox i,
.flow-moadl .createFlow-step3-view .flow-approver-box li div .radiobox .iconfont {
  margin-right: 5px;
  color: #c2d3eb;
  width: 20px;
  height: 18px;
  font-size: 16px;
  vertical-align: middle;
  font-style: normal;
}
.flow-moadl .createFlow-step3-view .flow-approver-box li div .radiobox i:before,
.flow-moadl .createFlow-step3-view .flow-approver-box li div .radiobox .iconfont:before {
  content: "\E6B4";
  font-weight: lighter;
}
.flow-moadl .createFlow-step3-view .flow-approver-box li div .radiobox input {
  position: absolute;
  left: -9999px;
  opacity: 0;
}
.flow-moadl .createFlow-step3-view .flow-approver-box li div .radiobox input:checked + i:before {
  content: "\E6B3";
  color: #4e80f5;
}
.flow-moadl .createFlow-step3-view .flow-approver-box li div input {
  position: relative;
  top: 2px;
  width: 10%;
}
.flow-moadl .createFlow-step3-view .flow-approver-box li div span {
  margin-left: 10px;
  width: auto;
  text-align: left;
  width: 75%;
}
.flow-moadl .createFlow-step3-view .flow-approver-box li span {
  float: right;
  width: 34%;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.flow-moadl .createFlow-step3-view .flow-approver-box .approver-click-box {
  margin-bottom: 30px;
}
.flow-moadl .createFlow-step3-view .flow-approver-box .approver-click-box.emailCtrl {
  margin-left: -10px;
  position: absolute;
  bottom: 0px;
  left: 42px;
}
.flow-moadl .createFlow-step3-view .flow-approver-box .approver-click-box.emailCtrl .email-ctrl-box {
  line-height: 32px;
}
.flow-moadl .createFlow-step3-view .flow-approver-box .approver-click-box span .controls {
  display: inline-block;
  margin-top: 10px;
  position: relative;
}
.flow-moadl .createFlow-step3-view .flow-approver-box .approver-click-box span .controls label {
  margin-bottom: -10px !important;
}
.flow-moadl .createFlow-step3-view .flow-approver-box .approver-click-box .approver-add-btn {
  color: #4e80f5;
  cursor: pointer;
}
.pengdingTicket-view .control-group {
  margin-bottom: 15px;
  cursor: pointer;
}
.pengdingTicket-view .control-group .control-label {
  width: auto !important;
}
.pengdingTicket-view .bg-repeal {
  background: #4a6583;
}
.pengdingTicket-view .bg-close {
  background: #666666;
}
.pengdingTicket-view .status-text {
  width: 68px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  padding: 0;
}
.info-detail-view .animateContent {
  width: 70% !important;
}
.info-detail-view .detail-left-box {
  width: 100%;
}
.Warning {
  color: #f39c12;
}
.ticketApply-page .res-apply-box {
  padding: 10px 0 150px 0;
  border-bottom: 1px solid #c2d3eb;
}
.ticketApply-page h6 {
  line-height: 40px;
  font-size: 16px;
  color: #888888;
  font-weight: 400;
  font-style: normal;
}
.ticketApply-page .apply-box {
  height: 136px;
  width: 31%;
  margin-top: 5px;
  border: solid #e5e5e5 1px;
  margin-right: 2%;
  display: inline-block;
}
.ticketApply-page .apply-box:hover {
  border: solid #36a0ec 1px;
}
.ticketApply-page .apply-box:hover .apply-type .icon-box {
  color: #51a3ff;
}
.ticketApply-page .apply-box:hover .apply-type .apply-click-box {
  color: #51a3ff;
}
.ticketApply-page .apply-box .apply-type div {
  float: left;
  height: 100%;
}
.ticketApply-page .apply-box .apply-type .icon-box {
  width: 30%;
  font-size: 60px;
  line-height: 136px;
  text-align: center;
  color: #888888;
}
.ticketApply-page .apply-box .apply-type .name-box {
  width: 20%;
  line-height: 136px;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: #666666;
  text-align: right;
  white-space: nowrap;
}
.ticketApply-page .apply-box .apply-type .apply-click-box {
  width: 50%;
  box-sizing: border-box;
  padding-left: 50px;
  line-height: 136px;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: #3068d5;
  cursor: pointer;
}
.detail-left-box {
  width: 75%;
  float: left;
}
.detail-left-box .ticket-border-box {
  border-right: 1px solid #c2d3eb;
}
.ticket-btn {
  width: 110px !important;
}
.detail-right-box {
  width: 24%;
  float: right;
  padding-top: 50px;
}
.detail-right-box h5 {
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: #666666;
}
.detail-right-box ul {
  margin-top: 30px;
}
.detail-right-box ul li {
  width: 100%;
  height: 60px;
  position: relative;
}
.detail-right-box ul li div {
  float: left;
}
.detail-right-box ul li div.img-box {
  position: absolute;
  top: 3px;
  left: 0px;
  height: 60px;
  width: 12px;
  background: url(/img/line.png) no-repeat center;
}
.detail-right-box ul li div.circle {
  width: 12px;
  height: 12px;
  border: #51a3ff 2px solid;
  border-radius: 50%;
  margin-top: 3px;
}
.detail-right-box ul li div.approve-info {
  margin-left: 10px;
  width: 80%;
}
.detail-right-box ul li div.approve-info span {
  font-size: 14px;
}
.detail-right-box ul li div.approve-info .approver-name {
  display: inline-block;
  width: 65%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.detail-right-box ul li div.approve-info .approve-result {
  float: right;
  font-size: 14px;
  color: #51a3ff;
}
.detail-right-box ul li div.approve-info p {
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  color: #bcbcbc;
}
.form_date .form-control {
  color: #666666;
}
.form_date .input-group-addon {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-left: none;
  border-radius: 0 2px 2px 0;
}
.ticketReport-page .status-text {
  width: 68px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  padding: 0;
}
.ticketReport-page .control-group {
  width: 20% !important;
}
.ticketReport-page .setting-group {
  float: right;
}
.ticketReport-page .box {
  border-bottom: none !important;
  margin-bottom: 0px !important;
}
.insApply-view .table-content {
  padding-bottom: 25px;
}
.insApply-view table {
  font-size: 12px !important;
}
.insApply-view .flavor-control {
  clear: both;
}
.insApply-view .psw-control-group {
  width: 48.5% !important;
  float: left;
  padding-left: 5px;
}
.insApply-view .psw-control-group .control-label {
  width: 34% !important;
}
.insApply-view .psw-control-group .controls {
  width: 66% !important;
}
.insApply-view .btn-item-group .type-btn {
  margin-right: 10px;
  margin-top: 10px;
  min-width: 60px;
}
.insApply-view .edit-name {
  width: auto !important;
}
.insApply-view .edit-name span {
  max-width: 68px !important;
}
.zr-topo .btn.btn-info.btn-scale,
.zr-topo .btn.btn-info.btn-reduce {
  background: transparent;
  border: none;
  color: #999;
  padding: 6px 5px;
  min-width: 35px;
}
.zr-topo .topo-main {
  min-height: 820px;
  overflow-y: hidden;
}
.zr-topo .topo-main div {
  overflow: visible !important;
}
.zr-topo .topo-detail .animateContent {
  width: 35%;
}
.zr-topo .topo-detail .animateContent.animateIn {
  top: 70px;
  position: fixed;
  height: 100%;
}
.zr-topo .topo-detail .animateContent.animateIn dt {
  width: 155px;
  padding: 0 0 0 30px;
}
.zr-topo .topo-detail .animateContent.animateIn dd {
  margin-left: 155px;
  word-wrap: break-word;
}
.zr-topo .topo-detail .animateContent .detail-title {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.zr-topo .topo-detail .animateContent .detail-title a {
  font-size: 18px;
}
.zr-topo .topo-detail .animateContent .detail-title strong {
  font-size: 16px;
  height: 18px;
  line-height: 18px;
  font-weight: normal;
}
.zr-topo .topo-detail .animateContent .detail-info {
  padding-top: 0px;
}
.zr-topo .topo-detail .animateContent .detail-info dd .table {
  margin: 10px 0;
}
.zr-topo .topo-detail .animateContent .detail-info dd .table tr th {
  padding: 5px 15px;
  font-weight: normal;
  border-right: 1px solid #e5e5e5;
}
.zr-topo .topo-detail .animateContent .detail-info dd .table tr td {
  padding: 5px 15px;
  border-right: 1px solid #e5e5e5;
  height: 32px;
}
.zr-topo .topo-detail .animateContent .detail-info dd .table tr td .lg {
  width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.zr-topo .topo-detail .animateContent .detail-info dd .bg {
  font-size: 12px;
  line-height: 1;
  padding: 4px 6px;
  color: #ffffff;
  border-radius: 2px;
  display: inline-block;
  white-space: nowrap;
}
.zr-topo .topo-detail .animateContent .detail-info form .ip_pool .control-label {
  float: left;
  width: 17%;
  margin-bottom: 0;
  line-height: 32px;
  text-align: right;
  font-weight: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}
.zr-topo .topo-detail .animateContent .detail-info form .ip_pool .group {
  position: relative;
  width: 83%;
  float: left;
  padding-left: 25px;
}
.zr-topo .topo-detail .animateContent .detail-info form .ip_pool .group.repeat {
  margin-left: 17%;
}
.zr-topo .topo-detail .animateContent .detail-info form .ip_pool .group .control-group {
  width: 50%;
  float: left;
}
.zr-topo .topo-detail .animateContent .detail-info form .ip_pool .group .control-group .controls {
  width: 70%;
}
.zr-topo .topo-detail .animateContent .detail-info form .ip_pool .group .del {
  position: absolute;
  right: 0;
  top: 7px;
}
.zr-topo .topo-detail .animateContent .detail-info form .ip_pool .group .del a {
  color: #e74c3c;
}
.zr-topo .topo-detail .animateContent .detail-info form .ip_pool .add-subpool {
  margin-left: 23%;
  float: left;
  margin-bottom: 10px;
}
.zr-topo .topo-detail .animateContent .detail-info .footer {
  margin-top: 40px;
  text-align: center;
}
.table-nowrap td {
  white-space: nowrap;
}
.cvm-view {
  padding-top: 10px;
}
.setting-show .nav.nav-tabs.top-nav {
  top: 57px;
}
.setting-show .billing .control-group.top {
  top: 65px;
}
.setting-show .page-inner .tab-offset-right > .nav-tabs {
  top: 66px;
}
.setting-show.setting-password .nav.nav-tabs.top-nav {
  top: 107px;
}
.setting-show.setting-password .billing .control-group.top {
  top: 112px;
}
.setting-show.setting-password .page-inner .tab-offset-right > .nav-tabs {
  top: 114px;
}
.setting-show .sec-setting-page .control-group.top {
  top: 65px;
}
.sec-setting-page .setting-box {
  margin: 60px auto;
  width: 1022px;
}
.sec-setting-page .setting-box .title {
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 16px;
}
.sec-setting-page .setting-box .set-wrap {
  border: 1px solid #d9e3f2;
  padding: 20px;
  min-height: 240px;
}
.sec-setting-page .setting-box .set-wrap .control-group {
  padding-bottom: 20px;
  border-bottom: dashed 1px #c2d3eb;
  margin-bottom: 15px;
}
.sec-setting-page .setting-box .set-wrap .desc {
  margin-left: 70px;
}
.sec-setting-page .setting-box .set-wrap .desc p {
  color: #999999;
  height: 32px;
  line-height: 32px;
}
.sec-setting-page .setting-box .btn-wrap {
  height: 100px;
  line-height: 100px;
}
.sec-setting-page .alert-tip {
  color: #51a3ff;
  height: 42px;
  background: #f1f6fd;
  line-height: 42px;
  border: 1px solid #c2d3eb;
  padding-left: 20px;
  margin-bottom: 15px;
}
.sec-setting-page .control-group.top {
  position: absolute;
  left: 115px;
  top: 10px;
  width: 200px;
}
.sec-setting-page .control-group.top .control-label {
  width: 30%;
  line-height: 34px;
}
.sec-setting-page .control-group.top .controls {
  width: 70%;
}
.sec-setting-page .pwd-strategy h4 {
  font-weight: bold;
  margin-bottom: 20px;
}
.sec-setting-page .pwd-strategy .row-item {
  height: 100px;
}
.sec-setting-page .pwd-strategy .controls {
  padding-left: 0;
}
.sec-setting-page .pwd-strategy .radio-item .radio {
  display: block;
  margin-left: 0;
  margin-bottom: 10px;
}
.sec-setting-page .pwd-strategy .hint-msg {
  vertical-align: 8px;
  color: #999;
  font-size: 12px;
  padding-left: 10px;
}
.sec-setting-page .pwd-strategy .row {
  margin-bottom: 10px;
}
.sec-setting-page .pwd-strategy .row .col-md-1 {
  margin-top: 4px;
}
.sec-setting-page .pwd-strategy .row .error {
  color: #e74c3c;
  font-size: 12px;
}
.sec-setting-page .pwd-strategy input[type="text"] {
  width: 200px;
}
.lock-set-modal .control-group .control-label.sub {
  position: relative;
}
.lock-set-modal .control-group .control-label .sub-label {
  position: absolute;
  top: 20px;
  right: 5px;
  color: #999;
  font-size: 12px;
}
.lock-set-modal .control-group .controls {
  width: 61%;
}
.lock-set-modal .control-group .controls .del {
  position: absolute;
  right: 0;
  top: 0px;
  font-size: 30px;
  font-weight: normal;
  line-height: 32px;
  height: 32px;
  color: #999999;
  text-shadow: none;
  padding: 0 10px;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  float: none;
  opacity: 1;
}
.lock-set-modal .alert-tip {
  color: #51a3ff;
  height: 42px;
  line-height: 28px;
  padding-left: 20px;
  padding-top: 5px;
  text-align: left;
  border-top: 1px solid #e5e5e5;
  border-style: dotted;
}
.lock-set-modal .alert-tip .left,
.lock-set-modal .alert-tip .right {
  float: left;
}
.service-tip {
  height: 52px;
  line-height: 52px;
  text-align: center;
  font-size: 16px;
  color: #666;
  border: 1px solid #e5e5e5;
}
.table-content .service-tip {
  border-top: 0;
  margin-top: -15px;
}
.plugin-ctrl {
  display: none;
}
.plugin-ctrl.shows {
  display: block;
}
.plugin-ctrl .plugin-ctrl-text {
  font-size: 20px;
  text-align: center;
  color: #999;
  padding-top: 170px;
}
.pvmview-plugin .col-4 {
  width: 50% !important;
}
.snapshots-page-inner .row {
  margin-bottom: 10px;
}
.snapshots-page-inner .row .col-md-1 {
  padding-top: 5px;
}
.collapse-title {
  cursor: pointer;
}
.monitor-client-modal.unfold {
  width: auto !important;
}
.monitor-client-modal .modal-body .left-box {
  position: relative;
  float: left;
}
.monitor-client-modal .modal-body .left-box.fold {
  width: 100%;
}
.monitor-client-modal .modal-body .left-box.unfold {
  width: 65%;
}
.monitor-client-modal .modal-body .left-box .fold-arrow {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.monitor-client-modal .modal-body .left-box .fold-arrow.unfold-arrow {
  color: #999999;
}
.monitor-client-modal .modal-body .left-box .tab-offset-right.phyinstances .nav-tabs {
  padding-left: 0px;
  border-bottom: none;
}
.monitor-client-modal .modal-body .left-box .tab-offset-right.phyinstances .nav-tabs li.active a {
  border-bottom-color: #c2d3eb;
}
.monitor-client-modal .modal-body .right-box {
  width: 35%;
  min-height: 420px;
  float: right;
  padding: 10px 35px;
}
.monitor-client-modal .modal-body .right-box h4 {
  font-weight: bold;
}
.monitor-client-modal .modal-body .right-box .v-wrap {
  margin-top: 10px;
  height: 350px;
  overflow-y: scroll;
}
.monitor-client-modal .modal-body .right-box .v-wrap li {
  line-height: 28px;
}
.monitor-client-modal .modal-body .right-box .v-wrap.win {
  overflow-y: hidden;
}
.monitor-client-modal .modal-body fieldset {
  margin-bottom: 25px;
}
.monitor-client-modal .modal-body fieldset .frag {
  height: auto;
  line-height: 24px;
  border: 1px solid #e5e5e5;
  padding: 5px 20px;
  margin: 15px 0 0;
  background: #fafafa;
}
.aggregate-modal .control-label {
  width: 17%;
  margin-bottom: 0;
  line-height: 32px;
  text-align: right;
  font-weight: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
.aggregate-modal .group {
  width: 80%;
  position: relative;
  margin-left: 17%;
}
.aggregate-modal .group .control-group {
  width: 50%;
  float: left;
}
.aggregate-modal .group .control-group .control-label {
  width: 15%;
}
.aggregate-modal .group .control-group .controls {
  width: 85%;
}
.aggregate-modal .group .del {
  position: absolute;
  right: -25px;
  top: 5px;
}
/*在firefox下的拖拽不兼容translate位移问题*/
.modal-open .modal .modal-dialog .initSetting-modal {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  left: 24%;
  top: 16%;
}
.modal-open .modal .modal-dialog .initConfig-modal {
  left: 20%;
  top: 16%;
}
.modal-open .modal .modal-dialog .createStorage-modal {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  left: 30%;
  top: 16%;
}
.ceph-detail .animateContent {
  width: 75%;
  position: fixed;
  top: 70px;
}
.table-in-modal .modal-header {
  margin-bottom: 10px !important;
}
.table-in-modal .modal-body {
  overflow: hidden;
  margin-bottom: 0 !important;
}
.table-in-modal .modal-body .pull-right {
  margin-bottom: 10px;
}
.table-in-modal .modal-body .table-content .table tr td {
  padding: 10px 16px !important;
}
.table-in-modal .modal-footer {
  margin-top: 10px !important;
}
.mem-table .mem-select {
  width: 150px;
}
.mem-table .mem-select .active {
  padding: 0;
}
.mem-table .mem-select .ui-select-match .btn {
  padding: 4px 10px;
}
.mem-table .mem-input {
  width: 150px;
}
.mem-table .mem-form .error {
  color: #f00;
  background: none;
}
.sriov-modal .ip-range .control-group {
  float: left;
  width: 48%;
}
.sriov-modal .ip-range .control-group:last-child {
  float: right;
}
.sriov-modal .ip-range .control-label {
  width: 30%;
}
.sriov-modal .ip-range .controls {
  width: 70%;
  padding-left: 10px;
}
.dpdk-node-list {
  width: 150px;
  float: left;
  margin-right: 7px;
}
.numa-msg {
  padding: 5px 0 0 10px;
  font-size: 12px;
}
.bigmem-list {
  padding: 5px 0 0 10px;
}
.bigmem-list li {
  line-height: 22px;
  color: #e67e22;
  font-size: 12px;
}
.nettype-vlan-list li {
  padding-right: 50px;
  position: relative;
  overflow: hidden;
}
.nettype-vlan-list li .control-group {
  width: 48%;
  float: left;
}
.nettype-vlan-list li .control-group:last-child {
  float: right;
}
.nettype-vlan-list li .opt {
  position: absolute;
  right: 7px;
  top: 7px;
}
.no-form-error-bg.table .error {
  color: #f00;
  background: none;
  padding: 0;
}
.set-detail {
  display: inline-block;
  position: relative;
}
.set-detail .set-list {
  position: absolute;
  bottom: 100%;
  right: 0;
  width: 220px;
  display: none;
  padding: 10px;
  background: #fff;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
}
.set-detail .set-list ul {
  max-height: 340px;
  overflow-y: auto;
}
.set-detail .set-list .clear-btn {
  width: 100%;
}
.set-detail:hover .set-list {
  display: block;
}
.set-detail .blue {
  text-decoration: underline;
}
.set-detail .set-item {
  line-height: 22px;
  margin-bottom: 10px;
}
.set-detail .set-item .label-text {
  display: inline-block;
  font-weight: bold;
}
.set-detail .set-item .value {
  max-width: 90px;
  word-break: break-all;
  display: inline-block;
}
.group-list-radio .switch-btn {
  display: block;
  overflow: hidden;
}
.group-list-radio .switch-btn > span {
  float: left;
  margin-top: 3px;
}
.group-list-radio .switch-btn > label {
  float: right;
}
.radio-group .radio-inline-item {
  display: inline-block;
  line-height: 26px;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  width: 80px;
  text-align: center;
  cursor: pointer;
}
.radio-group .radio-inline-item:hover {
  color: #51a3ff;
}
.radio-group .radio-inline-item + .radio-inline-item {
  border-left: none;
}
.radio-group .active {
  color: #51a3ff;
  border-color: #51a3ff;
  border-left: 1px solid #51a3ff !important;
}
.consume {
  margin-bottom: 15px;
}
.consume .control-group {
  width: auto;
}
.consume .control-group + .control-group {
  margin-left: 20px;
}
.consume .control-group .control-label {
  width: 80px;
}
.consume .control-group .controls {
  width: auto;
  min-width: 160px;
}
.consume .control-group .controls .ui-select-container {
  width: 160px;
}
.consume .control-group .billOptions .choices {
  width: 195px;
}
.consume button.btn-primary,
.consume button.btn-default {
  margin: 0 0 0 10px;
}
.consume .tab-group {
  display: inline-block;
  vertical-align: middle;
}
.consume .tab-group .time-tab {
  border: 1px solid #c2d3eb;
  border-radius: 0px;
  height: 28px;
}
.consume .tab-group .time-tab .btn-time {
  border-right: 1px solid #c2d3eb;
  margin-right: 0;
  padding: 6px;
  min-width: 80px;
  float: none;
}
.consume .tab-group .time-tab .btn-time.active {
  background: none;
  color: #51a3ff;
}
.consume .tab-group .time-tab .calendar-select {
  position: relative;
  display: inline-block;
  min-width: 100px;
  height: 30px;
}
.consume .tab-group .time-tab .calendar-select span {
  padding: 6px 16px;
  margin-right: 20px;
}
.consume .tab-group .time-tab .calendar-select span.active {
  background: none;
  color: #51a3ff;
}
.consume .tab-group .time-tab .calendar-select .time-input {
  display: none;
}
.consume .tab-group .time-tab .calendar-select .glyphicon-calendar {
  position: absolute;
  right: 10px;
  top: 2px;
  cursor: pointer;
}
.consume .tab-group .month-select {
  position: relative;
  display: inline-block;
  width: 160px;
  height: 30px;
}
.consume .tab-group .month-select .glyphicon-calendar {
  position: absolute;
  right: 10px;
  top: 6px;
  cursor: pointer;
}
.drag-disk .disk-title {
  margin-bottom: 10px;
  display: block;
}
.drag-disk .drag-edit {
  width: 73%;
  float: left;
  border-right: 1px solid #e5e5e5;
}
.drag-disk .drag-preview {
  width: 27%;
  float: left;
  padding-left: 5px;
}
.drag-disk .drag-preview .disk-title .btn {
  float: right;
  margin-top: -2px;
}
.drag-disk .drag-preview .drag-preview-list .drag-preview-item {
  border: 1px dashed #e5e5e5;
  margin-bottom: 10px;
  padding: 5px 10px 0 10px;
  position: relative;
}
.drag-disk .drag-preview .drag-preview-list .drag-preview-item.active {
  border: 1px dashed #0085fe;
}
.drag-disk .drag-preview .drag-preview-list .placeholder {
  position: absolute;
  z-index: 1;
  width: 100%;
  text-align: center;
  top: 50%;
  left: 0;
  margin-top: -10px;
  color: #ddd;
}
.drag-disk .drag-preview .drag-preview-list .drag-disk-item {
  float: left;
  margin: 0 5px 5px 0;
  position: relative;
  z-index: 4;
}
.drag-disk .drag-preview .drag-preview-list .del {
  position: absolute;
  right: 7px;
  top: 7px;
  z-index: 5;
  color: #ddd;
  cursor: pointer;
}
.drag-disk .drag-preview .drag-preview-list .del:hover {
  color: #0085fe;
}
.drag-disk .drag-nouse {
  border-right: 1px solid #e5e5e5;
  padding-right: 15px;
  width: 18%;
  float: left;
  min-height: 282px;
}
.drag-disk .drag-nouse ul li {
  margin-bottom: 10px;
  position: relative;
}
.drag-disk .drag-nouse ul li:hover .drag-disk-info {
  display: block;
}
.drag-disk .drag-nouse .drag-disk-item {
  cursor: pointer;
}
.drag-disk .drag-inuse {
  width: 82%;
  float: left;
  padding: 0 10px 0 20px;
}
.drag-disk .drag-inuse ul {
  border: 1px dashed #e5e5e5;
  background: #fafafa;
  margin-bottom: 10px;
  padding: 10px 0 0 10px;
  min-height: 102px;
}
.drag-disk .drag-inuse ul li {
  float: left;
  margin: 0 10px 10px 0;
  position: relative;
}
.drag-disk .drag-inuse ul li:hover .drag-disk-info {
  display: block;
}
.drag-disk .drag-inuse ul .del {
  position: absolute;
  width: 20px;
  height: 20px;
  color: #e74c3c;
  background: #fff;
  border-radius: 50%;
  font-size: 18px;
  text-align: center;
  line-height: 20px;
  top: -7px;
  right: -7px;
  cursor: pointer;
}
.drag-disk .drag-preview-source {
  border-bottom: 1px dashed #e5e5e5;
  position: relative;
  min-height: 26px;
}
.drag-disk .drag-preview-source ul li:hover .drag-disk-info {
  display: block;
}
.drag-disk .drag-preview-data {
  padding-top: 5px;
  position: relative;
  min-height: 30px;
}
.drag-disk .drag-preview-data ul li:hover .drag-disk-info {
  display: block;
}
.drag-disk .drag-disk-footer {
  height: 64px;
  margin: 0 -20px;
  padding: 15px;
  text-align: center;
  border-top: solid 1px #e5e5e5;
  background-color: #fafafa;
  border-radius: 0 0 2px 2px;
}
.drag-disk-item {
  width: 100px;
  border: 1px solid #0085fe;
  line-height: 26px;
  padding: 5px 0 5px 5px;
  height: 35px;
  background: #fff;
  white-space: nowrap;
}
.drag-disk-item i {
  color: #0085fe;
  font-size: 19px;
  display: inline-block;
  vertical-align: top;
}
.drag-disk-item span {
  display: inline-block;
  vertical-align: top;
}
.drag-disk-item .disk-path {
  display: none;
}
.drag-disk-item .bg {
  display: none;
}
.drag-disk-item.show-bg {
  position: relative;
}
.drag-disk-item.show-bg .bg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  display: block;
  background: rgba(164, 255, 239, 0.5);
}
.drag-disk-item.show-bg > div {
  position: relative;
}
.drag-disk-item.down {
  border-color: #e74c3c;
}
.drag-disk-item.out {
  border-color: #999;
}
.drag-disk-item.orange .bg {
  background: rgba(255, 237, 129, 0.5);
}
.drag-disk-item.red .bg {
  background: rgba(255, 180, 165, 0.5);
}
.active-border {
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}
.min .drag-disk-item {
  height: 20px;
  width: 50px;
  padding: 1px 2px;
  line-height: 18px;
  font-size: 12px;
}
.min .drag-disk-item .disk-path {
  display: inline-block;
  overflow: hidden;
  width: 39px;
  text-overflow: ellipsis;
}
.min .drag-disk-item .disk-size {
  display: none;
}
.min .drag-disk-item i {
  font-size: 12px;
}
.drag-disk-info {
  position: absolute;
  top: 100%;
  padding-top: 10px;
  z-index: 9;
  display: none;
}
.drag-disk-info .drag-disk-info-inner {
  padding: 10px 5px;
  width: 210px;
  background: #2a2a2a;
  border-radius: 4px;
  color: #fff;
}
.drag-disk-info .drag-disk-info-inner .angles {
  position: absolute;
  top: 10px;
  left: 10px;
  transform: rotate(-45deg);
  padding: 0;
}
.drag-disk-info .drag-disk-info-inner .angles .angle-top {
  border-top: 10px solid #2a2a2a;
  border-left: 10px solid transparent;
  position: absolute;
  top: 0;
}
.drag-disk-info .drag-disk-info-inner div {
  padding: 5px 0 5px 10px;
  white-space: nowrap;
}
.drag-disk-info .drag-disk-info-inner div span.sub-title {
  color: #888;
  display: inline-block;
  min-width: 100px;
  text-align: right;
}
.drag-disk-info .drag-disk-info-inner div span.status {
  padding: 2px 10px;
  border-radius: 2px;
  background: #4e80f5;
  color: #fff;
}
.drag-disk-info .drag-disk-info-inner div span.status.up {
  background: #16a085;
  color: #fff;
}
.drag-disk-info .drag-disk-info-inner div span.status.down {
  background: #e74c3c;
  color: #fff;
}
.drag-disk-info .drag-disk-info-inner div span.status.out {
  background: #999999;
  color: #fff;
}
.nodes-name-box {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 10px;
  padding-bottom: 7px;
}
.nodes-name-box .nodes-name-label {
  float: left;
  margin-top: 3px;
}
.nodes-name-box ul {
  overflow: hidden;
}
.nodes-name-box ul li {
  border: 1px solid #c2d3eb;
  line-height: 22px;
  float: left;
  margin: 0 3px 6px 3px;
  padding: 0 10px;
}
.node-disks-tab {
  border-bottom: 1px solid #c2d3eb;
  overflow: hidden;
  margin-bottom: 15px;
  padding-top: 10px;
}
.node-disks-tab .disk-name {
  color: #999;
  float: left;
  padding: 0 10px;
  border-bottom: 3px solid #fff;
  cursor: pointer;
}
.node-disks-tab .disk-name:hover {
  color: #333;
}
.node-disks-tab .disk-name.active {
  border-bottom: 3px solid #0085fe;
  color: #0085fe;
}
.node-disks-tab .disk-name.active:hover {
  color: #0085fe;
}
.initSetting-modal .nodes-name-box {
  margin-top: 20px;
}
.initSetting-modal .drag-preview {
  display: none;
}
.initSetting-modal .drag-disk .drag-edit {
  width: 100%;
  float: none;
  border: none;
}
.initSetting-modal .drag-disk .drag-disk-footer {
  margin: 20px 0 0;
  border: 0 none;
  background: none;
}
.ceph-grid .drag-disk-info-inner,
.drag-disk .drag-disk-info-inner {
  width: 260px;
}
#addVolume-quotaBar {
  padding-top: 15px;
}
.time-unit-group {
  display: flex;
  align-items: center;
}
.time-unit-group .time {
  flex: 1;
  display: flex;
  align-items: center;
}
.time-unit-group .time .control-label {
  width: 120.7px;
  white-space: normal;
  line-height: 18px;
}
.time-unit-group .time .controls {
  flex: 1;
}
.time-unit-group .unit {
  width: 116px;
  margin-left: 10px;
}
.time-unit-group .unit .controls {
  padding-left: 0;
  width: 100%;
  padding-right: 21px;
}
.enable-alarm-group {
  display: flex;
}
.enable-alarm-group .enable {
  display: flex;
}
.enable-alarm-group .enable .control-label {
  width: 120.7px;
}
.enable-alarm-group .enable .controls {
  flex: 1;
}
.enable-alarm-group .threshold {
  display: flex;
  flex: 1;
}
.enable-alarm-group .threshold .control-label {
  width: 77px;
}
.enable-alarm-group .threshold .controls {
  position: relative;
  flex: 1;
}
.enable-alarm-group .threshold .controls .percent {
  position: absolute;
  top: 4px;
  right: 8px;
}
.enable-alarm-group .level {
  display: flex;
  margin-left: 15px;
}
.enable-alarm-group .level .control-label {
  width: 76px;
}
.enable-alarm-group .level .controls {
  padding-right: 21px;
}
.enable-alarm-group .level .controls .level-select {
  width: 95px;
}
#monitor-echart-label {
  width: 17%;
  margin-right: 15px;
}
.half .customInput {
  padding: 6px 0;
  margin-left: 7px;
  width: 20%;
  float: left;
  cursor: pointer;
}
.half .chooseOS {
  width: 29%;
}
.half .chooseOSType {
  width: 45%;
}
.area .osInput {
  width: 70%;
}
.area .customInput {
  cursor: pointer;
}
.tcs_iframe {
  margin: 0 auto;
}
.tcs_iframe .page-inner {
  overflow-x: visible;
  min-height: 746px;
}
.tcs_iframe .settings-box .controls.group {
  text-align: center;
}
.tcs_iframe .settings-box .controls .switch-btn {
  float: left;
}
.tcs_iframe .settings-box .controls .chk-tip {
  display: inline-block;
  height: 34px;
  line-height: 34px;
}
.tcs_iframe .settings-box .controls .chk-tip .succ {
  color: #1abc9c;
}
.tcs_iframe .settings-box .controls .chk-tip .fail {
  color: #e74c3c;
}
.tcs_iframe .settings-box .controls .chk-btn {
  display: inline-block;
  float: right;
  line-height: 34px;
}
.main-content .hint-msg {
  padding: 10px 20px 0;
}
.number-chunk .count-box {
  background-color: red;
}
.number-chunk .count-box .number_input {
  width: 295px;
}
.count-box-chunk {
  display: inline-block;
  position: relative;
  width: 150px;
  left: 40px;
}
.count-box-chunk .button-num {
  color: #51a3ff;
  width: 150px;
  text-align: center;
}
.count-box-chunk .add {
  text-align: center;
  color: #666;
  background-color: #ffffff;
  position: absolute;
  right: -40px;
  top: 0px;
  width: 32px;
  height: 32px;
  line-height: 30px;
  border: solid 1px #e5e5e5;
  cursor: pointer;
}
.count-box-chunk .add.disabled {
  cursor: not-allowed;
  background-color: #fafafa;
}
.count-box-chunk .add + .popover {
  min-width: 200px;
}
.count-box-chunk .minus {
  text-align: center;
  color: #666;
  background-color: #ffffff;
  position: absolute;
  left: -40px;
  top: 0px;
  width: 32px;
  height: 32px;
  line-height: 30px;
  border: solid 1px #e5e5e5;
  cursor: pointer;
}
.count-box-chunk .minus.disabled {
  cursor: not-allowed;
  background-color: #fafafa;
}
.modal-smallest.updateWidth {
  width: 750px !important;
}
.vm-clone .steps-sytle-ver li.step-start {
  width: 50%;
}
.vm-clone .steps-sytle-ver li.step-end {
  width: 50%;
}
.vm-clone .count-box {
  display: inline-block;
  position: relative;
  width: 150px;
  left: 40px;
}
.vm-clone .count-box .button-num {
  color: #51a3ff;
  width: 150px;
  text-align: center;
}
.vm-clone .count-box .add {
  text-align: center;
  color: #666;
  background-color: #ffffff;
  position: absolute;
  right: -40px;
  top: 0px;
  width: 32px;
  height: 32px;
  line-height: 30px;
  border: solid 1px #e5e5e5;
  cursor: pointer;
}
.vm-clone .count-box .add.disabled {
  cursor: not-allowed;
  background-color: #fafafa;
}
.vm-clone .count-box .add + .popover {
  min-width: 200px;
}
.vm-clone .count-box .minus {
  text-align: center;
  color: #666;
  background-color: #ffffff;
  position: absolute;
  left: -40px;
  top: 0px;
  width: 32px;
  height: 32px;
  line-height: 30px;
  border: solid 1px #e5e5e5;
  cursor: pointer;
}
.vm-clone .count-box .minus.disabled {
  cursor: not-allowed;
  background-color: #fafafa;
}
.vm-clone .controls.col-1 {
  width: 20%;
}
.vm-clone .controls.col-2 {
  width: 60%;
}
.vm-clone .clone-vm-step {
  width: 280px;
  padding: 20px 0;
  margin-left: 50px;
}
.vm-clone .clone-vm-step li {
  float: left;
}
.vm-clone .clone-vm-step li .circle {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  text-align: center;
}
.vm-clone .clone-vm-step .clone-vm-step-info {
  width: 40%;
}
.vm-clone .clone-vm-step .clone-vm-step-icon {
  width: 20%;
}
.vm-clone .clone-vm-step .clone-vm-step-icon i {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 2px 0 0 5px;
}
.vm-clone .clone-vm-step .clone-vm-step-config {
  width: 40%;
}
.vm-clone .clone-vm-step .normal {
  color: #999999;
  border: 2px solid #999999;
}
.vm-clone .clone-vm-step .normal-active {
  color: #51A3FF;
  border: 2px solid #51A3FF;
}
.vm-clone .clone-vm-step .active {
  background: #51A3FF;
  color: #ffffff;
  line-height: 24px;
}
.vm-clone .clone-vm-step .normal-title {
  color: #999999;
}
.vm-clone .clone-vm-step .active-title {
  color: #51A3FF;
  line-height: 24px;
}
.label-form .controls {
  display: block;
}
.label-form .label-chunk {
  background-color: #fff;
  color: #0085fe;
  border-radius: 2px;
  border: solid 1px #0085fe;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 2px 15px;
  font-size: 12px;
  float: left;
  max-width: 500px;
  word-wrap: break-word;
}
.label-form .table-showchunk {
  height: 400px;
  overflow-y: scroll;
}
.label-form .margin {
  margin: 10px 0;
}
.add-label {
  margin-bottom: 10px;
}
.add-label .error {
  color: #e74c3c;
  padding-left: 15px;
}
.label-alert-table .labelText {
  width: 220px;
  display: block;
  word-wrap: break-word;
  float: left;
}
.label-alert-table .alert-close {
  cursor: pointer;
}
.label-alert-table .alert-close.icon-aw-modify {
  color: #51A3FF;
  margin-left: 5px;
}
.label-alert-table .alert-close.icon-aw-yes1 {
  color: #1abc9c;
}
.label-alert-table .alert-close.icon-aw-radio {
  color: #ff7e01;
}
.label-alert-table .label-input {
  width: 300px;
}
.label-table-chunk {
  margin-top: 10px;
}
input::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
.label-downselect .label-werp {
  margin-bottom: 10px;
  position: relative;
}
.label-downselect .label-werp .select-chunk {
  position: absolute;
  left: 0;
  top: 30px;
  background-color: #fff;
  border-radius: 2px;
  z-index: 9999;
  width: 100%;
  border: solid 1px #4e80f5;
}
.label-downselect .label-werp .select-chunk.roll {
  height: 260px;
  overflow-y: scroll;
}
.label-downselect .label-werp .select-chunk.hide {
  display: none;
}
.label-downselect .label-werp .select-chunk li {
  height: 26px;
  line-height: 23px;
  padding: 2px 10px;
  font-size: 14px;
  cursor: pointer;
}
.label-downselect .label-werp .select-chunk li:hover {
  background-color: #fafafa;
  color: #51A3FF;
}
.dns-update {
  width: 30% !important;
}
.dnschunk .dns-server {
  float: left;
  margin-left: 24px;
}
.dnschunk .dns-server .ip-wrap {
  margin-bottom: 10px;
  position: relative;
}
.dnschunk .dns-server .ip-wrap .error {
  color: #e74c3c;
  font-size: 12px;
}
.dnschunk .dns-server .ip-wrap ul li {
  float: left;
  width: 133px;
}
.dnschunk .dns-server .ip-wrap ul::after {
  content: '';
  display: block;
  clear: both;
}
.dnschunk .dns-server .ip-text {
  width: 125px;
}
.dnschunk .dns-server .ip-text:last-child {
  width: 123px;
}
.dnschunk .dns-server .del {
  right: -15px !important;
  font-size: 16px;
}
.dnschunk .dns-server .add-subpool {
  position: absolute;
  right: -40px;
  top: 7px;
}
.local-disk-create .size {
  width: 100px;
  height: 10px;
}
.local-disk-create .size .size-common {
  float: left;
  height: 100%;
}
.local-disk-create .size .size-remaining {
  background: #006EFF;
}
.local-disk-create .size .size-total {
  background: #ddd;
}
a.addLabelDisabled {
  cursor: not-allowed;
  color: #666;
}
.bcache-tip {
  padding-left: 22px;
  color: #bbb;
  display: block;
  line-height: 24px;
}
.ip-tpl {
  display: flex;
}
.ip-tpl .ip-tpl-item {
  width: 12%;
  margin-right: 3px;
}
.ip-tpl .ip-tpl-item.last {
  margin-right: 0;
}
.ip-tpl.v4 .ip-tpl-item {
  width: 25%;
}
.xunjian-period::after {
  content: '';
  display: block;
  clear: both;
}
.xunjian-period li {
  float: left;
  width: 70px;
  height: 26px;
  line-height: 23px;
  text-align: center;
  border: solid 1px #e5e5e5;
  font-size: 13px;
  cursor: pointer;
}
.xunjian-period li.adisabled {
  cursor: not-allowed;
}
.xunjian-period li.active {
  color: #51a3ff;
  border-color: #51a3ff;
}
.email-chunk textarea.nowInput {
  background-color: #f4f4f4;
  cursor: not-allowed;
}
.monitor-view .node-lable {
  width: 80px;
}
.monitor-view .controls-chunk {
  padding-left: 0;
  margin-right: 10px;
}
.monitor-view .controls-chunk .node-select {
  width: 160px;
}
.node-select-area {
  display: flex;
}
.clone-chain {
  min-height: 495px;
  margin-bottom: 10px;
  border: 1px solid #e5e5e5;
}
.clone-chain .clone-chain-form {
  padding-top: 10px;
}
.clone-chain .clone-chain-form .clone-chain-control-group {
  height: 46px;
  padding: 15px 0;
}
.clone-chain .clone-chain-form li:hover {
  background-color: #F2F2F2;
}
.clone-chain .clone-chain-form .clone-chain-label {
  width: 100px;
}
.clone-chain .clone-chain-form .clone-chain-form-input {
  width: 140px;
}
.clone-chain .clone-chain-select-daytime {
  position: absolute;
  top: 28px;
  height: 200px;
  border: 1px solid #e5e5e5;
  display: flex;
  background: #ffffff;
  z-index: 99;
}
.clone-chain .clone-chain-select-daytime.daytime-hour {
  left: 25px;
  width: 100px;
}
.clone-chain .clone-chain-select-daytime.daytime-week {
  left: 95px;
  z-index: 99;
  width: 150px;
}
.clone-chain .clone-chain-select-daytime .daytime-common {
  text-align: center;
  width: 50%;
  height: 100%;
}
.clone-chain .clone-chain-select-daytime .daytime-common.time-border {
  border-right: 1px solid #e5e5e5;
}
.clone-chain .clone-chain-select-daytime .daytime-common .daytime-common-time {
  height: 100%;
}
.clone-chain .clone-chain-select-daytime .daytime-common .daytime-common-time .title {
  height: 30px;
  line-height: 30px;
  background: #F2F2F2;
}
.clone-chain .clone-chain-select-daytime .daytime-common .daytime-common-time .time {
  border-top: 1px solid #e5e5e5;
  box-sizing: border-box;
  height: calc(100% - 30px);
  overflow: auto;
}
.clone-chain .clone-chain-select-daytime .daytime-common .daytime-common-time .time li {
  height: 30px;
  line-height: 30px;
  cursor: pointer;
}
.clone-chain .clone-chain-select-daytime .daytime-common .daytime-common-time .time li:hover {
  background-color: #F2F2F2;
}
.clone-chain .clone-chain-select-daytime .daytime-common .daytime-common-time .time li.active {
  color: #ffffff;
  background-color: #3b78e7;
}
.clone-chain .clone-chain-show-time {
  position: absolute;
  left: 25px;
  top: 30px;
  z-index: 90;
}
.clone-chain .clone-chain-footer {
  padding: 30px 0 30px 125px;
}
.clone-chain-time-scroll::-webkit-scrollbar {
  width: 10px;
  height: 1px;
}
.clone-chain-time-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px #e5e5e5;
  opacity: 0.2;
  background: #ebebeb;
}
.clone-chain-time-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #fff;
}
.storage-local-tpl .table-content {
  max-height: 250px;
  overflow-y: auto;
  margin: 0 25px 0 80px;
}
.storage-local-tpl .error-tip {
  margin-left: 55px;
}
.storage-local-detail-tpl .ui-select-container .ui-select-choices {
  width: 130px;
}
.ip-site .controls input {
  width: 129px;
}
.hint {
  padding: 5px 0;
  font-size: 12px;
  color: #bbb;
}
.attestation-chunk li {
  width: 132px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: solid 1px #e5e5e5;
  float: left;
  cursor: pointer;
}
.attestation-chunk li:hover {
  background-color: #f2f2f2;
}
.attestation-chunk li.active {
  color: #006eff;
  border-color: #006eff;
  background-color: #fff;
}
.controls.certificate {
  display: flex;
}
.controls.certificate .select-chunk {
  width: 65%;
}
.cidr-chunk .ipv4 {
  width: 122px;
}
.cidr-chunk .ipv6 {
  width: 59px;
}
.cidr-chunk .ip6 li {
  width: 67px;
}
.cidr-chunk .ip6 li input {
  padding: 0 2px;
}
.cidr-chunk ul li {
  float: left;
  width: 133px;
}
.cidr-chunk ul::after {
  content: '';
  display: block;
  clear: both;
}
.example_reference {
  cursor: pointer;
}
.example_reference span {
  font-size: 12px;
}
.dnschunk .dns-server {
  float: left;
  margin-left: 24px;
}
.dnschunk .dns-server .ip-wrap {
  margin-bottom: 10px;
  position: relative;
}
.dnschunk .dns-server .ip-wrap .error {
  color: #e74c3c;
  font-size: 12px;
}
.dnschunk .dns-server .ip-wrap ul li {
  float: left;
  width: 133px;
}
.dnschunk .dns-server .ip-wrap ul::after {
  content: '';
  display: block;
  clear: both;
}
.dnschunk .dns-server .ip-wrap .ip-six li {
  float: left;
  width: 70px;
  margin-right: 10px;
}
.dnschunk .dns-server .ip-wrap .ip-six li .ipv6-text {
  width: 70px;
}
.dnschunk .dns-server .ip-text {
  width: 125px;
}
.dnschunk .dns-server .ip-text:last-child {
  width: 123px;
}
.dnschunk .dns-server .del {
  right: -15px !important;
  font-size: 16px;
}
.dnschunk .dns-server .add-subpool {
  position: absolute;
  right: -40px;
  top: 7px;
}
.textareaNone {
  background-color: #f5f5f5;
  cursor: not-allowed !important;
}
.fileStorage .controls input {
  width: 70%;
}
.fileStorage .controls .file-select {
  float: left;
  width: 25%;
  margin-left: 26px;
}
.fileStorage .controls::after {
  content: '';
  display: block;
  clear: both;
}
.iframe-inner {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
}
.iframe-modal-open .main {
  min-width: 1280px;
  display: flex;
  flex-direction: row;
}
.iframe-modal-open .main aside {
  position: static;
}
.iframe-modal-open .main.open aside {
  flex: 0 0 240px;
}
.iframe-modal-open .main.closed aside {
  flex: 0 0 50px;
}
.iframe-modal-open .main .main-content {
  width: 0;
  margin-left: 0!important;
  flex: auto;
}
.iframe-modal-bg {
  overflow: hidden;
}
.iframe-modal-bg .iframe-inner {
  z-index: 3001;
}
.iframe-modal-bg .login-timeout {
  z-index: 3000;
}
.localdisk-mount .controls-error-tip {
  padding: 5px 0 0 0;
}
.localdisk-mount .control-label-haswitch {
  width: 16%;
  text-align: left;
}
.localdisk-mount .controls-haswitch {
  padding-left: 0;
}
.checkbox.checkbox_margin {
  margin-right: 10px !important;
}
.show-off-msg p {
  text-align: left;
}
.show-off-msg p:nth-child(1) {
  margin-bottom: 5px;
}
.show-off-msg p:nth-child(n+3) {
  text-indent: 30px;
}
.controls.agreement .chunk::after {
  content: '';
  display: block;
  clear: both;
}
.unloadTtEquipment .alart {
  text-align: center;
  margin-bottom: 30px;
  font-size: 16px;
  color: #E67E22;
}
.unloadTtEquipment .control-group-haForm {
  margin-left: 100px;
}
.unloadTtEquipment .control-group .controls-error {
  width: 100%;
}
.copyLiunx {
  cursor: pointer;
}
.page-inner.updatePositioner {
  position: relative;
}
.page-inner.updatePositioner .ulAbsolute {
  top: -60px;
  z-index: 99;
}
.tsafe-sec {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tsafe-sec .tsafe-sec-form {
  width: 500px;
  min-height: 200px;
  border: 1px solid #ccc;
}
.tsafe-sec .tsafe-sec-form .tip {
  margin: 20px 17px 20px 23px;
}
.tsafe-sec .tsafe-sec-form .sec-form {
  margin-top: 40px;
}
.tsafe-sec .tsafe-sec-form .sec-footer {
  margin-top: 40px;
  display: flex;
  justify-content: end;
  align-items: center;
}
.tsafe-sec .tsafe-sec-form .sec-footer .btn {
  margin: 0 15px 20px 0;
}
.slider-bar {
  height: 35px;
  width: 600px;
}
.slider-bar-min {
  margin-top: 20px;
  height: 35px;
  width: 300px;
}
.jx-line-box {
  height: 100%;
  width: 100%;
  position: relative;
}
.jx-line-box.jx-margin-left {
  margin-left: 5px;
}
.jx-line-box .jx-value-line {
  height: 5px;
  width: 80%;
  background: #e9e9e4;
  border-radius: 5px;
  position: absolute;
  top: calc(50% - 2px);
  cursor: pointer;
}
.jx-line-box .jx-value-box {
  position: absolute;
  left: 85%;
  height: 32px;
  border-radius: 5px;
  top: calc(50% - 16px);
  text-align: center;
  line-height: 32px;
}
.jx-line-box .jx-value-box .jx-value-inner {
  padding-left: 10px;
  padding-right: 0px;
  position: absolute;
  width: 50px;
}
.jx-line-box .jx-value-box .jx-error {
  position: absolute;
  width: 150px;
  text-align: left;
  left: 55px;
}
.jx-line-box .jx-value-box .jx-error .jx-error-tip {
  line-height: 32px;
  color: #e74c3c;
}
.jx-line-box .jx-ball {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #57a9f1;
  position: absolute;
  top: calc(50% - 6px);
  left: -6px;
  cursor: pointer;
}
.jx-line-box .jx-ball.jx-ball-chunk {
  left: -10px;
  top: calc(50% - 15px);
  height: 30px;
  width: 20px;
  border-radius: 3px;
  background: #ffffff;
  border: 1px solid #51a3ff;
}
.jx-line-box .jx-ball.jx-ball-chunk::after {
  content: '|||';
  position: absolute;
  left: 0;
  top: 0;
  height: 28px;
  width: 18px;
  line-height: 25px;
  text-align: center;
  color: #51a3ff;
}
.jx-line-box .jx-ball .jx-tip-box {
  position: absolute;
  top: -36px;
}
.jx-line-box .jx-ball .jx-tip-box .jx-rectangle {
  background: rgba(0, 0, 19, 0.5);
  text-align: center;
  line-height: 30px;
  border-radius: 3px;
  display: block;
}
.jx-line-box .jx-ball .jx-tip-box .jx-rectangle a {
  display: block;
  padding: 0 5px;
  color: floralwhite;
}
.jx-line-box .jx-ball .jx-tip-box .jx-triangle {
  border-width: 5px;
  border-style: solid;
  border-color: rgba(23, 23, 24, 0.5) transparent transparent;
  display: block;
  width: 0px;
  height: 0px;
  margin: auto;
}
.jx-line-box .jx-value-choice {
  height: 5px;
  width: 0;
  background: #51a3ff;
  border-radius: 5px;
  position: absolute;
  top: calc(50% - 2px);
  pointer-events: none;
}
.jx-line-box .jx-coor {
  display: block;
  position: absolute;
  top: 20px;
}
.jx-line-box .jx-coor a {
  position: absolute;
  width: 0px;
  height: 5px;
  border-right: 1px solid #c9c8c6;
}
.jx-line-box .jx-coor span {
  position: absolute;
  top: 15px;
  left: -6px;
}
.slide-height {
  height: 42px;
  padding-left: 30px !important;
}
.form-controls.form-input {
  width: 50%;
}
.mouseCss {
  cursor: pointer;
}
.fontWeight {
  font-weight: bold;
  padding-bottom: 10px;
}
.btnNone {
  background-color: #fafafa !important;
  color: #999 !important;
}
.database .specific {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}
.database .specific .cpu {
  width: 45%;
}
.database .specific .ram {
  width: 45%;
}
.database .add_net,
.database .securities-detail {
  height: 32px;
  line-height: 32px;
}
.database .add_net a i,
.database .securities-detail a i {
  padding-right: 10px;
}
.database .middle-group .controls {
  padding-right: 10px;
}
.database .popover.top {
  margin-top: -25px;
}
.add-msg {
  margin-top: 10px;
  color: #999;
}
.enhance-config .enhance-chunk {
  border: solid 1px #c2d3eb;
  padding: 40px 0;
}
.enhance-config .enhance-chunk .control-group {
  margin: 0 40px 20px;
  border-bottom: solid 1px #c2d3eb;
}
.enhance-config .enhance-chunk .control-group:last-child {
  padding-bottom: 0;
  border-bottom: none;
}
.enhance-config .enhance-chunk .control-group p {
  padding: 20px 0;
  color: #9caec1;
  font-size: 12px;
}
.enhance-config .bottomBtn {
  padding-top: 20px;
  text-align: center;
}
.shiftInHint-chunk .chunk-positioner {
  position: relative;
}
.shiftInHint-chunk .chunk-hover {
  display: inline-block;
  position: relative;
}
.shiftInHint-chunk .chunk-hover .shade {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 8888;
  cursor: pointer;
}
.shiftInHint-chunk .shiftInHint {
  background-color: #fff;
  z-index: 2000;
  width: 420px;
  box-shadow: 0 2px 6px 0px #A3A3A9;
  padding: 35px 40px;
  position: absolute;
}
.shiftInHint-chunk .shiftInHint .hint-body {
  display: flex;
}
.shiftInHint-chunk .shiftInHint .hint-body .left {
  width: 40px;
}
.shiftInHint-chunk .shiftInHint .hint-body .left .move {
  transform: translateY(-5px);
}
.shiftInHint-chunk .shiftInHint .modal-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}
.shiftInHint-chunk .shiftInHint .iconCss {
  color: #ff7200;
  font-size: 20px;
}
.shiftInHint-chunk .shiftInHint.bottom {
  border-bottom: solid 1px #bbbbbb;
  left: -20px;
  top: -183px;
}
.shiftInHint-chunk .shiftInHint.bottom .bottomchunk {
  background-color: transparent;
  bottom: -15px;
}
.shiftInHint-chunk .shiftInHint.bottom .bottomchunk::after {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  background-color: #fff;
  transform: rotateZ(45deg) translate(7px, 0px);
  border-right: solid 1px #bbbbbb;
  border-bottom: solid 1px #bbbbbb;
}
.shiftInHint-chunk .shiftInHint.top {
  border-top: solid 1px #bbbbbb;
  left: -20px;
  top: 47px;
}
.shiftInHint-chunk .shiftInHint.top .topchunk {
  background-color: transparent;
  top: -15px;
}
.shiftInHint-chunk .shiftInHint.top .topchunk::after {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  background-color: #fff;
  transform: rotateZ(45deg) translate(7px, 0px);
  border-left: solid 1px #bbbbbb;
  border-top: solid 1px #bbbbbb;
}
.shiftInHint-chunk .shiftInHint .bottomchunk,
.shiftInHint-chunk .shiftInHint .topchunk {
  width: 30px;
  height: 30px;
  left: 40px;
  position: absolute;
}
.confirm-msg {
  float: right;
  margin-right: 22px;
  margin-top: 10px;
}
.confirm-msg .confirm-msg-tpl {
  display: inline-block;
  position: relative;
  margin-left: 5px;
}
.confirm-msg .confirm-msg-tpl .text {
  cursor: pointer;
}
.confirm-msg-message {
  position: absolute;
  background-color: #fff;
  z-index: 2000;
  width: 420px;
  box-shadow: 0 2px 6px 0px #a3a3a9;
  padding: 35px 40px;
}
.confirm-msg-message .message-body {
  display: flex;
}
.confirm-msg-message .message-body .message-body-left {
  width: 40px;
}
.confirm-msg-message .message-body .message-body-left .left-move {
  transform: translateY(-5px);
}
.confirm-msg-message .message-body .message-body-left .left-move .left-move-icon {
  color: #ff7200;
  font-size: 20px;
}
.confirm-msg-message .modal-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}
.confirm-msg-message.arrow {
  border-bottom: solid 1px #bbbbbb;
}
.confirm-msg-message.arrow .topchunk {
  background-color: transparent;
}
.confirm-msg-message.arrow .topchunk::after {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  background-color: #fff;
  transform: rotateZ(45deg) translate(7px, 0px);
  border-left: solid 1px #bbbbbb;
  border-top: solid 1px #bbbbbb;
}
.confirm-msg-message.arrow .bottomchunk {
  background-color: transparent;
}
.confirm-msg-message.arrow .bottomchunk::after {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  background-color: #fff;
  transform: rotateZ(45deg) translate(7px, 0px);
  border-right: solid 1px #bbbbbb;
  border-bottom: solid 1px #bbbbbb;
}
.confirm-msg-message .bottomchunk,
.confirm-msg-message .topchunk {
  width: 30px;
  height: 30px;
  position: absolute;
}
.filter-select-group {
  float: right;
  position: relative;
}
.filter-select-group .btn-text {
  padding-right: 10px;
}
.filter-select-group .select-group-modal {
  position: absolute;
  top: 30px;
  left: 0px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0px #a3a3a9;
  border: 1px solid #5888F5;
  padding: 20px 35px;
  z-index: 999;
}
.filter-select-group .select-group-modal .select-control-group {
  width: 300px;
  margin-bottom: 10px;
}
.filter-select-group .select-group-modal .select-control-group .select-control-label {
  float: left;
  width: 25%;
  margin-bottom: 0;
  line-height: 28px;
  text-align: right;
  font-weight: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
.filter-select-group .select-group-modal .select-control-group .select-controls {
  float: left;
  width: 75%;
}
.filter-select-group .select-group-modal .modal-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.filter-select-group .select-group-modal .modal-footer .btn-close {
  margin-right: 0px;
}
.filter-tips .filter-tips-text {
  margin-left: 7px;
}
.filter-tips .complete-tips span {
  color: #f39c12;
}
.filter-tips .complete-tips .exit-filter {
  color: #666;
}
.filter-tips .complete-tips .exit-filter:hover {
  cursor: pointer;
  color: #51a3ff;
}
.filter-loading {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3b78e7;
  border-left: 2px solid #3b78e7;
  border-bottom: 2px solid #3b78e7;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: inline-block;
  animation: spin 0.8s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.alert-tip-chunk {
  color: #51a3ff;
  height: 42px;
  background: #f1f6fd;
  line-height: 42px;
  border: 1px solid #c2d3eb;
  padding-left: 20px;
  margin-bottom: 15px;
  font-size: 12px;
}
/** login-main **/
.auth header {
  margin: 0;
  opacity: 1;
  filter: alpha(opacity=100);
}
.auth footer {
  margin: 0;
  opacity: 1;
  filter: alpha(opacity=100);
}
.auth .main-content {
  opacity: 1;
  filter: alpha(opacity=100);
  overflow: initial;
}
.auth .login-main .login-form {
  opacity: 1;
  filter: alpha(opacity=100);
}
.auth .modal-backdrop.in {
  opacity: 0;
}
.login-main {
  background-size: cover;
  min-height: 600px;
  overflow-y: auto;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}
.login-main.is-auto-login {
  background: rgba(0, 0, 0, 0.5) !important;
}
.login-main .freelogin-tips {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  width: 400px;
  height: 100px;
  position: absolute;
  background: #fff;
  text-align: center;
  line-height: 100px;
  font-size: 16px;
  left: 50%;
  top: 45%;
  margin-left: -200px;
}
.login-main .freelogin-tips i {
  font-size: 22px;
  margin-right: 5px;
}
.login-main .hadLogined-msg {
  font-size: 18px;
  color: #e74c3c;
  text-align: center;
  margin-top: 40px;
}
.login-main .login-form {
  opacity: 0;
  width: 460px;
  /* height: 350px; */
  padding-bottom: 30px;
  position: absolute;
  left: 55%;
  top: 50%;
  margin-top: -250px;
  background: #ffffff;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.4);
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
  padding-top: 30px;
}
.login-main .login-form legend {
  font-size: 18px;
  color: #333;
  padding: 15px 65px 15px;
  width: 100%;
  height: 54px;
}
.login-main .login-form fieldset {
  margin: 0 65px;
}
.login-main .login-form fieldset .control-group {
  margin: 0 auto 16px;
  position: relative;
}
.login-main .login-form fieldset .control-group .control-label {
  overflow: hidden;
  position: absolute;
  background: #fafafa;
  left: 1px;
  top: 1px;
  height: 32px;
  width: 120px;
  color: #999;
  border-right: 1px solid #e5e5e5;
  border-radius: 2px 0 0 2px;
  padding: 3px;
  float: left;
  text-align: center;
  font-weight: normal;
  -webkit-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.login-main .login-form fieldset .control-group .control-label span {
  position: absolute;
  -webkit-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  line-height: 34px;
  padding-left: 30px;
  text-align: left;
}
.login-main .login-form fieldset .control-group .control-label .scroll-bg {
  background: #e7e7e7;
  -webkit-transform: translate3d(-100%, 0, 0);
  -ms-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  z-index: 1;
  opacity: 0;
}
.login-main .login-form fieldset .control-group .input-title {
  font-size: 14px;
  color: #666;
  height: 24px;
}
.login-main .login-form fieldset .control-group .code-img {
  width: 108px;
  height: 42px;
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 4px 4px;
  text-align: center;
  line-height: 38px;
}
.login-main .login-form fieldset .control-group .code-img img {
  width: 100px;
  height: 42px;
  max-height: 100%;
  max-width: 100%;
}
.login-main .login-form fieldset .control-group .verication-check {
  width: 24px;
  height: 42px;
  line-height: 42px;
  font-size: 16px;
  position: absolute;
  right: 108px;
  bottom: 0;
  padding: 4px 4px;
}
.login-main .login-form fieldset .control-group .verication-check .icon-aw-yes {
  color: #1abc9c;
}
.login-main .login-form fieldset .control-group .verication-check .icon-aw-wrong {
  color: #e74c3c;
}
.login-main .login-form fieldset .control-group .form-controls {
  padding: 6px 10px;
  background: #ffffff;
  border: 1px solid #d3d3d3;
  border-radius: 2px;
  display: block;
  width: 100%;
  outline: none;
  height: 40px;
  -webkit-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  line-height: 20px;
}
.login-main .login-form fieldset .control-group .form-controls:focus {
  border: 1px solid #51a3ff;
}
.login-main .login-form fieldset .control-group .verication {
  padding-right: 130px;
}
.login-main .login-form fieldset .control-group .error-messages {
  color: #e9322d;
  position: absolute;
}
.login-main .login-form fieldset .control-group.focus .scroll-bg {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
  filter: alpha(opacity=100);
}
.login-main .login-form fieldset .form-controls:focus ~ .control-label {
  color: #666666;
}
.login-main .login-form fieldset .signuperror {
  text-align: center;
  color: #e9322d;
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 7px;
}
.login-main .login-form .btn-item {
  margin: 30px auto 0;
  overflow: hidden;
}
.login-main .login-form .btn-item .btn {
  background: #af1e23;
  display: block;
  border: 1px solid #af1e23;
  font-size: 16px;
  color: #ffffff;
  width: 100%;
  height: 40px;
}
.login-main .login-form .btn-item .btn:hover {
  background: #ca1c22;
}
.login-main .login-form .btn-item .btn i {
  margin-left: 5px;
}
.login-main .login-form .btn-item .forget {
  float: right;
  font-size: 12px;
  color: #999999;
}
.login-main .login-form .link-wrap .link-cont {
  padding: 15px 35px;
  font-size: 12px;
}
.login-main .login-form .link-wrap .link-cont a,
.login-main .login-form .link-wrap .link-cont p {
  color: #7991ab;
}
.login-main .login-form .switch-btn {
  font-size: 14px;
  color: #999;
  text-align: center;
  padding: 10px 0;
}
.login-main .login-form .switch-btn span:hover {
  cursor: pointer;
  color: #0085FE;
}
.network-page h3 {
  font-weight: normal;
  margin-bottom: 10px;
  overflow: hidden!important;
  font-size: 16px;
}
.network-page .tab-content {
  padding-top: 10px!important;
}
.network-page .detail-info .detail-info {
  padding-left: 0px;
  padding-top: 0px;
}
.btn-box {
  float: left;
}
.filter-box {
  float: right;
  display: flex;
  margin-bottom: 20px;
}
.select-box {
  float: right;
  width: 10%;
}
.select-box .control-group {
  width: 100%;
}
.select-box .control-group .controls {
  width: 95%;
}
.phyMac-moadl .check-box {
  width: auto!important;
}
.addressRange-box {
  width: 37%!important;
}
.phyMac-add-del-box {
  margin-top: 7px;
}
.phyMac-add-del-box i {
  margin-left: 10px;
}
.phyMac-add-del-box .icon-aw-wrong {
  color: #e74c3c;
}
.phyMac-min-height {
  min-height: 330px;
}
.phyMac-min-height .detail-info {
  padding-right: 0px;
}
.phyMac-table-filter-box input {
  margin-top: 3px;
  margin-left: 10px!important;
  width: 15px;
  height: 15px;
  position: relative;
  top: 2px;
}
.phyMac-table-filter-box li {
  width: 150px;
  padding: 5px 0;
  line-height: 34px;
}
.phyMac-table-filter-box li .phyMac-label-box {
  padding-left: 5px;
  display: inline-block;
}
.phyMac-table-filter-box li .phyMac-check-box {
  display: inline-block;
}
.price-box {
  height: 48px;
  width: 100%;
  line-height: 48px;
  text-align: center;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: #c3d3ea;
  border-radius: 2px;
  background: #f6f9fe;
}
.price-box span {
  font-weight: 700;
  font-size: 16px;
  color: #FF9B11;
}
.pview-page .panels {
  min-height: 230px!important;
  padding-top: 30px;
}
.pview-page .box {
  border-bottom: solid 1px #c2d3eb !important;
}
.pview-page .alarmInfo-wrap .noData {
  color: #7991AB;
  text-align: center;
  margin-top: 21%;
}
/*
fast create vm
   */
.create-easy-vm {
  background: #f4f8fb;
}
.create-easy-vm .main aside {
  display: none;
}
.create-easy-vm .main .main-content {
  margin-left: 0!important;
}
.shortcuts-ins-page {
  padding: 0;
  position: relative;
}
.shortcuts-ins-page .ins-header {
  position: relative;
  border-bottom: 1px solid #c2d3eb;
  padding: 10px 20px 0 ;
  background: #fff;
}
.shortcuts-ins-page .ins-header .region-dp {
  width: 1260px;
  margin: 0 auto;
  position: relative;
}
.shortcuts-ins-page .ins-header .region-dp.tab-offset-right .nav-tabs {
  margin-bottom: 0;
  border-bottom: 0;
}
.shortcuts-ins-page .ins-header .reply-link {
  position: absolute;
  top: 15px;
}
.shortcuts-ins-page .ins-header .reply-link a {
  color: #666;
}
.shortcuts-ins-page .ins-header .reply-link i {
  padding-right: 2px;
  color: #51a3ff;
}
.shortcuts-ins-page .shortcuts-ins-page-inner {
  width: 1260px;
  margin: 0 auto;
  position: relative;
}
.shortcuts-ins-page .defined-ins {
  min-height: 735px;
  padding-top: 20px;
}
.shortcuts-ins-page .defined-ins .ins-footer {
  width: calc(100% + 40px);
  width: -moz-calc(100% + 40px);
  width: -webkit-calc(100% + 40px);
  margin-left: -20px;
}
.shortcuts-ins-page .defined-ins .ins-footer.isquick-page {
  width: 100%;
  margin: 0;
}
.shortcuts-ins-page .quick-ins {
  padding-top: 42px;
}
.shortcuts-ins-page .iso-ins {
  padding-top: 42px;
}
.shortcuts-ins-page .fixed-tip {
  position: fixed;
  top: 195px;
  right: 0;
  width: 40px;
  height: 124px;
  text-align: center;
  font-weight: 700;
  color: #51a3ff;
  padding: 15px 10px;
  border: 1px solid #c2d3eb;
  background: #fff;
}
.shortcuts-ins-page .animateContent {
  position: fixed;
  height: 100%;
  width: 68%;
}
.shortcuts-ins-page .tab-content {
  margin-bottom: 0;
}
.shortcuts-ins-page .quick-ins-wrap,
.shortcuts-ins-page .iso-ins-wrap,
.shortcuts-ins-page .defined-ins-wrap {
  -webkit-box-shadow: 0 0 10px #e5e5e5;
  -moz-box-shadow: 0 0 10px #e5e5e5;
  box-shadow: 0 0 20px #e5e5e5;
  padding: 30px 40px 80px;
  background: #ffffff;
}
.shortcuts-ins-page .quick-ins-wrap .content .left-field,
.shortcuts-ins-page .iso-ins-wrap .content .left-field,
.shortcuts-ins-page .defined-ins-wrap .content .left-field {
  width: 75%;
  float: left;
  padding-right: 20px;
  border-right: 1px solid #e5e5e5;
}
.shortcuts-ins-page .quick-ins-wrap .content .right-field,
.shortcuts-ins-page .iso-ins-wrap .content .right-field,
.shortcuts-ins-page .defined-ins-wrap .content .right-field {
  float: left;
  width: 25%;
  padding-left: 20px;
}
.shortcuts-ins-page .quick-ins-wrap .content .right-field .control-group,
.shortcuts-ins-page .iso-ins-wrap .content .right-field .control-group,
.shortcuts-ins-page .defined-ins-wrap .content .right-field .control-group {
  font-size: 12px;
  margin-bottom: 0;
}
.shortcuts-ins-page .quick-ins-wrap .content .right-field .control-group .control-label,
.shortcuts-ins-page .iso-ins-wrap .content .right-field .control-group .control-label,
.shortcuts-ins-page .defined-ins-wrap .content .right-field .control-group .control-label {
  width: 35%;
}
.shortcuts-ins-page .quick-ins-wrap .content .right-field .control-group .controls,
.shortcuts-ins-page .iso-ins-wrap .content .right-field .control-group .controls,
.shortcuts-ins-page .defined-ins-wrap .content .right-field .control-group .controls {
  width: 65%;
  padding-left: 10px;
  color: #51a3ff;
}
.shortcuts-ins-page .quick-ins-wrap .content .all-filed,
.shortcuts-ins-page .iso-ins-wrap .content .all-filed,
.shortcuts-ins-page .defined-ins-wrap .content .all-filed {
  width: 100%;
  border-right: none;
}
.shortcuts-ins-page .quick-ins-wrap fieldset .title,
.shortcuts-ins-page .iso-ins-wrap fieldset .title,
.shortcuts-ins-page .defined-ins-wrap fieldset .title {
  border-bottom: solid 1px #e5e5e5;
  margin-bottom: 15px;
  height: 40px;
  line-height: 40px;
  font-weight: bold;
}
.shortcuts-ins-page .quick-ins-wrap fieldset .control-items .control-group.no-margin,
.shortcuts-ins-page .iso-ins-wrap fieldset .control-items .control-group.no-margin,
.shortcuts-ins-page .defined-ins-wrap fieldset .control-items .control-group.no-margin {
  margin-bottom: 0;
}
.shortcuts-ins-page .quick-ins-wrap fieldset .control-items .control-group.no-margin .control-group.all .controls,
.shortcuts-ins-page .iso-ins-wrap fieldset .control-items .control-group.no-margin .control-group.all .controls,
.shortcuts-ins-page .defined-ins-wrap fieldset .control-items .control-group.no-margin .control-group.all .controls {
  width: 100%;
  padding-left: 0;
}
.shortcuts-ins-page .quick-ins-wrap fieldset .control-items .control-label,
.shortcuts-ins-page .iso-ins-wrap fieldset .control-items .control-label,
.shortcuts-ins-page .defined-ins-wrap fieldset .control-items .control-label {
  width: 14%;
}
.shortcuts-ins-page .quick-ins-wrap fieldset .control-items .control-label.left,
.shortcuts-ins-page .iso-ins-wrap fieldset .control-items .control-label.left,
.shortcuts-ins-page .defined-ins-wrap fieldset .control-items .control-label.left {
  text-align: left;
}
.shortcuts-ins-page .quick-ins-wrap fieldset .control-items .controls,
.shortcuts-ins-page .iso-ins-wrap fieldset .control-items .controls,
.shortcuts-ins-page .defined-ins-wrap fieldset .control-items .controls {
  width: 86%;
}
.shortcuts-ins-page .quick-ins-wrap fieldset .control-items .controls .control-items2 .control-label.left,
.shortcuts-ins-page .iso-ins-wrap fieldset .control-items .controls .control-items2 .control-label.left,
.shortcuts-ins-page .defined-ins-wrap fieldset .control-items .controls .control-items2 .control-label.left {
  text-align: left;
}
.shortcuts-ins-page .quick-ins-wrap fieldset .control-items .controls .control-items2 .control-label.left + .controls,
.shortcuts-ins-page .iso-ins-wrap fieldset .control-items .controls .control-items2 .control-label.left + .controls,
.shortcuts-ins-page .defined-ins-wrap fieldset .control-items .controls .control-items2 .control-label.left + .controls {
  padding-left: 0;
}
.shortcuts-ins-page .quick-ins-wrap fieldset .control-items2,
.shortcuts-ins-page .iso-ins-wrap fieldset .control-items2,
.shortcuts-ins-page .defined-ins-wrap fieldset .control-items2 {
  position: relative;
}
.shortcuts-ins-page .quick-ins-wrap fieldset .control-items2 .control-group,
.shortcuts-ins-page .iso-ins-wrap fieldset .control-items2 .control-group,
.shortcuts-ins-page .defined-ins-wrap fieldset .control-items2 .control-group {
  width: 70%;
  float: left;
}
.shortcuts-ins-page .quick-ins-wrap fieldset .control-items2 .control-group .control-label,
.shortcuts-ins-page .iso-ins-wrap fieldset .control-items2 .control-group .control-label,
.shortcuts-ins-page .defined-ins-wrap fieldset .control-items2 .control-group .control-label {
  width: 20%;
}
.shortcuts-ins-page .quick-ins-wrap fieldset .control-items2 .control-group .controls,
.shortcuts-ins-page .iso-ins-wrap fieldset .control-items2 .control-group .controls,
.shortcuts-ins-page .defined-ins-wrap fieldset .control-items2 .control-group .controls {
  width: 75%;
}
.shortcuts-ins-page .quick-ins-wrap fieldset .control-items2 .control-group .controls.col-1,
.shortcuts-ins-page .iso-ins-wrap fieldset .control-items2 .control-group .controls.col-1,
.shortcuts-ins-page .defined-ins-wrap fieldset .control-items2 .control-group .controls.col-1 {
  width: 30%;
}
.shortcuts-ins-page .quick-ins-wrap fieldset .control-items2 .control-group .controls.col-2,
.shortcuts-ins-page .iso-ins-wrap fieldset .control-items2 .control-group .controls.col-2,
.shortcuts-ins-page .defined-ins-wrap fieldset .control-items2 .control-group .controls.col-2 {
  width: 45%;
  padding-left: 10px;
}
.shortcuts-ins-page .quick-ins-wrap fieldset .control-items2 .control-group .controls .checkbox,
.shortcuts-ins-page .iso-ins-wrap fieldset .control-items2 .control-group .controls .checkbox,
.shortcuts-ins-page .defined-ins-wrap fieldset .control-items2 .control-group .controls .checkbox {
  position: relative;
}
.shortcuts-ins-page .quick-ins-wrap fieldset .control-items2 .control-group .controls .icon-aw-display,
.shortcuts-ins-page .iso-ins-wrap fieldset .control-items2 .control-group .controls .icon-aw-display,
.shortcuts-ins-page .defined-ins-wrap fieldset .control-items2 .control-group .controls .icon-aw-display,
.shortcuts-ins-page .quick-ins-wrap fieldset .control-items2 .control-group .controls .icon-aw-no-display,
.shortcuts-ins-page .iso-ins-wrap fieldset .control-items2 .control-group .controls .icon-aw-no-display,
.shortcuts-ins-page .defined-ins-wrap fieldset .control-items2 .control-group .controls .icon-aw-no-display {
  position: absolute;
  right: 5px;
  top: 0px;
  left: auto;
  font-size: 18px;
  line-height: 1;
  padding: 5px 0;
}
.shortcuts-ins-page .quick-ins-wrap fieldset .control-items2 .control-group .password-input,
.shortcuts-ins-page .iso-ins-wrap fieldset .control-items2 .control-group .password-input,
.shortcuts-ins-page .defined-ins-wrap fieldset .control-items2 .control-group .password-input {
  width: calc(75% - 153px);
}
.shortcuts-ins-page .quick-ins-wrap fieldset .control-items2 .control-group .password-btn,
.shortcuts-ins-page .iso-ins-wrap fieldset .control-items2 .control-group .password-btn,
.shortcuts-ins-page .defined-ins-wrap fieldset .control-items2 .control-group .password-btn {
  margin: 0 0 0 5px;
}
.shortcuts-ins-page .quick-ins-wrap fieldset .control-items2 .control-group.add,
.shortcuts-ins-page .iso-ins-wrap fieldset .control-items2 .control-group.add,
.shortcuts-ins-page .defined-ins-wrap fieldset .control-items2 .control-group.add {
  height: 32px;
  line-height: 32px;
}
.shortcuts-ins-page .quick-ins-wrap fieldset .control-items2 .control-group:nth-child(even),
.shortcuts-ins-page .iso-ins-wrap fieldset .control-items2 .control-group:nth-child(even),
.shortcuts-ins-page .defined-ins-wrap fieldset .control-items2 .control-group:nth-child(even) {
  margin-left: 0;
}
.shortcuts-ins-page .quick-ins-wrap fieldset .control-items2.field,
.shortcuts-ins-page .iso-ins-wrap fieldset .control-items2.field,
.shortcuts-ins-page .defined-ins-wrap fieldset .control-items2.field {
  border-bottom: 1px solid #e5e5e5;
}
.shortcuts-ins-page .quick-ins-wrap fieldset .control-items2 .right-tips-field,
.shortcuts-ins-page .iso-ins-wrap fieldset .control-items2 .right-tips-field,
.shortcuts-ins-page .defined-ins-wrap fieldset .control-items2 .right-tips-field {
  position: absolute;
  left: 70%;
  top: 8px;
  width: 150px;
}
.shortcuts-ins-page .quick-ins-wrap fieldset .control-items2 .right-tips-field.set-numa-field,
.shortcuts-ins-page .iso-ins-wrap fieldset .control-items2 .right-tips-field.set-numa-field,
.shortcuts-ins-page .defined-ins-wrap fieldset .control-items2 .right-tips-field.set-numa-field {
  width: 170px;
}
.shortcuts-ins-page .quick-ins-wrap fieldset .control-items2 .numa-set-msg,
.shortcuts-ins-page .iso-ins-wrap fieldset .control-items2 .numa-set-msg,
.shortcuts-ins-page .defined-ins-wrap fieldset .control-items2 .numa-set-msg {
  position: relative;
  display: inline-block;
  width: 50px;
  text-align: center;
}
.shortcuts-ins-page .quick-ins-wrap fieldset .control-items2 .numa-set-msg .popover,
.shortcuts-ins-page .iso-ins-wrap fieldset .control-items2 .numa-set-msg .popover,
.shortcuts-ins-page .defined-ins-wrap fieldset .control-items2 .numa-set-msg .popover {
  width: 200px;
}
.shortcuts-ins-page .quick-ins-wrap fieldset .control-items3,
.shortcuts-ins-page .iso-ins-wrap fieldset .control-items3,
.shortcuts-ins-page .defined-ins-wrap fieldset .control-items3 {
  position: relative;
}
.shortcuts-ins-page .quick-ins-wrap fieldset .control-items3 .control-group .control-label,
.shortcuts-ins-page .iso-ins-wrap fieldset .control-items3 .control-group .control-label,
.shortcuts-ins-page .defined-ins-wrap fieldset .control-items3 .control-group .control-label {
  width: 23%;
  float: left;
}
.shortcuts-ins-page .quick-ins-wrap fieldset .control-items3 .control-group .controls,
.shortcuts-ins-page .iso-ins-wrap fieldset .control-items3 .control-group .controls,
.shortcuts-ins-page .defined-ins-wrap fieldset .control-items3 .control-group .controls {
  width: 77%;
  float: left;
  padding-left: 10px;
}
.shortcuts-ins-page .quick-ins-wrap fieldset .control-items3 .control-group .controls p,
.shortcuts-ins-page .iso-ins-wrap fieldset .control-items3 .control-group .controls p,
.shortcuts-ins-page .defined-ins-wrap fieldset .control-items3 .control-group .controls p {
  color: #999;
  height: 32px;
  line-height: 32px;
  display: inline-block;
}
.shortcuts-ins-page .quick-ins-wrap fieldset .control-items3 .control-group .controls p span,
.shortcuts-ins-page .iso-ins-wrap fieldset .control-items3 .control-group .controls p span,
.shortcuts-ins-page .defined-ins-wrap fieldset .control-items3 .control-group .controls p span {
  color: #f39c12;
}
.shortcuts-ins-page .quick-ins-wrap fieldset .control-items3 .control-group .controls .selfVolume-select,
.shortcuts-ins-page .iso-ins-wrap fieldset .control-items3 .control-group .controls .selfVolume-select,
.shortcuts-ins-page .defined-ins-wrap fieldset .control-items3 .control-group .controls .selfVolume-select {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
}
.shortcuts-ins-page .quick-ins-wrap fieldset .footer,
.shortcuts-ins-page .iso-ins-wrap fieldset .footer,
.shortcuts-ins-page .defined-ins-wrap fieldset .footer {
  padding: 20px;
  text-align: center;
}
.shortcuts-ins-page .quick-ins-wrap .count-box,
.shortcuts-ins-page .iso-ins-wrap .count-box,
.shortcuts-ins-page .defined-ins-wrap .count-box {
  display: inline-block;
  position: relative;
  width: 150px;
  left: 40px;
}
.shortcuts-ins-page .quick-ins-wrap .count-box .button-num,
.shortcuts-ins-page .iso-ins-wrap .count-box .button-num,
.shortcuts-ins-page .defined-ins-wrap .count-box .button-num {
  color: #51a3ff;
  width: 150px;
  text-align: center;
}
.shortcuts-ins-page .quick-ins-wrap .count-box .add,
.shortcuts-ins-page .iso-ins-wrap .count-box .add,
.shortcuts-ins-page .defined-ins-wrap .count-box .add {
  text-align: center;
  color: #666;
  background-color: #ffffff;
  position: absolute;
  right: -40px;
  top: 0px;
  width: 32px;
  height: 32px;
  line-height: 30px;
  border: solid 1px #e5e5e5;
  cursor: pointer;
}
.shortcuts-ins-page .quick-ins-wrap .count-box .add.disabled,
.shortcuts-ins-page .iso-ins-wrap .count-box .add.disabled,
.shortcuts-ins-page .defined-ins-wrap .count-box .add.disabled {
  cursor: not-allowed;
  background-color: #fafafa;
}
.shortcuts-ins-page .quick-ins-wrap .count-box .add + .popover,
.shortcuts-ins-page .iso-ins-wrap .count-box .add + .popover,
.shortcuts-ins-page .defined-ins-wrap .count-box .add + .popover {
  min-width: 200px;
}
.shortcuts-ins-page .quick-ins-wrap .count-box .minus,
.shortcuts-ins-page .iso-ins-wrap .count-box .minus,
.shortcuts-ins-page .defined-ins-wrap .count-box .minus {
  text-align: center;
  color: #666;
  background-color: #ffffff;
  position: absolute;
  left: -40px;
  top: 0px;
  width: 32px;
  height: 32px;
  line-height: 30px;
  border: solid 1px #e5e5e5;
  cursor: pointer;
}
.shortcuts-ins-page .quick-ins-wrap .count-box .minus.disabled,
.shortcuts-ins-page .iso-ins-wrap .count-box .minus.disabled,
.shortcuts-ins-page .defined-ins-wrap .count-box .minus.disabled {
  cursor: not-allowed;
  background-color: #fafafa;
}
.shortcuts-ins-page .quick-ins-wrap .ip_cidr .flex,
.shortcuts-ins-page .iso-ins-wrap .ip_cidr .flex,
.shortcuts-ins-page .defined-ins-wrap .ip_cidr .flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.shortcuts-ins-page .quick-ins-wrap .ip_cidr .flex .ip-spilt,
.shortcuts-ins-page .iso-ins-wrap .ip_cidr .flex .ip-spilt,
.shortcuts-ins-page .defined-ins-wrap .ip_cidr .flex .ip-spilt {
  height: 20px;
  margin: 12px 2px 0;
}
.shortcuts-ins-page .quick-ins-wrap .footer,
.shortcuts-ins-page .iso-ins-wrap .footer,
.shortcuts-ins-page .defined-ins-wrap .footer {
  text-align: center;
  padding: 20px;
}
.shortcuts-ins-page .quick-ins-wrap .table-label,
.shortcuts-ins-page .iso-ins-wrap .table-label,
.shortcuts-ins-page .defined-ins-wrap .table-label {
  width: 14%;
  height: 32px;
  line-height: 32px;
  float: left;
  text-align: right;
}
.shortcuts-ins-page .quick-ins-wrap .table-content,
.shortcuts-ins-page .iso-ins-wrap .table-content,
.shortcuts-ins-page .defined-ins-wrap .table-content {
  float: left;
  width: 86%;
  padding-left: 25px;
  padding-bottom: 5px;
  position: relative;
}
.shortcuts-ins-page .quick-ins-wrap .table-content > i,
.shortcuts-ins-page .iso-ins-wrap .table-content > i,
.shortcuts-ins-page .defined-ins-wrap .table-content > i {
  position: absolute;
  line-height: 32px;
  left: 4px;
}
.shortcuts-ins-page .quick-ins-wrap .table-content .table tr td .chkradio,
.shortcuts-ins-page .iso-ins-wrap .table-content .table tr td .chkradio,
.shortcuts-ins-page .defined-ins-wrap .table-content .table tr td .chkradio {
  padding-left: 20px;
}
.shortcuts-ins-page .quick-ins-wrap .table-content .valid.error,
.shortcuts-ins-page .iso-ins-wrap .table-content .valid.error,
.shortcuts-ins-page .defined-ins-wrap .table-content .valid.error {
  color: #e74c3c;
  font-size: 12px;
}
.shortcuts-ins-page .quick-ins-wrap .table-content.table-small.no-pointed,
.shortcuts-ins-page .iso-ins-wrap .table-content.table-small.no-pointed,
.shortcuts-ins-page .defined-ins-wrap .table-content.table-small.no-pointed {
  min-height: 220px;
  max-height: 460px;
}
.shortcuts-ins-page .quick-ins-wrap .table-content.table-small.no-pointed .table-action,
.shortcuts-ins-page .iso-ins-wrap .table-content.table-small.no-pointed .table-action,
.shortcuts-ins-page .defined-ins-wrap .table-content.table-small.no-pointed .table-action {
  position: absolute;
  top: -38px;
  right: 0px;
}
.shortcuts-ins-page .quick-ins-wrap .table-content.table-small.no-pointed .table-action .search-box,
.shortcuts-ins-page .iso-ins-wrap .table-content.table-small.no-pointed .table-action .search-box,
.shortcuts-ins-page .defined-ins-wrap .table-content.table-small.no-pointed .table-action .search-box {
  margin-right: 0px;
}
.shortcuts-ins-page .quick-ins-wrap .table-content.table-small.no-pointed .table-wrap,
.shortcuts-ins-page .iso-ins-wrap .table-content.table-small.no-pointed .table-wrap,
.shortcuts-ins-page .defined-ins-wrap .table-content.table-small.no-pointed .table-wrap {
  max-height: 430px;
}
.shortcuts-ins-page .quick-ins-wrap .table-content.table-small.no-pointed .table-wrap .ng-table-pager,
.shortcuts-ins-page .iso-ins-wrap .table-content.table-small.no-pointed .table-wrap .ng-table-pager,
.shortcuts-ins-page .defined-ins-wrap .table-content.table-small.no-pointed .table-wrap .ng-table-pager {
  display: none;
}
.shortcuts-ins-page .quick-ins-wrap .step fieldset,
.shortcuts-ins-page .iso-ins-wrap .step fieldset,
.shortcuts-ins-page .defined-ins-wrap .step fieldset {
  margin: 20px 0;
  border-bottom: 1px solid #e5e5e5;
}
.shortcuts-ins-page .quick-ins-wrap .step fieldset:last-child,
.shortcuts-ins-page .iso-ins-wrap .step fieldset:last-child,
.shortcuts-ins-page .defined-ins-wrap .step fieldset:last-child {
  border-bottom: 0;
}
.shortcuts-ins-page .quick-ins-wrap .step fieldset .control-items2:last-child,
.shortcuts-ins-page .iso-ins-wrap .step fieldset .control-items2:last-child,
.shortcuts-ins-page .defined-ins-wrap .step fieldset .control-items2:last-child {
  margin-bottom: 10px;
}
.shortcuts-ins-page .quick-ins-wrap .step fieldset:last-child,
.shortcuts-ins-page .iso-ins-wrap .step fieldset:last-child,
.shortcuts-ins-page .defined-ins-wrap .step fieldset:last-child {
  margin: 20px 0 0;
}
.shortcuts-ins-page .quick-ins-wrap .step.first-cont #flavor-tip-msg,
.shortcuts-ins-page .iso-ins-wrap .step.first-cont #flavor-tip-msg,
.shortcuts-ins-page .defined-ins-wrap .step.first-cont #flavor-tip-msg {
  margin-bottom: 32px;
}
.shortcuts-ins-page .quick-ins-wrap .step.second-cont .capacity,
.shortcuts-ins-page .iso-ins-wrap .step.second-cont .capacity,
.shortcuts-ins-page .defined-ins-wrap .step.second-cont .capacity {
  margin-left: 12%;
}
.shortcuts-ins-page .quick-ins-wrap .step.second-cont .capacity .val,
.shortcuts-ins-page .iso-ins-wrap .step.second-cont .capacity .val,
.shortcuts-ins-page .defined-ins-wrap .step.second-cont .capacity .val {
  padding-right: 10px;
}
.shortcuts-ins-page .quick-ins-wrap .step.second-cont .add-group,
.shortcuts-ins-page .iso-ins-wrap .step.second-cont .add-group,
.shortcuts-ins-page .defined-ins-wrap .step.second-cont .add-group {
  display: block;
  margin-left: 8%;
}
.shortcuts-ins-page .quick-ins-wrap .step.second-cont .add-group strong,
.shortcuts-ins-page .iso-ins-wrap .step.second-cont .add-group strong,
.shortcuts-ins-page .defined-ins-wrap .step.second-cont .add-group strong {
  cursor: pointer;
}
.shortcuts-ins-page .quick-ins-wrap .step.second-cont .add-group i,
.shortcuts-ins-page .iso-ins-wrap .step.second-cont .add-group i,
.shortcuts-ins-page .defined-ins-wrap .step.second-cont .add-group i {
  color: #51a3ff;
  font-size: 20px;
  padding-right: 10px;
}
.shortcuts-ins-page .quick-ins-wrap .step.second-cont .control-items-group,
.shortcuts-ins-page .iso-ins-wrap .step.second-cont .control-items-group,
.shortcuts-ins-page .defined-ins-wrap .step.second-cont .control-items-group {
  border-bottom: 1px solid #51a3ff;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.shortcuts-ins-page .quick-ins-wrap .step.second-cont .control-items-group .control-group .control-label,
.shortcuts-ins-page .iso-ins-wrap .step.second-cont .control-items-group .control-group .control-label,
.shortcuts-ins-page .defined-ins-wrap .step.second-cont .control-items-group .control-group .control-label {
  width: 8%;
}
.shortcuts-ins-page .quick-ins-wrap .step.second-cont .control-items-group .del,
.shortcuts-ins-page .iso-ins-wrap .step.second-cont .control-items-group .del,
.shortcuts-ins-page .defined-ins-wrap .step.second-cont .control-items-group .del {
  position: absolute;
  top: 5px;
  left: 8%;
  font-size: 18px;
  color: #e74c3c;
  cursor: pointer;
}
.shortcuts-ins-page .quick-ins-wrap .step.second-cont .control-items-group.no-border,
.shortcuts-ins-page .iso-ins-wrap .step.second-cont .control-items-group.no-border,
.shortcuts-ins-page .defined-ins-wrap .step.second-cont .control-items-group.no-border {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}
.shortcuts-ins-page .quick-ins-wrap .step.second-cont .control-items3.large-label .control-group .control-label,
.shortcuts-ins-page .iso-ins-wrap .step.second-cont .control-items3.large-label .control-group .control-label,
.shortcuts-ins-page .defined-ins-wrap .step.second-cont .control-items3.large-label .control-group .control-label {
  width: 33%;
}
.shortcuts-ins-page .quick-ins-wrap .step.second-cont .control-items3.large-label .control-group .controls,
.shortcuts-ins-page .iso-ins-wrap .step.second-cont .control-items3.large-label .control-group .controls,
.shortcuts-ins-page .defined-ins-wrap .step.second-cont .control-items3.large-label .control-group .controls {
  width: 67%;
}
.shortcuts-ins-page .quick-ins-wrap .step.second-cont .control-items3 .control-group,
.shortcuts-ins-page .iso-ins-wrap .step.second-cont .control-items3 .control-group,
.shortcuts-ins-page .defined-ins-wrap .step.second-cont .control-items3 .control-group {
  padding: 0 10px 0 0;
}
.shortcuts-ins-page .quick-ins-wrap .step.second-cont .control-items3 .control-group .control-label,
.shortcuts-ins-page .iso-ins-wrap .step.second-cont .control-items3 .control-group .control-label,
.shortcuts-ins-page .defined-ins-wrap .step.second-cont .control-items3 .control-group .control-label {
  width: 23%;
}
.shortcuts-ins-page .quick-ins-wrap .step.second-cont .control-items3 .control-group:first-child .controls,
.shortcuts-ins-page .iso-ins-wrap .step.second-cont .control-items3 .control-group:first-child .controls,
.shortcuts-ins-page .defined-ins-wrap .step.second-cont .control-items3 .control-group:first-child .controls {
  padding-left: 25px;
}
.shortcuts-ins-page .quick-ins-wrap .step.second-cont .control-items3 .control-group.small-group,
.shortcuts-ins-page .iso-ins-wrap .step.second-cont .control-items3 .control-group.small-group,
.shortcuts-ins-page .defined-ins-wrap .step.second-cont .control-items3 .control-group.small-group {
  width: 20%;
}
.shortcuts-ins-page .quick-ins-wrap .step.second-cont .control-items3 .control-group.small-group .controls,
.shortcuts-ins-page .iso-ins-wrap .step.second-cont .control-items3 .control-group.small-group .controls,
.shortcuts-ins-page .defined-ins-wrap .step.second-cont .control-items3 .control-group.small-group .controls {
  padding-left: 0;
}
.shortcuts-ins-page .quick-ins-wrap .step.second-cont .control-items3 .control-group.middle-group,
.shortcuts-ins-page .iso-ins-wrap .step.second-cont .control-items3 .control-group.middle-group,
.shortcuts-ins-page .defined-ins-wrap .step.second-cont .control-items3 .control-group.middle-group {
  width: 40%;
}
.shortcuts-ins-page .quick-ins-wrap .step.second-cont .control-items3 .control-group.all .controls,
.shortcuts-ins-page .iso-ins-wrap .step.second-cont .control-items3 .control-group.all .controls,
.shortcuts-ins-page .defined-ins-wrap .step.second-cont .control-items3 .control-group.all .controls {
  width: 100%;
}
.shortcuts-ins-page .quick-ins-wrap .step.quick-cont .control-items .control-group,
.shortcuts-ins-page .iso-ins-wrap .step.quick-cont .control-items .control-group,
.shortcuts-ins-page .defined-ins-wrap .step.quick-cont .control-items .control-group {
  margin-bottom: 0;
}
.shortcuts-ins-page .quick-ins-wrap .step.quick-cont .control-items .control-group .controls,
.shortcuts-ins-page .iso-ins-wrap .step.quick-cont .control-items .control-group .controls,
.shortcuts-ins-page .defined-ins-wrap .step.quick-cont .control-items .control-group .controls {
  padding-left: 0;
}
.shortcuts-ins-page .quick-ins-wrap .step.quick-cont .control-items .control-group.all,
.shortcuts-ins-page .iso-ins-wrap .step.quick-cont .control-items .control-group.all,
.shortcuts-ins-page .defined-ins-wrap .step.quick-cont .control-items .control-group.all {
  margin-bottom: 10px;
}
.shortcuts-ins-page .quick-ins-wrap .step.quick-cont .no-border .control-group .control-label,
.shortcuts-ins-page .iso-ins-wrap .step.quick-cont .no-border .control-group .control-label,
.shortcuts-ins-page .defined-ins-wrap .step.quick-cont .no-border .control-group .control-label {
  width: 17%;
}
.shortcuts-ins-page .quick-ins-wrap .step.quick-cont .no-border .controls,
.shortcuts-ins-page .iso-ins-wrap .step.quick-cont .no-border .controls,
.shortcuts-ins-page .defined-ins-wrap .step.quick-cont .no-border .controls {
  padding-left: 0;
}
.shortcuts-ins-page .quick-ins-wrap .step.third-cont,
.shortcuts-ins-page .iso-ins-wrap .step.third-cont,
.shortcuts-ins-page .defined-ins-wrap .step.third-cont {
  border-bottom: 0;
  margin: 0;
}
.shortcuts-ins-page .quick-ins-wrap .step.third-cont .control-group.add_net,
.shortcuts-ins-page .iso-ins-wrap .step.third-cont .control-group.add_net,
.shortcuts-ins-page .defined-ins-wrap .step.third-cont .control-group.add_net {
  position: relative;
  width: 30%;
  height: 32px;
  line-height: 32px;
}
.shortcuts-ins-page .quick-ins-wrap .step.third-cont .control-group.add_net .popover,
.shortcuts-ins-page .iso-ins-wrap .step.third-cont .control-group.add_net .popover,
.shortcuts-ins-page .defined-ins-wrap .step.third-cont .control-group.add_net .popover {
  top: -50px !important;
}
.shortcuts-ins-page .quick-ins-wrap .step.third-cont .control-group .add,
.shortcuts-ins-page .iso-ins-wrap .step.third-cont .control-group .add,
.shortcuts-ins-page .defined-ins-wrap .step.third-cont .control-group .add {
  position: absolute;
  left: 70%;
  top: 8px;
}
.shortcuts-ins-page .quick-ins-wrap .step.third-cont .high-cfig .sec .control-group,
.shortcuts-ins-page .iso-ins-wrap .step.third-cont .high-cfig .sec .control-group,
.shortcuts-ins-page .defined-ins-wrap .step.third-cont .high-cfig .sec .control-group {
  margin-bottom: 10px;
}
.shortcuts-ins-page .quick-ins-wrap .step.third-cont .high-cfig .sec .control-group .controls a,
.shortcuts-ins-page .iso-ins-wrap .step.third-cont .high-cfig .sec .control-group .controls a,
.shortcuts-ins-page .defined-ins-wrap .step.third-cont .high-cfig .sec .control-group .controls a {
  height: 32px;
  line-height: 32px;
}
.shortcuts-ins-page .quick-ins-wrap .step.third-cont .control-group a i,
.shortcuts-ins-page .iso-ins-wrap .step.third-cont .control-group a i,
.shortcuts-ins-page .defined-ins-wrap .step.third-cont .control-group a i {
  padding-right: 10px;
}
.shortcuts-ins-page .quick-ins-wrap .step.four-cont .table tbody tr td,
.shortcuts-ins-page .iso-ins-wrap .step.four-cont .table tbody tr td,
.shortcuts-ins-page .defined-ins-wrap .step.four-cont .table tbody tr td {
  height: 44px;
  padding: 12px 16px;
}
.shortcuts-ins-page .ins-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 70px;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  background: #f6f9fe;
  padding: 10px 30px;
}
.shortcuts-ins-page .ins-footer .price-box {
  border: 0;
  padding-left: 40px;
  text-align: left;
}
.shortcuts-ins-page .ins-footer .footer {
  position: absolute;
  right: 150px;
  top: 20px;
}
.detailIns-box .animateContent.animateIn {
  min-height: auto;
}
.detailIns-box .animateContent.animateIn .tab-content {
  margin-bottom: 0;
}
.detail-info .shortcuts-ins-page .shortcuts-ins-page-inner {
  width: auto;
}
.detail-info .shortcuts-ins-page .iso-ins {
  padding-top: 10px;
}
.detail-info .shortcuts-ins-page .iso-ins-wrap {
  min-height: 735px;
  box-shadow: none;
}
.detail-info .shortcuts-ins-page .defined-ins-wrap {
  box-shadow: none;
  padding: 0px 20px 80px 30px;
}
.detail-info .shortcuts-ins-page .quick-ins-wrap {
  box-shadow: none;
  padding: 16px 40px 80px;
}
.detail-info .shortcuts-ins-page .quick-ins {
  padding-top: 0;
}
.detail-info .shortcuts-ins-page .ins-header .region-dp {
  width: auto;
}
.detail-info .shortcuts-ins-page .ins-header .reply-link {
  display: none;
}
.detail-info .shortcuts-ins-page .step-box {
  width: auto;
  padding: 0 100px;
}
.detail-row.ins-info .detail-info .tab-content h4 {
  margin-bottom: 5px;
}
.detail-row.ins-info .detail-info .tab-content .diskinfo {
  margin-top: 40px;
  overflow: auto;
}
.detail-row.ins-info .detail-info .tab-content .diskinfo h4 {
  margin-bottom: 5px;
}
.table-space {
  overflow-x: scroll;
}
.modal .modal-dialog .modal-large {
  width: 1040px;
}
.modal .modal-dialog .modal-large .rule-items > li {
  float: left;
  border-right: 1px solid #ddd;
  height: 350px;
}
.modal .modal-dialog .modal-large .rule-items > li h3 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
}
.modal .modal-dialog .modal-large .rule-items > li .rule-footer {
  padding-left: 84px;
}
.modal .modal-dialog .modal-large .rule-items > li .rule-footer .btn {
  margin: 0;
}
.modal .modal-dialog .modal-large .rule-items > li .rule-footer .error {
  color: #e74c3c;
}
.modal .modal-dialog .modal-large .rule-items > li:nth-child(1) {
  padding-left: 10px;
  width: 115px;
}
.modal .modal-dialog .modal-large .rule-items > li:nth-child(1) ul li {
  height: 30px;
  width: 75px;
  border: 1px solid #c2d3eb;
  text-align: center;
  line-height: 30px;
  margin: 10px 0;
  font-size: 12px;
  color: #313949;
  cursor: pointer;
}
.modal .modal-dialog .modal-large .rule-items > li:nth-child(1) ul li:hover {
  background: #f6f9ff;
  border-color: #5888f5;
  color: #5888f5;
}
.modal .modal-dialog .modal-large .rule-items > li:nth-child(1) ul .active {
  background: #f6f9ff;
  border-color: #5888f5;
  color: #5888f5;
}
.modal .modal-dialog .modal-large .rule-items > li:nth-child(2) {
  width: 475px;
  padding: 0 20px;
}
.modal .modal-dialog .modal-large .rule-items > li:nth-child(3) {
  width: 410px;
  padding: 0 20px;
  border: 0;
}
.modal .modal-dialog .modal-large .rule-items > li:nth-child(3) .rules-handle a {
  font-size: 12px;
}
.table-action .dropdown-menu li button[disabled] {
  color: #999999;
}
.table-action .dropdown-menu li button[disabled]:hover {
  background: #e5e5e5;
}
.table-action .dropdown-menu li .disabled {
  color: #999999;
}
.table-action .dropdown-menu li .disabled:hover {
  background: #e5e5e5;
}
.step-box {
  width: 940px;
  margin: 0 auto 20px;
}
.step-box .ui-step-4 li {
  width: 33.3%;
}
.step-box .ui-step {
  color: #b7b7b7;
  padding: 0 60px;
  margin-bottom: 30px;
  position: relative;
}
.step-box .ui-step:after {
  display: block;
  content: "";
  height: 0;
  font-size: 0;
  clear: both;
  overflow: hidden;
  visibility: hidden;
}
.step-box .ui-step li {
  float: left;
  position: relative;
}
.step-box .ui-step li .ui-step-line {
  height: 5px;
  background-color: #e5e5e5;
  margin-top: 11px;
  width: 50%;
  display: inline-block;
  float: left;
}
.step-box .ui-step li .ui-step-line.half1 {
  background-color: #51a3ff;
}
.step-box .ui-step li .ui-step-line.half2 {
  background: #e7e7e8;
}
.step-box .ui-step li .ui-step-line.all {
  background-color: #51a3ff;
}
.step-box .ui-step li .ui-step-cont {
  width: 120px;
  position: absolute;
  top: 0;
  left: -15px;
  text-align: center;
}
.step-box .ui-step li .ui-step-cont .ui-step-cont-number {
  display: inline-block;
  zoom: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 26px;
  height: 26px;
  line-height: 26px;
  color: #999999;
  font-weight: 600;
  border-radius: 50%;
  background-color: #e5e5e5;
}
.step-box .ui-step li .ui-step-cont .ui-step-cont-text {
  position: relative;
  top: -25px;
  left: -42px;
  font-size: 14px;
  font-weight: 600;
  color: #999;
}
.step-box .ui-step li.step-done .ui-step-cont-number {
  background-color: #51a3ff;
  color: #fff;
}
.step-box .ui-step li.step-done .ui-step-cont-text {
  color: #51a3ff;
}
.step-box .ui-step .step-end {
  width: 120px;
  position: absolute;
  top: 0;
  right: -60px;
}
.text-color-gray {
  color: #999;
}
.text-color-gray strong {
  color: #666;
}
.model-box .model-content {
  padding: 40px 0;
}
.model-box .model-content .disk-content {
  width: 90%;
  margin: 0 auto;
}
.model-box .model-content .disk-content .left-modal {
  width: 60%;
  float: left;
  height: 500px;
  border-right: 1px solid #e5e5e5;
}
.model-box .model-content .disk-content .left-modal .des-info.back-create-disk {
  height: 48px;
  line-height: 48px;
  margin-top: 80px;
  margin-right: 15px;
  font-size: 14px;
  border: 1px solid #C3D3EA;
  border-radius: 2px;
  background-color: #F6F9FE;
  text-align: center;
}
.model-box .model-content .disk-content .left-modal .des-info.back-create-disk strong {
  color: #FF9B11;
  font-size: 16px;
}
.model-box .model-content .disk-content .right-modal {
  width: 35%;
  float: right;
}
.model-box .steps-sytle-ver {
  width: 70%;
  margin: 0 auto;
}
.model-box .steps-sytle-ver li.step-start {
  width: 45%;
}
.model-box .steps-sytle-ver li.step-end {
  width: 45%;
}
.model-box .steps-sytle-ver li .inner {
  color: #999;
  font-size: 14px;
}
.model-box .steps-sytle-ver li .inner span {
  margin-bottom: 3px;
}
.model-box .steps-sytle-ver li .inner:before {
  background: #e5e5e5;
}
.model-box .steps-sytle-ver li .inner:after {
  background: #e5e5e5;
}
.model-box .steps-sytle-ver li .inner b {
  font-weight: bold;
  cursor: pointer;
}
.model-box .steps-sytle-ver li .inner i {
  width: 32px;
  height: 32px;
  line-height: 32px;
  background: #e5e5e5;
  color: #999;
  cursor: pointer;
}
.model-box .steps-sytle-ver li.active .inner i {
  background: #51a3ff;
  color: #fff;
}
.model-box .steps-sytle-ver li.active .inner:before {
  background: #51a3ff;
}
.model-box .steps-sytle-ver li.active .inner:after {
  background: #51a3ff;
}
.defined-ins .steps-sytle-ver li.step-start {
  width: 16.6666%;
}
.defined-ins .steps-sytle-ver li.step-end {
  width: 16.6666%;
}
.defined-ins .steps-sytle-ver li.step-middle {
  width: 33.3333%;
}
.defined-ins .steps-sytle-ver li .inner {
  color: #999;
  font-size: 14px;
}
.defined-ins .steps-sytle-ver li .inner span {
  margin-bottom: 3px;
}
.defined-ins .steps-sytle-ver li .inner:before {
  background: #e5e5e5;
}
.defined-ins .steps-sytle-ver li .inner:after {
  background: #e5e5e5;
}
.defined-ins .steps-sytle-ver li .inner b {
  font-weight: bold;
  cursor: pointer;
}
.defined-ins .steps-sytle-ver li .inner i {
  width: 26px;
  height: 26px;
  line-height: 26px;
  background: #e5e5e5;
  color: #999;
  cursor: pointer;
}
.defined-ins .steps-sytle-ver li.active .inner i {
  background: #51a3ff;
  color: #fff;
}
.defined-ins .steps-sytle-ver li.active .inner:before {
  background: #51a3ff;
}
.defined-ins .steps-sytle-ver li.active .inner:after {
  background: #51a3ff;
}
.modal-header .rule-steps .step-start,
.modal-header .rule-steps .step-end {
  width: 45%;
}
/*单数据中心*/
.main .main-content .overview-single .datacenter-title {
  padding-bottom: 0;
}
.main .main-content .overview-single .box .title {
  color: #7991ab;
  line-height: 32px;
}
.main .main-content .overview-single .box .title .total-num,
.main .main-content .overview-single .box .title .used-num,
.main .main-content .overview-single .box .title .avail-num {
  font-size: 14px;
}
.main .main-content .overview-single .box .title .total-num i,
.main .main-content .overview-single .box .title .used-num i,
.main .main-content .overview-single .box .title .avail-num i {
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
}
.main .main-content .overview-single .box .title .total-num {
  margin-left: 15px;
}
.main .main-content .overview-single .box .title .total-num i {
  color: #1f2a37;
}
.main .main-content .overview-single .box .title .used-num {
  margin-left: 40px;
}
.main .main-content .overview-single .box .title .used-num i {
  color: #51a3ff;
}
.main .main-content .overview-single .box .title .avail-num {
  margin-left: 40px;
}
.main .main-content .overview-single .box .single-title {
  font-size: 16px;
  color: #7991AB;
  float: left;
  line-height: 32px;
}
.main .main-content .overview-single .box .border-space-left {
  padding-right: 10px;
}
.main .main-content .overview-single .box .border-space-right {
  padding-left: 10px;
}
.main .main-content .overview-single .box .border-space-right .add-content {
  height: 100%;
}
.main .main-content .overview-single .box .border-space-right .space-content {
  cursor: pointer;
  min-height: 458px;
  padding: 0;
}
.main .main-content .overview-single .box .border-space-right .space-content .add-content {
  position: relative;
}
.main .main-content .overview-single .box .border-space-right .space-content .add-content .table-action {
  position: absolute;
  right: 0;
  top: 0;
}
.main .main-content .overview-single .box .border-space-right .space-content .add-item {
  padding: 0 65px;
  overflow: hidden;
}
.main .main-content .overview-single .box .border-space-right .space-content .add-item li {
  padding: 20px;
  width: 50%;
  text-align: center;
  float: left;
}
.main .main-content .overview-single .box .border-space-right .space-content .add-item li .inner {
  padding: 15px 0;
  border: 1px solid #c2d3eb;
}
.main .main-content .overview-single .box .border-space-right .space-content .add-item li i {
  display: block;
  color: #d3e1ec;
  font-size: 96px;
}
.main .main-content .overview-single .box .border-space-right .space-content .add-item li a,
.main .main-content .overview-single .box .border-space-right .space-content .add-item li span {
  font-size: 16px;
}
.main .main-content .overview-single .box .border-space-right .space-content .add-item li .add-pointer {
  color: #51a3ff;
  cursor: pointer;
}
.main .main-content .overview-single .box .bar-group {
  padding: 8px 25px;
}
.main .main-content .overview-single .box .panels .bar-wrap ul li .progress {
  width: 65%;
}
.main .main-content .overview-single .box .panels .pie-legend .dl-horizontal .ip-name {
  height: 40px;
}
.main .main-content .overview-single .box .panels .pie-legend .dl-horizontal .ip-name span {
  font-size: 16px;
  display: inline-block;
  width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.main .main-content .overview-single .box .choose-box {
  float: left;
}
.main .main-content .overview-single .box .nav-tabs {
  height: 32px;
  margin-bottom: 10px;
}
.main .main-content .overview-single .box .nav-tabs li {
  float: left;
  position: relative;
  cursor: pointer;
  background-color: #fff;
}
.main .main-content .overview-single .box .nav-tabs li a {
  padding: 0 20px;
  border: 1px solid #c2d3eb;
  display: block;
  height: 32px;
  line-height: 32px;
  text-decoration: none;
  color: #666666;
  width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.main .main-content .overview-single .box .nav-tabs li:first-child a {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.main .main-content .overview-single .box .nav-tabs li:last-child a {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.main .main-content .overview-single .box .nav-tabs li.active {
  z-index: 2;
  background-color: #f6f9ff;
}
.main .main-content .overview-single .box .nav-tabs li.active a {
  color: #51a3ff;
}
.main .main-content .overview-single .box .nav-tabs li + li {
  margin-left: -1px;
}
.storage-common .i-block {
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 50%;
}
.storage-common .green {
  background-color: #1abc9c;
}
.storage-common .blue {
  background-color: #51a3ff;
}
.storage-common .blue-dark {
  background-color: #4e80f5;
}
.storage-common .orange {
  background-color: #f39c12;
}
.storage-common .orange-dark {
  background-color: #e67e22;
}
.storage-common .red {
  background-color: #e74c3c;
}
.storage-common .red-dark {
  background-color: #c0392b;
}
.storage-common .gray {
  background: #c7c5c5;
}
.storage-common .light-gray {
  background: #e5e5e5;
}
.storage-common .storage-margin {
  margin-right: 10px;
}
.storage-common .margin-bottom20 {
  margin-bottom: 20px;
}
.modal-body .left-modal .table tbody tr td .edit-name {
  width: 100px;
}
.modal-body .left-modal .table tbody tr td .edit-name > span {
  max-width: 100px;
}
.modal-body .left-modal .info-right {
  color: #51a3ff;
  line-height: 20px;
  margin: -33px 0 0 0;
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 1;
}
.modal-dialog .modal-common.modal-del {
  width: 450px;
  min-height: 170px;
}
.modal-dialog .modal-common.modal-del .modal-header {
  border-bottom: none;
}
.modal-dialog .modal-common.modal-del .modal-body .box {
  display: block;
  text-align: center;
}
.modal-dialog .modal-common.modal-del .modal-body .box span {
  padding-bottom: 10px;
  display: block;
  font-size: 16px;
}
.modal-dialog .modal-common.modal-del .modal-body .box .del {
  color: #e74c3c;
}
.modal-dialog .modal-common.modal-del .modal-footer {
  border-top: none;
}
.modal-dialog .modal-common.modal-del .modal-footer .del-btn {
  background: #e74c3c;
  border-color: #e74c3c;
}
.modal-dialog .modal-common.modal-del .modal-footer .del-btn.disabled {
  background: #fafafa;
  border: 1px solid #e5e5e5;
}
/*初始化页面存储*/
.storage-initSetting .nics-list,
.storage-modal .nics-list,
.storage-initSetting .disks-list,
.storage-modal .disks-list {
  margin: 0 0 10px;
  background: #eff1f3;
  border: 1px dashed #c3cfdb;
  border-radius: 3px;
  position: relative;
}
.storage-initSetting .disks-warning,
.storage-modal .disks-warning {
  color: #ff9b11;
}
.storage-initSetting .disks-content,
.storage-modal .disks-content {
  max-height: 430px;
  padding-bottom: 150px;
}
.storage-initSetting .disks-content .group-content,
.storage-modal .disks-content .group-content {
  border-bottom: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
  background: #fafafa;
  padding: 20px 10px;
}
.storage-initSetting .disks-content .group-content .caching-content,
.storage-modal .disks-content .group-content .caching-content,
.storage-initSetting .disks-content .group-content .data-content,
.storage-modal .disks-content .group-content .data-content {
  float: left;
}
.storage-initSetting .disks-content .group-content .caching-content .name-item,
.storage-modal .disks-content .group-content .caching-content .name-item,
.storage-initSetting .disks-content .group-content .data-content .name-item,
.storage-modal .disks-content .group-content .data-content .name-item {
  margin-left: 18px;
  width: 100%;
}
.storage-initSetting .disks-content .group-content .caching-content .name-item .name-type,
.storage-modal .disks-content .group-content .caching-content .name-item .name-type,
.storage-initSetting .disks-content .group-content .data-content .name-item .name-type,
.storage-modal .disks-content .group-content .data-content .name-item .name-type {
  float: left;
  line-height: 32px;
}
.storage-initSetting .disks-content .group-content .caching-content .name-item .type-item,
.storage-modal .disks-content .group-content .caching-content .name-item .type-item,
.storage-initSetting .disks-content .group-content .data-content .name-item .type-item,
.storage-modal .disks-content .group-content .data-content .name-item .type-item {
  width: 180px;
  float: left;
}
.storage-initSetting .disks-content .group-content .caching-content .name-item .type-item .form-control,
.storage-modal .disks-content .group-content .caching-content .name-item .type-item .form-control,
.storage-initSetting .disks-content .group-content .data-content .name-item .type-item .form-control,
.storage-modal .disks-content .group-content .data-content .name-item .type-item .form-control {
  height: 32px;
}
.storage-initSetting .disks-content .group-content .caching-content .name-item .type-item .text-muted,
.storage-modal .disks-content .group-content .caching-content .name-item .type-item .text-muted,
.storage-initSetting .disks-content .group-content .data-content .name-item .type-item .text-muted,
.storage-modal .disks-content .group-content .data-content .name-item .type-item .text-muted {
  margin-left: 15px;
}
.storage-initSetting .disks-content .group-content .caching-content .data .tips,
.storage-modal .disks-content .group-content .caching-content .data .tips,
.storage-initSetting .disks-content .group-content .data-content .data .tips,
.storage-modal .disks-content .group-content .data-content .data .tips,
.storage-initSetting .disks-content .group-content .caching-content .caching .tips,
.storage-modal .disks-content .group-content .caching-content .caching .tips,
.storage-initSetting .disks-content .group-content .data-content .caching .tips,
.storage-modal .disks-content .group-content .data-content .caching .tips {
  line-height: 90px;
  color: #c9c9c9;
}
.storage-initSetting .disks-content .group-content .caching-content .data li,
.storage-modal .disks-content .group-content .caching-content .data li,
.storage-initSetting .disks-content .group-content .data-content .data li,
.storage-modal .disks-content .group-content .data-content .data li,
.storage-initSetting .disks-content .group-content .caching-content .caching li,
.storage-modal .disks-content .group-content .caching-content .caching li,
.storage-initSetting .disks-content .group-content .data-content .caching li,
.storage-modal .disks-content .group-content .data-content .caching li {
  width: 8%;
}
.storage-initSetting .disks-content .group-content .caching-content .data .disks-item,
.storage-modal .disks-content .group-content .caching-content .data .disks-item,
.storage-initSetting .disks-content .group-content .data-content .data .disks-item,
.storage-modal .disks-content .group-content .data-content .data .disks-item,
.storage-initSetting .disks-content .group-content .caching-content .caching .disks-item,
.storage-modal .disks-content .group-content .caching-content .caching .disks-item,
.storage-initSetting .disks-content .group-content .data-content .caching .disks-item,
.storage-modal .disks-content .group-content .data-content .caching .disks-item {
  cursor: default;
}
.storage-initSetting .disks-content .group-content .data-content,
.storage-modal .disks-content .group-content .data-content {
  width: 100%;
}
.storage-initSetting .disks-content .disks-group .group-name,
.storage-modal .disks-content .disks-group .group-name {
  position: relative;
}
.storage-initSetting .disks-content .disks-group .group-name .delete-group,
.storage-modal .disks-content .disks-group .group-name .delete-group {
  position: absolute;
  top: -8px;
  right: 12px;
  font-size: 25px;
}
.storage-initSetting .disks-content .disks-group .group-name .group-cache,
.storage-modal .disks-content .disks-group .group-name .group-cache {
  display: inline-block;
  margin: 0 15px;
}
.storage-initSetting .disks-content .disks-group .scale,
.storage-modal .disks-content .disks-group .scale {
  cursor: pointer;
  height: 62px;
  text-align: center;
  color: #fff;
}
.storage-initSetting .disks-content .disks-group .scale .scale-num,
.storage-modal .disks-content .disks-group .scale .scale-num,
.storage-initSetting .disks-content .disks-group .scale .name,
.storage-modal .disks-content .disks-group .scale .name {
  display: block;
  line-height: 16px;
  font-style: normal;
  white-space: nowrap;
}
.storage-initSetting .disks-content .disks-group .scale .no-disk,
.storage-modal .disks-content .disks-group .scale .no-disk {
  display: block;
  width: 100%;
  height: 100%;
  padding: 16px 0;
  background: #aeaeae;
}
.storage-initSetting .disks-content .disks-group .scale .data-disk,
.storage-modal .disks-content .disks-group .scale .data-disk {
  overflow: hidden;
  float: left;
  padding: 16px 0;
  display: inline-block;
  height: 100%;
  width: 60%;
  background: #16a085;
}
.storage-initSetting .data,
.storage-modal .data,
.storage-initSetting .caching,
.storage-modal .caching,
.storage-initSetting .no-use-disks,
.storage-modal .no-use-disks {
  display: block;
  font-size: 16px;
  text-align: center;
  min-height: 92px;
}
.storage-initSetting .data li,
.storage-modal .data li,
.storage-initSetting .caching li,
.storage-modal .caching li,
.storage-initSetting .no-use-disks li,
.storage-modal .no-use-disks li {
  float: left;
  width: 12%;
  padding: 13px 13px;
  position: relative;
}
.storage-initSetting .data li .disks-detail,
.storage-modal .data li .disks-detail,
.storage-initSetting .caching li .disks-detail,
.storage-modal .caching li .disks-detail,
.storage-initSetting .no-use-disks li .disks-detail,
.storage-modal .no-use-disks li .disks-detail {
  display: none;
  width: 200px;
  top: 85px;
  position: absolute;
  background: #fff;
  padding: 10px;
  border: 1px solid #c3cfdb;
  z-index: 200;
}
.storage-initSetting .data li .disks-detail .detail-item,
.storage-modal .data li .disks-detail .detail-item,
.storage-initSetting .caching li .disks-detail .detail-item,
.storage-modal .caching li .disks-detail .detail-item,
.storage-initSetting .no-use-disks li .disks-detail .detail-item,
.storage-modal .no-use-disks li .disks-detail .detail-item {
  width: 100%;
  line-height: 28px;
  font-size: 14px;
}
.storage-initSetting .data li .disks-detail .detail-item span,
.storage-modal .data li .disks-detail .detail-item span,
.storage-initSetting .caching li .disks-detail .detail-item span,
.storage-modal .caching li .disks-detail .detail-item span,
.storage-initSetting .no-use-disks li .disks-detail .detail-item span,
.storage-modal .no-use-disks li .disks-detail .detail-item span {
  display: inline-block;
}
.storage-initSetting .data li .disks-detail .detail-item .title,
.storage-modal .data li .disks-detail .detail-item .title,
.storage-initSetting .caching li .disks-detail .detail-item .title,
.storage-modal .caching li .disks-detail .detail-item .title,
.storage-initSetting .no-use-disks li .disks-detail .detail-item .title,
.storage-modal .no-use-disks li .disks-detail .detail-item .title {
  width: 52%;
  float: left;
  font-weight: 500;
  border: 0;
  padding: 0;
  text-align: right;
  color: #7991ab;
}
.storage-initSetting .data li .disks-detail .detail-item .title-name,
.storage-modal .data li .disks-detail .detail-item .title-name,
.storage-initSetting .caching li .disks-detail .detail-item .title-name,
.storage-modal .caching li .disks-detail .detail-item .title-name,
.storage-initSetting .no-use-disks li .disks-detail .detail-item .title-name,
.storage-modal .no-use-disks li .disks-detail .detail-item .title-name {
  display: inline-block;
  width: 70px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.storage-initSetting .data li .disks-detail .detail-item .pool-name,
.storage-modal .data li .disks-detail .detail-item .pool-name,
.storage-initSetting .caching li .disks-detail .detail-item .pool-name,
.storage-modal .caching li .disks-detail .detail-item .pool-name,
.storage-initSetting .no-use-disks li .disks-detail .detail-item .pool-name,
.storage-modal .no-use-disks li .disks-detail .detail-item .pool-name {
  width: 70px;
  text-align: left;
}
.storage-initSetting .data li .disks-detail i,
.storage-modal .data li .disks-detail i,
.storage-initSetting .caching li .disks-detail i,
.storage-modal .caching li .disks-detail i,
.storage-initSetting .no-use-disks li .disks-detail i,
.storage-modal .no-use-disks li .disks-detail i {
  position: absolute;
  top: -5px;
  width: 10px;
  height: 10px;
  left: 28px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  border-top: 1px solid #c3cfdb;
  border-left: 1px solid #c3cfdb;
  background: #fff;
}
.storage-initSetting .data li .disks-item,
.storage-modal .data li .disks-item,
.storage-initSetting .caching li .disks-item,
.storage-modal .caching li .disks-item,
.storage-initSetting .no-use-disks li .disks-item,
.storage-modal .no-use-disks li .disks-item {
  height: 64px;
  width: 69px;
  position: relative;
  background: #fff;
  cursor: pointer;
  border: 1px solid #c3cfdb;
}
.storage-initSetting .data li .disks-item .disks-remove,
.storage-modal .data li .disks-item .disks-remove,
.storage-initSetting .caching li .disks-item .disks-remove,
.storage-modal .caching li .disks-item .disks-remove,
.storage-initSetting .no-use-disks li .disks-item .disks-remove,
.storage-modal .no-use-disks li .disks-item .disks-remove {
  position: absolute;
  right: -8px;
  top: -8px;
  background: #51a3ff;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  color: #fff;
  border-radius: 50%;
  font-size: 12px;
  cursor: pointer;
}
.storage-initSetting .data li .disks-item .disk-out,
.storage-modal .data li .disks-item .disk-out,
.storage-initSetting .caching li .disks-item .disk-out,
.storage-modal .caching li .disks-item .disk-out,
.storage-initSetting .no-use-disks li .disks-item .disk-out,
.storage-modal .no-use-disks li .disks-item .disk-out {
  height: 100%;
  text-align: center;
}
.storage-initSetting .data li .disks-item .disk-out .disk-hhd-bg,
.storage-modal .data li .disks-item .disk-out .disk-hhd-bg,
.storage-initSetting .caching li .disks-item .disk-out .disk-hhd-bg,
.storage-modal .caching li .disks-item .disk-out .disk-hhd-bg,
.storage-initSetting .no-use-disks li .disks-item .disk-out .disk-hhd-bg,
.storage-modal .no-use-disks li .disks-item .disk-out .disk-hhd-bg,
.storage-initSetting .data li .disks-item .disk-out .disk-ssd-bg,
.storage-modal .data li .disks-item .disk-out .disk-ssd-bg,
.storage-initSetting .caching li .disks-item .disk-out .disk-ssd-bg,
.storage-modal .caching li .disks-item .disk-out .disk-ssd-bg,
.storage-initSetting .no-use-disks li .disks-item .disk-out .disk-ssd-bg,
.storage-modal .no-use-disks li .disks-item .disk-out .disk-ssd-bg {
  display: block;
  height: 36px;
  width: 100%;
  font-size: 22px;
}
.storage-initSetting .data li .disks-item .disk-out .disk-hhd-bg i,
.storage-modal .data li .disks-item .disk-out .disk-hhd-bg i,
.storage-initSetting .caching li .disks-item .disk-out .disk-hhd-bg i,
.storage-modal .caching li .disks-item .disk-out .disk-hhd-bg i,
.storage-initSetting .no-use-disks li .disks-item .disk-out .disk-hhd-bg i,
.storage-modal .no-use-disks li .disks-item .disk-out .disk-hhd-bg i,
.storage-initSetting .data li .disks-item .disk-out .disk-ssd-bg i,
.storage-modal .data li .disks-item .disk-out .disk-ssd-bg i,
.storage-initSetting .caching li .disks-item .disk-out .disk-ssd-bg i,
.storage-modal .caching li .disks-item .disk-out .disk-ssd-bg i,
.storage-initSetting .no-use-disks li .disks-item .disk-out .disk-ssd-bg i,
.storage-modal .no-use-disks li .disks-item .disk-out .disk-ssd-bg i {
  height: 100%;
  width: 100%;
  margin-top: 2px;
  display: inline-block;
}
.storage-initSetting .data li .disks-item .disk-out .disk-hhd-bg,
.storage-modal .data li .disks-item .disk-out .disk-hhd-bg,
.storage-initSetting .caching li .disks-item .disk-out .disk-hhd-bg,
.storage-modal .caching li .disks-item .disk-out .disk-hhd-bg,
.storage-initSetting .no-use-disks li .disks-item .disk-out .disk-hhd-bg,
.storage-modal .no-use-disks li .disks-item .disk-out .disk-hhd-bg {
  color: #51a3ff;
}
.storage-initSetting .data li .disks-item .disk-out .disk-ssd-bg,
.storage-modal .data li .disks-item .disk-out .disk-ssd-bg,
.storage-initSetting .caching li .disks-item .disk-out .disk-ssd-bg,
.storage-modal .caching li .disks-item .disk-out .disk-ssd-bg,
.storage-initSetting .no-use-disks li .disks-item .disk-out .disk-ssd-bg,
.storage-modal .no-use-disks li .disks-item .disk-out .disk-ssd-bg {
  color: #4a6583;
}
.storage-initSetting .data li .disks-item .disk-name,
.storage-modal .data li .disks-item .disk-name,
.storage-initSetting .caching li .disks-item .disk-name,
.storage-modal .caching li .disks-item .disk-name,
.storage-initSetting .no-use-disks li .disks-item .disk-name,
.storage-modal .no-use-disks li .disks-item .disk-name {
  display: block;
  width: 67px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.storage-initSetting .data li:hover .disks-detail,
.storage-modal .data li:hover .disks-detail,
.storage-initSetting .caching li:hover .disks-detail,
.storage-modal .caching li:hover .disks-detail,
.storage-initSetting .no-use-disks li:hover .disks-detail,
.storage-modal .no-use-disks li:hover .disks-detail {
  display: block;
}
.storage-initSetting .data li:hover .disks-detail .detail-item .title-name.pool-name,
.storage-modal .data li:hover .disks-detail .detail-item .title-name.pool-name,
.storage-initSetting .caching li:hover .disks-detail .detail-item .title-name.pool-name,
.storage-modal .caching li:hover .disks-detail .detail-item .title-name.pool-name,
.storage-initSetting .no-use-disks li:hover .disks-detail .detail-item .title-name.pool-name,
.storage-modal .no-use-disks li:hover .disks-detail .detail-item .title-name.pool-name {
  width: 70px;
}
.storage-initSetting .data li:hover .disks-item,
.storage-modal .data li:hover .disks-item,
.storage-initSetting .caching li:hover .disks-item,
.storage-modal .caching li:hover .disks-item,
.storage-initSetting .no-use-disks li:hover .disks-item,
.storage-modal .no-use-disks li:hover .disks-item {
  border: 3px solid #51a3ff;
}
.storage-initSetting .data li:hover .disks-item .disk-hhd-bg i,
.storage-modal .data li:hover .disks-item .disk-hhd-bg i,
.storage-initSetting .caching li:hover .disks-item .disk-hhd-bg i,
.storage-modal .caching li:hover .disks-item .disk-hhd-bg i,
.storage-initSetting .no-use-disks li:hover .disks-item .disk-hhd-bg i,
.storage-modal .no-use-disks li:hover .disks-item .disk-hhd-bg i,
.storage-initSetting .data li:hover .disks-item .disk-ssd-bg i,
.storage-modal .data li:hover .disks-item .disk-ssd-bg i,
.storage-initSetting .caching li:hover .disks-item .disk-ssd-bg i,
.storage-modal .caching li:hover .disks-item .disk-ssd-bg i,
.storage-initSetting .no-use-disks li:hover .disks-item .disk-ssd-bg i,
.storage-modal .no-use-disks li:hover .disks-item .disk-ssd-bg i {
  margin-top: 0px;
}
.storage-initSetting .data li:hover .disks-item .disk-name,
.storage-modal .data li:hover .disks-item .disk-name,
.storage-initSetting .caching li:hover .disks-item .disk-name,
.storage-modal .caching li:hover .disks-item .disk-name,
.storage-initSetting .no-use-disks li:hover .disks-item .disk-name,
.storage-modal .no-use-disks li:hover .disks-item .disk-name {
  display: block;
  line-height: 24px;
}
.storage-initSetting .fieid-box,
.storage-modal .fieid-box {
  line-height: 32px;
  height: 32px;
}
.storage-initSetting .fieid-box .disks-title,
.storage-modal .fieid-box .disks-title {
  float: left;
}
.storage-initSetting .fieid-box ul,
.storage-modal .fieid-box ul {
  float: left;
  display: inline-block;
  padding: 0 10px;
}
.storage-initSetting .fieid-box ul:first-child,
.storage-modal .fieid-box ul:first-child {
  padding-left: 0;
}
.storage-initSetting .fieid-box ul:last-child,
.storage-modal .fieid-box ul:last-child {
  border: none;
}
.storage-initSetting .fieid-box ul li,
.storage-modal .fieid-box ul li {
  float: left;
  padding: 0 10px;
}
.storage-initSetting .fieid-box ul li > i,
.storage-modal .fieid-box ul li > i {
  margin-right: 8px;
  color: #51a3ff;
}
.storage-initSetting .fieid-box ul li > i.ssd,
.storage-modal .fieid-box ul li > i.ssd {
  color: #466d74;
}
.storage-initSetting .nic-out,
.storage-modal .nic-out {
  display: none;
  overflow: hidden;
  padding: 10px 25px;
  background: #fff;
}
.storage-initSetting .net-tip,
.storage-modal .net-tip {
  height: 38px;
  border: 1px solid #b7cef1;
  border-radius: 4px;
  background: #ebf1fa;
  color: #51a3ff;
  text-align: center;
  line-height: 38px;
  margin-bottom: 32px;
}
.storage-initSetting .net-tip i,
.storage-modal .net-tip i {
  font-size: 16px;
  color: #404b5f;
}
.storage-initSetting .add-node,
.storage-modal .add-node {
  text-align: center;
}
.storage-initSetting .net-show,
.storage-modal .net-show {
  max-height: 320px;
  overflow-y: scroll;
}
.storage-initSetting .net-banner,
.storage-modal .net-banner {
  height: 191px;
  background: url(/img/host_card.png) no-repeat center bottom;
  background-size: contain;
  margin: 0 65px 45px;
}
.storage-initSetting .nodeNameShow,
.storage-modal .nodeNameShow {
  font-size: 16px;
}
.storage-initSetting .nics-choose .nic-out.nic1000,
.storage-modal .nics-choose .nic-out.nic1000 {
  display: block;
}
.storage-initSetting .nics-choose .nic-out.nic1000 .nic-bg i,
.storage-modal .nics-choose .nic-out.nic1000 .nic-bg i {
  color: #fff;
  font-size: 28px;
  line-height: 42px;
  text-align: center;
}
.storage-initSetting .nics-choose .nic-bg,
.storage-modal .nics-choose .nic-bg {
  background: #51a3ff;
}
.storage-initSetting .nics-choose .edit .addr,
.storage-modal .nics-choose .edit .addr {
  cursor: move;
}
.storage-initSetting .nics-choose .edit .addr:hover,
.storage-modal .nics-choose .edit .addr:hover {
  cursor: move;
}
.storage-initSetting .nics-choose,
.storage-modal .nics-choose {
  overflow: hidden;
  margin: 20px 0 20px;
}
.storage-initSetting .nics-choose li,
.storage-modal .nics-choose li {
  width: 32%;
  float: left;
  padding: 0 10px;
  margin-bottom: 20px;
}
.storage-initSetting .nics-choose li .text,
.storage-modal .nics-choose li .text {
  margin-bottom: 10px;
  color: #666;
  font-size: 16px;
  position: relative;
}
.storage-initSetting .nics-choose li .text .net-remove,
.storage-modal .nics-choose li .text .net-remove {
  position: absolute;
  right: -7px;
  top: 22px;
  background: #51a3ff;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  color: #fff;
  border-radius: 50%;
  font-size: 12px;
  cursor: pointer;
}
.storage-initSetting .nics-choose .addr,
.storage-modal .nics-choose .addr {
  background: #f0f3f5;
  border: 1px dashed #cfd9e3;
  padding: 4px;
}
.storage-initSetting .nics-choose .addr .nic-bg,
.storage-modal .nics-choose .addr .nic-bg {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  float: left;
  margin: 5px 0;
  text-align: center;
}
.storage-initSetting .nics-choose .addr .nic-info,
.storage-modal .nics-choose .addr .nic-info {
  float: left;
  margin-left: 25px;
  text-align: left;
  border-radius: 4px;
}
.storage-initSetting .nics-choose .addr .text-tip,
.storage-modal .nics-choose .addr .text-tip {
  display: inline-block;
  width: 100%;
  line-height: 72px;
  text-align: center;
  color: #d4dbe4;
  font-size: 16px;
}
.storage-initSetting .nics-choose .addr .text-tip.hide,
.storage-modal .nics-choose .addr .text-tip.hide {
  display: none;
}
.storage-initSetting .nics-choose .addr:hover,
.storage-modal .nics-choose .addr:hover {
  cursor: default;
}
.storage-initSetting .nics-choose .addr .nic-name,
.storage-modal .nics-choose .addr .nic-name {
  color: #313949;
  font-size: 16px;
  display: block;
  width: 75px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.storage-initSetting .nics-choose .addr .nic-out,
.storage-modal .nics-choose .addr .nic-out {
  padding: 10px 20px;
}
.storage-modal .node-config .storage-ipconfig-form .storage-row .storage-rgw-type .controls {
  width: 75%;
}
.storage-modal .node-config .storage-ipconfig-form .storage-row .storage-rgw-type .control-label {
  width: 10%;
}
.storage-modal .node-config .storage-ipconfig-form .storage-row .add-group .control-group {
  float: left;
  width: 50%;
}
.storage-modal .node-config .storage-ipconfig-form .storage-row .add-group .control-group .control-label {
  width: 19%;
}
.node-config .vlan-tips {
  line-height: 32px;
  color: #e74c3c;
}
.node-config .storage-ipconfig {
  padding-bottom: 10px;
  margin-bottom: 25px;
  border-bottom: 1px solid #e5e5e5;
}
.node-config .storage-ipconfig-form > .control-group {
  margin-bottom: 20px;
}
.node-config .storage-ipconfig-form > .control-group > .control-label {
  width: 10%;
  text-align: left;
  font-weight: bold;
}
.node-config .storage-ipconfig-form > .control-group > .controls {
  padding-left: 0;
  width: 90%;
}
.node-config .storage-ipconfig-form input.form-control {
  width: 140px;
}
.node-config .storage-ipconfig-form .control-group .controls .checkbox {
  width: 20%;
}
.node-config .storage-ipconfig-form .storage-row {
  float: left;
  width: 80%;
}
.node-config .storage-ipconfig-form .storage-row .control-group {
  margin-bottom: 0;
}
.node-config .storage-ipconfig-form .storage-row .control-label {
  text-align: left;
  width: 12%;
}
.node-config .storage-ipconfig-form .storage-row .controls {
  width: 88%;
}
.node-config .storage-ipconfig-form .storage-row .add-group .control-group {
  float: left;
  width: 50%;
}
.node-config .storage-ipconfig-form .storage-row .add-group .control-group .control-label {
  width: 24%;
}
.node-config .storage-ipconfig-form .storage-row .add-group .control-group .controls {
  width: 76%;
}
.node-config .storage-ipconfig-form .storage-row .storage-rgw-type .controls {
  width: 84%;
}
.node-config .storage-ipconfig-form .storage-row .val {
  line-height: 20px;
  font-size: 12px;
  color: #999;
}
.k8s-modal .add-label a {
  float: left;
  line-height: 32px;
  color: #666666;
}
.k8s-modal .add-label a i {
  color: #51a3ff;
}
.k8s-modal .modal-header {
  padding: 0px !important;
  margin-bottom: 0px !important;
  height: 70px !important;
}
.k8s-modal .lg-modal {
  width: 730px !important;
  padding: 0px;
}
.k8s-modal .lg-modal .steps-item li.step-start:after {
  width: 100px !important;
}
.k8s-modal .lg-modal .steps-item li.step-end:after {
  width: 100px !important;
}
.k8s-modal .lg-modal .steps-item .active i {
  background: #51a3ff !important;
}
.k8s-modal .cluster-first {
  height: 310px;
  overflow-y: auto;
}
.k8s-modal .cluster-next {
  margin-top: 20px;
  height: 600px;
  overflow-y: auto;
}
.k8s-modal .cluster-next .controls a {
  color: #666666;
}
.k8s-modal .cluster-next .controls a i {
  color: #51a3ff;
}
.k8s-modal .cluster-next .deleteNotOne {
  margin-left: 10px;
}
.k8s-modal .labels-group {
  position: relative;
  margin-bottom: 0;
}
.k8s-modal .labels-group .label-name {
  margin-left: 9%;
  float: left;
}
.k8s-modal .labels-group .add:hover {
  cursor: pointer;
}
.k8s-modal .labels-group .hiden:hover {
  cursor: pointer;
}
.k8s-modal .labels-group .hide-labels {
  width: 30%;
  float: right;
}
.k8s-modal .labels-group .left {
  margin-left: 7%;
  width: 43%;
  float: left;
}
.k8s-modal .labels-group .right {
  width: 43%;
  float: left;
}
.k8s-modal .labels-group .delete {
  position: absolute;
  right: 0px;
  font-size: 20px;
}
.k8s-modal .labels-group .delete i {
  color: red;
  font-size: 14px;
}
.k8s-modal .labels-group .delete .icon-aw-add-to2 {
  color: #51a3ff;
}
.k8s-modal .labels-group .delete:hover {
  cursor: pointer;
}
.k8s-modal .node-selectors-group {
  position: relative;
  margin-bottom: 0;
}
.k8s-modal .node-selectors-group .label-name {
  margin-left: 9%;
  width: 20%;
  float: left;
  margin-bottom: 10px;
}
.k8s-modal .node-selectors-group .error-msg {
  margin-left: 9%;
  float: left;
  margin-bottom: 10px;
}
.k8s-modal .node-selectors-group .select-key {
  width: 26%;
  float: left;
  margin-left: -7%;
  margin-bottom: 10px;
}
.k8s-modal .node-selectors-group .select-key .error {
  float: left;
  color: red;
  font-size: 12px;
}
.k8s-modal .node-selectors-group .select-key .controls {
  width: 65%;
}
.k8s-modal .node-selectors-group .select-key .controls.two .form-controls {
  width: 49%;
  float: left;
}
.k8s-modal .node-selectors-group .select-key .controls.two .form-controls:first-child {
  margin-right: 2%;
}
.k8s-modal .node-selectors-group .select-key .controls.two-select .select {
  width: 16%;
  float: left;
  margin-right: 2%;
}
.k8s-modal .node-selectors-group .select-key .controls.two-select > .form-controls {
  width: 82%;
  float: left;
}
.k8s-modal .node-selectors-group .select-key .controls .form-controls {
  width: 100%;
}
.k8s-modal .node-selectors-group .select-key .controls .btn-tips {
  position: absolute;
  right: -30px;
  top: 0;
  cursor: pointer;
  font-size: 20px;
}
.k8s-modal .node-selectors-group .select-type {
  width: 16%;
  float: left;
  margin-left: 2%;
  margin-bottom: 10px;
}
.k8s-modal .node-selectors-group .select-type .controls {
  width: 65%;
}
.k8s-modal .node-selectors-group .select-type .controls.two .form-controls {
  width: 49%;
  float: left;
}
.k8s-modal .node-selectors-group .select-type .controls.two .form-controls:first-child {
  margin-right: 2%;
}
.k8s-modal .node-selectors-group .select-type .controls.two-select .select {
  width: 16%;
  float: left;
  margin-right: 2%;
}
.k8s-modal .node-selectors-group .select-type .controls.two-select > .form-controls {
  width: 82%;
  float: left;
}
.k8s-modal .node-selectors-group .select-type .controls .form-controls {
  width: 100%;
}
.k8s-modal .node-selectors-group .select-type .controls .btn-tips {
  position: absolute;
  right: -30px;
  top: 0;
  cursor: pointer;
  font-size: 20px;
}
.k8s-modal .node-selectors-group .select-value {
  width: 26%;
  float: left;
  margin-left: 2%;
  margin-bottom: 10px;
}
.k8s-modal .node-selectors-group .select-value .error {
  float: left;
  color: red;
  font-size: 12px;
}
.k8s-modal .node-selectors-group .select-value .controls {
  width: 65%;
}
.k8s-modal .node-selectors-group .select-value .controls.two .form-controls {
  width: 49%;
  float: left;
}
.k8s-modal .node-selectors-group .select-value .controls.two .form-controls:first-child {
  margin-right: 2%;
}
.k8s-modal .node-selectors-group .select-value .controls.two-select .select {
  width: 16%;
  float: left;
  margin-right: 2%;
}
.k8s-modal .node-selectors-group .select-value .controls.two-select > .form-controls {
  width: 82%;
  float: left;
}
.k8s-modal .node-selectors-group .select-value .controls .form-controls {
  width: 100%;
}
.k8s-modal .node-selectors-group .select-value .controls .btn-tips {
  position: absolute;
  right: -30px;
  top: 0;
  cursor: pointer;
  font-size: 20px;
}
.k8s-modal .node-selectors-group .delete {
  position: absolute;
  right: 0px;
  font-size: 20px;
}
.k8s-modal .node-selectors-group .delete i {
  color: red;
  font-size: 14px;
}
.k8s-modal .node-selectors-group .delete .icon-aw-add-to2 {
  color: #51a3ff;
}
.k8s-modal .node-selectors-group .delete:hover {
  cursor: pointer;
}
.k8s-modal .container-group {
  position: relative;
  margin-top: 2px;
  margin-bottom: 10px;
  margin-left: 6%;
  margin-right: 6%;
  text-align: left;
}
.k8s-modal .container-group .container-label {
  margin-left: 3%;
  float: left;
}
.k8s-modal .container-group .delete {
  margin-right: 3%;
}
.k8s-modal .container-group .delete i {
  color: red;
}
.k8s-modal .container-group .delete .icon-aw-add-to2 {
  color: #51a3ff;
}
.k8s-modal .container-group .delete:hover {
  cursor: pointer;
}
.k8s-modal .container-group .container-detail {
  position: relative;
  margin-left: 4%;
  margin-right: 4%;
  width: auto;
  overflow: hidden;
}
.k8s-modal .container-group .container-detail .controls {
  line-height: 32px;
}
.k8s-modal .container-group .container-detail .container-image {
  margin-right: -2.5%;
  margin-bottom: -15px;
}
.k8s-modal .container-group .container-detail .container-image .image-labels {
  margin-bottom: 0;
}
.k8s-modal .container-group .container-detail .container-image .image-control {
  position: relative;
}
.k8s-modal .container-group .container-detail .container-image .image-control .left {
  width: 43%;
  float: left;
  margin-left: 18%;
  margin-bottom: 10px;
}
.k8s-modal .container-group .container-detail .container-image .image-control .left .image-label {
  float: left;
  text-align: right;
  font-size: 14px;
  font-weight: normal;
  line-height: 32px;
}
.k8s-modal .container-group .container-detail .container-image .image-control .left .controls {
  width: 65%;
}
.k8s-modal .container-group .container-detail .container-image .image-control .left .controls.two .form-controls {
  width: 49%;
  float: left;
}
.k8s-modal .container-group .container-detail .container-image .image-control .left .controls.two .form-controls:first-child {
  margin-right: 2%;
}
.k8s-modal .container-group .container-detail .container-image .image-control .left .controls.two-select .select {
  width: 16%;
  float: left;
  margin-right: 2%;
}
.k8s-modal .container-group .container-detail .container-image .image-control .left .controls.two-select > .form-controls {
  width: 82%;
  float: left;
}
.k8s-modal .container-group .container-detail .container-image .image-control .left .controls .form-controls {
  width: 100%;
}
.k8s-modal .container-group .container-detail .container-image .image-control .left .controls .btn-tips {
  position: absolute;
  right: -30px;
  top: 0;
  cursor: pointer;
  font-size: 20px;
}
.k8s-modal .container-group .container-detail .container-image .image-control .right {
  width: 39%;
  float: left;
  margin-bottom: 10px;
}
.k8s-modal .container-group .container-detail .container-image .image-control .right .image-label {
  float: left;
  text-align: right;
  font-size: 14px;
  font-weight: normal;
  line-height: 32px;
}
.k8s-modal .container-group .container-detail .container-image .image-control .right .controls {
  width: 65%;
}
.k8s-modal .container-group .container-detail .container-image .image-control .right .controls.two .form-controls {
  width: 49%;
  float: left;
}
.k8s-modal .container-group .container-detail .container-image .image-control .right .controls.two .form-controls:first-child {
  margin-right: 2%;
}
.k8s-modal .container-group .container-detail .container-image .image-control .right .controls.two-select .select {
  width: 16%;
  float: left;
  margin-right: 2%;
}
.k8s-modal .container-group .container-detail .container-image .image-control .right .controls.two-select > .form-controls {
  width: 82%;
  float: left;
}
.k8s-modal .container-group .container-detail .container-image .image-control .right .controls .form-controls {
  width: 100%;
}
.k8s-modal .container-group .container-detail .container-image .image-control .right .controls .btn-tips {
  position: absolute;
  right: -30px;
  top: 0;
  cursor: pointer;
  font-size: 20px;
}
.k8s-modal .container-group .container-detail .container-cmds {
  margin-bottom: 10px;
}
.k8s-modal .container-group .container-detail .container-cmds .controls {
  text-align: left;
}
.k8s-modal .container-group .container-detail .container-cmds .cmd-name {
  margin-left: 6%;
  float: left;
}
.k8s-modal .container-group .container-detail .container-cmds .add-cmds {
  width: 25%;
  float: left;
}
.k8s-modal .container-group .container-detail .container-cmds .add:hover {
  cursor: pointer;
}
.k8s-modal .container-group .container-detail .container-cmds .hiden:hover {
  cursor: pointer;
}
.k8s-modal .container-group .container-detail .container-cmds .hide-cmds {
  width: 35%;
  float: right;
}
.k8s-modal .container-group .container-detail .container-cmds .cmd-control {
  margin-left: 13%;
  line-height: 32px;
  position: relative;
  margin-bottom: 10px;
}
.k8s-modal .container-group .container-detail .container-cmds .cmd-control .env-label {
  float: left;
  width: 21.1%;
  margin-bottom: 0;
  text-align: right;
  color: #666666;
  font-size: 14px;
  font-weight: normal;
  padding-right: 5px;
}
.k8s-modal .container-group .container-detail .container-cmds .cmd-control .env-name {
  width: 70%;
  border-radius: 2px;
  outline: none;
}
.k8s-modal .container-group .container-detail .container-cmds .cmd-control .delete {
  margin-left: 2%;
  margin-right: 5%;
}
.k8s-modal .container-group .container-detail .container-cmds .cmd-control .delete i {
  font-size: 14px;
  color: red;
}
.k8s-modal .container-group .container-detail .container-cmds .cmd-control .delete .icon-aw-add-to2 {
  color: #51a3ff;
}
.k8s-modal .container-group .container-detail .container-cmds .cmd-control .delete:hover {
  cursor: pointer;
}
.k8s-modal .container-group .container-detail .container-cmds .error {
  margin-left: 31%;
  color: #e74c3c;
  font-size: 12px;
}
.k8s-modal .container-group .container-detail .container-envs {
  margin-bottom: 10px;
}
.k8s-modal .container-group .container-detail .container-envs .controls {
  text-align: left;
}
.k8s-modal .container-group .container-detail .container-envs .env-name {
  margin-left: 6%;
  float: left;
}
.k8s-modal .container-group .container-detail .container-envs .add-envs {
  float: left;
}
.k8s-modal .container-group .container-detail .container-envs .add:hover {
  cursor: pointer;
}
.k8s-modal .container-group .container-detail .container-envs .hiden:hover {
  cursor: pointer;
}
.k8s-modal .container-group .container-detail .container-envs .hide-envs {
  width: 35%;
  float: right;
}
.k8s-modal .container-group .container-detail .container-envs .env-control {
  position: relative;
}
.k8s-modal .container-group .container-detail .container-envs .env-control .left {
  width: 37%;
  float: left;
  margin-left: 19%;
}
.k8s-modal .container-group .container-detail .container-envs .env-control .left .env-name-label {
  float: left;
  text-align: right;
  color: #666666;
  font-size: 14px;
  font-weight: normal;
  line-height: 32px;
}
.k8s-modal .container-group .container-detail .container-envs .env-control .left .env-name {
  width: 80%;
  margin-left: 5px;
  border-radius: 2px;
  outline: none;
}
.k8s-modal .container-group .container-detail .container-envs .env-control .right {
  width: 37%;
  float: left;
  margin-left: 2%;
}
.k8s-modal .container-group .container-detail .container-envs .env-control .right .env-value-label {
  float: left;
  text-align: right;
  color: #666666;
  font-size: 14px;
  font-weight: normal;
  padding-right: 5px;
  line-height: 32px;
}
.k8s-modal .container-group .container-detail .container-envs .env-control .right .env-value {
  width: 75%;
  border-radius: 2px;
  outline: none;
}
.k8s-modal .container-group .container-detail .container-envs .env-control .delete {
  width: 5%;
  margin-right: 5px;
  line-height: 32px;
}
.k8s-modal .container-group .container-detail .container-envs .env-control .delete i {
  font-size: 14px;
  color: red;
}
.k8s-modal .container-group .container-detail .container-envs .env-control .delete .icon-aw-add-to2 {
  color: #51a3ff;
}
.k8s-modal .container-group .container-detail .container-envs .env-control .delete:hover {
  cursor: pointer;
}
.k8s-modal .container-group .container-detail .container-envs .error {
  margin-left: 31%;
  color: #e74c3c;
  font-size: 12px;
  width: 100%;
  float: left;
  margin-left: 25.5%;
}
.k8s-modal .container-group .container-detail .container-ports {
  text-align: left;
  margin-bottom: 10px;
}
.k8s-modal .container-group .container-detail .container-ports .controls {
  text-align: left;
}
.k8s-modal .container-group .container-detail .container-ports .port-labels {
  margin-left: 6%;
  float: left;
}
.k8s-modal .container-group .container-detail .container-ports .add-ports {
  width: 25%;
  float: left;
}
.k8s-modal .container-group .container-detail .container-ports .add:hover {
  cursor: pointer;
}
.k8s-modal .container-group .container-detail .container-ports .hiden:hover {
  cursor: pointer;
}
.k8s-modal .container-group .container-detail .container-ports .hide-ports {
  width: 35%;
  float: right;
}
.k8s-modal .container-group .container-detail .container-ports .ports-label {
  margin-left: 19%;
  margin-bottom: 10px;
  font-weight: normal;
}
.k8s-modal .container-group .container-detail .container-ports .delete {
  margin-right: 3%;
}
.k8s-modal .container-group .container-detail .container-ports .delete i {
  font-size: 14px;
  color: #e74c3c;
}
.k8s-modal .container-group .container-detail .container-ports .delete .icon-aw-add-to2 {
  color: #51a3ff;
}
.k8s-modal .container-group .container-detail .container-ports .delete:hover {
  cursor: pointer;
}
.k8s-modal .container-group .container-detail .container-ports .ports-control {
  position: relative;
}
.k8s-modal .container-group .container-detail .container-ports .ports-control .left {
  width: 41%;
  float: left;
  margin-left: 19%;
}
.k8s-modal .container-group .container-detail .container-ports .ports-control .left .ports-label-name {
  width: 30%;
  float: left;
  text-align: right;
  color: #666666;
  font-size: 14px;
  font-weight: normal;
  line-height: 32px;
}
.k8s-modal .container-group .container-detail .container-ports .ports-control .left .ports-label-value {
  width: 65%;
  border-radius: 2px;
  outline: none;
}
.k8s-modal .container-group .container-detail .container-ports .ports-control .right {
  width: 40%;
  float: left;
}
.k8s-modal .container-group .container-detail .container-ports .ports-control .right .ports-label-name {
  width: 30%;
  float: left;
  text-align: right;
  color: #666666;
  font-size: 14px;
  font-weight: normal;
  line-height: 32px;
}
.k8s-modal .container-group .container-detail .container-ports .ports-control .right .ports-label-value {
  width: 70%;
  border-radius: 2px;
  outline: none;
}
.k8s-modal .container-group .container-detail .container-ports .ports-control .right .controls {
  padding-left: 0px;
  width: 70%;
}
.k8s-modal .container-group .container-detail .container-ports .ports-control .right .controls.two .form-controls {
  width: 49%;
  float: left;
}
.k8s-modal .container-group .container-detail .container-ports .ports-control .right .controls.two .form-controls:first-child {
  margin-right: 2%;
}
.k8s-modal .container-group .container-detail .container-ports .ports-control .right .controls.two-select .select {
  width: 16%;
  float: left;
  margin-right: 2%;
}
.k8s-modal .container-group .container-detail .container-ports .ports-control .right .controls.two-select > .form-controls {
  width: 82%;
  float: left;
}
.k8s-modal .container-group .container-detail .container-ports .ports-control .right .controls .form-controls {
  width: 100%;
}
.k8s-modal .container-group .container-detail .container-ports .ports-control .right .controls .btn-tips {
  position: absolute;
  right: -30px;
  top: 0;
  cursor: pointer;
  font-size: 20px;
}
.k8s-modal .container-group .container-detail .container-ports .left-error {
  margin-left: 31%;
  color: #e74c3c;
  font-size: 12px;
}
.k8s-modal .container-group .container-detail .container-ports .left-ports-error {
  margin-left: 30%;
  color: #e74c3c;
  font-size: 12px;
}
.k8s-modal .container-group .container-detail .container-ports .right-ports-error {
  margin-left: 30%;
  color: #e74c3c;
  font-size: 12px;
}
.k8s-modal .container-group .container-detail .container-ports .right-error {
  margin-left: 60%;
  color: #e74c3c;
  font-size: 13px;
}
.k8s-modal .container-group .container-detail .container-volumeMounts {
  margin-bottom: 10px;
  text-align: left;
}
.k8s-modal .container-group .container-detail .container-volumeMounts .controls {
  text-align: left;
}
.k8s-modal .container-group .container-detail .container-volumeMounts .volumeMounts-label {
  margin-left: 6%;
  float: left;
}
.k8s-modal .container-group .container-detail .container-volumeMounts .add-volumeMounts {
  width: 25%;
  float: left;
}
.k8s-modal .container-group .container-detail .container-volumeMounts .hide-volumeMounts {
  width: 35%;
  float: right;
}
.k8s-modal .container-group .container-detail .container-volumeMounts .add:hover {
  cursor: pointer;
}
.k8s-modal .container-group .container-detail .container-volumeMounts .hiden:hover {
  cursor: pointer;
}
.k8s-modal .container-group .container-detail .container-volumeMounts .volumeMounts-index {
  margin-left: 19%;
  margin-bottom: 10px;
  font-weight: normal;
}
.k8s-modal .container-group .container-detail .container-volumeMounts .delete {
  margin-right: 3%;
}
.k8s-modal .container-group .container-detail .container-volumeMounts .delete i {
  font-size: 14px;
  color: red;
}
.k8s-modal .container-group .container-detail .container-volumeMounts .delete .icon-aw-add-to2 {
  color: #51a3ff;
}
.k8s-modal .container-group .container-detail .container-volumeMounts .delete:hover {
  cursor: pointer;
}
.k8s-modal .container-group .container-detail .container-volumeMounts .volumeMount-control {
  position: relative;
  margin-bottom: 10px;
}
.k8s-modal .container-group .container-detail .container-volumeMounts .volumeMount-control .left {
  width: 51%;
  float: left;
  margin-left: 19%;
}
.k8s-modal .container-group .container-detail .container-volumeMounts .volumeMount-control .left .volumeMount-name-label {
  float: left;
  text-align: right;
  font-size: 14px;
  font-weight: normal;
  line-height: 32px;
}
.k8s-modal .container-group .container-detail .container-volumeMounts .volumeMount-control .left .controls {
  width: 68%;
  padding-left: 0px;
}
.k8s-modal .container-group .container-detail .container-volumeMounts .volumeMount-control .left .controls.two .form-controls {
  width: 49%;
  float: left;
}
.k8s-modal .container-group .container-detail .container-volumeMounts .volumeMount-control .left .controls.two .form-controls:first-child {
  margin-right: 2%;
}
.k8s-modal .container-group .container-detail .container-volumeMounts .volumeMount-control .left .controls.two-select .select {
  width: 16%;
  float: left;
  margin-right: 2%;
}
.k8s-modal .container-group .container-detail .container-volumeMounts .volumeMount-control .left .controls.two-select > .form-controls {
  width: 82%;
  float: left;
}
.k8s-modal .container-group .container-detail .container-volumeMounts .volumeMount-control .left .controls .form-controls {
  width: 100%;
}
.k8s-modal .container-group .container-detail .container-volumeMounts .volumeMount-control .left .controls .btn-tips {
  position: absolute;
  right: -30px;
  top: 0;
  cursor: pointer;
  font-size: 20px;
}
.k8s-modal .container-group .container-detail .container-volumeMounts .volumeMount-control .right {
  width: 30%;
  float: left;
}
.k8s-modal .container-group .container-detail .container-volumeMounts .volumeMount-control .right .volumeMount-readOnly-label {
  float: left;
  text-align: right;
  font-size: 14px;
  font-weight: normal;
  line-height: 32px;
}
.k8s-modal .container-group .container-detail .container-volumeMounts .volumeMount-control .right .controls {
  width: 55%;
}
.k8s-modal .container-group .container-detail .container-volumeMounts .volumeMount-control .right .controls.two .form-controls {
  width: 43%;
  float: left;
}
.k8s-modal .container-group .container-detail .container-volumeMounts .volumeMount-control .right .controls.two .form-controls:first-child {
  margin-right: 2%;
}
.k8s-modal .container-group .container-detail .container-volumeMounts .volumeMount-control .right .controls.two-select .select {
  width: 16%;
  float: left;
  margin-right: 2%;
}
.k8s-modal .container-group .container-detail .container-volumeMounts .volumeMount-control .right .controls.two-select > .form-controls {
  width: 75%;
  float: left;
}
.k8s-modal .container-group .container-detail .container-volumeMounts .volumeMount-control .right .controls .form-controls {
  width: 90%;
}
.k8s-modal .container-group .container-detail .container-volumeMounts .volumeMount-control .right .controls .btn-tips {
  position: absolute;
  right: -30px;
  top: 0;
  cursor: pointer;
  font-size: 20px;
}
.k8s-modal .container-group .container-detail .container-volumeMounts .volumeMount-control .middle {
  float: left;
  margin-left: 19%;
  width: 86%;
}
.k8s-modal .container-group .container-detail .container-volumeMounts .volumeMount-control .middle .volumeMount-label-name {
  float: left;
  text-align: right;
  font-size: 14px;
  font-weight: normal;
  line-height: 32px;
}
.k8s-modal .container-group .container-detail .container-volumeMounts .volumeMount-control .middle .volumeMount-label-value {
  width: 76%;
  border-radius: 2px;
  outline: none;
}
.k8s-modal .container-group .container-detail .container-volumeMounts .left-error {
  margin-left: 15%;
  font-size: 12px;
  color: #e74c3c;
}
.k8s-modal .container-group .container-detail .container-resources {
  width: 100%;
  height: 32px;
}
.k8s-modal .container-group .container-detail .container-resources .resources-labels {
  margin-left: 6%;
  margin-bottom: 0;
  float: left;
}
.k8s-modal .container-group .container-detail .container-resources .hiden:hover {
  cursor: pointer;
}
.k8s-modal .container-group .container-detail .container-resources .hide-resources {
  width: 35%;
  float: right;
}
.k8s-modal .container-group .container-detail .container-resources .resource-control {
  position: relative;
}
.k8s-modal .container-group .container-detail .container-resources .resource-control .left {
  width: 40%;
  float: left;
  margin-left: 19%;
  margin-bottom: 10px;
}
.k8s-modal .container-group .container-detail .container-resources .resource-control .left .resource-label {
  width: 37%;
  float: left;
  text-align: right;
  font-size: 14px;
  font-weight: normal;
  line-height: 32px;
}
.k8s-modal .container-group .container-detail .container-resources .resource-control .left .resource-value {
  width: 60%;
  border-radius: 2px;
  outline: none;
}
.k8s-modal .container-group .container-detail .container-resources .resource-control .right {
  width: 38%;
  float: left;
  margin-bottom: 10px;
}
.k8s-modal .container-group .container-detail .container-resources .resource-control .right .resource-label {
  width: 40%;
  float: left;
  text-align: right;
  font-size: 14px;
  font-weight: normal;
  line-height: 32px;
}
.k8s-modal .container-group .container-detail .container-resources .resource-control .right .resource-value {
  width: 60%;
  border-radius: 2px;
  outline: none;
}
.k8s-modal .container-group .container-detail .container-resources .resource-control .left-error {
  width: 61%;
  float: left;
  margin-left: 37%;
  color: #e74c3c;
  font-size: 12px;
}
.k8s-modal .container-group .container-detail .container-resources .resource-control .right-error {
  width: 68%;
  float: left;
  margin-left: 40%;
  color: #e74c3c;
  font-size: 12px;
}
.k8s-modal .service-group {
  position: relative;
  margin-left: 5%;
}
.k8s-modal .service-group .service-ports {
  margin-bottom: 10px;
}
.k8s-modal .service-group .service-ports .port-index-label {
  position: relative;
  margin-bottom: 10px;
}
.k8s-modal .service-group .service-ports .ports-control .left {
  width: 45%;
  float: left;
  margin-left: 5%;
}
.k8s-modal .service-group .service-ports .ports-control .left .ports-label-name {
  float: left;
  text-align: right;
  font-size: 14px;
  font-weight: normal;
  border-radius: 2px;
}
.k8s-modal .service-group .service-ports .ports-control .left .ports-label-value {
  width: 72%;
  border-radius: 2px;
  outline: none;
}
.k8s-modal .service-group .service-ports .ports-control .right {
  width: 45%;
  float: left;
}
.k8s-modal .service-group .service-ports .ports-control .right .ports-label-name {
  float: left;
  text-align: right;
  font-size: 14px;
  font-weight: normal;
  border-radius: 2px;
}
.k8s-modal .service-group .service-ports .ports-control .right .ports-label-value {
  width: 72%;
  border-radius: 2px;
  outline: none;
}
.k8s-modal .service-group .service-ports .ports-control .full {
  width: 90%;
  float: left;
  margin-left: 5%;
}
.k8s-modal .service-group .service-ports .ports-control .full .ports-label-name {
  float: left;
  text-align: right;
  font-size: 14px;
  font-weight: normal;
}
.k8s-modal .service-group .service-ports .error {
  position: relative;
  margin-left: 16%;
  margin-bottom: 5px;
  color: red;
  font-size: 13px;
  line-height: 20px;
}
.k8s-modal .node-label-group {
  position: relative;
}
.k8s-modal .node-label-group .left {
  margin-left: 7%;
  width: 43%;
  float: left;
}
.k8s-modal .node-label-group .right {
  width: 43%;
  float: left;
}
.k8s-modal .node-label-group .add {
  position: absolute;
  right: 20px;
  font-size: 20px;
}
.k8s-modal .node-label-group .add i {
  font-size: 14px;
  color: #51a3ff;
}
.k8s-modal .node-label-group .add:hover {
  cursor: pointer;
}
.k8s-modal .node-label-group .delete {
  position: absolute;
  right: 0px;
  font-size: 20px;
}
.k8s-modal .node-label-group .delete i {
  font-size: 14px;
  color: red;
}
.k8s-modal .node-label-group .delete .icon-aw-add-to2 {
  color: #51a3ff;
}
.k8s-modal .node-label-group .delete:hover {
  cursor: pointer;
}
.k8s-modal .rolling-update-group {
  position: relative;
  width: auto;
}
.k8s-modal .rolling-update-group .container-image {
  margin-right: 0;
  margin-bottom: -15px;
}
.k8s-modal .rolling-update-group .container-image .image-labels {
  margin-bottom: 0;
}
.k8s-modal .rolling-update-group .container-image .image-control {
  position: relative;
}
.k8s-modal .rolling-update-group .container-image .image-control .left {
  width: 45%;
  float: left;
  margin-left: 9%;
  margin-bottom: 10px;
}
.k8s-modal .rolling-update-group .container-image .image-control .left .image-label {
  float: left;
  text-align: right;
  font-size: 14px;
  font-weight: normal;
}
.k8s-modal .rolling-update-group .container-image .image-control .left .controls {
  width: 68%;
  margin-left: 2%;
}
.k8s-modal .rolling-update-group .container-image .image-control .left .controls.two .form-controls {
  width: 49%;
  float: left;
}
.k8s-modal .rolling-update-group .container-image .image-control .left .controls.two .form-controls:first-child {
  margin-right: 2%;
}
.k8s-modal .rolling-update-group .container-image .image-control .left .controls.two-select .select {
  width: 16%;
  float: left;
  margin-right: 2%;
}
.k8s-modal .rolling-update-group .container-image .image-control .left .controls.two-select > .form-controls {
  width: 82%;
  float: left;
}
.k8s-modal .rolling-update-group .container-image .image-control .left .controls .form-controls {
  width: 100%;
}
.k8s-modal .rolling-update-group .container-image .image-control .left .controls .btn-tips {
  position: absolute;
  right: -30px;
  top: 0;
  cursor: pointer;
  font-size: 20px;
}
.k8s-modal .rolling-update-group .container-image .image-control .right {
  width: 45%;
  float: left;
  margin-bottom: 10px;
}
.k8s-modal .rolling-update-group .container-image .image-control .right .image-label {
  float: left;
  text-align: right;
  font-size: 14px;
  font-weight: normal;
}
.k8s-modal .rolling-update-group .container-image .image-control .right .controls {
  width: 68%;
  margin-left: 2%;
}
.k8s-modal .rolling-update-group .container-image .image-control .right .controls.two .form-controls {
  width: 49%;
  float: left;
}
.k8s-modal .rolling-update-group .container-image .image-control .right .controls.two .form-controls:first-child {
  margin-right: 2%;
}
.k8s-modal .rolling-update-group .container-image .image-control .right .controls.two-select .select {
  width: 16%;
  float: left;
  margin-right: 2%;
}
.k8s-modal .rolling-update-group .container-image .image-control .right .controls.two-select > .form-controls {
  width: 82%;
  float: left;
}
.k8s-modal .rolling-update-group .container-image .image-control .right .controls .form-controls {
  width: 100%;
}
.k8s-modal .rolling-update-group .container-image .image-control .right .controls .btn-tips {
  position: absolute;
  right: -30px;
  top: 0;
  cursor: pointer;
  font-size: 20px;
}
.container-monitor .echarts {
  height: 220px;
  width: 100%;
  min-height: 220px;
  float: left;
}
@media screen and (max-width: 1920px) {
  .control-group .controls > i.network-question-tip {
    left: 6px;
  }
}
@media screen and (max-width: 1680px) {
  .inspection-container {
    overflow-y: auto;
    max-height: 700px;
  }
  .inspection-box {
    overflow: hidden;
    padding: 15px 0;
  }
  .billing .control-group.top {
    top: 10px;
  }
  .page-inner {
    min-height: 775px;
  }
  .page-inner .TDsql-inner {
    width: 116%;
    height: 860px;
  }
  .page-inner {
    min-height: 775px;
  }
  .page-inner .TSF-inner {
    width: 116%;
    height: 860px;
  }
  .flowManage-box {
    height: 760px;
  }
  .tab-content .tab-pane > .ng-binding {
    max-height: 740px;
  }
  .animateContent .detail-info {
    max-height: 815px;
    overflow-y: auto;
  }
  .main .main-content .overview .box .content-rowtwo .tables tr td a {
    width: 50px;
  }
  .product-service > .service-title .service-dropdown-layer {
    width: 1680px;
  }
  .panels .pie-wrap.nova-pie {
    width: 45%;
  }
  .panels .pie-legend.category {
    width: 55%;
  }
  .panels .pie-legend.category .dl-horizontal dd b {
    display: none;
  }
  .panels .pie-legend.category .dl-horizontal dd > span {
    min-width: 25px;
  }
  .main-content .box .title .tips {
    display: block;
  }
  .permit .user-box .text-list .copy-li span > i.small {
    font-size: 50px;
  }
  .permit .user-box .text-list li em {
    padding: 0;
  }
  .permit .user-box .text-list li.small {
    font-size: 30px;
  }
  .detailInner .directive-action-wrap.table-action .control-group {
    width: 190px;
    margin-right: 10px;
  }
  .detailInner .directive-action-wrap.table-action .control-group .control-label {
    width: 80px;
  }
  .detailInner .directive-action-wrap.table-action .control-group .controls {
    width: 110px;
  }
  .detailInner .directive-action-wrap.table-action .control-group .controls .ui-select-container {
    width: 110px;
  }
  .detailInner .directive-action-wrap.table-action.price .control-group {
    width: 10%;
  }
  .detailInner .directive-action-wrap.table-action.price .control-group .controls {
    width: 100%;
  }
  .detailInner .directive-action-wrap.table-action .date-box .time-picker .form-control {
    width: 155px;
  }
  .detailInner .directive-action-wrap.table-action .date-box .time-picker .input-group > span {
    padding: 6px 10px;
  }
  .detailInner .directive-action-wrap.table-action .search-box {
    width: 110px;
  }
  .detailInner .directive-action-wrap.table-action .search-box .form-control {
    width: 110px;
  }
  .detailInner .tab-content .detail-info .pie-wrp .pie {
    width: 140px;
    height: 140px;
  }
  .detailInner .tab-content .detail-info .pie-legend .table tr th {
    padding: 10px 8px;
  }
  .detailInner .tab-content .detail-info .pie-legend .table tr td {
    padding: 0 8px;
  }
  .detailInner .tab-content .detail-info .pie-legend .table tr td .num {
    font-size: 16px;
  }
  .control-group .controls > i.network-question-tip {
    left: 6px;
  }
  header .service-dropdown-layer .sub-menu .menu-item {
    width: 170px;
  }
  .overview-single .box .border-space-right .space-content {
    min-height: 461px;
  }
  .overview-single .box .border-space-right .space-content .add-item {
    padding: 0 25px;
  }
  .table thead tr th {
    padding: 9px 15px;
    font-size: 12px;
  }
  .table tbody tr td {
    height: 48px;
    padding: 10px 16px;
  }
  .main .main-content .box div[class^="col-"] > .title {
    font-size: 14px;
  }
  .plugin-detail .log-wrap {
    height: 805px;
  }
  .zr-topo .animateContent.addCloudHost {
    width: 85%;
  }
  .zr-topo .animateContent {
    width: 40%;
  }
  .zr-topo .animateContent .detail-info dd .table tr td .lg {
    width: 150px;
  }
  .main .main-content .overview .box .inner .title span {
    font-size: 14px;
  }
  .table-responsive {
    overflow-x: auto;
  }
  .modal-open .modal .modal-dialog .initSetting-modal {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    left: 20%;
    top: 16%;
  }
  .modal-open .modal .modal-dialog .initConfig-modal {
    left: 14%;
    top: 16%;
  }
  .modal-open .modal .modal-dialog .createStorage-modal {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    left: 30%;
    top: 16%;
  }
  /**
    *项目管理 部门管理 详情
    **/
  .panels .pie-legend .dl-horizontal dt {
    width: 100px;
    padding: 0 10px 0 10px;
  }
  .panels .pie-legend .dl-horizontal dd {
    font-size: 16px;
  }
  .panels .pie-legend .dl-horizontal .default {
    font-size: 14px;
  }
  .resourcesInfo .panels .pie-legend .dl-horizontal dt {
    width: 60px;
  }
  .resourcesInfo .panels .pie-legend .dl-horizontal dd {
    font-size: 16px;
    margin-left: 60px;
  }
  .resourcesInfo .panels .pie-legend .dl-horizontal .default {
    font-size: 14px;
  }
}
@media screen and (max-width: 1440px) {
  .inspection-container {
    overflow-y: auto;
    max-height: 680px;
  }
  .inspection-box {
    overflow: hidden;
    padding: 10px 0;
  }
  .billing .control-group.top {
    top: 4px;
  }
  .recovery-box .animateContent .detailInner {
    height: 680px;
  }
  .shortcuts-ins-page {
    font-size: 12px;
  }
  .shortcuts-ins-page .shortcuts-ins-page-inner {
    width: 980px;
  }
  .shortcuts-ins-page .ins-header .region-dp {
    width: 980px;
  }
  .defined-ins .steps-sytle-ver li .inner {
    font-size: 12px;
  }
  .step-box {
    width: 900px;
  }
  header .service-dropdown-layer .sub-menu .menu-item {
    width: 150px;
  }
  .page-inner {
    min-height: 640px;
  }
  .flowManage-box {
    height: 660px;
  }
  .tab-content .tab-pane > .ng-binding {
    max-height: 600px;
  }
  .animateContent .detail-info {
    max-height: 550px;
    overflow-y: auto;
  }
  .animateContent.animateIn {
    min-height: 730px;
  }
  .form-inline .form-control.logs-key-word {
    margin-left: 10px;
  }
  .main aside .menu-title {
    padding: 0;
  }
  .main .main-content .overview .box .content-rowtwo .tables tr td a {
    width: 40px;
  }
  .modal-open .modal .modal-dialog .initSetting-modal {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    left: 14%;
    top: 16%;
  }
  .modal-open .modal .modal-dialog .initConfig-modal {
    left: 6%;
    top: 16%;
  }
  .modal-open .modal .modal-dialog .createStorage-modal {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    left: 24%;
    top: 16%;
  }
  .pie-center-message {
    font-size: 12px;
  }
  .billing .detailInner .detail-info .panels .pie-wrap .pie-center-message {
    left: 48%;
    top: 48%;
  }
  .billing .detailInner .detail-info .panels .pie-wrap .pie-center-message .block-min {
    display: block;
  }
  .product-service > .service-title .service-dropdown-layer {
    width: 1440px;
  }
  .animateContent {
    height: 600px;
  }
  .panels .pie-legend .dl-horizontal dd {
    font-size: 20px;
  }
  /**
     * 系统概况-日志信息
     */
  .logsInfo-wrap .cont .table tr td:nth-child(2) > span {
    max-width: 90px;
  }
  /**
     * 资源管理-部门配额/项目资源
     */
  .quotaInfo,
  .resourcesInfo {
    padding: 0 10px;
  }
  .quotaInfo ul li .total .quota-total {
    font-size: 24px;
    padding-top: 5px;
    min-height: 30px;
  }
  .quotaInfo ul li .total i {
    font-size: 40px;
  }
  .quotaInfo ul li .total .quotaNum {
    left: 50px;
  }
  /**
    * 项目管理 部门管理详情
    */
  .panels .pie-legend .dl-horizontal dt {
    width: 90px;
    padding: 0 10px 0 10px;
  }
  .panels .pie-legend .dl-horizontal dd {
    font-size: 16px;
  }
  .panels .pie-legend .dl-horizontal .default {
    font-size: 14px;
  }
  .main-content .layout-grid .col-3,
  .main-content .layout-grid .col-4 {
    width: 50%;
  }
  .main-content .layout-grid .col-3:nth-child(2n) .inner,
  .main-content .layout-grid .col-4:nth-child(2n) .inner {
    border-right: 0 none;
  }
  .main-content .layout-grid .col-4:nth-child(3n) .inner {
    border-right: solid 1px #c2d3eb;
  }
  .monitor-view .controls {
    font-size: 13px;
  }
  .billing .billing-box .tab-content .box .col-6 {
    min-height: 200px;
  }
  .main .main-content .overview-single .box .border-space-right .space-content {
    min-height: 0;
  }
  .main .main-content .overview-single .box .border-space-right .space-content .add-content {
    padding: 37px 0;
  }
  .main .main-content .overview-single .box .border-space-right .space-content .add-item {
    padding: 0;
  }
  .main .main-content .overview-single .box .border-space-right .space-content .add-item li {
    padding: 15px;
  }
  .main .main-content .overview-single .box .border-space-right .space-content .add-item li i {
    font-size: 80px;
  }
  .main .main-content .overview-single .box .border-space-right .space-content .add-item li a,
  .main .main-content .overview-single .box .border-space-right .space-content .add-item li span {
    font-size: 14px;
  }
  .table thead tr th {
    padding: 6px 14px;
  }
  .table tbody tr td {
    height: 45px;
  }
  .main-top-box {
    padding: 5px 0 4px;
    min-height: 42px;
  }
  .main-top-box .page-top-title {
    font-size: 16px;
    padding-top: 10px;
  }
  body {
    font-size: 12px;
  }
  .ui-select-container .ui-select-match .ui-select-toggle {
    font-size: 12px;
  }
  .ui-select-container .dropdown-menu {
    font-size: 12px;
  }
  .setting-show .nav.nav-tabs.top-nav {
    top: 54px;
  }
  .setting-show .billing .control-group.top {
    top: 60px;
  }
  .setting-show .page-inner .tab-offset-right > .nav-tabs {
    top: 63px;
  }
  .setting-show.setting-password .nav.nav-tabs.top-nav {
    top: 101px;
  }
  .setting-show.setting-password .billing .control-group.top {
    top: 105px;
  }
  .setting-show.setting-password .page-inner .tab-offset-right > .nav-tabs {
    top: 110px;
  }
  .zr-topo .animateContent {
    width: 45%;
  }
  ::-webkit-input-placeholder {
    /* WebKit browsers */
    font-size: 12px;
  }
  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    font-size: 12px;
  }
  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    font-size: 12px;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10+ */
    font-size: 12px;
  }
  .res-metering .control-groups .date-box > .control-group.min .controls .ui-select-container {
    width: 80px;
  }
}
@media screen and (max-width: 1366px) {
  body.animate-open {
    height: 100%;
    overflow: hidden;
  }
  .inspection-container {
    overflow-y: auto;
    max-height: 555px;
  }
  .inspection-box {
    overflow: hidden;
    padding: 8px 0;
  }
  .billing .control-group.top {
    top: 4px;
  }
  header .service-dropdown-layer .sub-menu .menu-item {
    width: 150px;
    padding: 0 10px;
  }
  .recovery-box .animateContent .detailInner {
    height: 538px;
  }
  .circle-blue,
  .circle-green,
  .circle-orange,
  .circle-waterblue {
    height: 64px;
    width: 64px;
  }
  .circle-blue i,
  .circle-green i,
  .circle-orange i,
  .circle-waterblue i {
    line-height: 56px;
    font-size: 30px;
  }
  .billing .detailInner .detail-info .control-box .control-group .controls {
    width: 110px;
  }
  .page-inner {
    min-height: 600px;
  }
  .page-inner .meihe-inner {
    width: 120%;
    height: 860px;
  }
  .flowManage-box {
    height: 660px;
  }
  .billing-box .animateContent .detail-info {
    max-height: 500px;
  }
  .animate-open .animateContent.animateIn {
    height: calc(100% - 70px);
    overflow-y: auto;
  }
  .animateContent.animateIn {
    height: 550px;
    min-height: 300px;
  }
  .animateContent .detail-info {
    min-height: 300px;
    max-height: 400px;
    overflow-y: auto;
  }
  .animateContent .detail-info.tab-offset-right {
    max-height: 480px;
  }
  .instance-create-ins .animateContent .detail-info {
    max-height: 490px;
  }
  .form-inline .form-control.logs-key-word {
    width: 150px;
    margin-left: 10px;
  }
  .tab-content .tab-pane > .ng-binding {
    max-height: 500px;
  }
  .main .main-content .overview .box .content-rowtwo .right-con .map-bg .nodes-type {
    padding-bottom: 40px;
  }
  .main .main-content .overview .box .content-rowtwo .right-con .map-bg .nodes-type li {
    width: 30%;
  }
  .main .main-content .overview .box .content-rowtwo .panels .pie-wrap .pie {
    width: 180px;
    height: 180px;
  }
  .main .main-content .overview .box .content-rowtwo .tables tr td a {
    width: 37px;
  }
  .main .main-content .overview .box .inner .title .region-tips li .region-name {
    display: none;
  }
  .main .main-content .overview .box .content-rowtwo .panels .pie-legend .dl-horizontal .pie-legend-value,
  .main .main-content .overview .box .content-rowtwo .panels .pie-legend .dl-horizontal .pie-legend-name {
    display: none;
  }
  .main .main-content .box div[class^="col-"] {
    min-height: 120px;
  }
  .product-service > .service-title .service-dropdown-layerr {
    width: 1366px;
  }
  .animateContent {
    height: 450px;
  }
  .main .main-content .menu-manage .dropdown-ul a {
    padding: 0 25px;
  }
  .main .main-content .menu-manage .nav > ul > li {
    padding: 0 5px;
  }
  .logsInfo-wrap .cont .table tr td:nth-child(2) > span {
    max-width: 70px;
  }
  .panels .pie-legend.category .dl-horizontal dd b {
    display: none;
  }
  .panels .pie-legend .dl-horizontal dt {
    width: 100px;
  }
  .panels .pie-legend .dl-horizontal dd {
    font-size: 16px;
  }
  .monitor-view .controls {
    font-size: 12px;
  }
  .alarmInfo-wrap .cont .table tr td,
  .workOrder-wrap .cont .table tr td,
  .logsInfo-wrap .cont .table tr td {
    padding: 10px 5px;
  }
  .table tbody tr td .btn {
    min-width: 54px;
  }
  .panels .pie-wrap {
    width: 130px;
    padding-top: 15px;
    margin-left: 15px;
  }
  .panels .pie-wrap .pie {
    width: 130px;
    height: 130px;
  }
  .project-pie-item .panels .pie-legend {
    margin-left: 0;
  }
  .panels .pie-legend {
    margin-left: 15px;
    width: auto;
  }
  .panels .pie-legend .dl-horizontal dt {
    padding: 0;
  }
  .res-vm-view .panels .pie-wrap {
    width: 50%;
    padding-top: 0;
    margin-left: 0;
  }
  .res-vm-view .panels .pie-wrap .pie {
    width: 160px;
    height: 160px;
  }
  .billing .tab-content .pie-legend .table tr td i {
    border-radius: 50%;
  }
  .billing .detailInner .detail-info .panels .pie-wrap {
    width: 35%;
  }
  .billing .detailInner .detail-info .panels .pie-wrap .pie {
    width: 110px;
    height: 110px;
    margin: -60px 0 0 -60px;
  }
  .billing .detailInner .detail-info .panels .pie-wrap .pie-center {
    top: 90%;
    left: 48%;
  }
  .billing .detailInner .detail-info .panels .pie-legend {
    width: 65%;
    padding: 0 30px 0 0;
  }
  .directive-action-wrap.table-action .control-group {
    width: 190px;
    margin-right: 20px;
  }
  .directive-action-wrap.table-action .control-group .control-label {
    width: 80px;
  }
  .directive-action-wrap.table-action .control-group .controls {
    width: 110px;
  }
  .directive-action-wrap.table-action .control-group .controls .ui-select-container {
    width: 110px;
  }
  .directive-action-wrap.table-action.price .control-group {
    width: 10%;
  }
  .directive-action-wrap.table-action.price .control-group .controls {
    width: 100%;
  }
  .directive-action-wrap.table-action .date-box .time-picker .form-control {
    width: 155px;
  }
  .directive-action-wrap.table-action .search-box {
    width: 135px;
  }
  .directive-action-wrap.table-action .search-box .form-control {
    width: 135px;
  }
  .main .main-content .overview-single .box .title .vlan-title {
    display: inline-block;
    overflow: hidden;
  }
  .main .main-content .overview-single .box .title .total-num,
  .main .main-content .overview-single .box .title .avail-num,
  .main .main-content .overview-single .box .title .used-num {
    margin-left: 10px;
    display: inline-block;
    width: 80px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .main .main-content .overview-single .box .title .total-num i,
  .main .main-content .overview-single .box .title .avail-num i,
  .main .main-content .overview-single .box .title .used-num i {
    font-size: 14px;
  }
  .main .main-content .overview-single .box .panels .bar-wrap ul li .progress {
    width: 52%;
  }
  .main .main-content .overview-single .box .border-space-right .space-content {
    min-height: 0;
  }
  .main .main-content .overview-single .box .border-space-right .space-content .add-content {
    padding: 38px 0;
    height: 462px;
  }
  .service-dropdown-layer .sub-menu {
    max-height: 580px;
  }
  .control-group .controls .checkbox.switch-btn label i,
  .control-group .controls .checkbox.switch-btn label .iconfont {
    height: 24px;
  }
  .control-group .controls .checkbox.switch-btn label input + i:before,
  .control-group .controls .checkbox.switch-btn label input + .iconfont:before {
    width: 18px;
    height: 18px;
  }
  .billing .control-group.top .controls .switch-btn span {
    height: 24px;
    line-height: 24px;
  }
  .zr-topo .animateContent {
    width: 45%;
  }
  .zr-topo .animateContent.animateIn dt {
    width: 150px;
  }
  .zr-topo .animateContent.animateIn dd {
    margin-left: 150px;
  }
  .monitor-view .disk > .control-items3 .control-group.path .controls .ui-select-container,
  .monitor-view .disk > .control-items3 .control-group.diskio .controls .ui-select-container {
    width: 140px;
  }
  .monitor-view .disk > .control-items3 .control-group.diskio .control-label {
    text-align: center;
  }
  .res-metering .control-groups .date-box > .control-group.min .controls .ui-select-container {
    width: 80px;
  }
  .value-decr-num {
    font-size: 32px;
  }
}
@media screen and (max-width: 1280px) {
  .inspection-container {
    overflow-y: auto;
    max-height: 505px;
  }
  .inspection-box {
    overflow: hidden;
    padding: 5px 0;
  }
  .billing .control-group.top {
    top: 4px;
  }
  .page-inner {
    min-height: 495px;
  }
  .page-inner .meihe-inner {
    width: 122%;
    height: 860px;
  }
  .ins-table-action .dropdown-menu {
    min-width: 130px;
  }
  .ins-table-action .search-box {
    width: 145px;
  }
  .ins-table-action .btn.dropdown-toggle {
    padding: 6px 8px 6px 8px;
  }
  .animateContent .detail-info {
    min-height: 300px;
    max-height: 400px;
    overflow-y: auto;
  }
  .animateContent.animateIn {
    min-height: 550px;
  }
  .modal-open .modal .modal-dialog .initSetting-modal {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    left: 10%;
    top: 16%;
  }
  .modal-open .modal .modal-dialog .initConfig-modal {
    left: 2%;
    top: 16%;
  }
  .modal-open .modal .modal-dialog .createStorage-modal {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    left: 20%;
    top: 16%;
  }
  .main .main-content .overview .box .content-rowtwo .panels .pie-wrap .pie {
    width: 150px;
    height: 150px;
  }
  .main .main-content .overview .box .content-rowtwo .tables tr td a {
    width: 35px;
  }
  .zr-topo .animateContent .detail-info dd .table tr td .lg {
    width: 100px;
  }
  .monitor-view .disk > .control-items3 .control-group.path,
  .monitor-view .disk > .control-items3 .control-group.diskio {
    padding: 0 30px;
  }
  .monitor-view .disk > .control-items3 .control-group.path .control-label,
  .monitor-view .disk > .control-items3 .control-group.diskio .control-label {
    width: 80px;
  }
  .monitor-view .disk > .control-items3 .control-group.path .controls .ui-select-container,
  .monitor-view .disk > .control-items3 .control-group.diskio .controls .ui-select-container {
    width: 140px;
  }
  .monitor-view .disk > .control-items3 .control-group.diskio .control-label {
    text-align: center;
  }
  .res-metering .control-groups .date-box > .control-group .controls {
    padding-left: 0;
  }
  .res-metering .control-groups .date-box > .control-group .controls .ui-select-container {
    width: 103px;
  }
  .res-metering .control-groups .date-box > .control-group.min .controls .ui-select-container {
    width: 60px;
  }
  .res-metering .control-groups .date-box > .control-group.min .controls .ui-select-container:last-child {
    width: 70px;
  }
  .value-decr-num {
    font-size: 32px;
  }
}
/*网卡拖动*/
.main-init .nics-list,
.main-init .disks-list {
  margin: 0 0 10px;
  background: #eff1f3;
  border: 1px dashed #c3cfdb;
  border-radius: 3px;
  position: relative;
}
.main-init .nics-list li {
  float: left;
  width: 20%;
  padding: 10px 20px;
}
.main-init .nics-list .addr {
  color: #313949;
}
.main-init .no-use {
  display: block;
  font-size: 16px;
  text-align: center;
  min-height: 92px;
  max-height: 184px;
  overflow-y: scroll;
}
.main-init .disks-warning {
  color: #ff9b11;
}
.main-init .localdisk,
.main-init .data,
.main-init .caching,
.main-init .no-use-disks {
  display: block;
  font-size: 16px;
  text-align: center;
  min-height: 92px;
}
.main-init .localdisk li,
.main-init .data li,
.main-init .caching li,
.main-init .no-use-disks li {
  float: left;
  width: 8%;
  padding: 13px 13px;
  position: relative;
}
.main-init .localdisk li .disks-detail,
.main-init .data li .disks-detail,
.main-init .caching li .disks-detail,
.main-init .no-use-disks li .disks-detail {
  display: none;
  width: 200px;
  top: 85px;
  position: absolute;
  background: #fff;
  padding: 10px;
  border: 1px solid #c3cfdb;
  z-index: 200;
}
.main-init .localdisk li .disks-detail .detail-item,
.main-init .data li .disks-detail .detail-item,
.main-init .caching li .disks-detail .detail-item,
.main-init .no-use-disks li .disks-detail .detail-item {
  width: 100%;
  font-size: 14px;
}
.main-init .localdisk li .disks-detail .detail-item span,
.main-init .data li .disks-detail .detail-item span,
.main-init .caching li .disks-detail .detail-item span,
.main-init .no-use-disks li .disks-detail .detail-item span {
  display: inline-block;
}
.main-init .localdisk li .disks-detail .detail-item .title,
.main-init .data li .disks-detail .detail-item .title,
.main-init .caching li .disks-detail .detail-item .title,
.main-init .no-use-disks li .disks-detail .detail-item .title {
  width: 40%;
  float: left;
  text-align: right;
  color: #7991ab;
}
.main-init .localdisk li .disks-detail i,
.main-init .data li .disks-detail i,
.main-init .caching li .disks-detail i,
.main-init .no-use-disks li .disks-detail i {
  position: absolute;
  top: -5px;
  width: 10px;
  height: 10px;
  left: 28px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  border-top: 1px solid #c3cfdb;
  border-left: 1px solid #c3cfdb;
  background: #fff;
}
.main-init .localdisk li .disks-item,
.main-init .data li .disks-item,
.main-init .caching li .disks-item,
.main-init .no-use-disks li .disks-item {
  height: 64px;
  width: 69px;
  position: relative;
  background: #fff;
  cursor: pointer;
  border: 1px solid #c3cfdb;
}
.main-init .localdisk li .disks-item .disks-remove,
.main-init .data li .disks-item .disks-remove,
.main-init .caching li .disks-item .disks-remove,
.main-init .no-use-disks li .disks-item .disks-remove {
  position: absolute;
  right: -8px;
  top: -8px;
  background: #51a3ff;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  color: #fff;
  border-radius: 50%;
  font-size: 12px;
  cursor: pointer;
}
.main-init .localdisk li .disks-item .disk-out,
.main-init .data li .disks-item .disk-out,
.main-init .caching li .disks-item .disk-out,
.main-init .no-use-disks li .disks-item .disk-out {
  height: 100%;
  text-align: center;
}
.main-init .localdisk li .disks-item .disk-out .disk-hhd-bg,
.main-init .data li .disks-item .disk-out .disk-hhd-bg,
.main-init .caching li .disks-item .disk-out .disk-hhd-bg,
.main-init .no-use-disks li .disks-item .disk-out .disk-hhd-bg,
.main-init .localdisk li .disks-item .disk-out .disk-ssd-bg,
.main-init .data li .disks-item .disk-out .disk-ssd-bg,
.main-init .caching li .disks-item .disk-out .disk-ssd-bg,
.main-init .no-use-disks li .disks-item .disk-out .disk-ssd-bg {
  display: block;
  height: 36px;
  width: 100%;
  font-size: 22px;
}
.main-init .localdisk li .disks-item .disk-out .disk-hhd-bg i,
.main-init .data li .disks-item .disk-out .disk-hhd-bg i,
.main-init .caching li .disks-item .disk-out .disk-hhd-bg i,
.main-init .no-use-disks li .disks-item .disk-out .disk-hhd-bg i,
.main-init .localdisk li .disks-item .disk-out .disk-ssd-bg i,
.main-init .data li .disks-item .disk-out .disk-ssd-bg i,
.main-init .caching li .disks-item .disk-out .disk-ssd-bg i,
.main-init .no-use-disks li .disks-item .disk-out .disk-ssd-bg i {
  height: 100%;
  width: 100%;
  margin-top: 2px;
  display: inline-block;
}
.main-init .localdisk li .disks-item .disk-out .disk-hhd-bg,
.main-init .data li .disks-item .disk-out .disk-hhd-bg,
.main-init .caching li .disks-item .disk-out .disk-hhd-bg,
.main-init .no-use-disks li .disks-item .disk-out .disk-hhd-bg {
  color: #51a3ff;
}
.main-init .localdisk li .disks-item .disk-out .disk-ssd-bg,
.main-init .data li .disks-item .disk-out .disk-ssd-bg,
.main-init .caching li .disks-item .disk-out .disk-ssd-bg,
.main-init .no-use-disks li .disks-item .disk-out .disk-ssd-bg {
  color: #4a6583;
}
.main-init .localdisk li:hover .disks-detail,
.main-init .data li:hover .disks-detail,
.main-init .caching li:hover .disks-detail,
.main-init .no-use-disks li:hover .disks-detail {
  display: block;
}
.main-init .localdisk li:hover .disks-item,
.main-init .data li:hover .disks-item,
.main-init .caching li:hover .disks-item,
.main-init .no-use-disks li:hover .disks-item {
  border: 3px solid #51a3ff;
}
.main-init .localdisk li:hover .disks-item .disk-hhd-bg i,
.main-init .data li:hover .disks-item .disk-hhd-bg i,
.main-init .caching li:hover .disks-item .disk-hhd-bg i,
.main-init .no-use-disks li:hover .disks-item .disk-hhd-bg i,
.main-init .localdisk li:hover .disks-item .disk-ssd-bg i,
.main-init .data li:hover .disks-item .disk-ssd-bg i,
.main-init .caching li:hover .disks-item .disk-ssd-bg i,
.main-init .no-use-disks li:hover .disks-item .disk-ssd-bg i {
  margin-top: 0px;
}
.main-init .localdisk li:hover .disks-item .disk-name,
.main-init .data li:hover .disks-item .disk-name,
.main-init .caching li:hover .disks-item .disk-name,
.main-init .no-use-disks li:hover .disks-item .disk-name {
  display: block;
  line-height: 24px;
}
.main-init .add-groups {
  cursor: pointer;
  color: #51a3ff;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
}
.main-init .add-groups .data-tip {
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  color: #f00;
}
.main-init .config-title {
  font-size: 14px;
  margin: 10px 0;
}
.main-init .disks-content {
  max-height: 430px;
  padding-bottom: 150px;
}
.main-init .disks-content .group-content {
  border-bottom: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
  background: #fafafa;
  padding: 20px 10px;
}
.main-init .disks-content .group-content .caching-content,
.main-init .disks-content .group-content .data-content {
  float: left;
}
.main-init .disks-content .group-content .caching-content .name-item,
.main-init .disks-content .group-content .data-content .name-item {
  margin-left: 18px;
  width: 100%;
}
.main-init .disks-content .group-content .caching-content .name-item .name-type,
.main-init .disks-content .group-content .data-content .name-item .name-type {
  float: left;
  line-height: 32px;
}
.main-init .disks-content .group-content .caching-content .name-item .type-item,
.main-init .disks-content .group-content .data-content .name-item .type-item {
  width: 180px;
  float: left;
}
.main-init .disks-content .group-content .caching-content .name-item .type-item .form-control,
.main-init .disks-content .group-content .data-content .name-item .type-item .form-control {
  height: 32px;
}
.main-init .disks-content .group-content .caching-content .name-item .type-item .text-muted,
.main-init .disks-content .group-content .data-content .name-item .type-item .text-muted {
  margin-left: 15px;
}
.main-init .disks-content .group-content .caching-content .data .tips,
.main-init .disks-content .group-content .data-content .data .tips,
.main-init .disks-content .group-content .caching-content .caching .tips,
.main-init .disks-content .group-content .data-content .caching .tips {
  line-height: 90px;
  color: #c9c9c9;
}
.main-init .disks-content .group-content .caching-content .data li,
.main-init .disks-content .group-content .data-content .data li,
.main-init .disks-content .group-content .caching-content .caching li,
.main-init .disks-content .group-content .data-content .caching li {
  width: 8%;
}
.main-init .disks-content .group-content .caching-content .data .disks-item,
.main-init .disks-content .group-content .data-content .data .disks-item,
.main-init .disks-content .group-content .caching-content .caching .disks-item,
.main-init .disks-content .group-content .data-content .caching .disks-item {
  cursor: default;
}
.main-init .disks-content .group-content .data-content {
  width: 100%;
}
.main-init .disks-content .disks-group .group-name {
  position: relative;
}
.main-init .disks-content .disks-group .group-name .delete-group {
  position: absolute;
  top: -8px;
  right: 12px;
  font-size: 25px;
}
.main-init .disks-content .disks-group .group-name .group-cache {
  display: inline-block;
  margin: 0 15px;
}
.main-init .disks-content .disks-group .scale {
  cursor: pointer;
  height: 62px;
  text-align: center;
  color: #fff;
}
.main-init .disks-content .disks-group .scale .scale-num,
.main-init .disks-content .disks-group .scale .name {
  display: block;
  line-height: 16px;
  font-style: normal;
  white-space: nowrap;
}
.main-init .disks-content .disks-group .scale .no-disk {
  display: block;
  width: 100%;
  height: 100%;
  padding: 16px 0;
  background: #aeaeae;
}
.main-init .disks-content .disks-group .scale .data-disk {
  overflow: hidden;
  float: left;
  padding: 16px 0;
  display: inline-block;
  height: 100%;
  width: 60%;
  background: #16a085;
}
.main-init .fieid-box {
  line-height: 32px;
  height: 32px;
}
.main-init .fieid-box .disks-title {
  float: left;
}
.main-init .fieid-box ul {
  float: left;
  display: inline-block;
  padding: 0 10px;
}
.main-init .fieid-box ul:first-child {
  padding-left: 0;
}
.main-init .fieid-box ul:last-child {
  border: none;
}
.main-init .fieid-box ul li {
  float: left;
  padding: 0 10px;
}
.main-init .fieid-box ul li > i {
  margin-right: 10px;
  color: #51a3ff;
}
.main-init .fieid-box ul li > i.ssd {
  color: #466d74;
}
.main-init .net-tip {
  height: 38px;
  border: 1px solid #b7cef1;
  border-radius: 4px;
  background: #ebf1fa;
  color: #51a3ff;
  text-align: center;
  line-height: 38px;
  margin-bottom: 32px;
}
.main-init .net-tip i {
  font-size: 16px;
  color: #404b5f;
}
.main-init .no-use .nic-bg {
  background: #e5e5e5;
}
.main-init .nic-out.nic10,
.main-init .nic-out.nic100,
.main-init .nic-out.nic1000,
.main-init .nic-out.nic10000 {
  display: block;
}
.main-init .no-use .nic-out.nic10 .nic-bg i,
.main-init .no-use .nic-out.nic100 .nic-bg i,
.main-init .no-use .nic-out.nic1000 .nic-bg i,
.main-init .no-use .nic-out.nic10000 .nic-bg i {
  font-size: 28px;
  line-height: 42px;
  text-align: center;
  color: #4a6583;
}
.main-init .addr:hover {
  cursor: move;
}
.main-init .nics-choose {
  overflow: hidden;
  margin: 0 0 20px;
}
.main-init .nics-choose li {
  width: 20%;
  float: left;
  padding: 0 10px;
}
.main-init .nics-choose li .text {
  margin-bottom: 10px;
  color: #666;
  font-size: 16px;
  position: relative;
}
.main-init .nics-choose li .text .net-remove {
  position: absolute;
  right: -7px;
  top: 22px;
  background: #51a3ff;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  color: #fff;
  border-radius: 50%;
  font-size: 12px;
  cursor: pointer;
}
.main-init .nics-choose .addr {
  background: #f0f3f5;
  border: 1px dashed #cfd9e3;
  padding: 4px;
}
.main-init .nics-choose .addr .text-tip {
  display: inline-block;
  width: 100%;
  line-height: 72px;
  text-align: center;
  color: #d4dbe4;
  font-size: 16px;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.main-init .nics-choose .addr .text-tip.hide {
  display: none;
}
.main-init .nics-choose .addr:hover {
  cursor: default;
}
.main-init .nics-choose .addr .nic-name {
  color: #51a3ff;
}
.main-init .nics-choose .addr .nic-out {
  padding: 10px 20px;
}
.main-init .bond-member .nic-out.nic10 .nic-bg i,
.main-init .bond-member .nic-out.nic100 .nic-bg i,
.main-init .bond-member .nic-out.nic1000 .nic-bg i,
.main-init .bond-member .nic-out.nic10000 .nic-bg i,
.main-init .nics-choose .nic-out.nic10 .nic-bg i,
.main-init .nics-choose .nic-out.nic100 .nic-bg i,
.main-init .nics-choose .nic-out.nic1000 .nic-bg i,
.main-init .nics-choose .nic-out.nic10000 .nic-bg i {
  color: #fff;
  font-size: 28px;
  line-height: 42px;
  text-align: center;
}
.main-init .bond-member .nic-bg,
.main-init .nics-choose .nic-bg {
  background: #51a3ff;
}
.main-init .addr {
  color: #313949;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  transition: all 0.3s linear;
  min-height: 72px;
}
.main-init .addr .nic-bg {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  float: left;
  margin: 5px 0;
  text-align: center;
}
.main-init .addr .nic-info {
  float: left;
  margin-left: 25px;
  text-align: left;
  border-radius: 4px;
}
.main-init .nic-out {
  display: none;
  overflow: hidden;
  padding: 10px 25px;
  background: #fff;
}
.main-init .addr .nic-name {
  color: #313949;
  font-size: 16px;
  display: block;
  width: 75px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.main-init .addr .nic-speed {
  color: #9ea3b4;
  font-size: 14px;
  display: block;
}
.main-init .nics-choose .edit .addr {
  cursor: move;
}
.main-init .nics-choose .edit .addr:hover {
  cursor: move;
}
.main-init .addr .nic_a,
.main-init .addr .nic_b {
  background: url(/img/nic_1000.png) no-repeat center top;
}
.main-init .addr .nic_c {
  background: url(/img/nic_10000.png) no-repeat center top;
}
.main-init .edit-cas,
.main-init .edit-ceph,
.main-init .eth-sdn {
  display: inline-block;
  margin: 0 20px 15px 0;
}
.main-init .btn-item {
  text-align: center;
}
.main-init .nics-bonds {
  margin-bottom: 100px;
}
.main-init .nic-error {
  color: #f00;
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: -25px;
}
.main-init .bond-member li {
  width: 20%;
  float: left;
  padding: 0 20px;
}
.main-init .bond-member .addr:hover {
  cursor: default;
}
.main-init .bond-merged .bond {
  width: 20%;
  float: left;
  padding: 0 25px;
  text-align: center;
  position: relative;
}
.main-init .bond-merged .bond .bond-detail {
  display: none;
  border: 1px solid #ccc;
  position: absolute;
  right: -190px;
  top: 0;
  font-size: 14px;
  padding: 10px;
  background: #fff;
  z-index: 100;
}
.main-init .bond-merged .bond .bond-detail .point-left {
  display: block;
  width: 0;
  height: 0;
  border: 10px solid #fff;
  border-width: 8px 8px 8px 0;
  border-style: solid;
  border-color: transparent #ccc transparent transparent;
  position: absolute;
  left: -8px;
  top: 45%;
}
.main-init .bond-merged .bond .bond-detail li {
  width: 180px;
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
}
.main-init .bond-merged .bond .bond-detail li .icon-aw-wrong {
  float: right;
}
.main-init .bond-merged .bond .bond-detail li span {
  display: inline-block;
}
.main-init .bond-merged .bond .bond-detail li .card-name {
  color: #999;
  width: 35%;
}
.main-init .bond-merged .bond .bond-detail li .card-speed {
  width: 45%;
}
.main-init .bond-merged .bond .bond-detail li:last-child {
  border: 0;
}
.main-init .bond-merged .bond:hover .bond-desc {
  background-color: #51a3ff;
}
.main-init .bond-merged .bond:hover .bond-desc .text,
.main-init .bond-merged .bond:hover .bond-desc .num {
  color: #fff;
}
.main-init .bond-merged .bond:hover .bond-detail {
  display: block;
}
.main-init .bond-merged .bond-desc {
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  transition: all 0.3s linear;
  position: relative;
  background: #fff;
  height: 120px;
  border: 1px solid #a2cdfd;
  border-radius: 5px;
  margin-bottom: 15px;
  padding: 0px 0 22px;
}
.main-init .bond-merged .new-bond-inner i {
  font-size: 38px;
  display: inline-block;
  margin-bottom: 10px;
}
.main-init .bond-merged .new-bond-inner {
  height: 120px;
  background: #f4f8fb;
  border: 1px dashed #cfd9e3;
  border-radius: 5px;
  color: #d7dadc;
}
.main-init .bond-merged .new-bond .text {
  display: block;
  width: 104px;
  margin: 0 auto;
  font-size: 12px;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.main-init .bond-merged .active .bond-desc {
  background: #51a3ff;
  border: 1px solid #51a3ff;
}
.main-init .bond-merged .active .bond-desc .num {
  color: #fff;
}
.main-init .bond-merged .active .bond-desc .text {
  color: #fff;
}
.main-init .bond-merged .bond-inner p {
  position: relative;
  color: #666;
  font-size: 16px;
}
.main-init .bond-merged .bond-inner i {
  position: absolute;
  right: 5px;
  top: 0;
  cursor: pointer;
  color: #c3cfdb;
}
.main-init .tool-tips {
  color: #f00;
  text-align: center;
}
.main-init .tool-tips.tool-success {
  color: #1abc9c;
}
.main-init .bond-merged .ui-select-match,
.main-init .bond-merged .ui-select-search {
  height: 34px;
  line-height: 34px;
}
.main-init .bond-merged .bond-desc .num {
  font-size: 34px;
  font-weight: normal;
  color: #51a3ff;
  display: block;
}
.main-init .bond-merged .bond-desc .text {
  font-size: 14px;
  color: #7991ab;
  display: block;
}
.main-init .bond-merged .bond-desc .select2-box {
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 100%;
  height: 28px;
}
